import { CSSProperties, useContext, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { getScaledCanvasSize, getTopLeft } from "../utils";
import { AnnotationContext, CanvasContext } from "../provider";
import { useBoxListener, useEdgeListener } from "../hooks";
import { IAnnotation } from "../api";

const EdgeDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: green;
  position: absolute;
  touch-action: none;
  border-radius: 50%;
`;

const EdgeDotTopLeft = styled(EdgeDot)`
  top: -5px;
  left: -5px;
`;

const EdgeDotTopRight = styled(EdgeDot)`
  top: -5px;
  right: -5px;
`;

const EdgeDotBottomLeft = styled(EdgeDot)`
  bottom: -5px;
  left: -5px;
`;

const EdgeDotBottomRight = styled(EdgeDot)`
  bottom: -5px;
  right: -5px;
`;

type Props = {
  annotation: IAnnotation;
  canvasWidth: number;
};

export default function CanvasBox({ canvasWidth, annotation }: Props) {
  // Compute information to place the box properly
  const boxRef = useRef<HTMLDivElement | null>(null);
  const topLeftRef = useRef<HTMLDivElement | null>(null);
  const topRightRef = useRef<HTMLDivElement | null>(null);
  const bottomLeftRef = useRef<HTMLDivElement | null>(null);
  const bottomRightRef = useRef<HTMLDivElement | null>(null);
  const { matrix, selectedAnnotation } = useContext(CanvasContext);
  const { deleteAnnotation } = useContext(AnnotationContext);
  const topLeft = getTopLeft(matrix);
  const size = getScaledCanvasSize(canvasWidth, matrix);

  useEffect(() => {
    // Add event listener for shortcut keys
    if (selectedAnnotation !== annotation.id) return;
    // Listen for delete key
    const handleDelete = (e: KeyboardEvent) => {
      if (!selectedAnnotation) return;
      if (e.key === "Delete" || e.key === "Backspace") {
        deleteAnnotation(annotation.id);
      }
    };
    window.addEventListener("keydown", handleDelete);
    return () => window.removeEventListener("keydown", handleDelete);
  }, [selectedAnnotation]);

  useBoxListener({ boxRef, currentBox: annotation, canvasWidth });
  useEdgeListener({
    boxRef: topLeftRef,
    currentBox: annotation,
    canvasWidth,
    edge: "topLeft",
  });
  useEdgeListener({
    boxRef: topRightRef,
    currentBox: annotation,
    canvasWidth,
    edge: "topRight",
  });
  useEdgeListener({
    boxRef: bottomLeftRef,
    currentBox: annotation,
    canvasWidth,
    edge: "bottomLeft",
  });
  useEdgeListener({
    boxRef: bottomRightRef,
    currentBox: annotation,
    canvasWidth,
    edge: "bottomRight",
  });

  const sharedStyle: CSSProperties = {
    borderWidth: 2,
    position: "absolute",
    touchAction: "none",
    zIndex: 10,
    pointerEvents: selectedAnnotation === annotation.id ? "auto" : "none",
  };

  if (selectedAnnotation === annotation.id) {
    return (
      <div
        style={{
          left: topLeft.x + annotation.x * size.w - 2,
          top: topLeft.y + annotation.y * size.h - 2,
          width: annotation.w * size.w + 4,
          height: annotation.h * size.h + 4,
          borderStyle: "dashed",
          borderColor: "black",
          ...sharedStyle,
        }}
      >
        <EdgeDotBottomLeft ref={bottomLeftRef} />
        <EdgeDotBottomRight ref={bottomRightRef} />
        <EdgeDotTopLeft ref={topLeftRef} />
        <EdgeDotTopRight ref={topRightRef} />
        <div
          ref={boxRef}
          style={{
            margin: 2,
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: "green",
            height: "calc( 100% - 8px )",
          }}
        />
      </div>
    );
  }
  return (
    <div
      ref={boxRef}
      style={{
        left: topLeft.x + annotation.x * size.w,
        top: topLeft.y + annotation.y * size.h,
        width: annotation.w * size.w,
        height: annotation.h * size.h,
        borderStyle: "solid",
        borderColor: "green",
        ...sharedStyle,
      }}
    />
  );
}
