import { useContext } from "react";
import { CheckCircle, HelpOutline } from "@mui/icons-material";
import { AnnotationContext, CanvasContext } from "../provider";
import { ISpecialAnnotation } from "../provider/AnnotationProvider/context";

type Props = {
  annotation: ISpecialAnnotation;
};

export default function NoneDefectBoxButton({ annotation }: Props) {
  const { setAnnotations, annotations } = useContext(AnnotationContext);
  const { mode, modeOptions } = useContext(CanvasContext);

  const toggleHasNoDefect = () => {
    const newAnnotations = annotations.map((a) => {
      if (a.id === annotation.id) {
        return { ...a, objectHasNoDefect: !a.objectHasNoDefect };
      }
      return a;
    });
    setAnnotations(newAnnotations);
  };

  if (mode !== modeOptions.NONE_DEFECT_VERIFICATION) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        right: "6px",
        bottom: "2px",
        cursor: "pointer",
        pointerEvents: "all",
        color: annotation.objectHasNoDefect ? "#0A0" : "#FA4",
      }}
      onMouseUp={toggleHasNoDefect}
    >
      {annotation.objectHasNoDefect ? (
        <CheckCircle
          style={{
            fontSize: "14px",
          }}
        />
      ) : (
        <HelpOutline
          style={{
            fontSize: "14px",
          }}
        />
      )}
    </div>
  );
}
