import { connect } from "react-redux";
import { RootState } from "state/store";
import Component from "./PoleGroupCatcher";

interface IStateProps {}

interface IOwnProps {}

interface IDispatchProps {}

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
