import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getWorkflowFilter(
  searchParams: URLSearchParams
): FilterType["WORKFLOW"] {
  const workflowParams = searchParams.get(FilterKey.WORKFLOW);
  return workflowParams ? workflowParams.split(",").map(Number) : [];
}

export function useWorkflowFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const workflowFilter = getWorkflowFilter(searchParams);

  function setWorkflowFilter(workflows: number[]) {
    if (workflows.length === 0) {
      if (searchParams.has(FilterKey.WORKFLOW))
        searchParams.delete(FilterKey.WORKFLOW);
    } else {
      searchParams.set(FilterKey.WORKFLOW, workflows.join(","));
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  }
  return { workflowFilter, setWorkflowFilter };
}
