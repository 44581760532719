import { Box, DialogContentText, Stack, Typography } from "@mui/material";
import SessionStoragePopupWindow from "components/SessionStoragePopupWindow";
import React from "react";

type Props = {};

export default function PopupManager({}: Props) {
  return (
    <>
      <SessionStoragePopupWindow storageKey="arkion-rebrand" maxWidth="md">
        <Stack direction="column" spacing={2}>
          <img
            src="/logo_center_400px.png"
            style={{
              alignSelf: "center",
            }}
          />
          <Typography variant="h4" component="h2">
            The company you knew as Skyqraft is now Arkion
          </Typography>
          <DialogContentText>
            When we launched as Skyqraft in 2019, we were a drone and AI
            startup. Since then, we have evolved to a data insights company that
            provides actionable insights in more areas than just drones.
            <br />
            <b>June 4th 2024</b> we rebranded from <b>Skyqraft to Arkion</b>. We
            are the same company, with the same team, the same values and the
            same goal of making preventative inspections more efficient and
            sustainable.
          </DialogContentText>
          <DialogContentText>
            Read more about the rebrand on our website{" "}
            <a href="https://www.arkion.co/resources/the-company-you-knew-as-skyqraft-is-now-arkion">
              arkion.co
            </a>
            .
          </DialogContentText>
          <DialogContentText>
            If you get emails from <i>arkion.co</i>, don't worry, it's us.
          </DialogContentText>
          <DialogContentText>
            We will still support our old domain <i>skyqraft.com</i> until we
            can be sure that everything is working as it should.
          </DialogContentText>
        </Stack>
      </SessionStoragePopupWindow>
    </>
  );
}
