import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { initialState, RootState } from "./reducers";
import thunk from "redux-thunk";

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export type { RootState };
export { initialState };
export default store;
