import { useContext } from "react";
import {
  Button,
  Dialog,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";

import { IGanttItem, context } from "../provider";
import { Clear, Edit, EditOff } from "@mui/icons-material";

type Props = {
  open: boolean;
  item?: IGanttItem;
  onClose: () => void;
};

export default function EditTask({ open, item, onClose }: Props) {
  const { deleteItem, setEditing, editing, updateItem } = useContext(context);

  if (!item) {
    return null;
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <Stack
        minWidth={400}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        px={3}
        py={2}
      >
        <Typography fontSize="large" fontWeight="bold">
          Gantt Task
        </Typography>
        <Stack direction="row">
          <IconButton onClick={() => setEditing(!editing)}>
            {editing ? <EditOff /> : <Edit />}
          </IconButton>
          <IconButton onClick={onClose}>
            <Clear />
          </IconButton>
        </Stack>
      </Stack>
      {editing ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target as HTMLFormElement);
            const name = formData.get("name") as string;
            const start_date = formData.get("start_date") as string;
            const end_date = formData.get("end_date") as string;
            const newName = name === item.name ? undefined : name;
            const newStartDate =
              new Date(start_date) === new Date(item.start_date)
                ? undefined
                : new Date(start_date).toISOString().substring(0, 10);
            const newEndDate =
              new Date(end_date) === new Date(item.end_date)
                ? undefined
                : new Date(end_date).toISOString().substring(0, 10);
            updateItem(
              item.id,
              newName,
              newStartDate,
              newEndDate,
              item.display_order
            );
            onClose();
          }}
        >
          <Stack spacing={1} px={3} pb={2}>
            <FormControl fullWidth sx={{ m: 0 }} variant="standard">
              <InputLabel htmlFor="task_name" shrink>
                Task name
              </InputLabel>
              <Input
                id="task_name"
                type="text"
                name="name"
                defaultValue={item.name}
              />
            </FormControl>

            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="start_date_input" shrink>
                Start date
              </InputLabel>
              <Input
                id="start_date_input"
                type="date"
                name="start_date"
                defaultValue={new Date(item.start_date).toLocaleDateString()}
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="end_date_input" shrink>
                End date
              </InputLabel>
              <Input
                id="end_date_input"
                type="date"
                name="end_date"
                defaultValue={new Date(item.end_date).toLocaleDateString()}
              />
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            px={3}
            py={2}
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              disabled={!editing}
              color="error"
              onClick={() => {
                deleteItem(item);
                onClose();
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              disabled={!editing}
              color="info"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!editing}
              color="success"
              type="submit"
            >
              Save
            </Button>
          </Stack>
        </form>
      ) : (
        <Stack spacing={1} px={3} pb={2}>
          <Typography>Task name: {item.name}</Typography>
          <Typography>
            Start Date: {new Date(item.start_date).toLocaleDateString()}
          </Typography>
          <Typography>
            End Date: {new Date(item.end_date).toLocaleDateString()}
          </Typography>
        </Stack>
      )}
    </Dialog>
  );
}
