import Button from "@mui/material/Button";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import translations from "translations";
import { useLanguage } from "hooks";
import { useParams } from "react-router-dom";
import {
  getPoleSummaryReport,
  getFeedBayReport,
  getSteelGradingReport,
} from "state/actions";
import { useDispatch, useSelector } from "hooks";
import { useFlags } from "launchdarkly-react-client-sdk";

export default function AssetReport() {
  const dispatch = useDispatch();
  const { language } = useLanguage();
  const params = useParams();
  const demoMode = useSelector((state) => state.ui.demoMode);
  const projectID = parseInt(params.mission);
  const project = useSelector((state) =>
    state.user.missions.find((mission) => mission.id === projectID)
  );
  const { steelworkAnnotationTool } = useFlags();

  return (
    <Stack sx={{ m: 2 }} spacing={1}>
      <Typography variant="h5" component="h1">
        {translations.Menu.Tools.Reports.AssetReport.Download[language]}
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack flex={1} justifyContent="space-between" height="100%">
            <Stack spacing={1}>
              <Typography variant="h6" component="h2">
                {
                  translations.Menu.Tools.Reports.AssetReport.FeedBayReport[
                    language
                  ]
                }
              </Typography>
              <Typography>
                {
                  translations.Menu.Tools.Reports.AssetReport.FeedBayReport
                    .Description1[language]
                }
              </Typography>
              <Typography>
                {
                  translations.Menu.Tools.Reports.AssetReport.FeedBayReport
                    .Description2[language]
                }
              </Typography>
              <Typography>
                {
                  translations.Menu.Tools.Reports.AssetReport.FeedBayReport
                    .Description3[language]
                }
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} mt={2}>
              <Button
                onClick={() =>
                  dispatch(getFeedBayReport(project, "excel", demoMode))
                }
                variant="contained"
                className="primaryButton"
              >
                Excel
              </Button>
              <Button
                onClick={() =>
                  dispatch(getFeedBayReport(project, "csv", demoMode))
                }
                variant="contained"
                className="secondaryButton"
              >
                CSV
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack flex={1} justifyContent="space-between" height="100%">
            <Stack spacing={1}>
              <Typography variant="h6" component="h2">
                {
                  translations.Menu.Tools.Reports.AssetReport.PoleReport[
                    language
                  ]
                }
              </Typography>
              <Typography>
                {
                  translations.Menu.Tools.Reports.AssetReport.PoleReport
                    .Description1[language]
                }
              </Typography>
              <Typography>
                {
                  translations.Menu.Tools.Reports.AssetReport.PoleReport
                    .Description2[language]
                }
              </Typography>
              <Typography>
                {
                  translations.Menu.Tools.Reports.AssetReport.PoleReport
                    .Description3[language]
                }
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} mt={2}>
              <Button
                onClick={() => dispatch(getPoleSummaryReport(project, "excel"))}
                variant="contained"
                className="primaryButton"
              >
                Excel
              </Button>
              <Button
                onClick={() => dispatch(getPoleSummaryReport(project, "csv"))}
                variant="contained"
                className="secondaryButton"
              >
                CSV
              </Button>
            </Stack>
          </Stack>
        </Grid>
        {steelworkAnnotationTool && (
          <Grid item xs={12} md={6}>
            <Stack flex={1} justifyContent="space-between" height="100%">
              <Stack spacing={1}>
                <Typography variant="h6" component="h2">
                  {
                    translations.Menu.Tools.Reports.AssetReport
                      .SteelGradingReport[language]
                  }
                </Typography>
                <Typography>
                  {
                    translations.Menu.Tools.Reports.AssetReport
                      .SteelGradingReport.Description[language]
                  }
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} mt={2}>
                <Button
                  onClick={() =>
                    dispatch(getSteelGradingReport(project, "excel"))
                  }
                  variant="contained"
                  className="primaryButton"
                >
                  Excel
                </Button>
                <Button
                  onClick={() =>
                    dispatch(getSteelGradingReport(project, "csv"))
                  }
                  variant="contained"
                  className="secondaryButton"
                >
                  CSV
                </Button>
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}
