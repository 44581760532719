import React, { ReactElement, useState } from "react";
import { Switch, Typography } from "@mui/material";
import translations from "translations";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { useLanguage } from "hooks";

interface Props {
  machineDetections: number;
  humanDetections: number;
  workflowStatus: Array<any>;
}

const MyResponsiveRadialBar = ({ data }) => (
  <ResponsiveRadialBar
    data={data}
    colors={{ datum: "data.color" }}
    padding={0.3}
    cornerRadius={2}
    margin={{ top: 0, right: 70, bottom: 30, left: 70 }}
    radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
    circularAxisOuter={{ tickSize: 10, tickPadding: 18, tickRotation: 0 }}
  />
);

export default function WorkflowStatus({
  machineDetections,
  humanDetections,
  workflowStatus,
}: Props): ReactElement {
  const { language } = useLanguage();
  const [toggleInventory, setToggleInventory] = useState(true);

  function toggle() {
    setToggleInventory((prevState) => !prevState);
  }

  function getWorkflowStatus(status) {
    let item = workflowStatus.find(
      (el) =>
        el.workflow_status === status && el.with_inventory === toggleInventory
    );
    let response = item ? item.count : 0;
    return response;
  }

  let dataDetection = [
    {
      id: `${translations.Menu.Statistics.MachineLearning.Machine[language]}`,
      label: "Machine",
      value: machineDetections,
      color: "hsl(210,100%,46%)",
    },
    {
      id: `${translations.Menu.Statistics.MachineLearning.Human[language]}`,
      label: "Human",
      value: humanDetections,
      color: "hsl(177, 70%, 50%)",
    },
  ];

  let dataWorkflowStatus = [
    {
      id: `${translations.AnnotationTool.WorkflowStatusMenu.ReviewRequested[language]}`,
      data: [
        {
          y: getWorkflowStatus(1),
          color: "hsl(210,100%,46%)",
        },
      ],
    },
    {
      id: `${translations.AnnotationTool.WorkflowStatusMenu.TruePositive[language]}`,
      data: [
        {
          y: getWorkflowStatus(2),
          color: "hsl(241.5,43.01%,36.47%)",
        },
      ],
    },
    {
      id: `${translations.AnnotationTool.WorkflowStatusMenu.SupervisorApprove[language]}`,
      data: [
        {
          y: getWorkflowStatus(3),
          color: "hsl(177, 70%, 50%)",
        },
      ],
    },
    {
      id: `${translations.AnnotationTool.WorkflowStatusMenu.FalsePositive[language]}`,
      data: [
        {
          y: getWorkflowStatus(4),
          color: "hsl(241.81,82.18%,39.61%)",
        },
      ],
    },
  ];

  return (
    <>
      {dataDetection[0].value > 0 && dataDetection[1].value > 0 && (
        <>
          <div className="statisticitem">
            <h2>
              {
                translations.Menu.Statistics.MachineLearning.WorkflowStatus[
                  language
                ]
              }
            </h2>
            <div style={{ height: "390px" }}>
              <div className="row">
                <Switch onChange={toggle} checked={toggleInventory} />
                <Typography>
                  {
                    translations.Menu.Statistics.MachineLearning
                      .ShowWithInventory[language]
                  }
                </Typography>
              </div>
              <MyResponsiveRadialBar data={dataWorkflowStatus} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
