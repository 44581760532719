import React from "react";
import Select from "react-select";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { useLanguage } from "hooks";

interface IProps {
  clientNaming: boolean;
  changeClientNaming;
}

function ClusterSwitch({ clientNaming, changeClientNaming }: IProps) {
  const onChange = (e) => {
    changeClientNaming(e.value);
  };
  const { language } = useLanguage();

  const options = [
    { value: false, label: "Arkion Naming" },
    { value: true, label: "Client Naming " },
  ];

  return (
    <MenuItem
      id="proximitySwitch"
      tooltip={translations.Menu.Settings.NamingConvention[language]}
    >
      <p>{translations.Menu.Settings.ClientNaming[language]}</p>
      <Select
        name={"Client Naming"}
        className="pr-10 pl-10 grow"
        options={options}
        defaultValue={options.find((o) => o.value === clientNaming)}
        onChange={onChange}
      />
    </MenuItem>
  );
}
export default ClusterSwitch;
