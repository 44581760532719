import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getShowDsoTsoFilter(
  searchParams: URLSearchParams
): FilterType["DSO_TSO"] {
  const showDsoTsoParams = searchParams.get(FilterKey.DSO_TSO) ?? "";
  return showDsoTsoParams === "true";
}

export function useShowDsoTsoFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const showDsoTso = getShowDsoTsoFilter(searchParams);

  const setShowDsoTso = (value: boolean) => {
    if (value) {
      if (searchParams.has(FilterKey.DSO_TSO))
        searchParams.delete(FilterKey.DSO_TSO);
    } else {
      searchParams.set(FilterKey.DSO_TSO, "true");
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { showDsoTso, setShowDsoTso };
}
