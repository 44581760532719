import React, { useState } from "react";
import {
  Button,
  Dialog,
  Typography,
  Stack,
  IconButton,
  TextField,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { authorizedPost } from "utils/request";
import { toast } from "react-toastify";
import Loading from "views/Loading";

interface IProps {
  mfaActive: boolean;
}

export default function ChangePassword({ mfaActive }: IProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());
    setLoading(true);
    authorizedPost("/user/settings/password", data)
      .then((response) => {
        toast.success("Password changed successfully");
        setOpenModal(false);
      })
      .catch(() => {
        toast.error("Failed to change password");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setOpenModal(!openModal)}>
        Change password
      </Button>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <form onSubmit={onSubmit}>
          <Stack p={2} spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Change Password</Typography>
              <IconButton onClick={() => setOpenModal(false)}>
                <Clear />
              </IconButton>
            </Stack>
            <Typography>
              Please enter your current password and your new password below.
            </Typography>
            <Stack spacing={1}>
              <TextField
                label="Current Password"
                variant="standard"
                type="password"
                name="old_password"
                autoFocus
                required
              />
              <TextField
                label="New Password"
                variant="standard"
                type="password"
                name="new_password"
                autoComplete="false"
                required
              />
              <TextField
                label="Confirm Password"
                variant="standard"
                type="password"
                name="new_password_repeat"
                autoComplete="false"
                required
              />
              {mfaActive && (
                <TextField
                  label="MFA Code"
                  variant="standard"
                  type="text"
                  name="mfa_code"
                  autoComplete="false"
                  required
                />
              )}
            </Stack>
            <Stack direction="row" justifyContent="space-between" pt={2}>
              <Button
                variant="outlined"
                color="error"
                disabled={loading}
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                type="submit"
                disabled={loading}
                endIcon={
                  loading ? (
                    <Loading relative size={15} color="white" />
                  ) : undefined
                }
              >
                Change Password
              </Button>
            </Stack>
          </Stack>
        </form>
      </Dialog>
    </>
  );
}
