import React from "react";
import Menu from "@mui/material/Menu";
import { IconButton, Stack } from "@mui/material";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { IMenu } from "./interfaces";
import { Menu as MenuIcon } from "@mui/icons-material";

type Props = {
  blok: {
    component: "menu";
    items: IMenu[];
  };
};

export default function CMSMenu({ blok }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack display={{ xs: "none", md: "flex" }}>
        {blok.items.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </Stack>

      <Stack
        alignItems="center"
        justifyContent="center"
        width="100%"
        display={{ xs: "flex", md: "none" }}
      >
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {blok.items.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </Menu>
    </>
  );
}
