import { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { CanvasContext, AnnotationContext } from "../provider";

const addSteelworkIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 87 87"
    fill="none"
    style={{ margin: "0 0 0 0" }}
  >
    <path
      d="M8 11V63C8 64.1046 8.89543 65 10 65H62.5C63.6046 65 64.5 64.1046 64.5 63V29C64.5 27.8954 65.3954 27 66.5 27H70C71.1046 27 72 27.8954 72 29V64.49C72 64.8247 71.916 65.1539 71.7558 65.4477L69.2776 69.991C69.0963 70.3235 68.8242 70.5976 68.4931 70.7816L64.953 72.7483C64.6559 72.9134 64.3216 73 63.9817 73H8.43322C8.14772 73 7.86552 72.9389 7.60561 72.8207L3.18277 70.8103C2.74256 70.6103 2.38975 70.2574 2.18965 69.8172L0.179267 65.3944C0.0611256 65.1345 0 64.8523 0 64.5668V9.4C0 9.13595 0.0522877 8.87451 0.153846 8.63077L2.12378 3.90292C2.36116 3.33322 2.84827 2.90502 3.4437 2.74263L7.7416 1.57047C7.9131 1.5237 8.09007 1.5 8.26784 1.5H44C45.1046 1.5 46 2.39543 46 3.5V7C46 8.10457 45.1046 9 44 9H10C8.89543 9 8 9.89543 8 11Z"
      fill="#666666"
    />
    <path
      d="M32 54.5V43C32 41.8954 31.1046 41 30 41H18.5C17.3954 41 16.5 40.1046 16.5 39V35C16.5 33.8954 17.3954 33 18.5 33H30C31.1046 33 32 32.1046 32 31V19C32 17.8954 32.8954 17 34 17H38C39.1046 17 40 17.8954 40 19V31C40 32.1046 40.8954 33 42 33H54C55.1046 33 56 33.8954 56 35V39C56 40.1046 55.1046 41 54 41H42C40.8954 41 40 41.8954 40 43V54.5C40 55.6046 39.1046 56.5 38 56.5H34C32.8954 56.5 32 55.6046 32 54.5Z"
      fill="#666666"
    />
    <path
      d="M67.25 10.75L55.75 22.25C55.0596 22.9404 55.0596 24.0596 55.75 24.75L56 25C56.5523 25.5523 56.5523 26.4477 56 27C55.4477 27.5523 54.5523 27.5523 54 27L46 19C45.4477 18.4477 45.4477 17.5523 46 17C46.5523 16.4477 47.4477 16.4477 48 17L48.25 17.25C48.9404 17.9404 50.0596 17.9404 50.75 17.25L62.25 5.75C62.9404 5.05964 62.9404 3.94036 62.25 3.25L62 3C61.4477 2.44772 61.4477 1.55228 62 1C62.5523 0.447715 63.4477 0.447714 64 0.999999L72 9C72.5523 9.55228 72.5523 10.4477 72 11C71.4477 11.5523 70.5523 11.5523 70 11L69.75 10.75C69.0596 10.0596 67.9404 10.0596 67.25 10.75Z"
      fill="#666666"
    />
  </svg>
);

export default function AddSteelworkButton() {
  const { mode, setMode, modeOptions } = useContext(CanvasContext);
  const { setSelectedAnnotation, steelworkObjectTypes } =
    useContext(AnnotationContext);

  const addMode = mode === modeOptions.ADD_STEELWORK;

  const onClick = () => {
    setMode(addMode ? modeOptions.VIEW : modeOptions.ADD_STEELWORK);
    setSelectedAnnotation(null);
  };
  return (
    <Tooltip
      title={addMode ? "Leave add mode" : "Enter add steelwork mode"}
      arrow
      placement="top"
      disableInteractive
    >
      <IconButton
        data-testid="add-steelwork-button"
        disabled={!steelworkObjectTypes.length}
        onClick={onClick}
        style={{ width: "42px", height: "42px" }}
      >
        {mode === modeOptions.ADD_STEELWORK ? <Clear /> : addSteelworkIcon}
      </IconButton>
    </Tooltip>
  );
}
