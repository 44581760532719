import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getFlightFilter(
  searchParams: URLSearchParams
): FilterType["FLIGHT"] {
  const flightParams = searchParams.get(FilterKey.FLIGHT);
  return flightParams ? flightParams.split(",").map(Number) : [];
}

export function useFlightFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const flightFilter = getFlightFilter(searchParams);

  const setFlightFilter = (listFilter: number[]) => {
    if (listFilter.length === 0) {
      if (searchParams.has(FilterKey.FLIGHT))
        searchParams.delete(FilterKey.FLIGHT);
    } else {
      searchParams.set(FilterKey.FLIGHT, listFilter.join(","));
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { flightFilter, setFlightFilter };
}
