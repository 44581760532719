import { createContext } from "react";
import {
  IPoleComponent,
  IPoleTemplate,
  IPoleSystem,
  IPoleStatus,
} from "../../api";

export interface IContextValue {
  components: IPoleStatus[];
  availableComponents: IPoleComponent[];
  poleValueSystems: IPoleSystem[];
  templates: IPoleTemplate[];
  loading: boolean;
  updateComponents: () => void;
  updateAvailableComponents: () => void;
  updateTemplates: () => void;
  addComponentToPole: (
    componentID: number,
    available?: IPoleComponent[] | null
  ) => void;
  createComponent: (
    name: string,
    gradingSystem: number
  ) => Promise<{ newID: number; availableComponents: IPoleComponent[] }>;
  deleteComponent: (componentID: number) => void;
  updateComponent: (componentID: number, name?: string) => void;
  addItemToPole: (componentID: number, name: string) => void;
  createComponentPlacement: (
    componentID: number,
    name: string,
    imageRegexPattern: string | null
  ) => Promise<{ newID: number; availableComponents: IPoleComponent[] }>;
  updateComponentPlacement: (
    componentID: number,
    placementID: number,
    name?: string | null,
    regex_pattern?: string | null
  ) => void;
  deleteComponentPlacement: (componentID: number, placementID: number) => void;
  createTemplate: (name: string) => void;
  deleteTemplate: (templateID: number) => void;
  updateTemplate: (templateID: number, name: string | null) => void;
  addItemToTemplate: (
    templateID: number,
    componentID: number,
    placementID: number
  ) => void;
  updateTemplateItem: (
    templateID: number,
    templateItemID: number,
    componentID: number | null,
    placementID: number | null
  ) => void;
  deleteTemplateItem: (templateID: number, templateItemID: number) => void;
  getPoleStatus: (poleID: number) => void;
  addPoleStatus: (
    poleID: number,
    poleComponentID: number,
    poleComponentPlacementID: number,
    valueID: number
  ) => void;
  deletePoleStatus: (
    poleID: number,
    componentID: number,
    placementID: number
  ) => void;
  updatePoleStatusValue: (
    poleID: number,
    componentID: number,
    placementID: number,
    valueID: number,
    imageID?: number | null
  ) => void;
  getPoleValues: () => void;
}

// Set up a context that is importable
const context = createContext<IContextValue>({
  components: [],
  availableComponents: [],
  poleValueSystems: [],
  templates: [],
  loading: false,
  updateComponents: () => {},
  updateAvailableComponents: () => {},
  createComponentPlacement: async () => ({
    newID: 1,
    availableComponents: [],
  }),
  updateTemplates: () => {},
  addComponentToPole: () => {},
  createComponent: async () => ({
    newID: 1,
    availableComponents: [],
  }),
  deleteComponent: () => {},
  updateComponent: () => {},
  addItemToPole: () => {},
  updateComponentPlacement: () => {},
  deleteComponentPlacement: () => {},
  createTemplate: () => {},
  deleteTemplate: () => {},
  updateTemplate: () => {},
  addItemToTemplate: () => {},
  updateTemplateItem: () => {},
  deleteTemplateItem: () => {},
  getPoleStatus: () => {},
  addPoleStatus: () => {},
  deletePoleStatus: () => {},
  updatePoleStatusValue: () => {},
  getPoleValues: () => {},
});

export { context };
