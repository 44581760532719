import { useSearchParams } from "react-router-dom";
import { FilterType, FilterKey } from "./constants";

export function getClearanceRulesFilter(
  searchParams: URLSearchParams
): FilterType["CLEARANCE_RULES"] {
  const clearanceRulesParams = searchParams.get(FilterKey.CLEARANCE_RULES);
  return clearanceRulesParams
    ? clearanceRulesParams.split(",").map(Number)
    : [];
}

export function useClearanceRulesFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const clearanceRules = getClearanceRulesFilter(searchParams);

  const setClearanceRules = (listFilter: number[]) => {
    if (listFilter.length === 0) {
      if (searchParams.has(FilterKey.CLEARANCE_RULES))
        searchParams.delete(FilterKey.CLEARANCE_RULES);
    } else {
      searchParams.set(FilterKey.CLEARANCE_RULES, listFilter.join(","));
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { clearanceRules, setClearanceRules };
}
