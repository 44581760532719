import { getAnnotations } from "api/image/annotation";
import { ImageFeedbackItem, ImageMeta, ImageType } from "interfaces";

type IAnnotation = Awaited<ReturnType<typeof getAnnotations>>[number];

export interface IState {
  lists: any[];
  blockedPowerlines: boolean;
  canSwitchImage: boolean;
  current: ImageMeta | null;
  lidarDistance: any;
  feedback: ImageFeedbackItem[];
  types: ImageType[];
  thumbnailRegexPattern: string | null;
  thumbnailPoleItemPlacement: number | null;
  annotations: IAnnotation[];
  filteredAnnotations: IAnnotation[];
  filteredAnnotationsImage: number | null;
  savedObjectTypeId?: number;
  savedDefectTypeId?: number;
}

export const initialState: IState = {
  lists: [],
  blockedPowerlines: false,
  canSwitchImage: true,
  current: null,
  lidarDistance: null,
  feedback: [],
  types: [],
  thumbnailRegexPattern: null,
  thumbnailPoleItemPlacement: null,
  annotations: [],
  filteredAnnotations: [],
  filteredAnnotationsImage: null,
  savedObjectTypeId: null,
  savedDefectTypeId: null,
};

const imageReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_IMAGE": {
      return {
        ...state,
        current: action.payload,
      };
    }
    case "SET_THUMBNAIL_REGEX_PATTERN": {
      return {
        ...state,
        thumbnailRegexPattern: action.payload,
      };
    }
    case "SET_THUMBNAIL_POLE_ITEM_PLACEMENT": {
      return {
        ...state,
        thumbnailPoleItemPlacement: action.payload,
      };
    }
    case "SET_IMAGE_TYPES": {
      return {
        ...state,
        types: action.payload,
      };
    }
    case "SET_IMAGE_FEEDBACK": {
      return {
        ...state,
        feedback: action.payload,
      };
    }
    case "SET_ANNOTATIONS": {
      return {
        ...state,
        annotations: action.payload,
      };
    }
    case "SET_FILTERED_ANNOTATIONS": {
      return {
        ...state,
        filteredAnnotations: action.payload.annotations,
        filteredAnnotationsImage: action.payload.image,
      };
    }
    case "SET_HEATMAP": {
      return {
        ...state,
        lidarDistance: action.payload,
      };
    }

    case "SET_LIDAR_DISTANCE": {
      return {
        ...state,
        lidarDistance: action.payload,
      };
    }

    case "SET_MARKERS_HIGHLIGHT": {
      return {
        ...state,
        markersHighlight: action.payload,
      };
    }
    case "SET_CAN_SWITCH_IMAGE": {
      return {
        ...state,
        canSwitchImage: action.payload,
      };
    }

    case "SET_POWERLINE_BLOCK": {
      return {
        ...state,
        blockedPowerlines: action.payload,
      };
    }

    case "SET_IMAGE_LISTS": {
      return {
        ...state,
        lists: action.payload,
      };
    }

    case "SET_SAVED_OBJECT_TYPE_ID": {
      return {
        ...state,
        savedObjectTypeId: action.payload,
      };
    }

    case "SET_SAVED_DEFECT_TYPE_ID": {
      return {
        ...state,
        savedDefectTypeId: action.payload,
      };
    }

    default:
      return state;
  }
};

export default imageReducer;
