import React, { useEffect, useRef, useState } from "react";
import {
  MenuList,
  MenuItem,
  Paper,
  Icon,
  Stack,
  Typography,
  List,
  Menu,
} from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import translations from "translations";
import {
  DoneAll,
  ThumbDown,
  Done,
  MotionPhotosOffOutlined,
  RemoveDone,
} from "@mui/icons-material";
import { defectOnly } from "react-image-annotate/Annotator/reducers/general-reducer";
import { useLanguage } from "hooks";

type Props = {
  menuPayload: {
    x: number;
    y: number;
    closeMenu: Function;
    onChangeRegion: Function;
    region;
    index: number;
    supervisor: boolean;
  };
  user;
};

export default function EditWorkflowContextMenu({ menuPayload, user }: Props) {
  const { language } = useLanguage();
  const [approveAnchorEl, setApproveAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const approveMenuOpen = Boolean(approveAnchorEl);

  const [rejectAnchorEl, setRejectAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const rejectMenuOpen = Boolean(rejectAnchorEl);

  const [flip, setFlip] = useState(false);
  const contextMenu = useRef();

  const checkIfClickedInside = (e) => {
    //@ts-ignore
    if (!!!contextMenu.current || !contextMenu.current.contains(e.target)) {
      menuPayload.closeMenu();
    }
  };

  useEffect(() => {
    window.addEventListener("click", checkIfClickedInside);
    return () => {
      window.removeEventListener("click", checkIfClickedInside);
    };
  });

  const setWorkflowStatus = (number) => {
    menuPayload.onChangeRegion({
      ...menuPayload.region,
      workflow_status: menuPayload.region.workflow_status.map((value, i) =>
        i === menuPayload.index ? number : value
      ),
    });
    menuPayload.closeMenu();
  };

  const toggleHidden = () => {
    menuPayload.onChangeRegion({
      ...menuPayload.region,
      skyqraft_hidden: menuPayload.region.skyqraft_hidden.map((value, i) =>
        i === menuPayload.index ? !value : value
      ),
    });
    menuPayload.closeMenu();
  };

  useEffect(() => {
    if (!!contextMenu.current) {
      // @ts-ignore
      const rect = contextMenu.current.getBoundingClientRect();
      if (rect.y + rect.height > window.innerHeight) {
        setFlip(true);
      } else {
        setFlip(false);
      }
    }
  }, [contextMenu]);

  return (
    <>
      <Paper
        ref={contextMenu}
        style={{
          position: "fixed",
          top: menuPayload.y,
          left: menuPayload.x,
          transform: flip ? "translate(0px, -100%)" : "translate(0px, 0px)",
          background: "white",
          boxShadow: "0px 0px 5px #777",
          zIndex: 1000,
        }}
        id="contextMenu"
      >
        <MenuList
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!!menuPayload.region.is_defect[menuPayload.index] && (
            <MenuItem
              style={{ padding: 10 }}
              onClick={() => toggleHidden()}
              disabled={
                defectOnly(user) ||
                !!menuPayload.region.processed[menuPayload.index]
              }
            >
              <MotionPhotosOffOutlined
                htmlColor={
                  menuPayload.region.skyqraft_hidden[menuPayload.index]
                    ? "orange"
                    : undefined
                }
              />
            </MenuItem>
          )}
          <RoleWrapper
            keyName="supervisorApprove"
            fallback={
              <MenuItem
                style={{ padding: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (
                    menuPayload.region.workflow_status[menuPayload.index] === 2
                  ) {
                    setWorkflowStatus(1);
                  } else {
                    setWorkflowStatus(2);
                  }
                }}
                disabled={defectOnly(user)}
              >
                <Stack>
                  {menuPayload.region.workflow_status[menuPayload.index] ===
                    2 && <Done htmlColor="green" />}
                  {menuPayload.region.workflow_status[menuPayload.index] ===
                    3 && <DoneAll htmlColor={"green"} />}
                  {![2, 3].includes(
                    menuPayload.region.workflow_status[menuPayload.index]
                  ) && <Done />}
                </Stack>
              </MenuItem>
            }
          >
            <MenuItem
              style={{ padding: 10 }}
              onClick={(event) => {
                setApproveAnchorEl(event.currentTarget);
              }}
              disabled={defectOnly(user)}
            >
              <Stack>
                {menuPayload.region.workflow_status[menuPayload.index] ===
                  2 && <Done htmlColor="green" />}
                {menuPayload.region.workflow_status[menuPayload.index] ===
                  3 && <DoneAll htmlColor={"green"} />}
                {![2, 3].includes(
                  menuPayload.region.workflow_status[menuPayload.index]
                ) && <Done />}
              </Stack>
            </MenuItem>
          </RoleWrapper>
          <RoleWrapper
            keyName="supervisorApprove"
            fallback={
              <MenuItem
                style={{ padding: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (
                    menuPayload.region.workflow_status[menuPayload.index] === 4
                  ) {
                    setWorkflowStatus(1);
                  } else {
                    setWorkflowStatus(4);
                  }
                }}
                disabled={defectOnly(user)}
              >
                <Stack>
                  {menuPayload.region.workflow_status[menuPayload.index] ===
                    4 && <ThumbDown htmlColor="red" />}
                  {menuPayload.region.workflow_status[menuPayload.index] ===
                    5 && <RemoveDone htmlColor="red" />}
                  {![4, 5].includes(
                    menuPayload.region.workflow_status[menuPayload.index]
                  ) && <ThumbDown />}
                </Stack>
              </MenuItem>
            }
          >
            <MenuItem
              style={{ padding: 10 }}
              onClick={(event) => {
                setRejectAnchorEl(event.currentTarget);
              }}
              disabled={defectOnly(user)}
            >
              <Stack>
                {menuPayload.region.workflow_status[menuPayload.index] ===
                  4 && <ThumbDown htmlColor="red" />}
                {menuPayload.region.workflow_status[menuPayload.index] ===
                  5 && <RemoveDone htmlColor="red" />}
                {![4, 5].includes(
                  menuPayload.region.workflow_status[menuPayload.index]
                ) && <ThumbDown />}
              </Stack>
            </MenuItem>
          </RoleWrapper>
        </MenuList>
      </Paper>
      <Menu
        open={rejectMenuOpen}
        anchorEl={rejectAnchorEl}
        onClose={() => {
          setRejectAnchorEl(null);
        }}
      >
        <RoleWrapper
          keyName="supervisorApprove"
          fallback={
            translations.AnnotationTool.WorkflowStatusMenu.FalsePositive[
              language
            ]
          }
        >
          <List>
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (
                  menuPayload.region.workflow_status[menuPayload.index] === 4
                ) {
                  setWorkflowStatus(1);
                } else {
                  setWorkflowStatus(4);
                }
                setApproveAnchorEl(null);
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Icon>
                  <ThumbDown />
                </Icon>
                <Typography>
                  {
                    translations.AnnotationTool.WorkflowStatusMenu
                      .FalsePositive[language]
                  }
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (
                  menuPayload.region.workflow_status[menuPayload.index] === 5
                ) {
                  setWorkflowStatus(1);
                } else {
                  setWorkflowStatus(5);
                }
                setApproveAnchorEl(null);
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Icon>
                  <RemoveDone />
                </Icon>
                <Typography>
                  Super{" "}
                  {
                    translations.AnnotationTool.WorkflowStatusMenu
                      .FalsePositive[language]
                  }
                </Typography>
              </Stack>
            </MenuItem>
          </List>
        </RoleWrapper>
      </Menu>
      <Menu
        open={approveMenuOpen}
        anchorEl={approveAnchorEl}
        onClose={() => {
          setApproveAnchorEl(null);
        }}
      >
        <RoleWrapper
          keyName="supervisorApprove"
          fallback={
            translations.AnnotationTool.WorkflowStatusMenu.Approve[language]
          }
        >
          <List>
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (
                  menuPayload.region.workflow_status[menuPayload.index] === 2
                ) {
                  setWorkflowStatus(1);
                } else {
                  setWorkflowStatus(2);
                }
                setRejectAnchorEl(null);
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Icon>
                  <Done />
                </Icon>
                <Typography>
                  {
                    translations.AnnotationTool.WorkflowStatusMenu.Approve[
                      language
                    ]
                  }
                </Typography>
              </Stack>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (
                  menuPayload.region.workflow_status[menuPayload.index] === 3
                ) {
                  setWorkflowStatus(1);
                } else {
                  setWorkflowStatus(3);
                }
                setRejectAnchorEl(null);
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Icon>
                  <DoneAll />
                </Icon>
                <Typography>
                  {
                    translations.AnnotationTool.WorkflowStatusMenu
                      .SupervisorApprove[language]
                  }
                </Typography>
              </Stack>
            </MenuItem>
          </List>
        </RoleWrapper>
      </Menu>
    </>
  );
}
