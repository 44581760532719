import { connect } from "react-redux";
import Component from "./Image";
import { RootState } from "state/store";

interface IStateProps {
  s3Folder: string;
}

interface IDispatchProps {}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    s3Folder: state.imageUpload.s3Folder,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
