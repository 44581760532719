import React from "react";
import { useParams } from "react-router-dom";
import {
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import RingLoader from "react-spinners/RingLoader";
import translations from "translations";
import { LogData } from "./JobUploadView";
import { useLanguage } from "hooks";

type Props = {
  loading?: boolean;
  data: LogData;
  update: () => void;
};

export default function LogView({ loading, data, update }: Props) {
  const { language } = useLanguage();
  const params = useParams();
  const job = params.job;
  return (
    <>
      <DialogTitle>
        <h2>
          {translations.Menu.Tools.ImageUpload.Import.Job[language]} #{job}
        </h2>
        {loading ? (
          <Typography style={{ margin: "auto", marginRight: 0 }}>
            Loading
          </Typography>
        ) : (
          <Typography style={{ margin: "auto", marginRight: 0 }}>
            {translations.Menu.Tools.ImageUpload.Import.Job[language]}{" "}
            {data.aborted
              ? translations.Menu.Tools.ImageUpload.Import.Aborted[language]
              : translations.Menu.Tools.ImageUpload.Import.Finished[language]}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Button startIcon={<Refresh/>} variant="contained" onClick={update}>
          Update
        </Button>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        ></div>
        <div className="statsRow">
          <div className="card">
            <p>{translations.Menu.Tools.ImageUpload.Import.Images[language]}</p>
            <p>{data.processed}</p>
          </div>
          <div className="card">
            <p>
              {
                translations.Menu.Tools.ImageUpload.Import.CloudDuplicate[
                  language
                ]
              }
            </p>
            <p>{data.cloud_duplicates}</p>
          </div>
          <div className="card">
            <p>
              {
                translations.Menu.Tools.ImageUpload.Import.DatabaseDuplicate[
                  language
                ]
              }
            </p>
            <p>{data.db_duplicates}</p>
          </div>
          <div className="card">
            <p>
              {translations.Menu.Tools.ImageUpload.Import.Skipped[language]}
            </p>
            <p>{data.skipped}</p>
          </div>
        </div>
        <div
          className="logWindow"
          style={{ overflowY: "scroll", height: "auto" }}
        >
          {loading && (
            <Stack alignItems="center" justifyContent="center" sx={{ p: 4 }}>
              <RingLoader size="100px" color="#FFF" />
            </Stack>
          )}
          {data.log.map((log, id) => (
            <pre key={id} style={{ whiteSpace: "pre-wrap" }}>
              {log}
            </pre>
          ))}
        </div>
      </DialogContent>
    </>
  );
}
