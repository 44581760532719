import React from "react";
import { IconButton } from "@mui/material";
import { Done } from "@mui/icons-material";
import { severities, workflowStatuses } from "constants/imageReview";
import { annotationActions } from "../constants";
import SeveritiesMenu from "./SeveritiesMenu";

interface IProps {
  workflowStatus: number;
  skyqraft_hidden: boolean;
  updateAnnotation: (args: {
    action: (typeof annotationActions)[keyof typeof annotationActions];
    severity?: (typeof severities)[keyof typeof severities];
  }) => void;
  isDefect: boolean;
  typeId: number;
}
export default function ApproveSelector(props: IProps) {
  const {
    workflowStatus,
    skyqraft_hidden,
    updateAnnotation,
    isDefect,
    typeId,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isRequestAction =
    workflowStatus === workflowStatuses.TRUE_POSITIVE && !skyqraft_hidden;

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          if (isRequestAction) {
            updateAnnotation({
              action: annotationActions.REQUEST,
            });
          } else if (!isDefect) {
            updateAnnotation({
              action: annotationActions.APPROVE,
            });
          } else if (isDefect) {
            setAnchorEl(e.currentTarget);
          }
        }}
        aria-controls={anchorEl ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
        data-testid={`machineReview.annotation.approve.${typeId}`}
      >
        <Done htmlColor={isRequestAction ? "#22ba22" : "white"} />
      </IconButton>
      <SeveritiesMenu
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        onClick={(severityId) => {
          updateAnnotation({
            action: annotationActions.APPROVE,
            severity: severityId,
          });
        }}
      />
    </>
  );
}
