import { connect } from "react-redux";
import Component from "./SelectFolder";
import { RootState } from "state/store";
import { getCloudFolders } from "state/actions";

interface IStateProps {
  cloudFolders: string[];
}

interface IDispatchProps {
  getCloudFolders: () => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    cloudFolders: state.imageUpload.cloudFolders,
  };
};

const mapDispatchToProps = {
  getCloudFolders,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
