import { Tooltip } from "@mui/material";

type Props = {
  x: number;
  y: number;
  w: number;
  h: number;
  onClick: (event) => void;
  onHover: (show) => void;
  children;
  open: boolean;
  rotation: number;
};

export default function InfoBox({
  x,
  y,
  w,
  h,
  onClick,
  onHover,
  open,
  children,
  rotation,
}: Props) {
  const clickFunction = (e) => {
    e.stopPropagation();
    onClick(e);
  };

  const hoverFunction = (e, show: boolean) => {
    e.stopPropagation();
    onHover(show);
  };

  return (
    <g
      transform={`translate(${x + w / 2} ${
        y + h / 2
      }) rotate(${rotation}) translate(${-w / 2} ${-h / 2})`}
      className="boxInfo"
      onClick={clickFunction}
      onMouseOver={(e) => hoverFunction(e, true)}
      onMouseLeave={(e) => hoverFunction(e, false)}
    >
      <Tooltip
        open={open}
        placement="top-start"
        title={children}
        PopperProps={{
          sx: { zIndex: 800 },
        }}
      >
        <rect x={0} y={0} height={h} width={w} fillOpacity={0} />
      </Tooltip>
    </g>
  );
}
