import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useGesture } from "@use-gesture/react";
import { MutableRefObject } from "react";
import { AnnotationContext, CanvasContext } from "../provider";
import { convertPositionToPercentage, getBoxFromPoints } from "../utils";
import { getCanvasBox } from "./utils";

interface IProps {
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
  setCurrentBox: Dispatch<SetStateAction<IBox>>;
}

interface IBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface IPoint {
  x: number;
  y: number;
}

export function useNewBoxListener({ canvasRef, setCurrentBox }: IProps) {
  const [startPoint, setStartPoint] = useState<IPoint | null>(null);
  const context = useContext(AnnotationContext);
  const { matrix, setSelectedAnnotation, setMode, mode } =
    useContext(CanvasContext);

  function resetListener() {
    setStartPoint(null);
    setSelectedAnnotation(null);
    setMode("view");
    setCurrentBox({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    });
  }

  function addNewAnnotation({
    x,
    y,
    w,
    h,
  }: {
    x: number;
    y: number;
    w: number;
    h: number;
  }) {
    context.addAnnotation({
      id: 1,
      x,
      y,
      w,
      h,
      items: [],
    });
  }

  useGesture(
    {
      onDrag: (state) => {
        if (!canvasRef.current) return;
        if (!startPoint) return;
        // @ts-ignore
        const { clientX, clientY } = state.event;
        const { width, top, left } = getCanvasBox(canvasRef);

        const newPoint = convertPositionToPercentage(
          top,
          left,
          width,
          clientX,
          clientY,
          matrix
        );
        const { x, y } = startPoint;
        const newBox = getBoxFromPoints({ x, y }, newPoint);
        setCurrentBox(newBox);
      },
      onDragEnd: (state) => {
        if (!canvasRef.current) return;
        if (!startPoint) return;
        // @ts-ignore
        const { clientX, clientY } = state.event;
        const { width, top, left } = getCanvasBox(canvasRef);
        const newPoint = convertPositionToPercentage(
          top,
          left,
          width,
          clientX,
          clientY,
          matrix
        );
        const { x, y } = startPoint;
        const newBox = getBoxFromPoints({ x, y }, newPoint);
        addNewAnnotation({
          x: newBox.x,
          y: newBox.y,
          w: newBox.width,
          h: newBox.height,
        });
        resetListener();
      },

      onDragStart: (state) => {
        // Start the creation of a box
        if (!canvasRef.current) return;

        // @ts-ignore
        const { clientX, clientY } = state.event;
        const { left, top, width } = getCanvasBox(canvasRef);

        // Check the current location of the mouse
        // in terms of canvas percentages
        const { x, y } = convertPositionToPercentage(
          top,
          left,
          width,
          clientX,
          clientY,
          matrix
        );

        // Save starting point to local state
        setStartPoint({
          x,
          y,
        });
      },
    },
    {
      target: canvasRef,
      drag: {
        enabled: mode === "add",
      },
    }
  );
}
