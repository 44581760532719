import { useDispatch as useDispatchRedux } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "state/store";

export function useDispatch() {
  const dispatch: ThunkDispatch<RootState, null, AnyAction> =
    useDispatchRedux();
  return dispatch;
}
