import RingLoader from "react-spinners/RingLoader";
import "./style.scss";

export default function CanvasContainer({
  children,
  dragging,
  mat,
  imageLoaded,
  reviewLoading,
}) {
  // Create data for the container
  const cursor = dragging ? "grabbing" : "grab";
  const zoomText = `Zoom: ${((1 / mat.a) * 100).toFixed(0)}%`;
  const showLoading = !imageLoaded || reviewLoading;

  // Display the data
  return (
    <div
      className="canvasContainer"
      id="imageContainer"
      style={{ cursor }}
    >
      {showLoading && (
        <div className="loadingContainer">
          <RingLoader size={60} color={"#FFFFFF"} loading={true} />
        </div>
      )}
      <div
        style={{ width: "100%", height: "100%", touchAction: "none" }}
      >
        {children}
      </div>

      <div className="zoomIndicator">{zoomText}</div>
    </div>
  );
}
