import { axiosInstance } from "utils/request";
import { ILanguages } from "utils/translations";

interface IReportGenerationResponse {
  id: number;
}

export async function startReportGeneration(
  projectID: number,
  imageList: number,
  language: ILanguages
) {
  // Start the report generation process
  const response = await axiosInstance.post(
    `/project/${projectID}/image_report`,
    {
      list_id: imageList,
      language,
    }
  );
  return response.data as IReportGenerationResponse;
}

export async function getReportStatus(projectId: number, reportId: number) {
  // Get the status of the report from the API
  const response = await axiosInstance.get(
    `/project/${projectId}/image_report/${reportId}`
  );
  return response.data as { progress: number; pending: boolean };
}

export async function downloadReportPDF(projectId: number, reportId: number) {
  // Get the signed URL to download the report
  const downloadLink = await axiosInstance.get(
    `/project/${projectId}/image_report/${reportId}/download`
  );

  // Download the report
  const response = await fetch(downloadLink.data);
  const blob = await response.blob();

  return blob;
}
