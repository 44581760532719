import { useFlags } from "launchdarkly-react-client-sdk";
import Steelwork, { IProps } from "./Steelwork";

export default function FlagProtectedSteelwork(props: IProps) {
  // Render conditionally on feature flags
  const { steelworkAnnotationTool } = useFlags();

  // https://app.launchdarkly.com/projects/default/flags/steelwork-annotation-tool?env=test&selected-env=test
  if (!steelworkAnnotationTool) {
    return null;
  }

  return <Steelwork {...props} />;
}
