import { useContext, useState } from "react";
import { Edit } from "@mui/icons-material";
import { AnnotationContext } from "../../../provider";
import translations from "translations";
import { useLanguage } from "hooks";

type Props = {
  annotation;
  index;
};

export default function InfoCommentBox({ annotation, index }: Props) {
  const { language } = useLanguage();
  const { updateAnnotation } = useContext(AnnotationContext);

  const [stateComment, setStateComment] = useState(annotation.type_comment);

  const handleCommentChange = (e, index, region) => {
    const newComment = [...stateComment];
    newComment[index] = e.target.value;
    setStateComment(newComment);

    updateAnnotation(annotation.id, {
      ...region,
      type_comment: newComment,
      isNew: false,
    });
  };

  return (
    <>
      {!annotation.id.includes("n") ? (
        <>
          {!!annotation?.is_defect[index] ? (
            <div className="commentSectionWrapper">
              <div className="commentSectionIssue">
                <div className="commentHeader">
                  <div className="headerTextFirst">
                    <Edit sx={{ height: "15px" }} />
                    <p>comment</p>
                  </div>
                </div>
                <div className="commentHeader">
                  <p className="headerText">created by</p>
                </div>
                <div className="commentHeader">
                  <p className="headerText">updated by</p>
                </div>
                <p className="commentText">
                  <textarea
                    className="commentInput"
                    placeholder={
                      translations.AnnotationTool.AddComment[language]
                    }
                    value={stateComment[index]}
                    onChange={(e) => handleCommentChange(e, index, annotation)}
                  />
                </p>
                <p className="commentText">
                  {!!annotation?.creator[index]
                    ? annotation.creator[index]
                    : "you"}
                </p>
                <p className="commentTextLast">
                  {!!annotation?.creator[index]
                    ? annotation.updated_by[index]
                    : "you"}
                </p>
              </div>
            </div>
          ) : (
            <div className="commentSectionWrapper">
              <div className="commentSection">
                <div className="commentHeader"></div>
                <div className="commentHeader">
                  <p className="headerText">created by</p>
                </div>
                <div className="commentHeader">
                  <p className="headerText">updated by</p>
                </div>
                <p></p>
                <p className="commentText">
                  {!!annotation?.creator[index]
                    ? annotation.creator[index]
                    : "you"}
                </p>
                <p className="commentTextLast">
                  {!!annotation?.creator[index]
                    ? annotation.updated_by[index]
                    : "you"}
                </p>
              </div>
            </div>
          )}
        </>
      ) : (
        !!annotation?.is_defect[index] && (
          <>
            <div className="commentSectionWrapper">
              <div className="commentSectionIssue">
                <div className="commentHeader">
                  <div className="headerTextFirst">
                    <Edit sx={{ height: "15px" }} />
                    <p>comment</p>
                  </div>
                </div>
                <div className="commentHeader">
                  <p className="headerText">created by</p>
                </div>
                <div className="commentHeader">
                  <p className="headerText">updated by</p>
                </div>
                <p className="commentText">
                  <textarea
                    className="commentInput"
                    placeholder={
                      translations.AnnotationTool.AddComment[language]
                    }
                    value={stateComment[index]}
                    onChange={(e) => handleCommentChange(e, index, annotation)}
                  />
                </p>
                <p className="commentText"></p>
                <p className="commentTextLast"></p>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
}
