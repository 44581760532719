import { connect } from "react-redux";
import ImageObjectUpload from "./ImageObjectUpload";
import { RootState } from "state/store";
import {
  setObjectFile,
  setDefaultUser,
  getUploadUsers,
  uploadObjectFile,
} from "state/actions";

interface IStateProps {
  uploadStatus: string;
  uploadUsers: Array<any>;
  defaultUser: number;
  objectFile: any;
}

interface IDispatchProps {
  setObjectFile: typeof setObjectFile;
  setDefaultUser: typeof setDefaultUser;
  getUploadUsers: () => void;
  uploadObjectFile: () => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    uploadStatus: state.imageObjectUpload.uploadStatus,
    uploadUsers: state.imageObjectUpload.uploadUsers,
    defaultUser: state.imageObjectUpload.defaultUser,
    objectFile: state.imageObjectUpload.objectFile,
  };
};

const mapDispatchToProps = {
  setObjectFile,
  setDefaultUser,
  getUploadUsers,
  uploadObjectFile,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ImageObjectUpload);
