import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { Add, Save } from "@mui/icons-material";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  description: string;
  children?: React.ReactNode;
  addLink?: string;
  linkReplace?: boolean;
  onSave?: () => void;
  saveDisabled?: boolean;
};

export default function ItemColumn({
  title,
  description,
  children,
  addLink,
  onSave,
  linkReplace = true,
  saveDisabled,
}: Props) {
  return (
    <Stack flex={1} spacing={1}>
      <Stack>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          height={30}
        >
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <Stack direction="row" spacing={1}>
            {addLink && (
              <Link to={addLink} replace={!!linkReplace}>
                <IconButton>
                  <Add />
                </IconButton>
              </Link>
            )}
            {onSave && (
              <IconButton disabled={!!saveDisabled} onClick={onSave}>
                <Save />
              </IconButton>
            )}
          </Stack>
        </Stack>
        <Typography color="text.secondary">{description}</Typography>
      </Stack>
      {children}
    </Stack>
  );
}
