import { useSelector } from "hooks/useSelector";
import { useLocation } from "react-router-dom";
import { useCurrentProject } from "./projects";

export interface IAnalyticsPayload {
  [key: string]: string | number | boolean | { [k: string]: string };
}

export function useAnalytics() {
  const location = useLocation();
  const employee = useSelector((state) => state.user.skyqraft_employee);
  const superuser = useSelector((state) => state.user.superuser);
  const currentProject = useCurrentProject();
  const groups = useSelector((state) => state.group.groups);
  const currentGroup = groups.find(
    (group) => group.id === currentProject?.group?.id
  );
  const searchParams = new URLSearchParams(location.search);
  const searchParamsObject = Object.fromEntries(searchParams.entries());

  function createPayload(eventName, payload: IAnalyticsPayload) {
    return {
      event: eventName,
      gtm: {
        origin: window.location.origin,
        pathname: location.pathname,
        search: location.search,
        searchParams: searchParamsObject,
        arkionEmployee: employee,
        superuser: superuser,
        projectId: currentProject?.id,
        groupId: currentGroup?.id,
        ...payload,
      },
    };
  }

  function sendEvent(eventName: string, payload: IAnalyticsPayload) {
    const totalPayload = createPayload(eventName, payload);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push(totalPayload);
  }

  return sendEvent;
}
