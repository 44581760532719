import { connect } from "react-redux";
import { RootState } from "state/store";
import Component from "./DemoSafeName"

interface IStateProps {
    demoMode: boolean;
}

interface IOwnProps {
    entityName: string;
    children: string;
}

interface IDispatchProps {}

const mapStateToProps = (state: RootState) => {
  return {
    demoMode: state.ui.demoMode,
  };
};

const mapDispatchToProps = {};


export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
