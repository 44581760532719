import { Link as LinkIcon } from "@mui/icons-material";
import { Divider, Stack, Typography, Button } from "@mui/material";
import { ISteelwork } from "api/image/annotation";
import { useCurrentProject } from "hooks";
import { useState } from "react";

type Props = {
  steelwork: ISteelwork;
};

export default function SteelworkInfo({ steelwork }: Props) {
  const [tabs, setTabs] = useState<{ [key: string]: Window | null }>({});
  const currentProject = useCurrentProject();

  const {
    item_id,
    direction,
    second_direction,
    steel_grading,
    bolt_condition,
    child_link,
    parent_link,
  } = steelwork;

  const openLinkInNewTab = (url: string) => {
    // Check if the tab for the URL is already open
    if (tabs[url] && !tabs[url]?.closed) {
      // Focus on the already opened tab
      tabs[url]?.focus();
    } else {
      // Open a new tab and store the reference
      const newTab = window.open(url, "_blank");
      setTabs((prevTabs) => ({
        ...prevTabs,
        [url]: newTab,
      }));
    }
  };

  return (
    <Stack direction="column" spacing={1} alignItems="start">
      {item_id && (
        <Typography fontSize="small">{`Item ID: ${item_id}`}</Typography>
      )}
      {direction?.name && (
        <Typography fontSize="small">
          {`Direction: ${direction.name}`}
        </Typography>
      )}
      {second_direction?.name && (
        <Typography fontSize="small">
          {`Second Direction: ${second_direction.name}`}
        </Typography>
      )}
      {steel_grading?.name && (
        <Typography fontSize="small">
          {`Steel Grading: ${steel_grading.name}`}
        </Typography>
      )}
      {bolt_condition?.name && (
        <Typography fontSize="small">
          {`Bolt Condition: ${bolt_condition.name}`}
        </Typography>
      )}
      <Divider />
      {parent_link?.image_id && (
        <Button
          size="small"
          variant="outlined"
          sx={{ backgroundColor: "white", justifyContent: "start" }}
          fullWidth
          startIcon={<LinkIcon fontSize="small" />}
          onClick={() => {
            const url = new URL(
              `${location.origin}/${currentProject.id}/${parent_link.image_id}${location.search}`
            );
            url.searchParams.set("itemId", item_id);
            const parentLinkUrl = url.toString();
            openLinkInNewTab(parentLinkUrl);
          }}
        >
          <Typography variant="body1" fontSize="small">
            This is the second angle. Click to go to primary angle
          </Typography>
        </Button>
      )}
      {child_link?.image_id && (
        <Button
          size="small"
          variant="outlined"
          sx={{ backgroundColor: "white", justifyContent: "start" }}
          fullWidth
          startIcon={<LinkIcon fontSize="small" />}
          onClick={() => {
            const url = new URL(
              `${location.origin}/${currentProject.id}/${child_link.image_id}${location.search}`
            );
            url.searchParams.set("itemId", item_id);
            const childLinkUrl = url.toString();
            openLinkInNewTab(childLinkUrl);
          }}
        >
          <Typography variant="body1" fontSize="small">
            This is the primary angle. Click to go to second angle
          </Typography>
        </Button>
      )}
    </Stack>
  );
}
