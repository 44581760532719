import { useState, useEffect } from "react";
import {
  IconButton,
  Stack,
  AppBar,
  Tab,
  Tabs,
  Box,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Clear } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StatusBar from "components/StatusBar";
import IssueSeverities from "./IssueSeverities";
import ObjectTypes from "./ObjectTypes";
import RoleWrapper from "components/RoleHOC/wrapper";
import { getTranslation } from "utils/translations";
import md5 from "md5-hash";
import {
  getClientIssueSeverities,
  getClientObjects,
  getAdminGroups,
} from "state/actions";
import { useSelector, useDispatch } from "hooks";
import "./style.scss";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#0078EB",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
  },
};

function NavigateBack() {
  const navigate = useNavigate();
  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="close"
      onClick={() => {
        navigate(-1);
      }}
    >
      <Clear />
    </IconButton>
  );
}

export default function ClientSettings() {
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();
  const objectTypes = useSelector((state) =>
    state.objects.objectTypes.filter(
      (t) => !t.skyqraft_only || state.user.skyqraft_employee
    )
  );
  const issueSeverities = useSelector((state) => state.objects.issueSeverities);
  const language = useSelector((state) => state.user.language);
  const groups = useSelector((state) => state.group.admin_groups);
  const clientIssueSeverities = useSelector(
    (state) => state.objects.clientIssueSeverities
  );
  const clientObjects = useSelector((state) => state.objects.clientObjects);
  const demoMode = useSelector((state) => state.ui.demoMode);
  const projects = useSelector((state) => state.user.missions);
  const missionID = useSelector((state) => state.mission.id);

  groups.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    dispatch(getAdminGroups());
  }, [dispatch]);

  useEffect(() => {
    if (groups.length > 0) {
      const missionGroup = projects.find((project) =>
        project.id === missionID ? project.group : null
      );
      const groupId = missionGroup?.group?.id;
      if (groupId) {
        setSelectedGroup(groupId);
      }
    }
  }, [groups, projects, missionID]);

  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    if (selectedGroup !== null) {
      dispatch(getClientObjects(selectedGroup));
      dispatch(getClientIssueSeverities(selectedGroup));
    }
  }, [selectedGroup, dispatch]);

  return (
    <RoleWrapper keyName="addClientIssueSeverities">
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            direction: "row",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            zIndex: 100,
          }}
        >
          <Box
            sx={{
              height: "100%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <StatusBar
              title={getTranslation("CustomizeDefectTypes", language)}
            />
            <AppBar
              component="div"
              position="static"
              elevation={0}
              sx={{ zIndex: 0 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tabs value={tab} textColor="inherit">
                  <Tab
                    label={getTranslation("ClientDefectType", language)}
                    onClick={() => setTab(0)}
                  />
                  <Tab
                    label={getTranslation("ClientSeverityTypes", language)}
                    onClick={() => setTab(1)}
                  />
                </Tabs>

                <NavigateBack />
              </Stack>
            </AppBar>

            <Box
              sx={{
                flex: 1,
                py: 2,
                px: 4,
                bgcolor: "#eaeff1",
                overflowY: "auto",
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-controlled-open-select-label">
                  {getTranslation("Client", language)}
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={selectedGroup}
                  label="Customer"
                  onChange={(e) => setSelectedGroup(e.target.value)}
                >
                  {groups?.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {demoMode
                        ? `Customer ${md5(e.name).substring(0, 4)}`
                        : e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {selectedGroup && (
                <div className="objectContainer">
                  {tab === 0 && (
                    <>
                      <ObjectTypes
                        objectTypes={objectTypes}
                        getClientObjects={(e) => dispatch(getClientObjects(e))}
                        clientObjects={clientObjects}
                        selectedGroup={selectedGroup}
                      />
                    </>
                  )}

                  {tab === 1 && (
                    <>
                      <IssueSeverities
                        objectTypes={issueSeverities}
                        getClientObjects={(e) =>
                          dispatch(getClientIssueSeverities(e))
                        }
                        clientObjects={clientIssueSeverities}
                        selectedGroup={selectedGroup}
                      />
                    </>
                  )}
                </div>
              )}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </RoleWrapper>
  );
}
