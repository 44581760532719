import { IMatrix } from "../types";

interface IPoint {
  x: number;
  y: number;
}
interface ISize {
  w: number;
  h: number;
}

export function getTopLeft(mat: IMatrix): IPoint {
  return {
    x: -mat.e / mat.a,
    y: -mat.f / mat.d,
  };
}

export function getScaledCanvasSize(
  referenceSize: number,
  mat: IMatrix
): ISize {
  return {
    w: referenceSize / mat.a,
    h: referenceSize / mat.a,
  };
}

export function convertPositionToPercentage(
  canvasTop: number,
  canvasLeft: number,
  canvasWidth: number,
  clientX: number,
  clientY: number,
  matrix: IMatrix
) {
  const size = getScaledCanvasSize(canvasWidth, matrix);
  const matrixOffset = {
    x: matrix.e / matrix.a,
    y: matrix.f / matrix.d,
  };

  const canvasLocation = {
    x: clientX - canvasLeft + matrixOffset.x,
    y: clientY - canvasTop + matrixOffset.y,
  };

  return {
    x: canvasLocation.x / size.w,
    y: canvasLocation.y / size.h,
  };
}

export function convertChangeToPercentage(
  dx: number,
  dy: number,
  canvasWidth: number,
  matrix: IMatrix
) {
  const size = getScaledCanvasSize(canvasWidth, matrix);
  return {
    dx: dx / size.w,
    dy: dy / size.h,
  };
}

export function getBoxFromPoints(startPoint: IPoint, endPoint: IPoint) {
  const topLeft = {
    x: Math.min(startPoint.x, endPoint.x),
    y: Math.min(startPoint.y, endPoint.y),
  };
  const bottomRight = {
    x: Math.max(startPoint.x, endPoint.x),
    y: Math.max(startPoint.y, endPoint.y),
  };

  return {
    x: topLeft.x,
    y: topLeft.y,
    width: bottomRight.x - topLeft.x,
    height: bottomRight.y - topLeft.y,
  };
}
