import { connect } from "react-redux";
import Component from "./ThumbnailSorting";
import { setImageSortingMethod } from "state/actions";
import { RootState } from "state/store";

interface IStateProps {
  imageSortingMethod: "compass" | "timestamp";
}

interface IDispatchProps {
  setImageSortingMethod: typeof setImageSortingMethod;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    imageSortingMethod: state.imageViewer.imageSortingMethod,
  };
};

const mapDispatchToProps = {
  setImageSortingMethod,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
