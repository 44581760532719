import { useParams, useSearchParams } from "react-router-dom";
import { useSearchQueryItem, useBoxAnnotation, usePoleImages } from "./hooks";
import NotFound from "./components/NotFound";
import ImageDialog from "./components/ImageDialog";

export default function ImageConnectorDialog() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const projectID = Number.parseInt(params.mission);
  const { item: poleID } = useSearchQueryItem("pole");
  const { item: boxID } = useSearchQueryItem("box");
  const { images } = usePoleImages(poleID, projectID);

  const { currentAnnotation, addItemToBox, deleteBoxItem } =
    useBoxAnnotation(boxID);

  if (!currentAnnotation) {
    return <NotFound />;
  }

  function removeConnection(itemID: number) {
    deleteBoxItem(poleID, boxID, itemID);
  }

  function onClose() {
    if (searchParams.has("addPoleItem")) {
      searchParams.delete("addPoleItem");
    }
    if (searchParams.has("box")) {
      searchParams.delete("box");
    }
    setSearchParams(searchParams);
  }

  async function connectImage(imageId: number) {
    await addItemToBox(poleID, boxID, imageId);
  }

  const annotationImageIDs = currentAnnotation.items.map(
    (item) => item.image.id
  );

  function onDisconnect(imageId: number) {
    const annotationItem = currentAnnotation.items.find(
      (item) => item.image.id === imageId
    );
    removeConnection(annotationItem.id);
  }

  return (
    <ImageDialog
      title="Connect images to this segment of the structure"
      onClose={onClose}
      images={images}
      filterImageIDs={annotationImageIDs}
      addImage={connectImage}
      removeImage={onDisconnect}
    />
  );
}
