export var getEnclosingBox = function getEnclosingBox(region) {
  return {
    x: region.x,
    y: region.y,
    w: region.w,
    h: region.h,
  };
};

export var moveRegion = function moveRegion(region, x, y) {
  // eslint-disable-next-line

  return {
    ...region,
    x: Math.min(Math.max(x - region.w / 2, 0) + region.w, 1) - region.w,
    y: Math.min(Math.max(y - region.h / 2, 0) + region.h, 1) - region.h,
  };
};
