import React from "react";
import { Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

export default function CloseReviewToolIcon() {
  return (
    <Tooltip title="Open image editor in new tab" placement="bottom" arrow>
      <Link
        to={`annotate${location.search}`}
        target="_blank"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Edit sx={{ color: "#ffff" }} />
      </Link>
    </Tooltip>
  );
}
