import colorAlpha from "color-alpha";

const LOWLIGHT_LINE_OPACITY = 0.35;
const MAIN_LINE_THICKNESS_SELECTED = 5;
const MAIN_LINE_THICKNESS = 3;
const DASHED_LINE_THICKNESS_SELECTED = 1.5;
const DASHED_LINE_THICKNESS = 0.4;

type Props = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  color: string;
  selected: boolean;
  lowlight: boolean;
  dashed?: boolean;
  forwardAnimation?: boolean;
  reverseAnimation?: boolean;
};

export default function SteelworkLine({
  x1,
  y1,
  x2,
  y2,
  color,
  selected,
  lowlight,
  dashed = false,
  forwardAnimation = false,
  reverseAnimation = false,
}: Props) {
  return (
    <line
      paintOrder={1}
      className={
        forwardAnimation
          ? "highlighted-line-forward"
          : reverseAnimation
            ? "highlighted-line-reverse"
            : ""
      }
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      stroke={
        lowlight
          ? colorAlpha(color, LOWLIGHT_LINE_OPACITY)
          : colorAlpha(color, 1)
      }
      strokeDasharray={dashed ? "10 10" : undefined}
      strokeWidth={
        selected
          ? dashed
            ? DASHED_LINE_THICKNESS_SELECTED
            : MAIN_LINE_THICKNESS_SELECTED
          : dashed
            ? DASHED_LINE_THICKNESS
            : MAIN_LINE_THICKNESS
      }
    />
  );
}
