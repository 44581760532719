import type { RootState } from "state/store";
import { updateMarkers } from "state/actions";

export function setFilterArea(area) {
  return {
    type: "SET_FILTER_AREA",
    payload: area,
  };
}

export function clearFilterArea() {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const filterArea = state.map.filterArea;
    if (filterArea) {
      filterArea.setMap(null);
      dispatch(setFilterArea(null));
    }
  };
}

async function updateURLParams(overlay, setSearchParams) {
  const paths = overlay
    .getPath()
    .getArray()
    .map((point) => point.toUrlValue())
    .map((value) => value.replace(",", "+"))
    .join(",");
  const search = new URLSearchParams(window.location.search);
  search.set("area", paths);
  setSearchParams(search, { replace: true });
}

function overlayListener(
  overlay: google.maps.Polygon,
  map: google.maps.Map,
  setSearchParams
) {
  return async (dispatch) => {
    const mapClickListener = map.addListener("click", () => {
      overlay.setEditable(false);
      overlay.setDraggable(false);
      document.removeEventListener("keyup", handleDeletePress);
    });
    overlay.set("mapClickListener", mapClickListener);

    const handleDeletePress = (e) => {
      if ((e.key === "Delete" || e.key === "Backspace") && !!overlay.getMap()) {
        if (
          window.confirm("Are you sure you want to delete this assignment?")
        ) {
          dispatch(clearFilterArea());

          const search = new URLSearchParams(window.location.search);
          search.delete("area");
          setSearchParams(search, { replace: true });

          dispatch(updateMarkers());
        }
      }
    };

    overlay.addListener("click", () => {
      overlay.setEditable(true);
      overlay.setDraggable(true);
      document.addEventListener("keyup", handleDeletePress);
    });
    overlay.addListener("dragstart", () => {
      overlay.set("dragged", true);
    });
    overlay.addListener("dragend", (e) => {
      overlay.set("dragged", false);
      updateURLParams(overlay, setSearchParams);
      dispatch(updateMarkers());
    });
    overlay.getPath().addListener("insert_at", (e) => {
      updateURLParams(overlay, setSearchParams);
      dispatch(updateMarkers());
    });
    overlay.getPath().addListener("remove_at", (e) => {
      updateURLParams(overlay, setSearchParams);
      dispatch(updateMarkers());
    });
    overlay.getPath().addListener("set_at", (e) => {
      if (!overlay.get("dragged")) {
        updateURLParams(overlay, setSearchParams);
        dispatch(updateMarkers());
      }
    });
  };
}

export function setFilterSelectorStatus(starting, setSearchParams) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const currentFilterArea = state.map.filterArea;
    const map: google.maps.Map = state.map.gmap;

    const urlSearchParams = new URLSearchParams(window.location.search);

    const drawingManager = new google.maps.drawing.DrawingManager({
      map: map,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      polylineOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode:
        !!currentFilterArea || !starting
          ? undefined
          : google.maps.drawing.OverlayType.POLYGON,
    });

    if (!starting && urlSearchParams.has("area")) {
      const coordinates = urlSearchParams.get("area");
      const paths = coordinates
        .split(",")
        .map((point) => point.replaceAll("+", ","))
        .map((p) => p.split(","))
        .map(
          (location: any) => new google.maps.LatLng(location[0], location[1])
        );
      if (!window.google) {
        return;
      }

      const newPolygon = new google.maps.Polygon({
        paths,
        editable: true,
        map: map,
        fillColor: "white",
        strokeColor: "white",
      });
      dispatch(setFilterArea(newPolygon));
      dispatch(overlayListener(newPolygon, map, setSearchParams));
    }

    drawingManager.addListener(
      "overlaycomplete",
      (e: { type: string; overlay: google.maps.Polygon }) => {
        e.overlay.setEditable(false);
        e.overlay.setDraggable(false);
        drawingManager.setDrawingMode(null);
        e.overlay.setOptions({
          fillColor: "white",
          strokeColor: "white",
        });
        dispatch(setFilterArea(e.overlay));
        dispatch(overlayListener(e.overlay, map, setSearchParams));
        const paths = e.overlay
          .getPath()
          .getArray()
          .map((point) => point.toUrlValue())
          .map((value) => value.replace(",", "+"))
          .join(",");
        const search = new URLSearchParams(window.location.search);
        search.set("area", paths);
        setSearchParams(search, { replace: true });
        dispatch(updateMarkers());
      }
    );

    drawingManager.setMap(map);
  };
}
