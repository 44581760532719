import React, { useRef, useState, useEffect } from "react";
import { MenuList } from "@mui/material";

interface IProps {
  x: number;
  y: number;
  items: [];
  onMapClick: () => void;
}

export const Menu: React.FC<IProps> = ({ x, y, items, onMapClick }) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const wrapper = useRef(null);

  useEffect(() => {
    setWidth(wrapper.current.getBoundingClientRect().width);
    setHeight(wrapper.current.getBoundingClientRect().height);
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  }, []); //empty dependency array so it only runs once at render
  return (
    <MenuList
      //check if menu is inspectorMode. If not then close menu after click.
      onClick={() => items.length < 3 && onMapClick()}
      ref={wrapper}
      style={{
        left: x + width > screenWidth ? x - width - 10 : x,
        top: Math.max(y + height > screenHeight ? y - height - 10 : y, 0),
        margin: 4,
        position: "absolute",
        zIndex: 3,
        backgroundColor: "white",
      }}
    >
      {items}
    </MenuList>
  );
};

export default Menu;
