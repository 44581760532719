import { RemoveDone } from "@mui/icons-material";
import MenuItem from "components/MenuItem";
import RoleWrapper from "components/RoleHOC/wrapper";
import { useLanguage } from "hooks";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import translations from "translations";

export default function FalseReview() {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <RoleWrapper keyName="supervisorFalseReview" fallback={<></>}>
      <MenuItem
        id="image-review-supervisor"
        tooltip={translations.ImageViewer.SuperFalseReview[language]}
        onClick={() => {
          navigate(`super-verify-fp-bbs?${searchParams}`);
        }}
      >
        <RemoveDone />
      </MenuItem>
    </RoleWrapper>
  );
}
