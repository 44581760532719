import React, { useContext, useState } from "react";
import { context } from "./provider";
import {
  Button,
  Dialog,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Add, Clear, Edit } from "@mui/icons-material";
import Chart from "./Chart";
import { Loading } from "views";

type Props = {};

export default function GanttChart({}: Props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { addItem, loading, editing, setEditing } = useContext(context);

  return (
    <Stack
      sx={{ position: "relative", width: "100%" }}
      alignItems="center"
      justifyContent="center"
    >
      <Paper sx={{ pb: 2, width: "90%" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pt={2}
          px={3}
          mb={2}
        >
          <Typography fontSize="large" fontWeight="bold">
            Gantt Chart of project
          </Typography>
          {loading && <Loading relative size={20} color="#404040" />}
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              size="small"
              color={editing ? "error" : "primary"}
              onClick={() => setEditing(!editing)}
              startIcon={editing ? <Clear /> : <Edit />}
            >
              {editing ? "Cancel" : "Edit"}
            </Button>

            <Button
              variant="contained"
              size="small"
              disabled={!!editing}
              onClick={() => setMenuOpen(true)}
              color="success"
              startIcon={<Add />}
            >
              Add
            </Button>
          </Stack>
        </Stack>
        <Stack alignItems="flex-end" justifyContent="center" px={3}>
          <Chart />

          <Dialog open={menuOpen} onClose={() => setMenuOpen(false)}>
            <Stack
              px={2}
              minWidth={400}
              py={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontSize="large" fontWeight={"bold"}>
                Add new task
              </Typography>
              <IconButton
                onClick={() => {
                  setMenuOpen(false);
                }}
              >
                <Clear />
              </IconButton>
            </Stack>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target as HTMLFormElement);
                const name = formData.get("name") as string;
                const start_date = formData.get("start_date") as string;
                const end_date = formData.get("end_date") as string;
                addItem(name, start_date, end_date);
                setMenuOpen(false);
              }}
            >
              <Stack spacing={1} px={2}>
                <FormControl fullWidth sx={{ m: 0 }} variant="standard">
                  <InputLabel htmlFor="task_name" shrink>
                    Task name
                  </InputLabel>
                  <Input id="task_name" type="text" name="name" />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="start_date_input" shrink>
                    Start date
                  </InputLabel>
                  <Input id="start_date_input" type="date" name="start_date" />
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="end_date_input" shrink>
                    End date
                  </InputLabel>
                  <Input id="end_date_input" type="date" name="end_date" />
                </FormControl>
                <Button type="submit">Add</Button>
              </Stack>
            </form>
          </Dialog>
        </Stack>
      </Paper>
    </Stack>
  );
}
