import md5 from "md5-hash";
import { Typography } from "@mui/material";

type Props = {
  entityName: string;
  demoMode: boolean;
  children: string;
};

export default function DemoSafeName({
  demoMode,
  children,
  entityName,
}: Props) {
  return (
    <Typography>
      {demoMode
        ? children.length > 0
          ? `${entityName} ${md5(children ?? "").substring(0, 4)}`
          : entityName
        : children}
    </Typography>
  );
}
