import { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { CanvasContext, AnnotationContext } from "../provider";

const addDefectIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 87 84"
    fill="none"
    style={{ margin: "0 0 5px 0" }}
  >
    <path
      d="M0 18.8284V77.2759C0 77.7438 0.164032 78.1968 0.463557 78.5563L4.40031 83.2804C4.78029 83.7364 5.34319 84 5.93675 84H64.6716C65.202 84 65.7107 83.7893 66.0858 83.4142L70.9142 78.5858C71.2893 78.2107 71.5 77.702 71.5 77.1716V37.5C71.5 36.3954 70.6046 35.5 69.5 35.5H65.5C64.3954 35.5 63.5 36.3954 63.5 37.5V74.5C63.5 75.6046 62.6046 76.5 61.5 76.5H9.5C8.39543 76.5 7.5 75.6046 7.5 74.5V22.5C7.5 21.3954 8.39543 20.5 9.5 20.5H50C51.1046 20.5 52 19.6046 52 18.5V15C52 13.8954 51.1046 13 50 13H5.82843C5.29799 13 4.78929 13.2107 4.41421 13.5858L0.585786 17.4142C0.210714 17.7893 0 18.298 0 18.8284Z"
      fill="#666666"
    />
    <path
      d="M32 28V44H16V51.5H32V67.5H40V51.5H56V44H40V28H32Z"
      fill="#666666"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.9548 0C68.5881 0 69.5833 0.458333 70.1262 1.00833C70.669 1.60417 74.6952 8.15833 79.0833 15.5833C83.4262 23.0083 87 29.5625 87 30.1583C87 30.7083 86.5929 31.5792 86.0952 32.0833C85.2357 32.9542 84.6024 33 68 33C51.3976 33 50.7643 32.9542 49.9048 32.0833C49.4071 31.5792 49 30.6625 49 30.0208C49 29.3333 52.3024 23.3292 56.9619 15.4458C61.35 8.1125 65.331 1.60417 65.8738 1.00833C66.3714 0.458333 67.3214 0 67.9548 0V0ZM66.1905 20.1667H69.8095V11H66.1905V20.1667ZM66.1905 27.5H69.8095V23.8333H66.1905V27.5Z"
      fill="#666666"
    />
  </svg>
);

export default function AddDefectButton() {
  const { mode, setMode, modeOptions } = useContext(CanvasContext);
  const { setSelectedAnnotation } = useContext(AnnotationContext);

  const addMode = mode === modeOptions.ADD_DEFECT;

  const onClick = () => {
    setMode(addMode ? modeOptions.VIEW : modeOptions.ADD_DEFECT);
    setSelectedAnnotation(null);
  };
  return (
    <Tooltip
      title={addMode ? "Leave add mode" : "Enter add defect mode"}
      arrow
      placement="top"
      disableInteractive
    >
      <IconButton onClick={onClick} style={{ width: "42px", height: "42px" }}>
        {mode === modeOptions.ADD_DEFECT ? <Clear /> : addDefectIcon}
      </IconButton>
    </Tooltip>
  );
}
