import { useSearchParams } from "react-router-dom";
import { setDateOfKey } from "./utils";
import { FilterKey, FilterType } from "./constants";

export function getDateDefectReportedFilter(
  searchParams: URLSearchParams
): FilterType["DATE_DEFECT_REPORTED"] {
  const dateDefectReportedParams =
    searchParams.get(FilterKey.DATE_DEFECT_REPORTED) ?? "";
  const splitDates = dateDefectReportedParams
    .split(",")
    .filter((d) => d.length > 0);
  let dateDefectReported: FilterType["DATE_DEFECT_REPORTED"] = null;
  if (splitDates.length === 1) {
    splitDates.push(splitDates[0]);
    dateDefectReported = new Date(splitDates[0]);
  } else if (splitDates.length === 2) {
    dateDefectReported = [new Date(splitDates[0]), new Date(splitDates[1])];
  } else if (splitDates.length > 2) {
    console.error("Too many dates in the dateDefectReported filter");
    dateDefectReported = [new Date(splitDates[0]), new Date(splitDates[1])];
  }
  return dateDefectReported;
}

export function useDateDefectReportedFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  // Collect the search parameters
  const dateDefectReported = getDateDefectReportedFilter(searchParams);

  // Convert the key function to each specific key
  function setDateDefectReported(startDate: Date | null, endDate: Date | null) {
    setDateOfKey(
      searchParams,
      setSearchParams,
      FilterKey.DATE_DEFECT_REPORTED,
      startDate,
      endDate
    );
  }

  // Export the functions and filters
  return {
    dateDefectReported,
    setDateDefectReported,
  };
}
