import { IssueSeverity, ObjectType } from "interfaces";
import { connect } from "react-redux";
import { updateAnnotationsData } from "state/actions";
import { RootState } from "state/store";
import { findCurrentMission } from "utils/utils";
import { IImageAnnotation } from "views/AnnotationTool/api";
import Component from "./InfoContent";

interface IStateProps {
  objectTypes: ObjectType[];
  severities: IssueSeverity[];
  image: number;
  processedPossible: boolean;
}

interface IDispatchProps {
  updateAnnotationsData: (newAnnotation, image) => void;
}

interface IOwnProps {
  annotation: IImageAnnotation;
  fixableDefects: boolean;
  setGetData: (payload) => void;
}

const mapStateToProps = (state: RootState) => {
  let currentCustomer = state.mission?.id ?? -1;
  //@ts-ignore
  currentCustomer = parseInt(currentCustomer);
  const currentMission = findCurrentMission(
    currentCustomer,
    state.user.missions
  );
  return {
    objectTypes: state.objects.objectTypes,
    severities: state.objects.issueSeverities,
    image: state.image?.current?.id || null,
    processedPossible: currentMission?.processable_defects,
  };
};

const mapDispatchToProps = {
  updateAnnotationsData,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
