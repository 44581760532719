import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import StatusBar from "components/StatusBar";
import RoleWrapper from "components/RoleHOC/wrapper";
import translations from "translations";
import { FilterCountProvider } from "providers/FilterCountProvider";
import { useLanguage, useSelector } from "hooks";
import { useFlags } from "launchdarkly-react-client-sdk";

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#0078EB",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
  },
};

export default function Report() {
  const customerExists = useSelector((state) => state.mission?.id ?? -1 > 0);
  const { language } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const { imageReport } = useFlags();
  
  let tab: boolean | number = false;
  if (location.pathname.includes("/report/object")) {
    tab = 0;
  } else if (location.pathname.includes("/report/defect")) {
    tab = 1;
  } else if (location.pathname.includes("/report/image")) {
    tab = 2;
  } else if (location.pathname.includes("/report/asset")) {
    tab = 3;
  } else if (location.pathname.includes("/report/missioninfo")) {
    tab = 4;
  } else if (location.pathname.includes("/report/vegetation")) {
    tab = 5;
  } else if (location.pathname.includes("/report/worksession")) {
    tab = 6;
  }

  return (
    <ThemeProvider theme={theme}>
      <FilterCountProvider>
        <Box
          sx={{
            direction: "row",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            zIndex: 100,
          }}
        >
          <Box
            sx={{
              height: "100%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <StatusBar
              title={translations.Menu.Tools.Reports[language]}
              sx={{ zIndex: 10 }}
            />
            <AppBar
              component="div"
              position="static"
              elevation={0}
              sx={{ zIndex: 0 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tabs value={tab} textColor="inherit">
                  <Link
                    to={`object${location.search}`}
                    replace
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Tab
                      disabled={!customerExists}
                      sx={{ color: "white" }}
                      label={
                        translations.Menu.Tools.InventoryReport.Inventory[
                          language
                        ]
                      }
                    />
                  </Link>

                  <Link
                    to={`defect${location.search}`}
                    replace
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Tab
                      disabled={!customerExists}
                      sx={{ color: "white" }}
                      label={
                        translations.Menu.Tools.DefectReport.Defect[language]
                      }
                    />
                  </Link>
                  {imageReport && (
                    <Link
                      to={`image${location.search}`}
                      replace
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Tab
                        disabled={!customerExists}
                        sx={{ color: "white" }}
                        label={
                          translations.Menu.Tools.DefectReport.ImageReport[
                            language
                          ]
                        }
                      />
                    </Link>
                  )}
                  <Link to={`asset${location.search}`} replace>
                    <Tab
                      disabled={!customerExists}
                      style={{ textDecoration: "none", color: "white" }}
                      label={translations.Menu.Tools.AssetReport[language]}
                    />
                  </Link>

                  <RoleWrapper keyName="usageReport">
                    <Link
                      to={`missioninfo${location.search}`}
                      replace
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Tab
                        sx={{ color: "white" }}
                        label={
                          translations.Menu.Tools.UsageReport.Usage[language]
                        }
                      />
                    </Link>
                  </RoleWrapper>

                  <RoleWrapper keyName="vegetationReport">
                    <Link
                      to={`vegetation${location.search}`}
                      replace
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Tab sx={{ color: "white" }} label={"Vegetation"} />
                    </Link>
                  </RoleWrapper>

                  <RoleWrapper keyName="workSessionReport">
                    <Link
                      to={`worksession${location.search}`}
                      replace
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Tab
                        sx={{ color: "white" }}
                        label={
                          translations.Menu.Tools.WorkSessionReport.Title[
                            language
                          ]
                        }
                      />
                    </Link>
                  </RoleWrapper>
                </Tabs>

                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <Close />
                </IconButton>
              </Stack>
            </AppBar>

            <Box
              sx={{
                flex: 1,
                py: 2,
                px: 4,
                bgcolor: "#eaeff1",
                overflowY: "auto",
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      </FilterCountProvider>
    </ThemeProvider>
  );
}
