import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Marker } from "interfaces";
import { HighlightMarkers, unHighlightMarkers } from "utils/utils";
import { useDispatch } from "hooks";
import { setPreviousDefectImages } from "state/actions";
import { useFlags } from "launchdarkly-react-client-sdk";

interface MyMarker extends Marker {
  missionId: string | number;
  isPreview?: boolean;
  filterActive: boolean;
  marginLeft?: number;
  marginTop?: number;
  hideMarker?: boolean;
  openedMarkers: number[];
}

function MapMarker(props: MyMarker) {
  const {
    id,
    weight,
    is_filtered,
    cluster_id,
    pole_id,
    compass_dir,
    missionId,
    image_type,
    isPreview = false,
    filterActive,
    marginLeft,
    marginTop,
    hideMarker = false,
    openedMarkers,
    viewed,
  } = props;

  const dispatch = useDispatch();
  const { structureEditorOpenOnDefault } = useFlags();

  let color2 = "#DDD";

  // The processed "green" markers should always stay behind the red ones.
  // The red ones will have a minimum z-index of 1000,
  // so we add 999 to the green ones to make sure they stay behind.
  const zIndex =
    weight > 0
      ? weight + (is_filtered ? 10000 : 0)
      : 999 + (is_filtered ? 10000 : 0);

  if (weight >= 1000) {
    color2 = "#DE0000";
  } else if (weight >= 100) {
    color2 = "#FF5634";
  } else if (image_type === 1) {
    color2 = "#B18977";
  } else if (weight >= 6) {
    color2 = "#0077FF";
  } else if (weight >= 1) {
    color2 = "#75B6FF";
  } else if (weight === 0) {
    color2 = "#C2DEFF";
  } else if (weight < 0) {
    color2 = "#81ed16";
  }
  const navigate = useNavigate();
  // Set standard border attribures
  const style = {
    backgroundColor: color2,
    zIndex: zIndex,
    borderBottomRightRadius: compass_dir ? 0 : "100%",
    transform: !isPreview
      ? `translate(-50%, -50%) rotate(${
          compass_dir ? compass_dir - 135 : 45
        }deg)`
      : `rotate(${compass_dir ? compass_dir - 135 : 45}deg)`,
    ...props,
  };

  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const image = parseInt(params.image) || null;
  return (
    <>
      {!hideMarker && (
        // biome-ignore lint/a11y/useKeyWithClickEvents: we don't want keypress on markers..
        <div
          data-testid={`mapMarker-${id}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (window.location.pathname.includes("/annotate")) {
              navigate(`/${missionId}/${id}/annotate${window.location.search}`);
            } else {
              if (searchParams.has("pole")) {
                if (pole_id) {
                  searchParams.set("pole", pole_id.toString());
                } else {
                  searchParams.delete("pole");
                }
                setSearchParams(searchParams);
              }
              dispatch(setPreviousDefectImages([]));
              const params = new URLSearchParams(window.location.search);
              //Show structure pole editor by default for steelwork clients
              if (structureEditorOpenOnDefault && pole_id) {
                params.set("poleStatus", "true");
                params.set("pole", pole_id.toString());
              }
              navigate(`/${missionId}/${id}?${params.toString()}`);
            }
          }}
          className={`marker image-${id}${
            filterActive
              ? is_filtered && !isPreview
                ? " filtered"
                : ""
              : " normal"
          }
       cluster-${cluster_id} pole-${pole_id}${isPreview ? " preview" : ""} ${
         id === image && "current"
       }`}
          onMouseEnter={() => {
            HighlightMarkers(id, cluster_id, pole_id);
          }}
          onMouseLeave={() => {
            unHighlightMarkers(id, cluster_id, pole_id);
          }}
          style={style}
        />
      )}
      {(!!openedMarkers?.includes(id) || !!viewed) && (
        <div
          className="openedMarker"
          style={{
            marginLeft: props.marginLeft,
            zIndex: zIndex,
            marginTop: props.marginTop,
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg">
            <title>Image seen</title>
            <path
              d="M 0 6 L 4 9 L 10 0"
              stroke="white"
              fill="none"
              strokeWidth="3"
              strokeOpacity="1"
            />
          </svg>
        </div>
      )}
      {id === image && !isPreview && (
        <div
          className="crosshair"
          style={{ marginLeft: props.marginLeft, marginTop: props.marginTop }}
        >
          <svg
            className="umbrella"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title"
          >
            <title>Current image</title>
            <path
              d="M0,25 L20,25 M50,25 L30,25 M25,0 L25,20 M25,50 L25,30 M25,25 L25,25 z"
              stroke="white"
              strokeWidth="3"
              strokeOpacity="1"
            />
          </svg>
        </div>
      )}
      {id === image && !isPreview && (
        <div
          className={`connectedLine ${
            Math.sign(marginTop) !== Math.sign(marginLeft)
              ? "positiveLine"
              : "negativeLine"
          }`}
          style={{
            width: Math.abs(marginLeft) || 0,
            height: Math.abs(marginTop) || 0,
            marginTop: marginTop < 0 ? style.marginTop : 0,
            marginLeft: marginLeft < 0 ? style.marginLeft : -0,
            zIndex: 2,
          }}
        >
          <svg
            height={Math.abs(marginTop) || 0}
            width={Math.abs(marginLeft) || 0}
          >
            <title>Line to current image</title>
            <line
              x1="0"
              y1={
                (Math.sign(marginTop) !== Math.sign(marginLeft)
                  ? Math.abs(marginTop)
                  : 0) || 0
              }
              x2={Math.abs(marginLeft) || 0}
              y2={
                (Math.sign(marginTop) !== Math.sign(marginLeft)
                  ? 0
                  : Math.abs(marginTop)) || 0
              }
            />
          </svg>
        </div>
      )}
    </>
  );
}

export default MapMarker;
