import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Stack,
  Chip,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ProgressBar from "views/Upload/Images/ProgressBar/ProgressBar";
import { round } from "utils";
import { PulseLoader } from "react-spinners";
import { useTranslation } from "hooks";

type Props = {
  open?: boolean;
  loading: boolean;
  title: string;
  description: string;
  entity: string;
  assignments: {
    name: string;
    progress: {
      analysis: {
        assigned: number;
        completed: number;
      };
      criticalAnalysis: {
        assigned: number;
        completed: number;
      };
    };
  }[];
};

export default function AssignmentList({
  open,
  title,
  description,
  entity,
  assignments,
  loading,
}: Props) {
  const AnalysisTranslation = useTranslation("Analysis");
  const CriticalAnalysisTranslation = useTranslation("CriticalAnalysis");
  const ofTranslation = useTranslation("Of");
  const notAssignedTranslation = useTranslation("NotAssigned");

  return (
    <Accordion defaultExpanded={!!open}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <PulseLoader margin={1} size={7} speedMultiplier={0.7} />
            ) : (
              <Chip label={assignments.length} />
            )}
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <Typography>{title}</Typography>
          </Grid>
          <Grid item xs={7} display="flex" alignItems="center">
            <Typography sx={{ color: "text.secondary" }}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={4} display="flex">
            <Typography fontWeight="bold">{entity}</Typography>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="center">
            <Typography fontWeight="bold">{AnalysisTranslation}</Typography>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="center">
            <Typography fontWeight="bold">
              {CriticalAnalysisTranslation}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2 }} />
        <Stack divider={<Divider />}>
          {assignments.map((status, i) => (
            <Grid key={`${i}`} container spacing={2} sx={{ py: 1 }}>
              <Grid display="flex" alignItems="center" item xs={4}>
                {status.name}
              </Grid>
              <Grid
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                item
                xs={4}
              >
                <ProgressBar
                  disabled={status.progress.analysis.assigned === 0}
                  percentage={
                    status.progress.analysis.completed /
                    status.progress.analysis.assigned
                  }
                />
                <Typography>
                  {status.progress.analysis.assigned > 0
                    ? `${round(
                        status.progress.analysis.completed / 1000,
                        1
                      )} km ${ofTranslation} ${round(
                        status.progress.analysis.assigned / 1000,
                        1
                      )} km`
                    : notAssignedTranslation}
                </Typography>
              </Grid>
              <Grid
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                item
                xs={4}
              >
                <ProgressBar
                  disabled={status.progress.criticalAnalysis.assigned === 0}
                  percentage={
                    status.progress.criticalAnalysis.completed /
                    status.progress.criticalAnalysis.assigned
                  }
                />
                <Typography>
                  {status.progress.criticalAnalysis.assigned > 0
                    ? `${round(
                        status.progress.criticalAnalysis.completed / 1000,
                        1
                      )} km ${ofTranslation} ${round(
                        status.progress.criticalAnalysis.assigned / 1000,
                        1
                      )} km`
                    : notAssignedTranslation}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
