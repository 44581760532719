import React from "react";
import HeaderButton, { HeaderButtonContext } from "../HeaderButton";
import {
  PanTool as PanToolIcon,
  Add as AddIcon,
  Clear,
  Warning as WarningIcon,
  Save as SaveIcon,
  Restore as RestoreIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import { regionsAreEqual, canSaveObject } from "../Utils";
import { defectOnly } from "../Annotator/reducers/general-reducer";
import translations from "translations";
import "./style.scss";
import { useLanguage } from "hooks";

export var Header = function Header({
  onHeaderButtonClick,
  onToolButtonClick,
  state,
  objectTypes,
  allObjectHuman,
  user,
  setCanSwitchImage,
  issueCategories,
}) {
  const { language } = useLanguage();
  let somethingChanged = regionsAreEqual(
    state.regions,
    state.lastSavedRegions,
    setCanSwitchImage,
    state.allObjectHuman,
    true
  );

  var canSaveArray = state.regions?.map((region) => {
    let result = canSaveObject(region, objectTypes, issueCategories);
    return result;
  });

  var saveErrorMessage = canSaveArray?.find((value) => value.canSave === false)
    ? canSaveArray.find((value) => value.canSave === false).message
    : translations.AnnotationTool.Save[language];

  var canSave = canSaveArray?.every((region) => region.canSave);

  const allMLConfirmed = state.regions.every((region) =>
    region.workflow_status.every((v) => v !== 1)
  );

  return (
    <div className="header">
      <div className="toolActions">
        <HeaderButtonContext.Provider
          value={{ onHeaderButtonClick: onToolButtonClick }}
        >
          <HeaderButton
            id="select"
            name={translations.AnnotationTool.Select[language]}
            Icon={PanToolIcon}
          />
          {!defectOnly(user) && (
            <HeaderButton
              id="detection"
              name={translations.AnnotationTool.Detection[language]}
              Icon={AddIcon}
            />
          )}
          <HeaderButton
            id="defect"
            name={translations.AnnotationTool.Defect[language]}
            Icon={WarningIcon}
          />
        </HeaderButtonContext.Provider>
      </div>
      <div className="headerActions">
        <HeaderButtonContext.Provider value={{ onHeaderButtonClick }}>
          {!defectOnly(user) && (
            <HeaderButton
              id="verify all"
              name={"Verify all "}
              Icon={!allMLConfirmed ? CheckBoxOutlineBlankIcon : CheckBoxIcon}
              disabled={allMLConfirmed}
            />
          )}
          <HeaderButton
            id="Reset"
            name={translations.AnnotationTool.Reset[language]}
            Icon={RestoreIcon}
            disabled={somethingChanged && !state.allObjectHuman}
          />

          <HeaderButton
            id="Save"
            name={saveErrorMessage}
            Icon={SaveIcon}
            disabled={!allObjectHuman && (!canSave || somethingChanged)}
          />
          <HeaderButton
            id="Close"
            name={translations.AnnotationTool.Close[language]}
            Icon={Clear}
          />
        </HeaderButtonContext.Provider>
      </div>
    </div>
  );
};
export default Header;
