import { useState, useEffect } from "react";
import { authorizedGet } from "utils/request";

interface IProject {
  id: number;
  name: string;
  deleted: boolean;
  group: { id: number; name: string } | null;
}

/**
 * A React hook that collects projects as admin
 *
 *  @example
 *  ```javascript
 *  const [projects, loading] = useAdminProjects();
 *  ```
 */
export function useAdminProjects(): [IProject[], boolean] {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  function collectProjects() {
    let endpoint = "/admin/project";
    setLoading(true);
    authorizedGet<{ projects: IProject[] }>(endpoint)
      .then((response) => {
        setLoading(false);
        setProjects(response.projects);
      })
      .catch((err) => {
        setLoading(false);
        setProjects([]);
        console.error("Failed to collect responsible annotator stats", err);
      });
  }

  useEffect(() => {
    collectProjects();
  }, []);

  return [projects, loading];
}
