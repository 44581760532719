import { useMemo } from "react";
import { useMetaData, useSelection } from "../hooks";
import { Stack, Tooltip, Typography } from "@mui/material";
import { IOverview } from "../utils";
import {
  ColorCircle,
  ColorCircleCenter,
  TableData,
  CellExtraPadding,
  NoDataCell,
} from "./StyledComponents";

type Props = {
  poleId: number;
  overviews: IOverview[];
  onMouseOver: (x: number, y: number) => void;
  y: number;
  hoveredX: number | null;
  hoveredY: number | null;
};

export function hexColorToRed(hex: string) {
  return parseInt(hex.substring(1, 3), 16);
}

export function hexColorToGreen(hex: string) {
  return parseInt(hex.slice(3, 5), 16);
}

export function isDarkerGreenAndHigherRed(color1: string, color2: string) {
  const green1 = hexColorToGreen(color1);
  const green2 = hexColorToGreen(color2);
  const red1 = hexColorToRed(color1);
  const red2 = hexColorToRed(color2);

  if (green1 < green2) {
    return true;
  } else if (green1 === green2) {
    return red1 > red2;
  } else {
    return false;
  }
}

export default function TableBodyRow({
  poleId,
  overviews,
  onMouseOver,
  y,
  hoveredX,
  hoveredY,
}: Props) {
  const { headers } = useMetaData();
  const { setSelectedPoleId, setSelectedComponentId } = useSelection();
  const rowItems = useMemo(() => {
    // We loop through the headers such that the order becomes automatically correct.
    return headers.map((header, x) => {
      // Extract the overview items that match the header
      // Each structure status can have multiple overview items (one per component placement).
      // This logic will extract all components that has the same name as the column,
      // so you essentially have a list of all the placements.
      const items = overviews.filter((o) => o.component.name === header);

      // Find the color with the darkest green and highest red in the items
      const targetColorHex = items.reduce((acc, item) => {
        return isDarkerGreenAndHigherRed(item.status.color, acc)
          ? item.status.color
          : acc;
      }, "#FFFFFF");
      // Find the name of the color with the target color
      const targetColorName = items.find(
        (item) => item.status.color === targetColorHex
      )?.status.name;

      return {
        items,
        worstStatus: {
          color: targetColorHex,
          name: targetColorName,
        },
      };
    });
    // Update when the overviews change
    // (we want stringify to make updates consistent)
  }, [headers, overviews]);

  function getTooltipOfRowItem(item: (typeof rowItems)[number]) {
    return (
      <Stack spacing={1}>
        {item.items
          .sort((itemA, itemB) =>
            itemA.placement.name < itemB.placement.name ? -1 : 1
          )
          .map((item, i) => (
            <Stack
              key={`tooltip_${i}`}
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-start"
            >
              <ColorCircle color={item.status.color} />
              <Typography fontSize="small">{item.placement.name}</Typography>
            </Stack>
          ))}
      </Stack>
    );
  }

  return (
    <>
      {rowItems.map((item, x) => {
        // Calculate the background color based
        // on the hover location and the item
        let backgroundColor = x % 2 === 0 ? "#EFEFEF" : "#FFFFFF";
        if (hoveredX === x && hoveredY === y) {
          backgroundColor = "#FFEA9D";
        } else if (hoveredX === x || hoveredY === y) {
          backgroundColor = "#FEF7DD";
        }

        // If there are no items, show an empty cell
        if (item.items.length === 0) {
          return (
            <TableData key={`empty_${x}`} onMouseOver={() => onMouseOver(x, y)}>
              <CellExtraPadding color={backgroundColor}>
                <NoDataCell>N/A</NoDataCell>
              </CellExtraPadding>
            </TableData>
          );
        }

        // Show a real cell if we have data
        return (
          <Tooltip
            key={`tooltip_${x}`}
            title={getTooltipOfRowItem(item)}
            placement="right"
            arrow
            disableInteractive
          >
            <TableData
              key={`circle_${x}`}
              onClick={() => {
                setSelectedPoleId(poleId);
                setSelectedComponentId(item.items[0].component.id);
              }}
              onMouseOver={() => onMouseOver(x, y)}
            >
              <CellExtraPadding color={backgroundColor}>
                <ColorCircleCenter color={item.worstStatus.color}>
                  {item?.worstStatus?.name?.substring?.(0, 3) ?? ""}{" "}
                </ColorCircleCenter>
              </CellExtraPadding>
            </TableData>
          </Tooltip>
        );
      })}
    </>
  );
}
