import React from "react";
import styled from "styled-components";
import { SecondaryFont } from "arkionui/constants";

const Bar = styled.div`
	display: inline-block;
	padding: 4px 8px;
	border-radius: 8px;
	background-color: #535353;
	color: #fff;
	font-weight: 600;
	font-size: 11px;
	font-family: "${SecondaryFont}", sans-serif;

	${(props) => props.onClick && "cursor: pointer;"}
`;

interface IProps {
  children: React.ReactNode;
  onClick?: () => void;
}
export default function TextBar(props: IProps) {
  const { children, ...htmlProps } = props;

  return <Bar {...htmlProps}>{children}</Bar>;
}
