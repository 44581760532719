import { connect } from "react-redux";
import { RootState } from "state/store";
import { updateGridStatistics, updateDefectMetrics } from "state/actions";
import Component from "./Statistics";

interface IStateProps {
  customerId: number;
  gridLength: number;
  inspectedLength: number;
  reflightLength: number;
  imageLength: number;
  skyqraft_employee: boolean;
  machineDetections: number;
  machineInventoryDetections: number;
  machineDefectDetections: number;
  humanDetections: number;
  humanInventoryDetections: number;
  humanDefectDetections: number;
  workflowStatus: Array<any>;
  defectFixedObjects: number;
  issueCategoryCount: Array<any>;
  truePositive: Array<any>;
  falsePositive: Array<any>;
  falseNegative: Array<any>;
  totalPoles: number;
  metrics: any;
}

interface IDispatchProps {
  updateGridStatistics: typeof updateGridStatistics;
  updateDefectMetrics: typeof updateDefectMetrics;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    customerId: state.mission?.id ?? -1,
    skyqraft_employee: state.user.skyqraft_employee,
    gridLength: state.statistics.totalGridLength,
    inspectedLength: state.statistics.totalInspectedLength,
    reflightLength: state.statistics.totalReflightLength,
    imageLength: state.statistics.totalImageLength,
    machineDetections: state.statistics.machineDetections,
    machineInventoryDetections: state.statistics.machineInventoryDetections,
    machineDefectDetections: state.statistics.machineDefectDetections,
    humanDetections: state.statistics.humanDetections,
    humanInventoryDetections: state.statistics.humanInventoryDetections,
    humanDefectDetections: state.statistics.humanDefectDetections,
    workflowStatus: state.statistics.workflowStatus,
    defectFixedObjects: state.statistics.defectFixedObjects,
    issueCategoryCount: state.statistics.issueCategoryCount,
    truePositive: state.statistics.truePositive,
    falsePositive: state.statistics.falsePositive,
    falseNegative: state.statistics.falseNegative,
    totalPoles: state.statistics.totalPoles,
    metrics: state.metrics,
  };
};

const mapDispatchToProps = {
  updateGridStatistics,
  updateDefectMetrics,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export { default as AllDetections } from "./AllDetections";
export { default as DetectionCategories } from "./DetectionCategories";
export { default as IssueCategories } from "./IssueCategories";
export { default as MachineDetectionCategories } from "./MachineDetectionCategories";
export { default as MachineDetectionCategoriesChart } from "./MachineDetectionCategoriesChart";
export { default as workflowStatus } from "./WorkflowStatus";
