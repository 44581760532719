import { useContext } from "react";
import { FilterCountContext } from "providers/FilterCountProvider";

export function useFlightCount() {
  const { flight, setFlightTabActive, flightLoading } =
    useContext(FilterCountContext);

  return {
    counts: flight,
    loading: flightLoading,
    setActive: setFlightTabActive,
  };
}
