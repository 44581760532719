import { axiosInstance } from "utils/request";

interface IComponent {
  id: number;
  name: string;
}

interface IPlacement {
  id: number;
  name: string;
}

export interface IStatus {
  id: number;
  color: string;
  name: string;
  value_system_id: number;
}

export interface IOverview {
  component: IComponent;
  placement: IPlacement;
  status: IStatus;
}

interface IPole {
  id: number;
  label: string;
  clientId: string;
}

export interface IData {
  overview: IOverview[];
  pole: IPole;
}

export async function getStatusData(
  projectId: number,
  nPoles: number,
  offset: number,
  searchValue: string
) {
  const response = await axiosInstance.get<IData[]>(
    `/project/${projectId}/pole/heatmap`,
    {
      params: {
        count: nPoles,
        offset,
        search: searchValue,
      },
    }
  );

  return response.data;
}

export async function getTotalStatusCount(
  projectId: number,
  searchValue: string
) {
  interface IResponse {
    count: number;
  }
  const response = await axiosInstance.get<IResponse>(
    `/project/${projectId}/pole/heatmap/count`,
    {
      params: {
        search: searchValue,
      },
    }
  );

  return response.data.count;
}

export async function getStatusTypes(projectId: number) {
  interface IResponse {
    status: IStatus[];
  }
  const response = await axiosInstance.get<IResponse>(
    `/project/${projectId}/pole/heatmap/status`
  );

  return response.data.status;
}

export async function getImage(
  projectId: number,
  poleId: number,
  componentId: number
) {
  interface IAnnotation {
    x: number;
    y: number;
    w: number;
    h: number;
    color: string;
  }
  interface IImage {
    src: string;
    id: number;
    annotations: IAnnotation[];
  }
  const response = await axiosInstance.get<IImage>(
    `/project/${projectId}/pole/${poleId}/component/${componentId}/image`
  );
  return response.data;
}
