//This file has has everything to check that the pasted annotation object is valid.

const expectedKeys = [
  "confidence",
  "creator",
  "fixed",
  "h",
  "id",
  "item_id",
  "objectHasNoDefect",
  "processed",
  "reported",
  "rotation",
  "severities",
  "skyqraft_hidden",
  "steelwork",
  "type_comment",
  "type_id",
  "types",
  "updated_by",
  "w",
  "workflow_status",
  "x",
  "y",
  "isLocked",
  "visible",
  "categories",
  "is_defect",
  "highlighted",
  "hover",
  "active",
  "client_types",
  "editIndex",
  "editType",
];

function isNumberArray(arr): arr is number[] {
  return Array.isArray(arr) && arr.every((item) => typeof item === "number");
}

function isStringArray(arr): arr is string[] {
  return Array.isArray(arr) && arr.every((item) => typeof item === "string");
}

function isBooleanArray(arr): arr is boolean[] {
  return Array.isArray(arr) && arr.every((item) => typeof item === "boolean");
}

function isNullableNumberArray(arr): arr is (number | null)[] {
  return (
    Array.isArray(arr) &&
    arr.every((item) => typeof item === "number" || item === null)
  );
}

function isNullableObjectArray(arr): arr is (object | null)[] {
  return (
    Array.isArray(arr) &&
    arr.every((item) => typeof item === "object" || item === null)
  );
}

function validateSteelwork(steelwork): boolean {
  return (
    steelwork === null ||
    (typeof steelwork === "object" &&
      typeof steelwork.bolt_condition === "object" &&
      (typeof steelwork.bolt_condition.id === "number" ||
        steelwork.bolt_condition.id === null) &&
      (typeof steelwork.bolt_condition.name === "string" ||
        steelwork.bolt_condition.name === null) &&
      typeof steelwork.child_link === "object" &&
      (typeof steelwork.child_link.id === "number" ||
        steelwork.child_link.id === null) &&
      (typeof steelwork.child_link.image_id === "number" ||
        steelwork.child_link.image_id === null) &&
      typeof steelwork.direction === "object" &&
      typeof steelwork.direction.id === "number" &&
      typeof steelwork.direction.name === "string" &&
      typeof steelwork.id === "number" &&
      typeof steelwork.item_id === "string" &&
      typeof steelwork.parent_link === "object" &&
      (typeof steelwork.parent_link.id === "number" ||
        steelwork.parent_link.id === null) &&
      (typeof steelwork.parent_link.image_id === "number" ||
        steelwork.parent_link.image_id === null) &&
      (typeof steelwork.pole_id === "number" || steelwork.pole_id === null) &&
      typeof steelwork.second_direction === "object" &&
      (typeof steelwork.second_direction.id === "number" ||
        steelwork.second_direction.id === null) &&
      (typeof steelwork.second_direction.name === "string" ||
        steelwork.second_direction.name === null) &&
      typeof steelwork.steel_grading === "object" &&
      (typeof steelwork.steel_grading.id === "number" ||
        steelwork.steel_grading.id === null) &&
      (typeof steelwork.steel_grading.name === "string" ||
        steelwork.steel_grading.name === null) &&
      (steelwork.save_error === undefined ||
        typeof steelwork.save_error === "string" ||
        steelwork.save_error === null))
  );
}

export default function objectPasteValidation(data): boolean {
  const hasExpectedKeys =
    Object.keys(data).every((key) => expectedKeys.includes(key)) &&
    expectedKeys.every((key) => key in data);

  return (
    hasExpectedKeys &&
    isNumberArray(data.confidence) &&
    isStringArray(data.creator) &&
    (typeof data.fixed === "string" || data.fixed === null) &&
    typeof data.h === "number" &&
    typeof data.id === "string" &&
    (typeof data.item_id === "string" || data.item_id === null) &&
    typeof data.objectHasNoDefect === "boolean" &&
    isNullableObjectArray(data.processed) &&
    (typeof data.reported === "string" || data.reported === null) &&
    typeof data.rotation === "number" &&
    isNullableNumberArray(data.severities) &&
    isBooleanArray(data.skyqraft_hidden) &&
    validateSteelwork(data.steelwork) &&
    isStringArray(data.type_comment) &&
    isNumberArray(data.type_id) &&
    isNumberArray(data.types) &&
    isStringArray(data.updated_by) &&
    typeof data.w === "number" &&
    isNumberArray(data.workflow_status) &&
    typeof data.x === "number" &&
    typeof data.y === "number" &&
    typeof data.isLocked === "boolean" &&
    typeof data.visible === "boolean" &&
    isNumberArray(data.categories) &&
    isBooleanArray(data.is_defect) &&
    typeof data.highlighted === "boolean" &&
    typeof data.hover === "boolean" &&
    typeof data.active === "boolean" &&
    Array.isArray(data.client_types) &&
    (typeof data.editIndex === "number" || data.editIndex === null) &&
    (typeof data.editType === "string" || data.editType === null)
  );
}
