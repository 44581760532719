import { useProjectName } from "hooks/useProjectName";

type Props = {
  projectID: number;
};

export default function ProjectDropdown({ projectID }: Props) {
  const { fullname, region } = useProjectName(projectID);

  return <option value={projectID}>{`${region} ${fullname}`}</option>;
}
