import { useContext } from "react";

import { FilterCountContext } from "providers/FilterCountProvider";

export function useDetectionCount() {
  const { detection, detectionLoading, setDetectionTabActive } =
    useContext(FilterCountContext);

  return {
    counts: detection,
    loading: detectionLoading,
    setActive: setDetectionTabActive,
  };
}
