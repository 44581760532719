import { Fragment } from "react";
import Image from "./Image";
import { getCDNURL } from "authentication/apiDetails";
type Props = {
  imageSrc: string;
  quality: string;
  imagePosition: {
    topLeft: {
      x: number;
      y: number;
    };
    bottomRight: {
      x: number;
      y: number;
    };
  };
  dimensions: {
    canvasWidth: number;
    canvasHeight: number;
  };
  mat: {
    a: number;
    d: number;
    e: number;
    f: number;
  };
  onLoad?: (payload) => void;
  cdn: string | null;
};

const imageCount = {
  low: [1, 1],
  medium: [5, 5],
  high: [10, 10],
};

const zIndexes = {
  low: 1,
  medium: 2,
  high: 3,
};

export default function ImageLayer({
  imageSrc,
  quality,
  imagePosition,
  dimensions,
  mat,
  onLoad,
  cdn,
}: Props) {
  const [xImageCount, yImageCount] = imageCount[quality];
  const zIndex = zIndexes[quality];

  // Extract width and height of image and canvas
  const currentWidth = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const currentHeight = imagePosition.bottomRight.y - imagePosition.topLeft.y;
  const canvasWidth = dimensions.canvasWidth;
  const canvasHeight = dimensions.canvasHeight;

  // Compute offsets in relation to viewing width/height
  const rightOffset = currentWidth - (mat.e / mat.a + canvasWidth);
  const bottomOffset = currentHeight - (mat.f / mat.d + canvasHeight);

  // Compute percentage from edge not visible
  const leftEdge = Math.min(
    mat.e / mat.a <= 0 ? 0 : mat.e / mat.a / currentWidth,
    1
  );
  const rightEdge =
    1 - Math.min(rightOffset <= 0 ? 0 : rightOffset / currentWidth, 1);
  const topEdge = Math.min(
    mat.f / mat.d <= 0 ? 0 : mat.f / mat.d / currentHeight,
    1
  );
  const bottomEdge =
    1 - Math.min(bottomOffset <= 0 ? 0 : bottomOffset / currentHeight, 1);

  if (
    quality === "medium" &&
    (currentWidth < 1200 || currentWidth > 15000 || Number.isNaN(currentWidth))
  ) {
    return <></>;
  }
  if (
    quality === "high" &&
    (currentWidth < 3000 || currentWidth > 30000 || Number.isNaN(currentWidth))
  ) {
    return <></>;
  }
  return (
    <>
      {[...Array(xImageCount)].map((x, i) => {
        const xPercent = 1 / xImageCount;
        return (
          <Fragment key={`row-${i}`}>
            {[...Array(yImageCount)].map((y, j) => {
              const yPercent = 1 / yImageCount;
              const topLeft = {
                x: imagePosition.topLeft.x + i * xPercent * currentWidth,
                y: imagePosition.topLeft.y + j * xPercent * currentHeight,
              };
              const bottomRight = {
                x: imagePosition.topLeft.x + (i + 1) * yPercent * currentWidth,
                y: imagePosition.topLeft.y + (j + 1) * yPercent * currentHeight,
              };

              const inView =
                i * xPercent < rightEdge &&
                (i + 1) * xPercent > leftEdge &&
                j * yPercent < bottomEdge &&
                (j + 1) * yPercent > topEdge;

              const base = `${getCDNURL()}/image`;
              const cdn_components = cdn.split(".");
              const extension = cdn_components.pop();
              const path = cdn_components.join(".");
              const link = encodeURI(
                `${base}/${path}_${quality}_${i}_${j}.${extension}`
              );

              return (
                (inView || quality === "low") && (
                  <Image
                    key={`${i}_${j}_${quality}`}
                    zIndex={zIndex}
                    imagePosition={{ topLeft, bottomRight }}
                    imageSrc={link}
                    onLoad={onLoad}
                  />
                )
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
}
