import React from "react";
import MenuItem from "components/MenuItem";
import { Slider } from "@mui/material";
import translations from "translations";
import { useLanguage } from "hooks";

interface Props {
  zoomSpeed;
  setZoomSpeed: Function;
}

export default function ZoomSpeed({ zoomSpeed, setZoomSpeed }: Props) {
  const { language } = useLanguage();
  function onChange(e, value) {
    setZoomSpeed(value);
  }

  return (
    <MenuItem id="zoomSpeed" passive>
      <p>{translations.Menu.Settings.ZoomSpeed[language]}</p>
      <Slider
        aria-label="Volume"
        value={zoomSpeed}
        valueLabelDisplay="auto"
        onChange={onChange}
        min={1}
        max={100}
        style={{ width: 130, marginRight: 20 }}
      />
    </MenuItem>
  );
}
