import { Box, FormControlLabel, Switch } from "@mui/material";
import { useCurrentProject, useDispatch, useSelector } from "hooks";
import { useEffect } from "react";
import { setHeatmapEnabled, updateMarkers } from "state/actions";

export default function HeatmapToggle() {
  const heatmapEnabled = useSelector((state) => state.ui.heatmapEnabled);
  const currentProject = useCurrentProject();
  const dispatch = useDispatch();

  function onChange(e, checked) {
    if (!checked) {
      const confirmation = window.confirm(
        "Are you sure you want to disable heatmap? This may crash the browser"
      );
      if (!confirmation) {
        return;
      }
    }
    dispatch(setHeatmapEnabled(checked));
    dispatch(updateMarkers());
  }

  useEffect(() => {
    // Always enable heatmap when project ID changes
    // To avoid large crashes
    dispatch(setHeatmapEnabled(true));
  }, [currentProject?.id]);
  return (
    <Box px={2} py={1}>
      <FormControlLabel
        labelPlacement="start"
        control={<Switch />}
        label="Heatmap"
        sx={{ width: "100%" }}
        checked={heatmapEnabled}
        onChange={onChange}
      />
    </Box>
  );
}
