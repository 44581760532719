import { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Clear, AddBoxOutlined } from "@mui/icons-material";
import { CanvasContext, AnnotationContext } from "../provider";
import RoleWrapper from "components/RoleHOC/wrapper";

export default function AddDetectionButton() {
  const { mode, setMode, modeOptions } = useContext(CanvasContext);
  const { setSelectedAnnotation } = useContext(AnnotationContext);

  const addMode = mode === modeOptions.ADD_DETECTION;

  const onClick = () => {
    setMode(addMode ? modeOptions.VIEW : modeOptions.ADD_DETECTION);
    setSelectedAnnotation(null);
  };

  return (
    <RoleWrapper keyName="annotationToolEditObjects">
      <Tooltip
        title={addMode ? "Leave add mode" : "Enter add detection mode"}
        arrow
        placement="top"
        disableInteractive
      >
        <IconButton onClick={onClick} style={{ width: "42px", height: "42px" }}>
          {mode === modeOptions.ADD_DETECTION ? (
            <Clear />
          ) : (
            <AddBoxOutlined style={{ width: "27px", height: "27px" }} />
          )}
        </IconButton>
      </Tooltip>
    </RoleWrapper>
  );
}
