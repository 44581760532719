import React from "react";
import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import GoogleMapsIcon from "views/image/GoogleMapsIcon.png";

interface IProps {
  data;
}

const GMapItem: React.FC<IProps> = ({ data }) => {
  let link = `https://maps.google.com?q=${data.lat},${data.lng}&t=k`;
  return (
    <MenuItem onClick={() => window.open(link)}>
      <ListItemIcon>
        <img
          src={GoogleMapsIcon}
          alt={"GoogleMaps"}
          style={{ width: 20, height: 20 }}
        />
      </ListItemIcon>
      <Typography>Google Maps</Typography>
    </MenuItem>
  );
};

export default GMapItem;
