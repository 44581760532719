import { useState, useEffect } from "react";
import { authorizedGet } from "utils/request";

export type IUpload = {
  id: number;
  mission: string;
  created: string;
  folder: string;
};

interface IResponse {
  uploads: IUpload[];
}

/**
 * A React hook that collects your last uploads
 *
 *  @example
 *  ```javascript
 *  const {data, loading} = useLastUploads();
 *  ```
 */
export function useLastUploads(): { data: IUpload[]; loading: boolean } {
  const [lastUploads, setLastUploads] = useState<IUpload[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  async function collectDefectCount() {
    // Define API endpoint
    const endpoint = "/upload/overview";

    setLoading(true);
    authorizedGet<IResponse>(endpoint)
      .then((response) => {
        setLoading(false);
        if (response?.uploads) {
          setLastUploads(response.uploads);
        } else {
          console.error("Failed to get response from Arkion");
          setLastUploads([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setLastUploads([]);
      });
  }

  useEffect(() => {
    collectDefectCount();
  }, []);

  return { data: lastUploads, loading };
}
