import React, { memo, createContext, useContext } from "react";
import { Tooltip, Button } from "@mui/material";
import { useEventCallback } from "utils";

export var HeaderButtonContext = createContext();
var MemoizedHeaderButton = memo(
  function (_ref) {
    var disabled = _ref.disabled,
      name = _ref.name,
      id = _ref.id,
      Icon = _ref.Icon,
      onClick = _ref.onClick;

    return (
      <Tooltip title={name} arrow>
        <span style={{ height: "100%" }}>
          <Button
            variant="text"
            color="inherit"
            id={id}
            disabled={disabled}
            onClick={onClick}
            sx={{ height: "100%" }}
          >
            <Icon />
          </Button>
        </span>
      </Tooltip>
    );
  },
  function (prevProps, nextProps) {
    return (
      prevProps.name === nextProps.name &&
      prevProps.disabled === nextProps.disabled
    );
  }
);
export var HeaderButton = function HeaderButton(_ref2) {
  var name = _ref2.name,
    disabled = _ref2.disabled,
    width = _ref2.width,
    manualOnClick = _ref2.onClick,
    id = _ref2.id,
    Icon = _ref2.Icon;

  var _useContext = useContext(HeaderButtonContext),
    onHeaderButtonClick = _useContext.onHeaderButtonClick;

  var onClick = useEventCallback(function () {
    return onHeaderButtonClick(id);
  });
  return React.createElement(MemoizedHeaderButton, {
    name: name,
    disabled: disabled,
    Icon: Icon,
    onClick: manualOnClick ? manualOnClick : onClick,
    id: id,
    width: width,
  });
};
export default HeaderButton;
