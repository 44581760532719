import { useEffect, useState } from "react";

export function LoadingDots() {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === "...") {
          return ".";
        } else {
          return `${prevDots}.`;
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <span
      style={{
        // Force three characters wide
        display: "inline-block",
        width: "1.1ch",
        textAlign: "left",
      }}
    >
      {dots}
    </span>
  );
}
