import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useSelector, useTranslation } from "hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRafState } from "react-use";
import { compose, scale, translate } from "transformation-matrix";
import { CanvasContext } from "views/AnnotationTool/provider";
import { reviewModes } from "constants/imageReview";
import { useInputListener } from "../AnnotationPreview/hooks/inputListener";
import type { IAnnotation } from "views/AnnotationTool/api";
import AnnotationsList from "./AnnotationsList";
import RegionShapes from "../AnnotationPreview/Canvas/RegionShapes/RegionShapes";

export interface IProps {
  reviewMode: (typeof reviewModes)[keyof typeof reviewModes];
  imageId: number;
  annotations: IAnnotation[];
  renderAnnotationReviewButtons?: (typeId: number) => JSX.Element;
  loading: boolean;
  onAnnotationTypeUpdate?: (args: {
    typeId: number;
    workflow_status?: number;
    type?: number;
    severity?: number;
  }) => void;
}

export default function Annotations(props: IProps) {
  const {
    reviewMode,
    imageId,
    annotations,
    renderAnnotationReviewButtons,
    loading,
    onAnnotationTypeUpdate,
  } = props;

  const setDateTranslation = useTranslation("SetDate");
  const ClearTranslation = useTranslation("Clear");
  const CloseTranslation = useTranslation("Close");
  const getDataFailedTranslation = useTranslation("GetDataFailed");
  const SaveTranslation = useTranslation("Save");

  const zoomSpeed = useSelector((state) => state.imageViewer.zoomSpeed);
  const fixableDefects = useSelector(
    (state) =>
      state.user.missions.find((m) => m.id === state.mission?.id)
        ?.fixable_defects
  );

  const autoSpeedZoomInReviewModes = useSelector(
    (state) => state.user.auto_speed_zoom_in_review_modes
  );

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [annotationListScroll, setAnnotationListScroll] = useState(false);
  const annotationListRef = useRef(null);

  const [lockedRegion, setLockedRegion] = useRafState(null);
  const [hoveredRegion, setHoveredRegion] = useRafState(null);
  const [selectedRegions, setSelectedRegions] = useRafState([]);
  const [getDate, setGetDate] = useRafState({
    visible: false,
    callback: (e) => {},
    comment: "",
    isProcessDefect: false,
  });

  const {
    imageDimensions,
    setMatrix,
    setZoomSpeed,
    canvasWidth,
    canvasHeight,
    imageTopLeft,
    imageBottomRight,
    imageLoaded,
    resetMatrix,
    canvasRef,
  } = useContext(CanvasContext);

  // Update selectedRegions when the searchParams change
  useEffect(() => {
    //This will handle the old steelwork links that used itemId instead of boxIds
    annotations.length > 0 && checkOldSteelworkParams();

    const boxIDs = searchParams.get("boxIds")?.split(",") || [];
    setSelectedRegions([...boxIDs]);
  }, [searchParams, annotations]);

  //Function to toggle selected boxes in the annotation list and image viewer
  const toggleBox = (ids: string[], listToggle = false) => {
    let boxIDs = searchParams.get("boxIds")?.split(",") || [];

    if (ids.some((i) => selectedRegions.includes(i))) {
      //Remove the id from the selectedRegions if it is already selected
      boxIDs = boxIDs.filter((i) => !ids.includes(i));
    } else {
      if (listToggle) {
        //This is used by the annotation list to support multiple selection
        boxIDs = [...boxIDs, ...ids];
      } else {
        //This is used by the image viewer to select the boxes
        boxIDs = [...ids];
      }
    }

    if (boxIDs.length === 0) {
      searchParams.delete("boxIds");
      setLockedRegion(null);
    } else {
      searchParams.set("boxIds", boxIDs.join(","));
    }
    // Use navigate with replace option to update the URL without changing the history
    navigate(`${window.location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const checkOldSteelworkParams = () => {
    const itemIDs = searchParams.get("itemId")?.split(",") || [];
    const steelworkBoxIds = [];
    //Get the annotation ids from steelwork item ids
    if (itemIDs.length > 0) {
      for (const id of itemIDs) {
        const filteredAnnotations = annotations.filter(
          (a) => a?.steelwork?.item_id === id
        );
        for (const annotation of filteredAnnotations) {
          steelworkBoxIds.push(annotation.id);
        }
      }

      //add the steelworkBoxIds to the boxIds
      const boxIDs = searchParams.get("boxIds")?.split(",") || [];
      //only add the steelworkBoxIds that are not already in the boxIds
      const filteredSteelworkBoxIds = steelworkBoxIds.filter(
        (id) => !boxIDs.includes(id)
      );
      const updatedBoxIds = [...boxIDs, ...filteredSteelworkBoxIds];
      //update the searchParams with the updated boxIds
      searchParams.set("boxIds", updatedBoxIds.join(","));
      //remove the old steelwork item ids
      searchParams.delete("itemId");
      // Use navigate with replace option to update the URL without changing the history
      navigate(`${window.location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  };

  // Update canvas zoomSpeed.
  useEffect(() => {
    setZoomSpeed(annotationListScroll ? 0 : zoomSpeed);
  }, [zoomSpeed, setZoomSpeed, annotationListScroll]);

  const [speedZoomToggle, setSpeedZoomToggle] = useState(-1);

  const speedZoom = (x, y, w, h, id) => {
    if (speedZoomToggle !== id && id !== "image") {
      // Get the width of the annotations list.
      const annotationsListWidth = annotationListRef.current?.offsetWidth;
      // We subtract that width from the width of the image canvas "viewport" so we can make the object always fit in the width.
      const availableWidth = canvasWidth - annotationsListWidth;

      // Margin as a multiplier to work independently of image size.
      // Adds a bit of a buffer area around the annotation so it completely fits into view.
      // Has to be a multiplier because this is in image dimension space.
      // Any pixel value would have widely different results depending on the annotation & image size.
      const marginMultiplier = 1.3;

      // Scale boils down to "how much of the canvas does this take up". I.e 0.5 means we zoom in 2x.
      const scaleWidth =
        (imageDimensions.naturalWidth * w * marginMultiplier) / availableWidth;
      const scaleHeight =
        (imageDimensions.naturalHeight * h * marginMultiplier) / canvasHeight;

      // Since smaller scale means more zoom, we use the max scale to make sure that box fits into viewport on it's largest dimension.
      const zoomInScale = Math.max(scaleWidth, scaleHeight);

      // Complicated math.
      // We convert the position of the annotation to pixels in the image (from relative coordinates)
      // and then offset it by half the size of the canvas (modified by the zoom scale to convert it into "local" coordinate scale of the image)
      // For X-axis, offset it by the annotations list width to make sure they don't overlap (this is also accounted for in `scaleWidth`).
      const translateMatrix = translate(
        imageDimensions.naturalWidth * x +
          (imageDimensions.naturalWidth * w) / 2 -
          (availableWidth / 2 + annotationsListWidth) * zoomInScale,
        imageDimensions.naturalHeight * y +
          (imageDimensions.naturalHeight * h) / 2 -
          (canvasHeight / 2) * zoomInScale
      );

      const zoomMatrix = compose(translateMatrix, scale(zoomInScale));

      setMatrix(zoomMatrix);
      setSpeedZoomToggle(id);
    } else {
      resetMatrix();
      setSpeedZoomToggle(-1);
    }
  };

  useInputListener({
    canvasRef,
    annotations,
    setHoveredRegion,
    setLockedRegion,
    reviewMode,
    speedZoom,
    toggleBox,
    lockedRegion,
  });

  // Marker for if speedZoom has been triggered for this image.
  const [speedZoomTriggered, setSpeedZoomTriggered] = useState(false);

  useEffect(() => {
    if (
      !imageLoaded ||
      speedZoomTriggered ||
      !annotations.length ||
      reviewMode === reviewModes.NONE
    )
      return;

    // Separated set to prevent zooming after entering an image.
    // Might otherwise happen if you turn the setting on after loading.
    setSpeedZoomTriggered(true);

    if (autoSpeedZoomInReviewModes) {
      const annotation = annotations[0];

      speedZoom(
        annotation.x,
        annotation.y,
        annotation.w,
        annotation.h,
        annotation.id
      );
      toggleBox([annotation.id]);
    }
  }, [annotations, imageLoaded]);

  useEffect(() => {
    setSpeedZoomToggle(-1);
    setSpeedZoomTriggered(false);
  }, [imageId]);

  // Reset scaling when new image loads.
  useEffect(() => {
    if (
      imageDimensions?.naturalWidth &&
      (reviewMode === reviewModes.NONE || !autoSpeedZoomInReviewModes)
    ) {
      resetMatrix();
    }
  }, [imageDimensions.naturalWidth, canvasRef.current]);

  return (
    <>
      <AnnotationsList
        loading={loading}
        containerRef={annotationListRef}
        setAnnotationListScroll={setAnnotationListScroll}
        selectedRegions={selectedRegions}
        speedZoom={speedZoom}
        toggleBox={toggleBox}
        reviewMode={reviewMode}
        annotations={annotations}
        image={imageId}
        renderAnnotationReviewButtons={renderAnnotationReviewButtons}
        onAnnotationTypeUpdate={onAnnotationTypeUpdate}
      />
      <RegionShapes
        imagePosition={{
          topLeft: imageTopLeft,
          bottomRight: imageBottomRight,
        }}
        setGetData={setGetDate}
        lockedRegion={lockedRegion}
        hoveredRegion={hoveredRegion}
        fixableDefects={fixableDefects}
        imageLoaded={imageLoaded}
        selectedRegions={selectedRegions}
        annotations={annotations}
        reviewMode={reviewMode}
      />

      <Dialog
        sx={{ zIndex: 1500 }}
        open={getDate.visible}
        onClose={() =>
          setGetDate({
            visible: false,
            comment: "",
            isProcessDefect: false,
            callback: (e) => {},
          })
        }
      >
        <DialogTitle>{setDateTranslation}</DialogTitle>
        <DialogContent>
          <TextField
            type="date"
            fullWidth
            id="inputDate"
            defaultValue={new Date().toLocaleDateString("en-CA")}
          />
          {getDate.isProcessDefect && (
            <TextField
              minRows={3}
              multiline
              fullWidth
              id="inputComment"
              label="Comment"
              defaultValue={getDate?.comment}
              onKeyDown={(e) => e.stopPropagation()}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              getDate.callback(null);
              setGetDate({
                visible: false,
                comment: "",
                isProcessDefect: false,
                callback: (e) => {},
              });
            }}
          >
            {ClearTranslation}
          </Button>
          <Button
            className="errorButton"
            onClick={() =>
              setGetDate({
                visible: false,
                comment: "",
                isProcessDefect: false,
                callback: (e) => {},
              })
            }
            style={{ marginRight: 10 }}
          >
            {CloseTranslation}
          </Button>

          <Button
            className="secondaryButton"
            onClick={() => {
              const element = document.getElementById("inputDate");
              const comment = document.getElementById("inputComment");
              if (getDate.isProcessDefect) {
                if (element && comment) {
                  // @ts-ignore
                  getDate.callback(element.value, comment.value);
                } else {
                  toast.error(getDataFailedTranslation);
                }
              } else {
                if (element) {
                  // @ts-ignore
                  getDate.callback(element.value);
                } else {
                  toast.error(getDataFailedTranslation);
                }
              }
              setGetDate({
                visible: false,
                comment: "",
                isProcessDefect: false,
                callback: (e) => {},
              });
            }}
          >
            {SaveTranslation}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
