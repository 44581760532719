import { useContext } from "react";
import { useGesture } from "@use-gesture/react";
import { MutableRefObject } from "react";
import { useSearchParams } from "react-router-dom";
import { AnnotationContext, CanvasContext } from "../provider";
import { convertChangeToPercentage } from "../utils";
import { IAnnotation } from "../api";

interface IProps {
  boxRef: MutableRefObject<HTMLDivElement | null>;
  currentBox: IAnnotation;
  canvasWidth: number;
}

export function useBoxListener({ boxRef, currentBox, canvasWidth }: IProps) {
  const { updateAnnotation } = useContext(AnnotationContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { matrix, setSelectedAnnotation, selectedAnnotation, mode } =
    useContext(CanvasContext);

  useGesture(
    {
      onClick: () => {
        if (mode === "edit") {
          setSelectedAnnotation(currentBox.id);
        } else {
          searchParams.set("box", JSON.stringify(currentBox.id));
          setSearchParams(searchParams);
        }
      },
      onDrag: (state) => {
        if (mode !== "edit") return;
        if (currentBox.id !== selectedAnnotation) return;
        // Extract the movement from useGesture state
        const movementX = state.delta[0];
        const movementY = state.delta[1];

        // Check the change in terms of canvas percentages
        const { dx, dy } = convertChangeToPercentage(
          movementX,
          movementY,
          canvasWidth,
          matrix
        );

        const newBox = { ...currentBox };
        newBox.x += dx;
        newBox.y += dy;

        if (newBox.x < 0) newBox.x = 0;
        if (newBox.y < 0) newBox.y = 0;
        if (newBox.x + newBox.w > 1) newBox.x = 1 - newBox.w;
        if (newBox.y + newBox.h > 1) newBox.y = 1 - newBox.h;

        // Update the annotation
        updateAnnotation(currentBox.id, newBox, true);
      },
      onDragEnd: () => {
        updateAnnotation(currentBox.id, currentBox, false);
      },
    },
    {
      target: boxRef,
    }
  );
}
