import { assignmentStatus, inspectionAssignment } from "api";
import AreaAssignment from "components/AreaAssignment";
import { validateRule } from "components/RoleHOC/wrapper";
import { toast } from "react-toastify";
import { RootState } from "state/store";
import { authorizedPut, axiosInstance } from "utils/request";
import { showMenu } from "./menu";

export interface IAnnotator {
  id: number;
  color: string;
  username: string;
}

export function setOverlays(overlays: google.maps.Polygon[]) {
  return {
    type: "SET_OVERLAYS",
    payload: overlays,
  };
}

export function setAnnotatorColor(annotatorID: number, color: string) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const currentProject = state.mission?.id;
    if (!currentProject) return;
    await authorizedPut(`/user/${annotatorID}/color`, { color });
    dispatch(getMissionAnnotators(currentProject));
  };
}

export function getMissionAnnotators(projectID: number) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const projects = state.user.missions;
    const project = projects.find((p) => p.id === projectID);
    if (!project) {
      return;
    }

    const hasAccess = validateRule({
      missions: state.user.missions,
      keyName: "supervisorMode",
      accessRules: state.user.accessRules,
      customerId: projectID,
      superuser: state.user.superuser,
      skyqraft_employee: state.user.skyqraft_employee,
      noOverride: false,
      accessLevelOverride: state.user.accessLevelOverride,
      treatAsMissionAgnostic: false,
    });

    if (!hasAccess) return;
    if (projectID === -1) return;

    const response = await axiosInstance.get<{ users: IAnnotator[] }>(
      `/project/${projectID}/annotator`
    );
    try {
      const annotators = response.data;
      dispatch({
        type: "SET_MISSION_ANNOTATORS",
        payload: annotators.users,
      });
    } catch (e) {
      console.error(e);
    }
  };
}

function changeOverlay(overlay: google.maps.Polygon, value: boolean) {
  overlay.setEditable(value);
  overlay.setDraggable(value);
}

function updateOverlayLocation(overlay: google.maps.Polygon) {
  const areaID = overlay.get("id");
  const paths = overlay
    .getPath()
    .getArray()
    .map((point) => point.toJSON());
  inspectionAssignment.update(areaID, {
    coordinates: paths.map((c) => ({ lat: c.lat, lng: c.lng })),
  });
}

function deleteOverlay(overlay: google.maps.Polygon) {
  return async (dispatch) => {
    const areaID = overlay.get("id");
    assignmentStatus
      .delete(areaID)
      .then(() => {
        dispatch(clearOverlay(overlay));
      })
      .catch((e) => {
        toast.error("Failed to delete assignment", e);
      });
  };
}

export function clearOverlay(overlay) {
  return async (dispatch, getState) => {
    const areaID = overlay.get("id");
    overlay.setMap(null);
    google.maps.event.removeListener(overlay.get("mapClickListener"));
    const state: RootState = getState();
    const newOverlays = [
      ...state.map.overlays.filter((ol) => ol.get("id") !== areaID),
    ];

    dispatch(setOverlays(newOverlays));
  };
}

function overlayListener(overlay: google.maps.Polygon, map: google.maps.Map) {
  return async (dispatch) => {
    const mapClickListener = map.addListener("click", () => {
      changeOverlay(overlay, false);
      document.removeEventListener("keyup", handleDeletePress);
    });
    overlay.set("mapClickListener", mapClickListener);

    const handleDeletePress = (e) => {
      if (e.key === "Delete" && !!overlay.getMap()) {
        if (
          window.confirm("Are you sure you want to delete this assignment?")
        ) {
          dispatch(deleteOverlay(overlay));
        }
      }
    };

    changeOverlay(overlay, false);

    overlay.addListener("click", () => {
      changeOverlay(overlay, true);
      document.addEventListener("keyup", handleDeletePress);
    });
    overlay.addListener("dragstart", () => {
      overlay.set("dragged", true);
    });
    overlay.addListener("dragend", (e) => {
      overlay.set("dragged", false);
      updateOverlayLocation(overlay);
    });
    overlay.getPath().addListener("insert_at", (e) => {
      updateOverlayLocation(overlay);
    });
    overlay.getPath().addListener("remove_at", (e) => {
      updateOverlayLocation(overlay);
    });
    overlay.getPath().addListener("set_at", (e) => {
      if (!overlay.get("dragged")) {
        updateOverlayLocation(overlay);
      }
    });
    overlay.addListener("contextmenu", (e) => {
      const menuItems: JSX.Element[] = [];
      menuItems.push(
        <AreaAssignment key="areaAssignment" editable area={overlay} />
      );
      dispatch(showMenu(e, true, menuItems, map));
    });
  };
}
export function updateAssignments() {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const overlays: google.maps.Polygon[] = state.map.overlays;
    // biome-ignore lint/complexity/noForEach: Google Maps API requires a forEach loop
    overlays.forEach((overlay) => dispatch(clearOverlay(overlay)));
    dispatch(setOverlays([]));
    dispatch(loadAssignments());
  };
}

export function clearDrawingManager(callback?: () => void) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const drawingManager: google.maps.drawing.DrawingManager =
      state.map.drawingManager;
    const overlays: google.maps.Polygon[] = state.map.overlays;
    // biome-ignore lint/complexity/noForEach: Google maps API requires a forEach loop
    overlays.forEach((overlay) => dispatch(clearOverlay(overlay)));
    dispatch(setOverlays([]));
    drawingManager?.setMap(null);
    dispatch({
      type: "SET_DRAWING_MANAGER",
      payload: null,
    });
    callback?.();
  };
}

export function loadAssignments() {
  return async (dispatch, getState) => {
    inspectionAssignment.get({ supervisor: true }).then((areas) => {
      const state: RootState = getState();
      const map: google.maps.Map = state.map.gmap;
      const newOverlays = [...state.map.overlays];
      if (!window.google) {
        return;
      }
      for (const assignment of areas) {
        const newPolygon = new google.maps.Polygon({
          paths: assignment.coordinates.map(
            ({ lat, lng }) => new google.maps.LatLng(lat, lng)
          ),
          editable: true,
          map: map,
          fillColor: assignment.color,
          strokeColor: assignment.color,
        });
        newPolygon.set("id", assignment.id);
        newPolygon.set("annotator", assignment.annotator);
        newPolygon.set("analysis", assignment.analysis);
        newPolygon.set("criticalAnalysis", assignment.criticalAnalysis);
        newOverlays.push(newPolygon);
        dispatch(overlayListener(newPolygon, map));
      }
      dispatch(setOverlays(newOverlays));
    });
  };
}

export function loadDrawingManager() {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const map: google.maps.Map = state.map.gmap;
    dispatch(
      clearDrawingManager(() => {
        const drawingManager = new google.maps.drawing.DrawingManager({
          map: map,
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
          polygonOptions: {
            draggable: true,
            editable: true,
          },
          polylineOptions: {
            draggable: true,
            editable: true,
          },
        });

        drawingManager.addListener(
          "overlaycomplete",
          (e: { type: string; overlay: google.maps.Polygon }) => {
            e.overlay.setEditable(false);
            e.overlay.setDraggable(false);
            drawingManager.setDrawingMode(null);
            const paths = e.overlay
              .getPath()
              .getArray()
              .map((point) => point.toJSON());

            if (paths.length > 2) {
              assignmentStatus
                .create({
                  coordinates: paths.map((c) => ({ lat: c.lat, lng: c.lng })),
                })
                .then((createdID: string) => {
                  e.overlay.set("id", parseInt(createdID));
                  e.overlay.set("annotator", null);
                  e.overlay.set("analysis", true);
                  e.overlay.set("criticalAnalysis", true);
                  const state: RootState = getState();
                  const newOverlays = [...state.map.overlays];
                  newOverlays.push(e.overlay);
                  dispatch(setOverlays(newOverlays));
                  dispatch(overlayListener(e.overlay, map));
                })
                .catch((failed) => {
                  console.log("Creation failed", failed);
                  e.overlay.setMap(null);
                });
            } else {
              e.overlay.setVisible(false);
            }
          }
        );
        dispatch({
          type: "SET_DRAWING_MANAGER",
          payload: drawingManager,
        });
        drawingManager.setMap(map);
        dispatch(loadAssignments());
      })
    );
  };
}
