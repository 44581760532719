import React from "react";
import { Link } from "react-router-dom";

type Props = {
  content: any;
};

export default function CMSText({ content }: Props) {
  const text = content.text;
  const marks: any[] = content.marks;
  let base = text;
  if (marks) {
    for (let i = 0; i < marks.length; i++) {
      const mark = marks[i];
      switch (mark.type) {
        case "italic":
          base = <i>{base}</i>;
          break;
        case "bold":
          base = <b>{base}</b>;
          break;
        case "underline":
          base = <u>{base}</u>;
          break;
        case "subscript":
          base = <sup>{base}</sup>;
          break;
        case "superscript":
          base = <sub>{base}</sub>;
          break;
        case "strike":
          base = <del>{base}</del>;
          break;
        case "code":
          base = <code>{base}</code>;
          break;
        case "textStyle":
          base = <span style={{ ...mark.attrs }}>{base}</span>;
          break;
        case "highlight":
          base = (
            <span style={{ backgroundColor: mark.attrs.color }}>{base}</span>
          );
          break;
        case "link":
          const href = mark.attrs.href;
          if (href.startsWith("http")) {
            base = (
              <a href={href} target={mark.attrs.target}>
                {base}
              </a>
            );
          } else {
            base = (
              <Link to={href} target={mark.attrs.target}>
                {base}
              </Link>
            );
          }

          break;
        default:
          break;
      }
    }
  }
  return base;
}
