import React, { ReactElement, useRef } from "react";
import { Mission } from "interfaces";
import { Slider, SliderMark, Tooltip } from "@mui/material";
import { findCurrentMission } from "utils/utils";
import { useParams, useNavigate } from "react-router-dom";
import translations from "translations";
import { getProjectName } from "utils/utils";
import "./style.scss";

interface IProps {
  missions: Mission[];
}

export default function TimeSelector({ missions }: IProps): ReactElement {
  const params = useParams();
  const navigate = useNavigate();
  const currentMission: number = parseInt(params.mission) || 0;
  const ref = useRef(null);
  const selectedMission = findCurrentMission(currentMission, missions);

  if (
    !!!selectedMission ||
    !!!currentMission ||
    !!!selectedMission?.area?.name
  ) {
    return null;
  }
  let areaMissions = [];

  missions.forEach((mission) => {
    if (mission.area?.id === selectedMission.area?.id && !mission.deleted) {
      areaMissions.push(mission);
    }
  });

  areaMissions = areaMissions.sort((a, b) =>
    new Date(a.timestamp) < new Date(b.timestamp) ? -1 : 1
  );

  if (areaMissions.length < 2) {
    return null;
  }

  if (areaMissions.length === 0) {
    return null;
  }

  if (areaMissions.length > 5) {
    areaMissions = areaMissions.filter((a, i) => i >= areaMissions.length - 5);
  }

  function CustomMark(props) {
    const { children, className, ...other } = props;

    let index = props["data-index"];
    let projectId = areaMissions[index]?.id;

    let getProjectNameResult = getProjectName(projectId);

    return (
      <SliderMark {...other} className={className}>
        <Tooltip title={getProjectNameResult.name} placement="top">
          <p>{getProjectNameResult.year}</p>
        </Tooltip>
      </SliderMark>
    );
  }

  return (
    <div id="time-selector">
      <Slider
        sx={{
          width: (window.innerWidth < 768 ? 70 : 100) * areaMissions.length,
        }}
        ref={ref}
        aria-label="Restricted values"
        onChangeCommitted={(e, value) => {
          //@ts-expect-error
          const mission = areaMissions[value];
          navigate(`/${mission.id}?zoomOut=false`);
        }}
        value={areaMissions.indexOf(
          areaMissions.find((a) => a.id === currentMission)
        )}
        max={areaMissions.length - 1}
        components={{
          Mark: CustomMark,
        }}
        marks
      />
    </div>
  );
}
