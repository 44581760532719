import { connect } from "react-redux";
import Component from "./InformationPopup";
import { RootState } from "state/store";
import { ImageMeta } from "interfaces";

interface IDispatchProps {}

interface IStateProps {
  imageMeta: ImageMeta;
}

interface IOwnProps {}

const mapStateToProps = (state: any, ownProps: IOwnProps) => {
  return {
    imageMeta: state.image.current,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
