import React from "react";
import { Stack, Typography } from "@mui/material";
import translations from "translations";
import { useLanguage } from "hooks";

type Props = {
  missionName: string;
};

export default function Header({ missionName }: Props) {
  const time = new Date(Date.now()).toLocaleString();
  const { language } = useLanguage();
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Stack>
        <Typography fontSize={24} fontWeight="bold">
          {translations.Inbox.Reports.Preview.PageTitle[language]}
        </Typography>
        <Typography fontSize={22}>{missionName}</Typography>
        <Typography fontSize={20}>{time}</Typography>
      </Stack>
      <img
        style={{ paddingTop: 12 }}
        src="/logo_50px_blue.png"
        height={50}
        alt="blue logo"
      />
    </Stack>
  );
}
