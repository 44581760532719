import React from "react";
import CMSRichTextContent from ".";

type Props = {
  content: any;
};

export default function CMSListItem({ content }: Props) {
  return (
    <li>
      {content.map((c, i) => (
        <CMSRichTextContent key={i} content={c} />
      ))}
    </li>
  );
}
