export interface IState {
  folder: string;
  jobId: number | null;
  jobs: Array<any>;
  jobSubmitted: boolean;
  subFolders: Array<any>;
  rerun: boolean;
  queue_enabled: boolean;
  overwrite_db: boolean;
  loading: boolean;
  children: string[];
  lastUploads: any[];

  cloudFolders: string[];
  rawFolder: string;
  bucket: string;
  files: any[];
  status: "idle" | "running" | "stopped";
  toast: any;
  currentFile: string;
  s3Folder: string;
  startImportAutomatically: boolean;
}

export const initialState: IState = {
  folder: "",
  jobId: null,
  jobs: [],
  jobSubmitted: false,
  subFolders: [],
  rerun: false,
  queue_enabled: true,
  overwrite_db: false,
  loading: true,
  children: [],
  lastUploads: [],

  cloudFolders: [],
  rawFolder: "",
  bucket: "",
  files: [],
  status: "idle",
  toast: undefined,
  currentFile: "",
  s3Folder: "",
  startImportAutomatically: true,
};

const imageUploadReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case "SET_CLOUD_FOLDERS":
      return {
        ...state,
        cloudFolders: action.payload,
      };
    case "SET_LAST_UPLOADS":
      return {
        ...state,
        lastUploads: action.payload,
      };
    case "SET_AUTOMATIC_IMPORT":
      return {
        ...state,
        startImportAutomatically: action.payload,
      };
    case "SET_RAW_FOLDER":
      return {
        ...state,
        rawFolder: action.payload,
      };
    case "SET_S3_BUCKET":
      return {
        ...state,
        bucket: action.payload,
      };

    case "SET_S3_FOLDER":
      return {
        ...state,
        s3Folder: action.payload,
      };
    case "SET_FILES":
      return {
        ...state,
        files: action.payload,
      };
    case "SET_IMAGE_UPLOAD_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    case "SET_CURRENT_FILE":
      return {
        ...state,
        currentFile: action.payload,
      };
    case "SET_TOAST_HANDLE":
      return {
        ...state,
        toast: action.payload,
      };
    case "SET_FOLDER":
      return {
        ...state,
        folder: action.payload,
      };

    case "SET_JOB_ID":
      return {
        ...state,
        jobId: action.payload,
      };

    case "SET_JOBS":
      return {
        ...state,
        jobs: action.payload,
      };

    case "SET_JOB_SUBMITTED":
      return {
        ...state,
        jobSubmitted: action.payload,
      };

    case "SET_SUB_FOLDERS":
      return {
        ...state,
        subFolders: action.payload,
      };

    case "SET_RERUN":
      return {
        ...state,
        rerun: action.payload,
      };

    case "SET_QUEUE":
      return {
        ...state,
        queue_enabled: action.payload,
      };

    case "SET_OVERWRITE":
      return {
        ...state,
        overwrite_db: action.payload,
      };

    case "SET_LOADING_STATUS":
      return {
        ...state,
        loading: action.payload,
      };

    case "SET_CHILDREN":
      return {
        ...state,
        children: action.payload,
      };

    default:
      return state;
  }
};

export default imageUploadReducer;
