import { useContext } from "react";
import { useGesture } from "@use-gesture/react";
import { MutableRefObject } from "react";
import { ISpecialAnnotation } from "../provider/AnnotationProvider/context";
import { AnnotationContext, CanvasContext } from "../provider";
import { getCanvasBox } from "./utils";
import { convertPositionToPercentage } from "../utils";

interface IProps {
  boxRef: MutableRefObject<HTMLDivElement | null>;
  currentBox: ISpecialAnnotation;
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
}

export function useRotateListener({ boxRef, currentBox, canvasRef }: IProps) {
  const { updateAnnotation } = useContext(AnnotationContext);
  const { matrix, imageDimensions } = useContext(CanvasContext);

  useGesture(
    {
      onDrag: (state) => {
        const boxCenter = {
          x: (currentBox.x + currentBox.w / 2) * imageDimensions.naturalWidth,
          y: (currentBox.y + currentBox.h / 2) * imageDimensions.naturalHeight,
        };
        const { left, top } = getCanvasBox(canvasRef);
        // Define it as a percentage of the canvas with respect to scaling matrix
        const { x, y } = convertPositionToPercentage(
          top,
          left,
          imageDimensions,
          state.xy[0],
          state.xy[1],
          matrix
        );

        const angle = Math.atan2(
          y * imageDimensions.naturalHeight - boxCenter.y,
          x * imageDimensions.naturalWidth - boxCenter.x
        );
        const newBox = { ...currentBox };

        let rotation = (angle * 180) / Math.PI;

        //filter the rotation to only go from 0 to 360 and be an integer
        rotation = Math.round(rotation);
        rotation = rotation % 360;

        newBox.rotation = rotation;
        updateAnnotation(currentBox.id, newBox);
      },
    },
    { target: boxRef }
  );
}
