import { useContext, MutableRefObject, useState } from "react";
import { useGesture } from "@use-gesture/react";
import { compose, scale, translate } from "transformation-matrix";
import { AnnotationContext, CanvasContext } from "../provider";
import { getCanvasBox } from "./utils";
import { IWheelState, IPinchState, IDragEvent } from "./types";
import { useSearchParams } from "react-router-dom";
import { convertPositionToPercentage } from "../utils";

interface IProps {
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
  zoomSpeed: number;
}

export function useInputListener({ canvasRef, zoomSpeed }: IProps) {
  // Collect variables from context
  const [searchParams, setSearchParams] = useSearchParams();
  const canvas = useContext(CanvasContext);
  const annotationContext = useContext(AnnotationContext);
  const [dragged, setDragged] = useState(false);

  // Manage drags across the canvas
  function onDrag(state: IDragEvent) {
    if (Math.abs(state.delta[0]) > 0 || Math.abs(state.delta[1]) > 0) {
      setDragged(true);
    }

    const matrixTranslation = translate(-state.delta[0], -state.delta[1]);
    let newMat = compose(canvas.matrix, matrixTranslation);
    canvas.setMatrix(newMat);
  }

  // Manage click events (often clicks are used to deselect annotations)
  function onClick(state) {
    if (dragged) {
      return;
    }
    // Figure out where the click happened
    const { left, top, width } = getCanvasBox(canvasRef);
    const { clientX, clientY } = state.event;

    // Define it as a percentage of the canvas with respect to scaling matrix
    const { x, y } = convertPositionToPercentage(
      top,
      left,
      width,
      clientX,
      clientY,
      canvas.matrix
    );

    // Search for an annotation at that point
    const boxes = annotationContext.data;
    const annotations = boxes.filter((box) => {
      return x > box.x && x < box.x + box.w && y > box.y && y < box.y + box.h;
    });

    if (annotations.length === 0) {
      canvas.setSelectedAnnotation(null);
      return;
    }

    const smallestAnnotation = annotations.reduce((prev, curr) => {
      return prev.w * prev.h < curr.w * curr.h ? prev : curr;
    }, annotations[0]);

    // If we found an annotation, select it
    if (smallestAnnotation) {
      if (canvas.mode === "edit") {
        canvas.setSelectedAnnotation(smallestAnnotation.id);
      } else {
        searchParams.set("box", JSON.stringify(smallestAnnotation.id));
        searchParams.set("addPoleItem", "true");
        setSearchParams(searchParams);
      }
    }
  }

  // Manage zooming in and out
  function onWheel(state: IWheelState) {
    if (!canvasRef.current) return;
    const { left, top } = getCanvasBox(canvasRef);

    // get mouse position
    const currentMouseX = state.event.clientX - left;
    const currentMouseY = state.event.clientY - top;

    // Define scale value based on Y scroll
    const scaleValue = 1 + (zoomSpeed / 100) * state.direction[1];

    // Translate, zoom and then translate back
    const matrixManipulations = [
      translate(currentMouseX, currentMouseY),
      scale(scaleValue),
      translate(-currentMouseX, -currentMouseY),
    ];

    // Save the new matrix
    const newMatrix = compose(canvas.matrix, ...matrixManipulations);
    canvas.setMatrix(newMatrix);
  }

  function onPinch(state: IPinchState) {
    if (!canvasRef.current) return;
    // Extract some mouse values
    const { left, top } = getCanvasBox(canvasRef);
    const currentMouseX = state.origin[0] - left;
    const currentMouseY = state.origin[1] - top;

    // Figure out how much to rescale
    var scaleValue = 1 + (zoomSpeed / 100) * Math.sign(-state.delta[0]);

    // Translate, zoom and then translate back
    const matrixManipulations = [
      translate(currentMouseX, currentMouseY),
      scale(scaleValue),
      translate(-currentMouseX, -currentMouseY),
    ];
    const newMatrix = compose(canvas.matrix, ...matrixManipulations);
    canvas.setMatrix(newMatrix);
  }

  function onMouseDown() {
    setDragged(false);
  }

  useGesture(
    {
      onDrag,
      onClick,
      onWheel,
      onPinch,
      onMouseDown,
    },
    {
      target: canvasRef,
      enabled: canvas.mode !== "add",
    }
  );
}
