import { IImageAnnotation } from "views/AnnotationTool/api";
import { useBbox } from "hooks";
import { ReviewMode } from "../..";
import Region from "../Region";

interface IProps {
  lockedRegion: null | string;
  hoveredRegion: null | string;
  imagePosition: {
    topLeft: {
      x: number;
      y: number;
    };
    bottomRight: {
      x: number;
      y: number;
    };
  };
  annotations: IImageAnnotation[];
  setGetData: (payload) => void;
  fixableDefects: boolean;
  imageLoaded: boolean;
  selectedRegions: string[];
  reviewMode: ReviewMode;
}

export default function RegionShapes({
  imagePosition,
  annotations,
  hoveredRegion,
  selectedRegions,
  lockedRegion,
  fixableDefects,
  setGetData,
  imageLoaded,
}: IProps) {
  const { showBoxes } = useBbox();

  const iw = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const ih = imagePosition.bottomRight.y - imagePosition.topLeft.y;
  if (Number.isNaN(iw) || Number.isNaN(ih)) return null;
  if (!imageLoaded) return null;

  return (
    <svg
      width={iw}
      height={ih}
      style={{
        position: "absolute",
        zIndex: 5,
        left: imagePosition.topLeft.x,
        top: imagePosition.topLeft.y,
        width: iw,
        height: ih,
        overflow: "visible",
        pointerEvents: "none",
      }}
    >
      <title>RegionShapes</title>
      {showBoxes &&
        [...annotations]
          .sort((a, b) => b.w * b.h - a.w * a.h)
          .map((a) => (
            <Region
              key={a.id}
              annotation={a}
              iw={iw}
              ih={ih}
              hoveredRegion={hoveredRegion}
              lockedRegion={lockedRegion}
              fixableDefects={fixableDefects}
              setGetData={setGetData}
              selectedRegions={selectedRegions}
            />
          ))}
    </svg>
  );
}
