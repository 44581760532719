import React, { ReactElement } from "react";
import { Box } from "@mui/material";
import translations from "translations";
import { ResponsivePie } from "@nivo/pie";
import { useLanguage } from "hooks";

interface Props {
  machineDetections: number;
  humanDetections: number;
  sx?;
  hideTitle?;
}

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  let total = 0;
  dataWithArc.forEach((datum) => {
    total += datum.value;
  });

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "20px",
        fontWeight: 600,
      }}
    >
      {total}
    </text>
  );
};

const MyResponsivePie = ({ data }) => (
  <ResponsivePie
    data={data}
    layers={["arcs", "arcLinkLabels", "legends", CenteredMetric]}
    arcLinkLabelsStraightLength={10}
    arcLinkLabelsDiagonalLength={8}
    valueFormat={(value) =>
      `${Math.round(
        (value / data.reduce((partialSum, a) => partialSum + a.value, 0)) * 100
      )}%`
    }
    margin={{ top: 10, right: 70, bottom: 10, left: 70 }}
    innerRadius={0.55}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    borderColor={{
      from: "color",
      modifiers: [["darker", 0.2]],
    }}
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: "color" }}
    colors={{ datum: "data.color" }}
  />
);

export default function AllDetections({
  machineDetections,
  humanDetections,
  sx,
  hideTitle,
}: Props): ReactElement {
  const { language } = useLanguage();
  let dataDetection = [
    {
      id: `${translations.Menu.Statistics.MachineLearning.Machine[language]}`,
      label: "Machine",
      value: machineDetections,
      color: "hsl(210,100%,46%)",
    },
    {
      id: `${translations.Menu.Statistics.MachineLearning.Human[language]}`,
      label: "Human",
      value: humanDetections,
      color: "hsl(177, 70%, 50%)",
    },
  ];

  return (
    <>
      {dataDetection[0].value > 0 && dataDetection[1].value > 0 && (
        <Box
          sx={{
            width: 500,
            height: 550,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 1,
            background: "#fff",
            borderColor: "rgba(0, 0, 0, 0.24)",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 20px 0 rgba(0, 0, 0, 0.12)",
            padding: 2.5,
            ...sx,
          }}
        >
          {!!!hideTitle && (
            <h2>
              {
                translations.Menu.Statistics.MachineLearning.Detections[
                  language
                ]
              }
            </h2>
          )}
          <div style={{ height: sx?.height ?? 300 }}>
            <MyResponsivePie data={dataDetection} />
          </div>
        </Box>
      )}
    </>
  );
}
