import { useState, useEffect } from "react";
import { axiosInstance } from "utils/request";
import { EventHint, captureException } from "@sentry/browser";

interface IImageList {
  id: number;
  images: number[];
  locked: boolean;
  owner: {
    id: number;
    name: string;
  };
  publicList: boolean;
  title: string;
}

export function useProjectImageLists(projectID: number) {
  const [loading, setLoading] = useState<boolean>(true);
  const [projectImageLists, setProjectImageLists] = useState<IImageList[]>([]);

  async function updateImageLists(targetProjectID: number) {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/list/all", {
        headers: { MissionID: targetProjectID },
      });

      setProjectImageLists(response.data.lists);
    } catch (error) {
      const exceptionHint: EventHint = {
        event_id: "image_report.hook.updateImageLists",
        originalException: error,
      };
      captureException(error, exceptionHint);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!projectID) return;
    // Fetch project image lists
    updateImageLists(projectID);
  }, [projectID]);

  return { list: projectImageLists, loading: loading };
}
