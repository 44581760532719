import React, { ReactElement, useEffect } from "react";
import { MapOutlined, FormatListBulleted } from "@mui/icons-material";
import "./style.scss";
import { Tooltip } from "@mui/material";
import { useSearchParams, useLocation } from "react-router-dom";
import { isFilterActive } from "utils/filter";
import translations from "translations";
import { useLanguage } from "hooks";

interface Props {
  setListView: (value: boolean) => void;
  showListView: boolean;
}

export default function PoleImageButton({
  setListView,
  showListView,
}: Props): ReactElement {
  const { language } = useLanguage();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  let filterActive = isFilterActive(searchParams);

  useEffect(() => {
    !filterActive && setListView(false);
  }, [filterActive]);

  return (
    <div
      className="mapListButtonContainer"
      style={{
        opacity:
          (!filterActive || location.pathname.includes("/landing")) && 0.5,
      }}
    >
      <div className="mapListButtonWrapper" data-testid="ListViewIcon">
        <Tooltip
          title={
            !filterActive && translations.ListView.ActivateFilter[language]
          }
          placement="right"
          arrow
        >
          <div
            className="mapListButton"
            onClick={() =>
              filterActive &&
              !location.pathname.includes("/landing") &&
              setListView(!showListView)
            }
          >
            <div
              className={
                showListView ? "mapListElement" : "mapListElement active"
              }
            ></div>

            <MapOutlined
              htmlColor="white"
              style={{ zIndex: 1, marginRight: "2px" }}
            />

            <FormatListBulleted
              htmlColor="white"
              style={{ zIndex: 1, marginRight: "-3px" }}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
