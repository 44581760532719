import { IGroup, IGroupDetailed } from "state/actions/group";

export interface IState {
  groups: IGroup[];
  admin_groups: IGroup[];
  current: IGroupDetailed | null;
}

export const initialState: IState = {
  groups: [],
  admin_groups: [],
  current: null,
};

const groupReducer = (
  state: IState = initialState,
  action = { type: null, payload: null }
) => {
  switch (action.type) {
    case "SET_GROUPS":
      return {
        ...state,
        groups: action.payload,
      };
    case "SET_ADMIN_GROUPS":
      return {
        ...state,
        admin_groups: action.payload,
      };
    case "SET_GROUP":
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
};

export default groupReducer;
