import React, { ReactElement } from "react";
import { Button, Stack } from "@mui/material";
import translations from "translations";
import { authorizedGet, authorizedPut } from "utils/request";
import { toast } from "react-toastify";
import { useLanguage } from "hooks";

interface IResponse {
  poles: {
    client_id?: number;
    id: number;
    lat: number;
    lng: number;
    location: string;
  }[];
}

export default function Poles(): ReactElement {
  const { language } = useLanguage();
  async function assignImages(useDirection) {
    let response;

    // Collect the poles for the mission
    response = await authorizedGet<IResponse>("/poles");
    if (!!!response) {
      toast.error("Failed to find structures");
      return;
    }
    if (response.poles.length === 0) {
      toast.error("No structures found.");
      return;
    }

    // Prepare for keeping track of progress
    const nPoles = response.poles.length;
    let failedPoles = 0;
    let successfulPoles = 0;

    // Inform the user what's happening
    const toastHandle = toast.info("Allocating structures", {
      progress: 0 / nPoles,
      autoClose: false,
      closeButton: false,
      closeOnClick: false,
    });

    // Assign images around each pole
    for (let i = 0; i < nPoles; i++) {
      const pole = response.poles[i];
      try {
        await authorizedPut(`/poles/${pole.id}/assign_area`, { useDirection });
        successfulPoles += 1;
      } catch {
        failedPoles += 1;
      }
      const progress = (i + 1) / nPoles;
      toast.update(toastHandle, { progress });
    }

    // Present the user with a final summary
    toast.update(toastHandle, {
      autoClose: 5000,
      closeButton: true,
      closeOnClick: true,
      render: `Processed ${successfulPoles} poles.${
        !!failedPoles ? ` Failed ${failedPoles} poles.` : ""
      }`,
      type: "success",
    });
  }
  return (
    <div>
      <h2>{translations.Menu.Tools.Powerlines.Poles.Title[language]}</h2>
      <p>{translations.Menu.Tools.Powerlines.Poles.Description[language]}</p>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          className="secondaryButton"
          fullWidth
          onClick={() => assignImages(true)}
        >
          {
            translations.Menu.Tools.Powerlines.Poles
              .AssignImagesToExistingPoles[language]
          }
        </Button>
        <Button
          variant="contained"
          className="secondaryButton"
          fullWidth
          onClick={() => assignImages(false)}
        >
          Assign Images to structures without direction
        </Button>
      </Stack>
    </div>
  );
}
