import React, { useContext, useState } from "react";
import { context } from "providers/NewDefectsProvider";
import { Button, Divider, Stack, Typography } from "@mui/material";
import SeverityCountRow from "./SeverityCountRow";
import Loading from "views/Loading";
import translations from "translations";
import { useLanguage } from "hooks";

type Props = {
  severityMap: {
    "1": string;
    "2": string;
    "3": string;
    "4": string;
  };
  demoMode: boolean;
};

export default function SeverityCountProjectTable({ severityMap }: Props) {
  const { language } = useLanguage();
  const { data, loading } = useContext(context);
  const [showMore, setShowMore] = useState<boolean>(false);

  function handleShowMore() {
    setShowMore(!showMore);
  }

  return loading ? (
    <Stack
      mt={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Loading relative size={50} color="#404040" />
      <Typography sx={{ mt: 2 }}>Loading project statistics</Typography>
    </Stack>
  ) : (
    <Stack direction="column">
      <SeverityCountRow
        color="#000000"
        textColor="#FFFFFF"
        critical="Critical"
        issue="Issue"
        shouldBeFixed="Should be fixed"
        goodToKnow="Good to know"
        noColors
        severityMap={severityMap}
        projectID={null}
      />
      <Divider />
      {data.length > 0 &&
        data.map((project, i) => {
          return i <= 5 ? (
            <SeverityCountRow
              key={project.id}
              color={i % 2 === 0 ? "#f5f5f5" : "#ffffff"}
              textColor="#404040"
              critical={project["1"]}
              issue={project["2"]}
              shouldBeFixed={project["3"]}
              goodToKnow={project["4"]}
              link={`/${project.id}`}
              severityMap={severityMap}
              projectID={project.id}
            />
          ) : null;
        })}
      {data.length > 5 && !showMore && (
        <Button onClick={handleShowMore}>
          {translations.words.ShowMore[language]}
        </Button>
      )}
      {data.length > 0 &&
        showMore &&
        data.map((project, i) => {
          return i > 5 ? (
            <SeverityCountRow
              key={project.id}
              color={i % 2 === 0 ? "#f5f5f5" : "#ffffff"}
              textColor="#404040"
              critical={project["1"]}
              issue={project["2"]}
              shouldBeFixed={project["3"]}
              goodToKnow={project["4"]}
              link={`/${project.id}`}
              severityMap={severityMap}
              projectID={project.id}
            />
          ) : null;
        })}
      {data.length > 5 && showMore && (
        <Button onClick={handleShowMore}>
          {translations.words.ShowLess[language]}
        </Button>
      )}
    </Stack>
  );
}
