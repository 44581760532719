import { toast } from "react-toastify";
import translations from "translations";
import { ILanguages } from "./translations";

export function zoomToImage(map: google.maps.Map, lat: number, lng: number) {
  map.panTo({ lat, lng });
  map.setZoom(18);
}

export function getFeedIdentifier(
  feedStation: string | null,
  feedBay: string | null
) {
  if (feedBay && feedBay !== "null" && feedStation && feedStation !== "null") {
    return `${feedStation}${feedBay}`;
  } else if (feedBay && feedBay !== "null") {
    return feedBay;
  } else if (feedStation && feedStation !== "null") {
    return feedStation;
  }
  return null;
}

export function zoomToFeedIdentifiers(
  map: google.maps.Map,
  feedIdentifiers: string[]
) {
  let bounds = new google.maps.LatLngBounds();
  if (!map) return;
  // biome-ignore lint/complexity/noForEach: Doesn't work on google maps
  map.data.forEach((feature) => {
    const featureFeedBay = feature.getProperty("feedBay");
    const featureFeedStation = feature.getProperty("feedStation");
    const featureFeedIdentifier = getFeedIdentifier(
      featureFeedStation,
      featureFeedBay
    );
    if (
      !feedIdentifiers.includes(featureFeedIdentifier) &&
      feedIdentifiers.length > 0
    ) {
      return;
    }
    // get bounds of feature
    const geometry = feature.getGeometry();
    // Extend bounds with the new feature

    geometry.forEachLatLng((latLng) => {
      bounds.extend(latLng);
    });
  });
  // Extend bounds a little bit more
  bounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(
      bounds.getSouthWest().lat() - 0.0001,
      bounds.getSouthWest().lng() - 0.0001
    ),
    new google.maps.LatLng(
      bounds.getNorthEast().lat() + 0.0001,
      bounds.getNorthEast().lng() + 0.0001
    )
  );
  // Make sure the features are at the center of the bounds
  map.panTo(bounds.getCenter());
  map.fitBounds(bounds);
}

export function checkTopologyData(
  map: google.maps.Map,
  language: ILanguages = "EN"
) {
  let foundTopologyData = false;
  // biome-ignore lint/complexity/noForEach: google maps require forEach features
  map.data.forEach((feature: google.maps.Data.Feature) => {
    const feedBay = feature.getProperty("feedBay");
    const feedStation = feature.getProperty("feedStation");
    if (feedBay || feedStation) {
      foundTopologyData = true;
    }
  });
  if (!foundTopologyData) {
    toast.error(translations.notifications.noTopologyDataFound[language]);
  }
}
