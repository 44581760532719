import { connect } from "react-redux";
import ClusterDistance from "./ClusterDistance";
import { setClusterDistance } from "state/actions";
import { RootState } from "state/store";

interface IStateProps {
  clusterDistance: number;
}

interface IDispatchProps {
  setClusterDistance: typeof setClusterDistance;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    clusterDistance: parseInt(state.imageViewer.distance),
  };
};

const mapDispatchToProps = {
  setClusterDistance,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ClusterDistance);
