import React, { memo } from "react";
import colorAlpha from "color-alpha";
import { CheckCircle, HelpOutline } from "@mui/icons-material";
import { getClientObjectTypeName } from "utils/utils";

var RegionComponents = {
  box: memo(function (_ref2) {
    var region = _ref2.region,
      iw = _ref2.iw,
      showNoDefectVerification = _ref2.showNoDefectVerification,
      onChangeRegion = _ref2.onChangeRegion,
      objectTypes = _ref2.objectTypes,
      issueCategories = _ref2.issueCategories,
      annotatorObjectColor = _ref2.annotatorObjectColor,
      ih = _ref2.ih;

    let color;
    let types = [];
    let categories = [];
    let issueIds = [];
    let severities = [];
    issueIds = issueCategories.map((e) => e.id);

    region.workflow_status.forEach((w, i) => {
      if (w < 4 && objectTypes.find((ot) => ot.id === region.types[i])) {
        types.push(region.types[i]);
        categories.push(region.categories[i]);
        severities.push(region.severities[i]);
      }
    });

    // Sort types and severities by severity
    categories = categories.sort((a, b) => {
      const aSeverity = severities[types.indexOf(a)];
      const bSeverity = severities[types.indexOf(b)];
      return bSeverity - aSeverity;
    });
    types = types.sort((a, b) => {
      const aSeverity = severities[types.indexOf(a)];
      const bSeverity = severities[types.indexOf(b)];
      return bSeverity - aSeverity;
    });
    severities = severities.sort((a, b) => {
      const aSeverity = severities[types.indexOf(a)];
      const bSeverity = severities[types.indexOf(b)];
      return bSeverity - aSeverity;
    });

    if (types.length === 0) {
      return null;
    }
    if (types.length > 0 && !!types[0]) {
      const humanObjectTypes = types.map((type) =>
        objectTypes.find((ot) => ot.id === type)
      );
      const humanIssueType = humanObjectTypes.find((ot) => !!ot?.categories);
      if (!!humanIssueType) {
        color = annotatorObjectColor
          ? getClientObjectTypeName(humanIssueType.id).class_color
          : getClientObjectTypeName(humanIssueType.id).color;
      } else if (humanObjectTypes.length > 0) {
        color = annotatorObjectColor
          ? getClientObjectTypeName(humanObjectTypes[0].id).class_color
          : getClientObjectTypeName(humanObjectTypes[0].id).color;
      }
    } else {
      color = issueIds.includes(categories[0]) ? "#F00" : "#0F0";
    }

    const IconToUse = region.objectHasNoDefect ? CheckCircle : HelpOutline;

    let isDefect = region.workflow_status
      .map((e, i) => !!region.is_defect[i] && e !== 4)
      .includes(true);
    const checkmark = React.createElement(
      "foreignObject",
      {
        x: iw * region.w - 17,
        y: ih * region.h - 17,
        width: 25,
        height: 15,
      },
      React.createElement(
        "div",
        {
          onClick: function onClick(e) {
            e.stopPropagation();
            onChangeRegion({
              ...region,
              objectHasNoDefect: !region.objectHasNoDefect,
            });
          },
          style: {
            cursor: "pointer",
            pointerEvents: "all",
            color: region.objectHasNoDefect ? "#0A0" : "#FA4",
            width: 15,
            height: 15,
          },
        },
        <IconToUse
          style={{
            background: "#FFF",
            borderRadius: "100%",
            padding: 0,
            width: 15,
            height: 15,
          }}
        />
      )
    );

    return React.createElement(
      "g",
      {
        transform: "translate("
          .concat(region.x * iw, " ")
          .concat(region.y * ih, ")"),
      },
      React.createElement("rect", {
        strokeWidth: 1,
        x: 0,
        y: 0,
        width: region.w * iw,
        height: region.h * ih,
        stroke: colorAlpha(color, 0.75),
        fill: colorAlpha(color, 0),
        strokeWidth: 1.5,
      }),

      React.createElement("circle", {
        cx: 0,
        cy: 0,
        r: iw * 0.0003 + 1,
        fill: isDefect ? "#FF0000" : "#00ff44",
      }),
      React.createElement("circle", {
        cx: region.w * iw,
        cy: 0,
        r: iw * 0.0003 + 1,
        fill: isDefect ? "#FF0000" : "#00ff44",
      }),
      React.createElement("circle", {
        cx: region.w * iw,
        cy: region.h * ih,
        r: iw * 0.0003 + 1,
        fill: isDefect ? "#FF0000" : "#00ff44",
      }),
      React.createElement("circle", {
        cx: 0,
        cy: region.h * ih,
        r: iw * 0.0003 + 1,
        fill: isDefect ? "#FF0000" : "#00ff44",
      }),
      showNoDefectVerification && checkmark
    );
  }),
};
export var WrappedRegionList = memo(
  function (_ref13) {
    var regions = _ref13.regions,
      iw = _ref13.iw,
      onChangeRegion = _ref13.onChangeRegion,
      allRegionsVerified = _ref13.allRegionsVerified,
      objectTypes = _ref13.objectTypes,
      issueCategories = _ref13.issueCategories,
      annotatorObjectColor = _ref13.annotatorObjectColor,
      showHumanMachine = _ref13.showHumanMachine,
      ih = _ref13.ih,
      showNoDefectVerification = _ref13.showNoDefectVerification;
    return regions
      .filter(function (r) {
        return r.visible !== false;
      })
      .map(function (r) {
        var Component = RegionComponents["box"];
        return React.createElement(Component, {
          key: r.id,
          region: r,
          objectTypes: objectTypes,
          issueCategories: issueCategories,
          iw: iw,
          showHumanMachine: showHumanMachine,
          onChangeRegion: onChangeRegion,
          allRegionsVerified,
          ih: ih,
          annotatorObjectColor: annotatorObjectColor,
          showNoDefectVerification,
        });
      });
  },
  function (n, p) {
    return (
      n.regions === p.regions &&
      n.iw === p.iw &&
      n.ih === p.ih &&
      n.allRegionsVerified === p.allRegionsVerified &&
      n.showHumanMachine === p.showHumanMachine
    );
  }
);
export var RegionShapes = function RegionShapes(_ref14) {
  var imagePosition = _ref14.imagePosition,
    _ref14$regions = _ref14.regions,
    objectTypes = _ref14.objectTypes,
    issueCategories = _ref14.issueCategories,
    annotatorObjectColor = _ref14.annotatorObjectColor,
    onChangeRegion = _ref14.onChangeRegion,
    showHumanMachine = _ref14.showHumanMachine,
    allRegionsVerified = _ref14.allRegionsVerified,
    regions = _ref14$regions === void 0 ? [] : _ref14$regions;
  var iw = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  var ih = imagePosition.bottomRight.y - imagePosition.topLeft.y;
  if (isNaN(iw) || isNaN(ih)) return null;

  return React.createElement(
    "svg",
    {
      width: iw,
      height: ih,
      style: {
        position: "absolute",
        zIndex: 5,
        left: imagePosition.topLeft.x,
        top: imagePosition.topLeft.y,
        pointerEvents: "none",
        width: iw,
        height: ih,
      },
    },
    React.createElement(WrappedRegionList, {
      regions: regions,
      iw: iw,
      onChangeRegion: onChangeRegion,
      showHumanMachine: showHumanMachine,
      allRegionsVerified,
      ih: ih,
      objectTypes: objectTypes,
      issueCategories: issueCategories,
      annotatorObjectColor: annotatorObjectColor,
      showNoDefectVerification: showHumanMachine,
    })
  );
};
export default RegionShapes;
