import React, { ReactElement } from "react";
import { Image } from "@mui/icons-material";
import "./style.scss";

interface Props {
  poleImageToggle: boolean;
  setListViewPoleImageToggle: Function;
}

export default function PoleImageButton({
  poleImageToggle,
  setListViewPoleImageToggle,
}: Props): ReactElement {
  return (
    <div className="poleImageButtonWrapper">
      <div
        className="poleImageButton"
        onClick={() => setListViewPoleImageToggle(!poleImageToggle)}
      >
        <div
          className={
            poleImageToggle ? "poleImageElement" : "poleImageElement active"
          }
        ></div>
        <svg
          width="20"
          height="23"
          viewBox="0 0 16 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ zIndex: 1, marginRight: "3px" }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.64179 18L7.01881 9.46894C7.04475 8.88208 6.81137 8.31349 6.38061 7.91408L3.50746 5.25H1.69063C1.3092 5.25 1 4.9408 1 4.55938V4.55938C1 4.17795 1.3092 3.86875 1.69063 3.86875H1.73134V3.06035C1.73134 2.6853 2.03539 2.38125 2.41045 2.38125V2.38125C2.78551 2.38125 3.08955 2.6853 3.08955 3.06035V3.86875H3.8209V3.06035C3.8209 2.6853 4.12494 2.38125 4.5 2.38125V2.38125C4.87506 2.38125 5.1791 2.6853 5.1791 3.06035V3.86875H7.16418L7.3276 1.62521C7.35326 1.2728 7.64666 1 8 1V1C8.35334 1 8.64674 1.2728 8.67241 1.62521L8.83582 3.86875H10.8209V3.06035C10.8209 2.6853 11.1249 2.38125 11.5 2.38125V2.38125C11.8751 2.38125 12.1791 2.6853 12.1791 3.06035V3.86875H12.9104V3.06035C12.9104 2.6853 13.2145 2.38125 13.5896 2.38125V2.38125C13.9646 2.38125 14.2687 2.6853 14.2687 3.06035V3.86875H14.3094C14.6908 3.86875 15 4.17795 15 4.55938V4.55938C15 4.9408 14.6908 5.25 14.3094 5.25H12.3881L9.5873 7.92565C9.17563 8.31894 8.95108 8.86903 8.96994 9.43805L9.25373 18H6.64179ZM6.95522 6.84375L5.49254 5.25H7.16418V6.84375H6.95522ZM8.83582 6.84375V5.25H10.5075L9.04478 6.84375H8.83582Z"
            fill="white"
            stroke="white"
            strokeWidth="0.1"
          />
        </svg>
        <Image htmlColor="white" style={{ zIndex: 1, marginRight: "-4px" }} />
      </div>
    </div>
  );
}
