import { createContext, MutableRefObject } from "react";
import { IMatrix } from "../../types";

import { compose, scale } from "transformation-matrix";

const defaultMatrix = compose(scale(0, 0));

export const modeOptions = {
  ADD_DEFECT: "addDefect",
  ADD_DETECTION: "addDetection",
  ADD_STEELWORK: "addSteelwork",
  NONE_DEFECT_VERIFICATION: "noneDefectVerification",
  VIEW: "view",
} as const;

export type ModeType = (typeof modeOptions)[keyof typeof modeOptions] | null;

export interface IContextValue {
  mode: ModeType;
  setMode: (mode: ModeType) => void;
  modeOptions: typeof modeOptions;
  matrix: IMatrix;
  setMatrix: (matrix: IMatrix) => void;
  resetMatrix: () => void;
  imageDimensions: {
    naturalWidth: number | undefined;
    naturalHeight: number | undefined;
  };
  changeImageDimensions: (imageDimensions) => void;
  zoomSpeed: number;
  setZoomSpeed: (zoomSpeed: number) => void;
  dragged: boolean;
  setDragged: (dragged: boolean) => void;
  canvasWidth: number;
  canvasHeight: number;
  setCanvasWidth(width: number): void;
  setCanvasHeight: (height: number) => void;
  imageTopLeft: { x: number; y: number };
  imageBottomRight: { x: number; y: number };
  imageLoaded: boolean;
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
}

// Set up a context that is importable
const context = createContext<IContextValue>({
  mode: "view",
  setMode: () => {},
  modeOptions,
  matrix: defaultMatrix,
  setMatrix: () => {},
  resetMatrix: () => {},
  imageDimensions: {
    naturalWidth: undefined,
    naturalHeight: undefined,
  },
  changeImageDimensions: () => {},
  zoomSpeed: 17,
  setZoomSpeed: () => {},
  dragged: false,
  setDragged: () => {},
  canvasWidth: 0,
  canvasHeight: 0,
  setCanvasWidth: () => {},
  setCanvasHeight: () => {},
  imageTopLeft: { x: 0, y: 0 },
  imageBottomRight: { x: 0, y: 0 },
  imageLoaded: false,
  canvasRef: null,
});

export { context };
