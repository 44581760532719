import { useContext } from "react";

import { FilterCountContext } from "providers/FilterCountProvider";

export function useActorsCount() {
  const { actor, actorLoading, setActorTabActive } =
    useContext(FilterCountContext);

  return { actors: actor, loading: actorLoading, setActive: setActorTabActive };
}
