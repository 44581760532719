import React from "react";
import MenuItem from "components/MenuItem";
import translations from "translations";
import GoogleMapsIcon from "views/image/GoogleMapsIcon.png";
import { ImageMeta } from "interfaces";
import { useLanguage } from "hooks";
type Props = {
  imageMeta: ImageMeta;
};

export default function GoogleMaps({ imageMeta }: Props) {
  const openGmapLink = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${imageMeta.lat},${imageMeta.lng}&t=k`
    );
  };

  const { language } = useLanguage();
  return (
    <MenuItem
      tooltip={translations.ImageViewer.GoogleMaps[language]}
      onClick={openGmapLink}
      id="image-open-in-google-maps"
    >
      <img
        src={GoogleMapsIcon}
        alt={"GoogleMaps"}
        style={{ width: 20, height: 20 }}
      />
    </MenuItem>
  );
}
