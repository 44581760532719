import { Chip } from "@mui/material";
import Select from "react-select";
import { updateMarkers } from "state/actions";
import { getFilter, setFilter } from "utils/filter";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useFlightCount, useLanguage } from "hooks";
import translations from "translations";

export default function FlightFilter() {
  const { counts } = useFlightCount();
  const dispatch = useDispatch();
  const { language } = useLanguage();
  const [searchParams, setSearchParams] = useSearchParams();
  const flightFilter = getFilter("flight", searchParams);

  function labelWithCount(item: (typeof counts)[number]) {
    return {
      label: (
        <span>
          <Chip size="small" label={item.count} variant="outlined" />{" "}
          {translations.Filter.Flight[language]} {item.id} (
          {item.uploadLocation})
        </span>
      ),
      text: item.id,
      value: item.id,
    };
  }

  const flightsWithCount = counts.map(labelWithCount);
  function onChange(selectedOptions) {
    setFilter(
      "flight",
      selectedOptions.map((s) => s.value),
      searchParams,
      setSearchParams
    );
    dispatch(updateMarkers());
  }

  return (
    <Select
      placeholder={translations.Filter.SelectFlight[language]}
      options={flightsWithCount}
      value={flightsWithCount.filter((f) => flightFilter.includes(f.value))}
      isMulti
      name={"type"}
      onChange={onChange}
      maxMenuHeight={400}
      menuPosition="fixed"
      captureMenuScroll={false}
      getOptionValue={(option) => `${option.value}`}
    />
  );
}
