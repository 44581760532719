import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getFilterClearanceAxes(
  searchParams: URLSearchParams
): FilterType["CLEARANCE_AXES"] {
  const clearanceAxesParams = searchParams.get(FilterKey.CLEARANCE_AXES);
  return clearanceAxesParams ? clearanceAxesParams.split(",").map(Number) : [];
}

export function useClearanceAxesFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const clearanceAxes = getFilterClearanceAxes(searchParams);

  const setClearanceAxes = (listFilter: number[]) => {
    if (listFilter.length === 0) {
      if (searchParams.has(FilterKey.CLEARANCE_AXES))
        searchParams.delete(FilterKey.CLEARANCE_AXES);
    } else {
      searchParams.set(FilterKey.CLEARANCE_AXES, listFilter.join(","));
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { clearanceAxes, setClearanceAxes };
}
