import { useContext, useEffect, useState, useCallback } from "react";
import { AnnotationContext } from "views/AnnotationTool/provider";
import { Alert, Box } from "@mui/material";
import { ISpecialAnnotation } from "views/AnnotationTool/provider/AnnotationProvider/context";
import { checkSteelworkConnection } from "views/AnnotationTool/api";
import { useParams } from "react-router-dom";
import DropdownEdit from "./DropdownEdit";
import AnnotherMemberDetected from "./AnnotherMemberDetected";
import {
  checkItemIdMissing,
  checkDirectionMissing,
  checkSameImage,
  checkBringBackLinkOnRevert,
  checkRemoveLinkOnRevert,
  checkChangeWillUnlink,
  checkAlreadyLinked,
  checkLinkOrChange,
} from "./Utils";
import { useSelector, useCurrentProject, useLanguage } from "hooks";
import { saveErrors } from "api/image/annotation";
import debounce from "lodash/debounce";
import translations from "translations";

export type ISteelworkConnection = Awaited<
  ReturnType<typeof checkSteelworkConnection>
>;

interface ISetSaveErrorValue {
  error: string | null;
  parent_link?: number | null;
  child_link?: number | null;
  pole_id?: number | null;
}

export interface IProps {
  annotation: ISpecialAnnotation;
}

export default function Steelwork({ annotation }: IProps) {
  const {
    annotations,
    updateAnnotation,
    originalAnnotations,
    steelworkLegacyDirectionId,
  } = useContext(AnnotationContext);

  const currentProject = useCurrentProject();
  const { language } = useLanguage();

  const pathParams = useParams();
  const imageID = parseInt(pathParams.image);

  const pole_id = useSelector((state) => state?.image?.current?.pole_id);

  const [checkConnection, setCheckConnection] = useState<ISteelworkConnection>({
    exists: false,
    sameImageWarning: false,
    already_linked: false,
    steelwork_id: null,
    item_id: null,
    direction_id: null,
    second_direction_id: null,
    bolt_condition_id: null,
    steel_grading_id: null,
  });

  const [saveErrorValue, setSaveErrorValue] =
    useState<ISetSaveErrorValue | null>(null);

  //The first useEffect to trigger the debouncedGetData function when link-sensitive fields are changed
  useEffect(() => {
    const originalAnnotation = originalAnnotations.find(
      (a) => a.id === annotation.id
    );

    debouncedGetData(annotation, originalAnnotation);
  }, [
    annotation?.steelwork?.item_id,
    annotation?.steelwork?.direction,
    annotation?.steelwork?.second_direction,
    annotation?.steelwork?.child_link,
    annotation?.steelwork?.parent_link,
    originalAnnotations,
    imageID,
  ]);

  //Using a debounced function to check the connection between steelwork members to not spam the API when typing in the input fields
  const debouncedGetData = useCallback(
    debounce(async (annotation, originalAnnotation) => {
      //This is to check if the annotation has a legacy item_id to give it a pole_id if the direction is changed
      let legacyPoleIdUpdate = null;
      if (
        originalAnnotation?.steelwork?.direction?.id ===
          steelworkLegacyDirectionId &&
        annotation.steelwork.direction.id !== steelworkLegacyDirectionId
      ) {
        legacyPoleIdUpdate = pole_id;
      }

      //First check non link sensitive fields that dosent need the API
      if (checkItemIdMissing(annotation)) {
        setSaveErrorValue({ error: saveErrors.ITEM_ID_MISSING });
        return;
      }

      if (checkDirectionMissing(annotation)) {
        setSaveErrorValue({ error: saveErrors.DIRECTION_MISSING });
        return;
      }
      if (checkSameImage(annotation, annotations)) {
        setSaveErrorValue({ error: saveErrors.SAME_IMAGE });
        return;
      }
      if (annotation.steelwork?.pole_id === null && pole_id === null) {
        setSaveErrorValue(null);
        return;
      }
      //Then get the link data from the API
      const checkConnectionResponse = await checkSteelworkConnection(
        currentProject.id,
        annotation,
        imageID
      );
      setCheckConnection(checkConnectionResponse);

      let editLinks = null;

      //This is two functions that check if the links should be added or removed when the connection is changed
      editLinks = checkBringBackLinkOnRevert(
        checkConnectionResponse,
        annotation,
        originalAnnotation,
        editLinks
      );

      editLinks = checkRemoveLinkOnRevert(
        checkConnectionResponse,
        annotation,
        editLinks
      );

      //This checks if the changes will unlink members to give a warning
      if (checkChangeWillUnlink(checkConnectionResponse, originalAnnotation)) {
        setSaveErrorValue({
          error: saveErrors.CHANGES_WILL_UNLINK,
          parent_link: null,
          child_link: null,
        });
        return;
      }

      //This checks if the members are already linked and blocks the save
      if (checkAlreadyLinked(checkConnectionResponse, originalAnnotation)) {
        setSaveErrorValue({ error: saveErrors.ALREADY_LINKED });
        return;
      }

      //This notices that annother member is detected and gives the option to link them
      if (
        checkLinkOrChange(
          checkConnectionResponse,
          annotation,
          originalAnnotation
        )
      ) {
        setSaveErrorValue({ error: saveErrors.LINK_OR_CHANGE });
        return;
      }

      //If no errors are found, the save error is set to null
      //The editLinks object is used to update the links in the annotation
      if (editLinks) {
        if (legacyPoleIdUpdate) {
          setSaveErrorValue({
            error: null,
            ...editLinks,
            pole_id: legacyPoleIdUpdate,
          });
        } else {
          setSaveErrorValue({ error: null, ...editLinks });
        }
      }
      if (legacyPoleIdUpdate) {
        setSaveErrorValue({ error: null, pole_id: legacyPoleIdUpdate });
      }
      setSaveErrorValue(null);
    }, 200),
    []
  );

  //This useEffect triggers when the saveErrorValue is changed and updates the annotation with the new values
  useEffect(() => {
    let updateValues = {};

    if (saveErrorValue === null) {
      if (annotation.steelwork?.save_error) {
        updateValues = { ...updateValues, save_error: null };
      }
    } else {
      if ("parent_link" in saveErrorValue) {
        if (
          annotation.steelwork?.parent_link?.id !== saveErrorValue.parent_link
        ) {
          updateValues = {
            ...updateValues,
            parent_link: saveErrorValue.parent_link,
          };
        }
      }
      if ("child_link" in saveErrorValue) {
        if (
          annotation.steelwork?.child_link?.id !== saveErrorValue.child_link
        ) {
          updateValues = {
            ...updateValues,
            child_link: saveErrorValue.child_link,
          };
        }
      }

      if ("pole_id" in saveErrorValue) {
        if (annotation.steelwork?.pole_id !== saveErrorValue.pole_id) {
          updateValues = { ...updateValues, pole_id: saveErrorValue.pole_id };
        }
      }

      if ("error" in saveErrorValue) {
        if (annotation.steelwork?.save_error !== saveErrorValue.error) {
          updateValues = { ...updateValues, save_error: saveErrorValue.error };
        }
      }
    }

    if (Object.keys(updateValues).length > 0) {
      updateAnnotation(annotation.id, {
        ...annotation,
        steelwork: {
          ...annotation.steelwork,
          ...updateValues,
        },
      });
    }
  }, [saveErrorValue]);

  const renderAlert = (annotation, checkConnection, saveErrors) => {
    switch (annotation?.steelwork?.save_error) {
      case saveErrors.ALREADY_LINKED:
        return (
          <Alert severity="error" sx={{ m: 2 }} variant="outlined">
            {
              translations.AnnotationTool.Steelwork.MemberAlreadyLinked[
                language
              ]
            }
          </Alert>
        );
      case saveErrors.SAME_IMAGE:
        return (
          <Alert severity="error" sx={{ m: 2 }} variant="outlined">
            {
              translations.AnnotationTool.Steelwork.MemberAlreadyInImage[
                language
              ]
            }
          </Alert>
        );
      case saveErrors.LINK_OR_CHANGE:
        return (
          <AnnotherMemberDetected
            annotation={annotation}
            checkConnection={checkConnection}
          />
        );
      case saveErrors.CHANGES_WILL_UNLINK:
        return (
          <Alert severity="warning" sx={{ m: 2 }} variant="outlined">
            {translations.AnnotationTool.Steelwork.ChangesWillUnlink[language]}
          </Alert>
        );
      case saveErrors.ITEM_ID_MISSING:
        return (
          <Alert
            data-testId="item-id-missing"
            severity="error"
            sx={{ m: 2 }}
            variant="outlined"
          >
            {translations.AnnotationTool.Steelwork.ItemIdMissing[language]}
          </Alert>
        );
      case saveErrors.DIRECTION_MISSING:
        return (
          <Alert
            data-testId="direction-missing"
            severity="error"
            sx={{ m: 2 }}
            variant="outlined"
          >
            {translations.AnnotationTool.Steelwork.DirectionMissing[language]}
          </Alert>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "10px",
      }}
    >
      {annotation?.steelwork?.direction?.id === steelworkLegacyDirectionId && (
        <Alert severity="warning">
          {`Legacy data detected with item_id: ${annotation?.steelwork?.item_id}. Please update the direction data.`}
        </Alert>
      )}
      {renderAlert(annotation, checkConnection, saveErrors)}

      <DropdownEdit annotation={annotation} checkConnection={checkConnection} />
    </Box>
  );
}
