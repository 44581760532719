import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { severities } from "AppConstants";
import { useTranslation } from "hooks";
import { useState } from "react";
import { getClientSeverityName } from "utils/utils";

const display_severities = [
  severities.CRITICAL,
  severities.ISSUE,
  severities.SHOULD_BE_FIXED,
  severities.GOOD_TO_KNOW,
];

type Props = {
  onClose: (severity: (typeof display_severities)[number]) => void;
};

export default function SetTypeSeverityDialog({ onClose }: Props) {
  const confirmText = useTranslation("Confirm");
  const setSeverityForIssueText = useTranslation("SetSeverityForIssue");

  const [selectedSeverity, setSelectedSeverity] = useState(severities.ISSUE);

  const onDialogClose = () => onClose(selectedSeverity);

  return (
    <Dialog
      open={true}
      onClose={onDialogClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          onDialogClose();
        },
        "data-testid": "settypeseveritydialog.dialog",
      }}
    >
      <DialogTitle>{setSeverityForIssueText}</DialogTitle>
      <div className="dialogFlex">
        <DialogContent>
          <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
            <Select
              labelId="select-severity-label"
              id="select-severity"
              value={selectedSeverity}
              onChange={(event) =>
                setSelectedSeverity(Number(event.target.value))
              }
            >
              {display_severities.map((severity) => (
                <MenuItem
                  value={severity}
                  data-testid={`settypeseveritydialog.severity.${severity}`}
                >
                  {getClientSeverityName(severity).name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="submit" data-testid="submit-set-type-severity">
            {confirmText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
