import { useSelector } from "hooks";
import WorkSession from "./WorkSession";

export default function WorkSessionWrapper() {
  const annotationMode = useSelector(
    (state) => state.map.inspectorMode || state.user.annotator_color
  );
  if (!annotationMode) {
    return null;
  }
  return <WorkSession />;
}
