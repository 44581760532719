import isEqual from "lodash/isEqual";
import store from "state/store";
import { asMutable } from "seamless-immutable";
import translations from "translations";

let propertiesToCheck = [
  "h",
  "categories",
  "types",
  "severities",
  "w",
  "x",
  "y",
  "is_defect",
  "workflow_status",
  "skyqraft_hidden",
  "isLocked",
  "properties",
  "objectHasNoDefect",
  "type_comment",
];

const checkState = (state) => {
  let currentRegions = state.regions;
  let lastSavedRegions = state.lastSavedRegions;

  currentRegions = currentRegions.map((r) => ({
    ...r,
    highlighted: false,
    editingLabels: false,
    reporting: false,
    locked: false,
    visible: false,
    secondMenuOpen: false,
    thirdMenuOpen: false,
  }));

  lastSavedRegions = lastSavedRegions.map((r) => ({
    ...r,
    highlighted: false,
    editingLabels: false,
    reporting: false,
    locked: false,
    visible: false,
    secondMenuOpen: false,
    thirdMenuOpen: false,
  }));
  // @ts-ignore
  const req = isEqual(lastSavedRegions, currentRegions, true);

  return req;
};

const compareRegions = (state, setCanSwitchImage: (value: boolean) => void) => {
  // Compare the last saved region state, and the current version with eachother

  const currentRegions = state.regions;
  const lastSavedRegions = state.lastSavedRegions;
  const allObjectHuman = state.allObjectHuman;

  const allEqual = regionsAreEqual(
    currentRegions,
    lastSavedRegions,
    setCanSwitchImage,
    allObjectHuman,
    true
  );
  // Inform map-ui state
  setCanSwitchImage(allEqual);
  return allEqual;
};

export { compareRegions, checkState };

export const checkRegionUpdate = (
  region,
  regions,
  objectTypes,
  issueCategories
) => {
  let oldRegion = regions.find((r) => r.id === region.id);
  if (!!!oldRegion) {
    // The region does not exist
    return {
      new: true,
      saved: false,
      canSave: canSaveObject(region, objectTypes, issueCategories)?.canSave,
    };
  }

  let identical = propertiesToCheck.every((property) =>
    isEqual(region[property], oldRegion[property])
  );
  if (identical) {
    return {
      new: false,
      saved: true,
      canSave: canSaveObject(region, objectTypes, issueCategories)?.canSave,
    };
  } else {
    return {
      new: false,
      saved: false,
      canSave: canSaveObject(region, objectTypes, issueCategories)?.canSave,
    };
  }
};

export const regionsAreEqual = (
  regionsA,
  regionsB,
  setCanSwitchImage: (value: boolean) => void,
  allObjectHuman = false,
  updateState = false
) => {
  let extractedRegionsA, extractedRegionsB;

  if (!!regionsA.asMutable) {
    extractedRegionsA = asMutable(regionsA);
  } else {
    extractedRegionsA = regionsA;
  }
  if (!!regionsB.asMutable) {
    extractedRegionsB = asMutable(regionsB);
  } else {
    extractedRegionsB = regionsB;
  }

  extractedRegionsA = extractedRegionsA.map((r) => {
    let extractedRegion = {};
    propertiesToCheck.forEach(
      (property) => (extractedRegion[property] = r[property])
    );
    return extractedRegion;
  });
  extractedRegionsB = extractedRegionsB.map((r) => {
    let extractedRegion = {};
    propertiesToCheck.forEach(
      (property) => (extractedRegion[property] = r[property])
    );
    return extractedRegion;
  });
  const req = isEqual(
    extractedRegionsA.sort((a, b) => (a.id < b.id ? 1 : -1)),
    extractedRegionsB.sort((a, b) => (a.id < b.id ? 1 : -1))
  );

  if (updateState) {
    const oldValue = store.getState().image.canSwitchImage;
    const newValue = req && !allObjectHuman;
    if (oldValue !== newValue) {
      setCanSwitchImage(newValue);
    }
  }

  return req;
};

export const canSaveObject = (region, objectTypes, issueCategories) => {
  let language = store.getState().user.language;
  let canSave = true;
  let message = "";
  let issueCatIds = issueCategories.map((e) => e.id);
  const detectionTypes = objectTypes.filter((o) => !o.issue).map((o) => o.id);

  // We cannot allow objects without definition
  if (region.types.length === 0 || region.severities.length === 0) {
    return {
      canSave: false,
      message: translations.AnnotationTool.NoDefinitionsSpecified[language],
    };
  }

  // Definition array length must be equal
  if (region.types.length !== region.severities.length) {
    return {
      canSave: false,
      message: translations.AnnotationTool.DefinitionError[language],
    };
  }

  // Type cannot be null
  if (region.types.includes(null)) {
    return {
      canSave: false,
      message: translations.AnnotationTool.TypeMissing[language],
    };
  }

  // Verify each post
  for (let i = 0; i < region.types.length; i++) {
    if (detectionTypes.includes(region.types[i])) {
      canSave = canSave && Boolean(region.types[i]);
      message = !canSave && translations.AnnotationTool.TypeError[language];
    } else {
      canSave = canSave && Boolean(region.severities[i]);
      message =
        !canSave && translations.AnnotationTool.SeverityMissing[language];
    }
  }

  return { canSave: canSave, message: message };
};
