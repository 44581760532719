import Select from "react-select";
import {
  Stack,
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import Preview from "./Preview";
import { Mission } from "interfaces";
import { groupBy } from "lodash";
import translations from "translations";
import {
  useActorsCount,
  useCurrentProject,
  useDefectCount,
  useDetectionCount,
  useFlightCount,
  useLanguage,
  useSelector,
  useSeverityCount,
  useWorkflowCount,
} from "hooks";
import { getProjectName } from "utils/utils";
import {
  ActorsFilter,
  DateSelectorFilter,
  TypeSelectFilter,
  FlaggedImageFilter,
  FlightFilter,
  ImageListFilter,
  NewDefectsFilter,
  SkyqraftHiddenFilter,
  WorkflowStatusFilter,
} from "components/Filter/FilterComponents";
import { useEffect } from "react";

interface IOption {
  label: string;
  value: number;
}

export default function NewReport() {
  const projects = useSelector((state) => state.user.missions);
  const { language } = useLanguage();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const context = useOutletContext<{ updateTrigger?: () => void }>();
  const currentProject = useCurrentProject();
  const updateTrigger = context?.updateTrigger;

  const severityCount = useSeverityCount();
  const workflowCount = useWorkflowCount();
  const defectCount = useDefectCount();
  const detectionCount = useDetectionCount();
  const flightCount = useFlightCount();
  const actorCount = useActorsCount();

  useEffect(() => {
    console.log("Setting all to active");
    severityCount.setActive(true);
    workflowCount.setActive(true);
    defectCount.setActive(true);
    detectionCount.setActive(true);
    flightCount.setActive(true);
    actorCount.setActive(true);
  }, [
    severityCount,
    workflowCount,
    defectCount,
    detectionCount,
    flightCount,
    actorCount,
  ]);

  const selectedMission = currentProject
    ? {
        label: getProjectName(currentProject.id)?.fullname,
        value: currentProject.id,
        fixable: currentProject.fixable_defects,
        processable_defects: currentProject.processable_defects,
      }
    : null;

  function onMissionSelect(option: IOption) {
    const searchParamsString = searchParams.toString();
    navigate(
      `/${option.value}/inbox/new${
        searchParamsString.length ? `?${searchParamsString}` : ""
      }`
    );
    updateTrigger?.();
  }

  const groups = groupBy(projects, (m: Mission) => m?.group?.name);
  const options = [];
  const groupKeys = Object.keys(groups).sort((a, b) => (a > b ? 1 : -1));
  for (const groupName of groupKeys) {
    const groupMissions: Mission[] = groups[groupName];
    let thisName = groupName;
    if (!groupName || groupName === "undefined") {
      thisName = translations.Inbox.Reports.NoGroup[language];
    }
    options.push({
      label: thisName,
      options: groupMissions.map((gm) => {
        const projectName = getProjectName(gm.id);
        const region = gm.region ? `${gm.region.name} -` : "";
        return {
          label: `${region} ${projectName?.fullname}`,
          value: gm.id,
        };
      }),
    });
  }

  return (
    <Box>
      <Container>
        <Typography component="h2" variant="h4" sx={{ p: 2 }}>
          {translations.Inbox.Reports.CreateNewReport[language]}
        </Typography>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography component="h3" variant="h6">
              {translations.words.Mission[language]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ mb: 1 }}>
              {translations.Inbox.Reports.SelectProject[language]}
            </Typography>
            <Select
              styles={{
                // @ts-ignore
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: selectedMission
                    ? "rgba(0,0,0,0)"
                    : undefined,
                }),
              }}
              value={selectedMission}
              onChange={onMissionSelect}
              options={options}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            disabled={!selectedMission}
          >
            <Typography component="h3" variant="h6">
              {translations.Inbox.Reports.DefectType[language]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ mb: 1 }}>
              {translations.Inbox.Reports.SelectDefect[language]}
            </Typography>
            <Typography sx={{ mb: 1, fontStyle: "italic" }}>
              {translations.Inbox.Reports.ExplainFilters[language]}
            </Typography>
            <TypeSelectFilter issue={true} vegetation={false} />
          </AccordionDetails>
        </Accordion>

        <Accordion disabled={!selectedMission}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography component="h3" variant="h6">
              {translations.Inbox.Reports.AdditionalDetails[language]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ImageListFilter />
            <NewDefectsFilter />
            <ActorsFilter />
            <WorkflowStatusFilter />
            <DateSelectorFilter />
            <FlightFilter />
            <FlaggedImageFilter />
            <SkyqraftHiddenFilter />
          </AccordionDetails>
        </Accordion>
        <Stack spacing={2} mt={2}>
          {selectedMission && (
            <Preview
              mission={selectedMission}
              projectID={selectedMission?.value}
            />
          )}
        </Stack>
      </Container>
    </Box>
  );
}
