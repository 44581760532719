import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Slide, Typography, Box, Tabs, Tab } from "@mui/material";
import { authorizedGet } from "utils/request";
import { TransitionProps } from "@mui/material/transitions";
import LogView from "./LogView";
import StepView from "./StepView";
import "./style.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Log {
  level: "debug" | "info" | "warning" | "error" | "critical";
  text: string;
  timestamp: string;
}

interface IStep {
  end: string;
  id: number;
  status: "running" | "waiting" | "cancel" | "fail" | "success";
  logs: Log[];
}

interface JobStep {
  creator: string;
  end: string;
  job_id: number;
  mission_id: number;
  n_images: number;
  start: string;
  status: "running" | "waiting" | "cancel" | "fail" | "success";
  steps: IStep[];
  watchers: string[];
}

export interface LogData {
  log;
  running;
  aborted;
  processed;
  cloud_duplicates;
  db_duplicates;
  skipped;
  json: JobStep | null;
}

export default function AlertDialogSlide() {
  const navigate = useNavigate();
  const params = useParams();
  const job = params.job;
  const [loading, setLoading] = useState(true);
  const [logData, setLogs] = useState<LogData>({
    log: [],
    running: false,
    aborted: false,
    processed: 0,
    cloud_duplicates: 0,
    db_duplicates: 0,
    skipped: 0,
    json: null,
  });
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    getLog();
  }, []);

  const getLog = () =>
    authorizedGet<LogData>(`/upload/log?job_id=${job}`).then((data) => {
      setLoading(false);
      setLogs(data);
    });

  const handleClose = () => {
    navigate("..");
  };

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        id="uploadView"
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
            aria-label="basic tabs example"
          >
            <Tab label="Logs" />
            {!!logData?.json?.mission_id && <Tab label="Steps (beta)" />}
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <LogView loading={loading} data={logData} update={getLog} />
        </TabPanel>
        {!!logData?.json?.mission_id && (
          <TabPanel value={tabValue} index={1}>
            <StepView loading={loading} data={logData} />
          </TabPanel>
        )}
      </Dialog>
    </div>
  );
}
