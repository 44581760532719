import { IssueSeverity, ObjectType } from "interfaces";
import { connect } from "react-redux";
import { RootState } from "state/store";
import { IImageAnnotation } from "views/AnnotationTool/api";
import Component from "./Region";

interface IStateProps {
  objectTypes: ObjectType[];
  skyqraft_employee: boolean;
  issueSeverities: IssueSeverity[];
  annotatorObjectColor: boolean;
}

interface IDispatchProps {}

interface IOwnProps {
  annotation: IImageAnnotation;
  iw: number;
  ih: number;
}

const mapStateToProps = (state: RootState) => {
  return {
    objectTypes: state.objects.objectTypes,
    issueSeverities: state.objects.issueSeverities,
    skyqraft_employee: state.user.skyqraft_employee,
    annotatorObjectColor: state.user.annotator_color,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
