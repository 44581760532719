import React, { ReactElement, useState } from "react";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
  Grid,
} from "@mui/material";
import {
  Check,
  Clear,
  Info,
  Share,
  Tune,
  Edit,
  LockOutlined,
  LockOpenOutlined,
} from "@mui/icons-material";
import { FilterKey } from "hooks/filter/search_parameters/constants";
import RoleWrapper from "components/RoleHOC/wrapper";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import {
  useCurrentProject,
  useDispatch,
  useListFilter,
  useSelector,
  useTranslation,
} from "hooks";
import {
  createList,
  deleteList,
  updateList,
  updateMarkers,
} from "state/actions";
import { copyLinkToClipboard } from "utils/clipboard";

export default function ImageListFilter({
  open,
}: {
  open?: boolean;
}): ReactElement {
  const dispatch = useDispatch();
  const { listFilter, setListFilter } = useListFilter();
  const lists = useSelector((state) => state.image.lists);
  const userID = useSelector((state) => state.user.id);
  const currentProject = useCurrentProject();

  const DeleteConfirm = useTranslation("ListDeleteConfirm");
  const RenamePrompt = useTranslation("ListRenamePrompt");
  const ListFilterActive = useTranslation("ListFilterActive");
  const ListFilterList = useTranslation("List");
  const ListFilterImages = useTranslation("Images");
  const ListFilterInfo = useTranslation("Info");
  const ListFilterPublic = useTranslation("Public");
  const ListFilterLocked = useTranslation("Locked");
  const ListFilterUnLocked = useTranslation("Unlocked");
  const ListFilterRename = useTranslation("Rename");
  const ListFilterDelete = useTranslation("Delete");
  const ListFilterShare = useTranslation("Share");
  const ListFilterCopiedToClipboard = useTranslation("LinkCopiedToClipboard");
  const ListFilterNewList = useTranslation("NewList");
  const ListFilterAdd = useTranslation("ListFilterAdd");

  const [newListName, setNewListName] = useState("");
  const [publicList, setPublic] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const addList = () => {
    if (newListName.length > 0) {
      dispatch(createList(currentProject.id, newListName, publicList));
      setNewListName("");
    } else {
      toast.error("You must enter a name for the list.");
    }
  };

  const removeList = (list) => {
    if (window.confirm(`${DeleteConfirm} "${list.title}"`)) {
      const currentSearchParams = new URLSearchParams(searchParams);
      const activeLists = currentSearchParams.get("list");
      if (activeLists) {
        const newActiveLists = activeLists
          .split(",")
          .filter((id) => id !== list.id.toString());
        const newValue = newActiveLists.join(",");
        if (newValue.length > 0) {
          currentSearchParams.set("list", newActiveLists.join(","));
        } else {
          currentSearchParams.delete("list");
        }

        setSearchParams(currentSearchParams, { replace: true });
      }
      dispatch(deleteList(currentProject.id, list.id));
      dispatch(updateMarkers());
    }
  };

  const updateTitle = (list) => {
    const newTitle = window
      .prompt(`${RenamePrompt} "${list.title}"`, list.title)
      .trim();

    if (newTitle === list.title) {
      return;
    }

    dispatch(updateList(currentProject.id, list.id, { title: newTitle }));
  };

  return (
    <Grid container spacing={1} pl={2} py={1}>
      <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
        <Typography>{ListFilterActive}</Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
        <Typography>{ListFilterList}</Typography>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography>{ListFilterImages}</Typography>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography>{ListFilterInfo}</Typography>
      </Grid>

      {lists.map((list, i) => (
        <React.Fragment key={`${i}`}>
          <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={listFilter.includes(list.id)}
              onChange={() => {
                if (listFilter.includes(list.id)) {
                  setListFilter(listFilter.filter((id) => id !== list.id));
                } else {
                  setListFilter([...listFilter, list.id]);
                }
                dispatch(updateMarkers());
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
            <Typography>{list.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{list.images.length}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Tooltip
              placement="right"
              arrow
              title={
                <Grid container spacing={3} width={140} margin={0}>
                  {list.owner.id === userID && (
                    <Grid
                      container
                      xs={12}
                      onClick={() =>
                        dispatch(
                          updateList(currentProject.id, list.id, {
                            public: !list.publicList,
                          })
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Grid item xs={8}>
                        <Typography>{ListFilterPublic}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                      >
                        {list.publicList ? <Check /> : <Clear />}
                      </Grid>
                    </Grid>
                  )}
                  {list.owner.id === userID && (
                    <Grid
                      container
                      xs={12}
                      onClick={() =>
                        dispatch(
                          updateList(currentProject.id, list.id, {
                            locked: !list.locked,
                          })
                        )
                      }
                      sx={{ cursor: "pointer" }}
                    >
                      <Grid item xs={8}>
                        <Typography>
                          {list.locked ? ListFilterLocked : ListFilterUnLocked}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                      >
                        {!list.locked ? <LockOpenOutlined /> : <LockOutlined />}
                      </Grid>
                    </Grid>
                  )}
                  {list.owner.id === userID && (
                    <Grid
                      container
                      xs={12}
                      onClick={() => updateTitle(list)}
                      sx={{ cursor: "pointer" }}
                    >
                      <Grid item xs={8}>
                        <Typography>{ListFilterRename}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                      >
                        <Edit style={{ color: "gold" }} />
                      </Grid>
                    </Grid>
                  )}
                  {list.owner.id === userID && (
                    <RoleWrapper keyName="deleteFilterList">
                      <Grid
                        container
                        xs={12}
                        onClick={() => removeList(list)}
                        sx={{ cursor: "pointer" }}
                      >
                        <Grid item xs={8}>
                          <Typography>{ListFilterDelete}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Clear className="redIcon" />
                        </Grid>
                      </Grid>
                    </RoleWrapper>
                  )}
                  <Grid
                    container
                    xs={12}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      const uriQuery = new URLSearchParams();
                      uriQuery.set(FilterKey.LIST, list.id);
                      uriQuery.set(FilterKey.FILTER_ACTIVE, "true");
                      copyLinkToClipboard({
                        pathname: `/${currentProject.id}`,
                        query: uriQuery,
                      });
                      toast.success(ListFilterCopiedToClipboard);
                    }}
                  >
                    <Grid item xs={8}>
                      <Typography>{ListFilterShare}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Share style={{ color: "blue" }} />
                    </Grid>
                  </Grid>
                </Grid>
              }
            >
              {list.owner.id === userID ? <Tune /> : <Info />}
            </Tooltip>
          </Grid>
        </React.Fragment>
      ))}
      <RoleWrapper keyName="filterListCreate">
        <>
          <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              variant="standard"
              required
              id="standard-required"
              placeholder={ListFilterNewList}
              onChange={(e) => setNewListName(e.target.value)}
              value={newListName}
              onKeyDown={(e) => e.stopPropagation()}
              onKeyPress={(e) => e.stopPropagation()}
            />
          </Grid>
          <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={publicList}
                  onChange={() => setPublic(!publicList)}
                  name="public"
                />
              }
              label="Public"
            />
          </Grid>
          <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
            <Button
              className="greenButton"
              onClick={addList}
              sx={{ color: "inherit" }}
            >
              {ListFilterAdd}
            </Button>
          </Grid>
        </>
      </RoleWrapper>
    </Grid>
  );
}
