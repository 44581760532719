import React, { Fragment } from "react";
import { Stack, Typography } from "@mui/material";
import { Mission } from "interfaces";
import groupBy from "lodash/groupBy";
import translations from "translations";
import RegionItem from "./RegionItem";
import MissionItem from "./MissionItem";
import { useLanguage } from "hooks";

type Props = {
  missions: Mission[];
  demoMode?: boolean;
};

export default function MissionList({ missions, demoMode }: Props) {
  let addedMissions = new Set();
  let groups = groupBy(missions, (g) => g?.group?.name);
  const { language } = useLanguage();
  return (
    <Stack>
      {Object.keys(groups)
        .sort()
        .filter((g) => g !== "undefined")
        .map((groupName) => {
          if (!!!groupName || groupName === "undefined") {
            return null;
          }
          const regions = groupBy(groups[groupName], (m) => m?.region?.name);
          const regionNames = Object.keys(regions).sort();
          return (
            <Fragment key={`fragment-${groupName}`}>
              <Typography
                key={groupName}
                sx={{ backgroundColor: "black", color: "white", px: 1 }}
              >
                {demoMode
                  ? groupName.length > 0
                    ? `${translations.words.Customer[language]} ${groupName[0]}...`
                    : translations.words.Customer[language]
                  : groupName}
              </Typography>
              {regionNames.map((regionName, j) => {
                if (regionName === "undefined") {
                  return null;
                }
                const missions = regions[regionName].filter((m) => !m.deleted);
                if (missions.length === 0) {
                  return null;
                }
                return (
                  <RegionItem
                    key={`Group-region-${regionName}`}
                    name={regionName}
                    missions={missions}
                  />
                );
              })}
              {groups[groupName]
                .filter((m) => !!!m.region)
                .sort((ma, mb) => (ma.name > mb.name ? 1 : -1))
                .map((mission) => {
                  addedMissions.add(mission.id);
                  return <MissionItem key={mission.id} mission={mission} />;
                })}
            </Fragment>
          );
        })}
      {missions.filter((mission) => !addedMissions.has(mission.id)).length >
        0 && (
        <Typography sx={{ backgroundColor: "black", color: "white", px: 1 }}>
          {translations.Menu.Missions.OtherMissions[language]}
        </Typography>
      )}
      {missions
        .filter((mission) => !!!mission.group)
        .sort((ma, mb) => (ma.name > mb.name ? 1 : -1))
        .map((mission) => (
          <MissionItem key={mission.id} mission={mission} />
        ))}
    </Stack>
  );
}
