import { useState, useEffect } from "react";
import lidarAPI from "api/lidar";

type IClearanceAxis = Awaited<
  ReturnType<typeof lidarAPI.cleranceAxis.getClearanceAxes>
>[number];

export function useClearanceAxis(projectID: number) {
  const [loading, setLoading] = useState<boolean>(false);
  const [clearanceAxes, setClearanceAxes] = useState<IClearanceAxis[]>([]);

  useEffect(() => {
    collectClearanceAxis(projectID);
  }, [projectID]);

  async function collectClearanceAxis(projectID: number) {
    if (projectID === -1) {
      setClearanceAxes([]);
      console.error("Project ID is not valid");
      return;
    }
    setLoading(true);
    try {
      const axes = await lidarAPI.cleranceAxis.getClearanceAxes(projectID);
      setClearanceAxes(axes);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  }

  return { clearanceAxes, loading };
}
