import FolderView from "../FolderView";
import { Stack, Divider, Button } from "@mui/material";
import Dropzone from "../Dropzone";

interface IProps {
  disabled?: boolean;
  startBatchJob: (
    rerun?: boolean,
    queue_enabled?: boolean,
    overwrite_db?: boolean
  ) => void;
}

export default function FolderUploadView({ disabled, startBatchJob }: IProps) {
  return (
    <Stack spacing={2} sx={{ pt: 4 }}>
      <Divider flexItem>Upload images</Divider>
      <Dropzone disabled={disabled} />
      <Divider flexItem>Previous uploads</Divider>
      <Stack
        spacing={2}
        direction="row"
        px={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Button
          variant="contained"
          color="success"
          onClick={() => startBatchJob()}
        >
          Start job manually
        </Button>
      </Stack>
      <FolderView disabled={disabled} />
    </Stack>
  );
}
