import SteelworkLine from "./SteelworkLine";

type Props = {
  h: number;
  w: number;
  color: string;
  selected: boolean;
  lowlight: boolean;
};
const styles = `
  .highlighted-line-forward {
    animation: dashForward 1s linear infinite;
  }

  .highlighted-line-reverse {
    animation: dashReverse 1s linear infinite;
  }

  @keyframes dashForward {
    to {
      stroke-dashoffset: -20; /* Moves dashes forward */
    }
  }

  @keyframes dashReverse {
    to {
      stroke-dashoffset: 20; /* Moves dashes in reverse */
    }
  }
`;

export default function SteelworkBox({
  w,
  h,
  color,
  selected,
  lowlight,
}: Props) {
  return (
    <>
      <style>{styles}</style>
      {/* Dashed Lines */}
      <SteelworkLine
        x1={w}
        y1={0}
        x2={w}
        y2={h}
        color={color}
        selected={selected}
        lowlight={lowlight}
        dashed
        reverseAnimation={selected}
      />
      <SteelworkLine
        x1={0}
        y1={h}
        x2={w}
        y2={h}
        color={color}
        selected={selected}
        lowlight={lowlight}
        dashed
        forwardAnimation={selected}
      />

      {/* Main Line */}
      {w >= h ? (
        <>
          <SteelworkLine
            x1={0}
            y1={0}
            x2={w}
            y2={0}
            color={color}
            selected={selected}
            lowlight={lowlight}
          />
          <SteelworkLine
            x1={0}
            y1={0}
            x2={0}
            y2={h}
            color={color}
            selected={selected}
            lowlight={lowlight}
            dashed
            forwardAnimation={selected}
          />
        </>
      ) : (
        <>
          <SteelworkLine
            x1={0}
            y1={0}
            x2={0}
            y2={h}
            color={color}
            selected={selected}
            lowlight={lowlight}
          />
          <SteelworkLine
            x1={0}
            y1={0}
            x2={w}
            y2={0}
            color={color}
            selected={selected}
            lowlight={lowlight}
            dashed
            reverseAnimation={selected}
          />
        </>
      )}
    </>
  );
}
