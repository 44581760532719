import { useSelector } from "hooks";
import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getSkyqraftHiddenFilter(
  searchParams: URLSearchParams,
  defaultValue?: FilterType["SKYQRAFT_HIDDEN"]
): FilterType["SKYQRAFT_HIDDEN"] {
  const skyqraftHiddenParams = searchParams.get(FilterKey.SKYQRAFT_HIDDEN);
  if (skyqraftHiddenParams === "both") {
    return "both";
  } else if (skyqraftHiddenParams === "only") {
    return "only";
  } else if (skyqraftHiddenParams === "none") {
    return "none";
  }
  return defaultValue || "none";
}

export function getDefaultValue(isSkyqraftEmployee: boolean) {
  return isSkyqraftEmployee ? "both" : "none";
}

export function useSkyqraftHiddenFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isSkyqraftEmployee = useSelector(
    (state) => state.user.skyqraft_employee
  );
  const defaultValue = getDefaultValue(isSkyqraftEmployee);

  const skyqraftHidden = getSkyqraftHiddenFilter(searchParams, defaultValue);

  const setSkyqraftHidden = (value: FilterType["SKYQRAFT_HIDDEN"]) => {
    if (value === defaultValue) {
      if (searchParams.has(FilterKey.SKYQRAFT_HIDDEN))
        searchParams.delete(FilterKey.SKYQRAFT_HIDDEN);
    } else {
      searchParams.set(FilterKey.SKYQRAFT_HIDDEN, value);
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { skyqraftHidden, setSkyqraftHidden };
}
