import { useState, useEffect } from "react";
import { authorizedGet } from "utils/request";

interface Feedback {
  id: number;
  image_id: number;
  customer_id: number;
  old_type: number;
  new_type: number;
  old_severity: number;
  new_severity: number;
}

interface IProps {
  mission?: number;
}

/**
 * A React hook that collects client altered types
 */
export function useClientAlteredTypes({ mission }: IProps = {}): [
  Feedback[],
  boolean
] {
  const [imageFeedbacks, setImageFeedbacks] = useState<Feedback[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Find the endpoint based on the input
    let endpoint = "/object/clientalteredtypes";

    // Send a request
    setLoading(true);
    authorizedGet<{ images: Feedback[] }>(endpoint)
      .then((response) => {
        setLoading(false);
        setImageFeedbacks(
          !!mission
            ? response.images.filter((f) => f.customer_id === mission)
            : response.images
        );
      })
      .catch((err) => {
        setLoading(false);
        setImageFeedbacks([]);
        console.error("Failed to collect image feedbacks", err);
      });
  }, [mission]);

  return [imageFeedbacks, loading];
}
