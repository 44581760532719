import React from "react";
import MenuItem from "components/MenuItem";
import { LinearScale } from "@mui/icons-material";
import translations from "translations";
import { useSearchParams } from "react-router-dom";
import { useLanguage } from "hooks";

const KEY = "pipes";
const ENABLED_VALUE = "0";
const DISABLED_VALUE = "1";

export default function Pipelines() {
  const pipeData = [];
  const [searchParams, setSearchParams] = useSearchParams();
  const { language } = useLanguage();
  const togglePipes = () => {
    if (searchParams.has(KEY)) {
      searchParams.delete(KEY);
    } else {
      searchParams.append(KEY, ENABLED_VALUE);
    }
    setSearchParams(searchParams.toString(), { replace: true });
  };
  const pipes = parseInt(searchParams.get("bbox") ?? DISABLED_VALUE);

  return pipeData.length > 1 ? (
    <MenuItem
      tooltip={translations.ImageViewer.Pipelines[language]}
      onClick={togglePipes}
      id="image-toggle-pipelines"
    >
      {pipes ? <LinearScale /> : <LinearScale />}
    </MenuItem>
  ) : null;
}
