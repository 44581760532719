import { useState, useEffect } from "react";
import { authorizedGet, authorizedPost } from "utils/request";

interface IArea {
  id: number;
  name: string;
  group_id: number;
}

/**
 * A React hook that collects and manipulates areas for a group.
 *
 *  @example
 *  ```javascript
 *  const [areas, createArea, loading] = useGroupAreas(group_id);
 *  ```
 */
export function useGroupAreas(
  group_id: number
): [IArea[], (name: string) => Promise<IArea>, boolean] {
  const [areas, setAreas] = useState<IArea[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  let endpoint = `/admin/group/${group_id}/area`;

  async function collectAreas() {
    setLoading(true);
    const response = await authorizedGet<{ areas: IArea[] }>(endpoint);
    try {
      setLoading(false);
      setAreas(response.areas);
    } catch (err) {
      setLoading(false);
      setAreas([]);
      console.error("Failed to collect group areas", err);
    }
  }

  async function addArea(name: string) {
    setLoading(true);
    const newArea: IArea = await authorizedPost(endpoint, { name });
    await collectAreas();
    return newArea;
  }

  useEffect(() => {
    collectAreas();
  }, []);

  return [areas, addArea, loading];
}
