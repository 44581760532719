export const translations = {
  NoFilterActive: {
    EN: "No filter active",
    SE: "Inget filter aktivt",
    NO: "Ingen filter aktiv",
  },
  ShowingConnectedImages: {
    EN: "Showing connected images",
    SE: "Visar anslutna bilder",
    NO: "Viser tilkoblede bilder",
  },
  ShowingDisconnectedImages: {
    EN: "Showing not connected images",
    SE: "Visar ej anslutna bilder",
    NO: "Viser frakoblede bilder",
  },
};
