import { axiosInstance } from "utils/request";

export interface IClearanceAxis {
  id: number;
  name: {
    en: string;
    sv: string;
  };
  colorScale: {
    id: number;
    color: string;
    axisValue: number;
  } | null;
  minValue: number;
  maxValue: number;
  thresholds: {
    1: number;
    2: number;
    3: number;
    4: number;
  };
}

interface IGetClearanceAxis {
  axes: IClearanceAxis[];
}

export async function getClearanceAxes(projectID: number) {
  const endpoint = "/lidar/clearance/axes";
  const options = {
    headers: { MissionID: projectID },
  };
  const response = await axiosInstance.get<IGetClearanceAxis>(
    endpoint,
    options
  );

  if (response.status !== 200) {
    throw new Error(
      `Failed to collect clearance axis for project ${projectID}`
    );
  }

  return response.data.axes;
}

export default {
  getClearanceAxes,
} as const;
