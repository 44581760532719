import { createContext, useState, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import {
  IPoleTemplate,
  getPoleTemplates,
  deletePoleTemplate,
  updatePoleTemplate,
  deletePoleTemplateItem,
} from "views/PoleViewer/api";
import {
  extractComponents,
  extractPlacements,
  IComponent,
  IPlacement,
} from "./utils";
import { RootState } from "state/reducers";

type ParamsKeys = "template_id" | "component_id" | "placement_id";

interface IContextValue {
  templates: IPoleTemplate[];
  components: IComponent[];
  placements: IPlacement[];
  setTemplates: (templates: IPoleTemplate[]) => void;
  loading: boolean;
  templateID: number | null;
  componentID: number | null;
  placementID: number | null;
  deleteTemplate: (templateID: number) => Promise<void>;
  updateTemplate: (templateID: number, name: string) => Promise<void>;
  deleteComponent: (templateID: number, componentID: number) => Promise<void>;
  deletePlacement: (
    templateID: number,
    componentID: number,
    placementID: number
  ) => Promise<void>;
  group: RootState["group"]["groups"][number] | null;
  setGroup: (group: RootState["group"]["groups"][number] | null) => void;
}

// Set up a context that is importable
const context = createContext<IContextValue>({
  templates: [],
  components: [],
  placements: [],
  setTemplates: () => {},
  loading: false,
  templateID: null,
  componentID: null,
  placementID: null,
  deleteTemplate: (templateID: number) => Promise.resolve(),
  updateTemplate: (templateID: number, name: string) => Promise.resolve(),
  deleteComponent: (templateID: number, componentID: number) =>
    Promise.resolve(),
  deletePlacement: (
    templateID: number,
    componentID: number,
    placementID: number
  ) => Promise.resolve(),
  group: null,
  setGroup: () => null,
});

function TemplateOptionsProvider() {
  // Define the shared state
  const [templates, setTemplates] = useState<IPoleTemplate[]>([]);
  const [components, setComponents] = useState<IComponent[]>([]);
  const [placements, setPlacements] = useState<IPlacement[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [group, setGroup] = useState<
    RootState["group"]["groups"][number] | null
  >(null);

  const params = useParams<Record<ParamsKeys, string>>();
  const templateID = parseInt(params.template_id);
  const componentID = parseInt(params.component_id);
  const placementID = parseInt(params.placement_id);

  // Collect templates when the component mounts
  useEffect(() => {
    updateTemplates(group?.id);
  }, [group?.id]);

  // Extract components when the templates change
  useEffect(() => {
    if (templateID !== null) {
      const template = templates.find((template) => template.id === templateID);
      const components = extractComponents(template);
      setComponents(components);
    }
  }, [templateID, templates]);

  // Extract placements when the components change
  useEffect(() => {
    if (!components.map((c) => c.id).includes(componentID)) {
      return;
    }
    if (componentID !== null) {
      const template = templates.find((template) => template.id === templateID);
      const placements = extractPlacements(template, componentID);
      setPlacements(placements);
    }
  }, [componentID, components, templateID, templates]);

  async function updateTemplates(groupID: number) {
    setLoading(true);
    const response = await getPoleTemplates(groupID);
    setTemplates(response);
    setLoading(false);
  }

  async function deleteTemplate(templateID: number) {
    // Verify that we have data loaded
    if (loading) {
      throw new Error("Cannot delete template while loading");
    }

    // Verify that the template exists
    const template = templates.find((t) => t.id === templateID);
    if (!template) {
      throw new Error(`Template with id ${templateID} does not exist`);
    }

    // Delete the template
    await deletePoleTemplate(templateID);

    await updateTemplates(group?.id);
  }

  async function updateTemplate(templateID: number, name: string) {
    await updatePoleTemplate(templateID, name, group?.id);
    await updateTemplates(group?.id);
  }

  async function deleteComponent(templateID: number, componentID: number) {
    const template = templates.find((t) => t.id === templateID);
    const itemsToDelete = template.items.filter(
      (item) => item.component.id === componentID
    );

    for (const items of itemsToDelete) {
      await deletePoleTemplateItem(templateID, items.id);
    }
    await updateTemplates(group?.id);
  }

  async function deletePlacement(
    templateID: number,
    componentID: number,
    placementID: number
  ) {
    const template = templates.find((t) => t.id === templateID);
    const itemsToDelete = template.items.filter(
      (item) =>
        item.component.id === componentID && item.placement.id === placementID
    );

    for (const items of itemsToDelete) {
      await deletePoleTemplateItem(templateID, items.id);
    }
    await updateTemplates(group?.id);
  }

  // Build the payload to all consumers
  const payload: IContextValue = {
    templates,
    components,
    placements,
    setTemplates,
    loading,
    templateID,
    componentID,
    placementID,
    deleteTemplate,
    updateTemplate,
    deleteComponent,
    deletePlacement,
    group,
    setGroup,
  };

  // Render the context provider
  return (
    <context.Provider value={payload}>
      <Outlet />
    </context.Provider>
  );
}

export default TemplateOptionsProvider;
export { context };
export type { IContextValue };
