import { connect } from "react-redux";
import Component from "./TimeSelector";
import { RootState } from "state/store";
import { changeMission } from "state/actions";
import { setMission } from "utils/path";
import { Mission } from "interfaces";

interface IStateProps {
  missions: Mission[];
  setMission: (number) => void;
}

interface IDispatchProps {
  changeMission: (number, boolean) => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    missions: state.user.missions,
    setMission: setMission,
  };
};

const mapDispatchToProps = {
  changeMission,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
