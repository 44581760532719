import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { storyblokEditable } from "@storyblok/react";
import { Link, useLocation } from "react-router-dom";

type Props = {
  blok: any;
};

export default function CMSMenuItem({ blok }: Props) {
  const location = useLocation();

  let cleanLocation = blok.to.cached_url.startsWith("/")
    ? blok.to.cached_url
    : "/" + blok.to.cached_url;
  const active = location.pathname === cleanLocation;

  return (
    <Box
      sx={{
        backgroundColor: active ? "#F8F8F8" : undefined,
        "&:hover": {
          backgroundColor: "white",
        },
        transition: "background-color 400ms",
        borderRadius: 2,
      }}
    >
      <Link
        to={
          blok.to.cached_url.startsWith("/")
            ? blok.to.cached_url
            : "/" + blok.to.cached_url
        }
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >
        <Stack
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          py={{ xs: 1, md: 0 }}
          pr={2}
        >
          <div
            style={{
              width: 8,
              height: 8,
              borderRadius: 10,
              background: active ? "blue" : "transparent",
              marginRight: 8,
              marginLeft: 8,
            }}
          />
          <Typography>{blok.text}</Typography>
        </Stack>
      </Link>
    </Box>
  );
}
