import { useState, useEffect } from "react";
import lidarAPI from "api/lidar";

type IClearanceSystem = Awaited<
  ReturnType<typeof lidarAPI.clearanceSystem.getClearanceSystems>
>[number];

/**
 * A React hook that collects the clearance systems for a project
 *
 *  @example
 *  ```javascript
 *  const [clearanceSystems, loading] = useClearanceSystems(25);
 *  ```
 */
export function useClearanceSystems(
  projectID: number
): [IClearanceSystem[], boolean] {
  const [clearanceSystems, setClearanceSystems] = useState<IClearanceSystem[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  async function collectClearanceSystems(projectID: number) {
    setLoading(true);
    try {
      const response =
        await lidarAPI.clearanceSystem.getClearanceSystems(projectID);
      setClearanceSystems(response);
    } catch (error) {
      console.error(error);
      setClearanceSystems([]);
    }
    setLoading(false);
  }

  useEffect(() => {
    collectClearanceSystems(projectID);
  }, [projectID]);

  return [clearanceSystems, loading];
}
