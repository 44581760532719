import { useSearchParams } from "react-router-dom";
import { setDateOfKey } from "./utils";
import { FilterKey, FilterType } from "./constants";

export function getDateDefectAddedFilter(
  searchParams: URLSearchParams
): FilterType["DATE_DEFECT_ADDED"] {
  const dateDefectAddedParams =
    searchParams.get(FilterKey.DATE_DEFECT_ADDED) ?? "";
  const splitDates = dateDefectAddedParams
    .split(",")
    .filter((d) => d.length > 0);
  let dateDefectAdded: FilterType["DATE_DEFECT_ADDED"] = null;
  if (splitDates.length === 1) {
    splitDates.push(splitDates[0]);
    dateDefectAdded = new Date(splitDates[0]);
  } else if (splitDates.length === 2) {
    dateDefectAdded = [new Date(splitDates[0]), new Date(splitDates[1])];
  } else if (splitDates.length > 2) {
    console.error("Too many dates in the dateDefectAdded filter");
    dateDefectAdded = [new Date(splitDates[0]), new Date(splitDates[1])];
  }
  return dateDefectAdded;
}

export function useDateDefectAddedFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  // Collect the search parameters
  const dateDefectAdded = getDateDefectAddedFilter(searchParams);

  // Convert the key function to each specific key
  function setDateDefectAdded(startDate: Date | null, endDate: Date | null) {
    setDateOfKey(
      searchParams,
      setSearchParams,
      FilterKey.DATE_DEFECT_ADDED,
      startDate,
      endDate
    );
  }

  // Export the functions and filters
  return {
    dateDefectAdded,
    setDateDefectAdded,
  };
}
