import { ReactElement } from "react";
import Select from "react-select";
import { useSearchParams } from "react-router-dom";
import { getFilter, setFilter } from "utils/filter";
import {
  useDispatch,
  useClearanceSystems,
  useCurrentProject,
  useLanguage,
} from "hooks";
import { updateMarkers } from "state/actions";
import translations from "translations";

export default function ClearanceRuleFilter({
  open,
}: {
  open?: boolean;
}): ReactElement {
  const dispatch = useDispatch();
  const { language } = useLanguage();
  const currentProject = useCurrentProject();
  const [clearanceSystems, loading] = useClearanceSystems(
    currentProject?.id ?? -1
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedClearanceSystems = getFilter("clearanceRules", searchParams);

  function setClearanceRules(ruleIDs) {
    setFilter("clearanceRules", ruleIDs, searchParams, setSearchParams);
    dispatch(updateMarkers());
  }

  const selectOptions = clearanceSystems.map((clearanceSystem) => {
    return {
      value: clearanceSystem.id,
      label: clearanceSystem.name,
    };
  });

  return (
    <Select
      isMulti
      isLoading={loading}
      placeholder={translations.Filter.ClearanceRule[language]}
      loadingMessage={() => translations.Filter.Loading[language]}
      options={selectOptions}
      value={clearanceSystems
        .filter((o) => selectedClearanceSystems.includes(o.id))
        .map((o) => {
          return {
            value: o.id,
            label: o.name,
          };
        })}
      onChange={(e) => {
        setClearanceRules(
          e.map((o) => {
            return o.value;
          })
        );
      }}
    />
  );
}
