import React, { ReactElement, useState } from "react";
import translations from "translations";
import Select from "react-select";
import { useLanguage } from "hooks";

interface Props {
  truePositive: Array<any>;
  falsePositive: Array<any>;
  falseNegative: Array<any>;
  totalPoles: number;
}

export default function MachineDetectionCategories({
  truePositive,
  falsePositive,
  falseNegative,
  totalPoles,
}: Props): ReactElement {
  const { language } = useLanguage();

  const [dropdownSelect, setDropdownSelect] = useState([]);

  let dataMachineAccuracy = [];
  let type_id = [];
  let TP = [];
  let FN = [];
  let FP = [];
  let TN = [];

  function MachineValues() {
    let Accuracy = [];
    let Precision = [];
    let Recall = [];

    truePositive.forEach((e) => {
      type_id.push({ name: e.name, sv_name: e.sv_name, type_id: e.type_id });
    });

    falseNegative.forEach((e) => {
      type_id.findIndex((item) => item.type_id === e.type_id) === -1 &&
        type_id.push({ name: e.name, sv_name: e.sv_name, type_id: e.type_id });
    });

    falsePositive.forEach((e) => {
      type_id.findIndex((item) => item.type_id === e.type_id) === -1 &&
        type_id.push({ name: e.name, sv_name: e.sv_name, type_id: e.type_id });
    });

    type_id.forEach((e, i) => {
      let TP_index = truePositive.findIndex(
        (item) => item.type_id === e.type_id
      );
      let FN_index = falseNegative.findIndex(
        (item) => item.type_id === e.type_id
      );
      let FP_index = falsePositive.findIndex(
        (item) => item.type_id === e.type_id
      );

      TP_index === -1
        ? TP.push({
            count: 0,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          })
        : TP.push({
            count: truePositive[TP_index].count,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          });
      FN_index === -1
        ? FN.push({
            count: 0,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          })
        : FN.push({
            count: falseNegative[FN_index].count,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          });
      FP_index === -1
        ? FP.push({
            count: 0,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          })
        : FP.push({
            count: falsePositive[FP_index].count,
            name: e.name,
            sv_name: e.sv_name,
            type_id: e.type_id,
          });

      TN.push({
        count: totalPoles - TP[i].count - FP[i].count - FN[i].count,
        name: e.name,
        sv_name: e.sv_name,
        type_id: e.type_id,
      });
      Accuracy.push((TP[i].count + TN[i].count) / totalPoles);
      TP[i].count + FP[i].count === 0
        ? Precision.push(0)
        : Precision.push(TP[i].count / (TP[i].count + FP[i].count));
      TP[i].count + FN[i].count === 0
        ? Recall.push(0)
        : Recall.push(TP[i].count / (TP[i].count + FN[i].count));

      if (!(TP[i].count === 0 && FN[i].count === 0)) {
        dataMachineAccuracy.push({
          type: language === "EN" ? e.name : e.sv_name,
          Accuracy: Math.round(Accuracy[i] * 100),
          Precision: Math.round(Precision[i] * 100),
          Recall: Math.round(Recall[i] * 100),
        });
      }
    });
  }

  MachineValues();

  const RecallAccuracy = (type, index) => {
    return (
      <div key={index}>
        {(dropdownSelect["value"] === type.name ||
          dropdownSelect["value"] === type.sv_name) && (
          <>
            <div
              style={{ height: "400px" }}
              className="precision-recall-accuracy-wrapper"
            >
              <div className="precision-recall-accuracy">
                <div></div>
                <div className="grid-label">
                  {
                    translations.Menu.Statistics.MachineLearning.Positive[
                      language
                    ]
                  }
                </div>
                <div className="grid-label">
                  {
                    translations.Menu.Statistics.MachineLearning.Negative[
                      language
                    ]
                  }
                </div>
                <div className="grid-label">
                  {translations.Menu.Statistics.MachineLearning.True[language]}
                </div>
                <div className="grid-item true">
                  {
                    TP[TP.findIndex((item) => item.type_id === type.type_id)]
                      .count
                  }
                </div>
                <div className="grid-item true">
                  {
                    TN[TN.findIndex((item) => item.type_id === type.type_id)]
                      .count
                  }
                </div>
                <div className="grid-label">
                  {translations.Menu.Statistics.MachineLearning.False[language]}
                </div>
                <div className="grid-item false">
                  {
                    FP[FP.findIndex((item) => item.type_id === type.type_id)]
                      .count
                  }
                </div>
                <div className="grid-item false">
                  {
                    FN[FN.findIndex((item) => item.type_id === type.type_id)]
                      .count
                  }
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {type_id.length > 0 && (
        <div className="statisticitem">
          <h2>
            {
              translations.Menu.Statistics.MachineLearning
                .MachineDetectionCategories[language]
            }
          </h2>

          <Select
            options={type_id.map((e) =>
              language === "EN"
                ? { label: e.name, value: e.name }
                : { label: e.sv_name, value: e.sv_name }
            )}
            onChange={setDropdownSelect}
            value={type_id
              .map((e) =>
                language === "EN"
                  ? { label: e.name, value: e.name }
                  : { label: e.sv_name, value: e.sv_name }
              )
              .find((o) => o.value === dropdownSelect)}
          />

          {type_id.map((e, i) => RecallAccuracy(e, i))}
        </div>
      )}
    </>
  );
}
