import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getFlaggedFilter(
  searchParams: URLSearchParams
): FilterType["FLAGGED"] {
  const flaggedParams = searchParams.get(FilterKey.FLAGGED) ?? "";
  return flaggedParams === "true";
}

export function useFlaggedFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const flagged = getFlaggedFilter(searchParams);

  const setFlagged = (value: boolean) => {
    if (value) {
      if (searchParams.has(FilterKey.FLAGGED))
        searchParams.delete(FilterKey.FLAGGED);
    } else {
      searchParams.set(FilterKey.FLAGGED, "true");
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { flagged, setFlagged };
}
