import React from "react";

type Props = {
  content: any;
};

export default function CMSEmoji({ content }: Props) {
  const attributes = content.attrs;
  return attributes.emoji;
}
