import { Accordion } from "components";
import { useImageStatus, useLanguage } from "hooks";
import { useNavigate, useParams } from "react-router-dom";
import translations from "translations";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";

type Props = {
  setSupervisorMode: (value: boolean) => void;
  setInspectorMode: (value: boolean) => void;
  changeAnnotatorColor: (value: boolean) => void;
  toggleDemoMode: (value: boolean) => void;
};

export default function FlaggedImageList({
  setSupervisorMode,
  setInspectorMode,
  changeAnnotatorColor,
  toggleDemoMode,
}: Props) {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const params = useParams();
  const missionID = parseInt(params.mission);
  const [imageStatus, loading] = useImageStatus({ mission: missionID });

  const totalFlagged = imageStatus
    .map((a) => a.flaggedAssignedImages)
    .reduce((a, b) => a + b, 0);

  return (
    <Accordion
      title={translations.Landing.Assignments.FlaggedImages[language]}
      description={
        translations.Landing.Assignments.FlaggedImages.Description[language]
      }
      value={totalFlagged}
    >
      <Grid container spacing={2}>
        <Grid item xs={4} display="flex">
          <Typography fontWeight="bold">
            {translations.words.Mission[language]}
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center">
          <Typography fontWeight="bold">
            {translations.Landing.Assignments.AssignedImages[language]}
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center">
          <Typography fontWeight="bold">
            {translations.Landing.Assignments.FlaggedImages[language]}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }} />
      <Stack divider={<Divider />}>
        {imageStatus.map((status, i) => (
          <Grid key={`${i}`} container spacing={2} sx={{ py: 1 }}>
            <Grid display="flex" alignItems="center" item xs={4}>
              <Box
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`${status.mission.id}`);
                  setInspectorMode(true);
                  changeAnnotatorColor(false);
                  toggleDemoMode(false);
                  setSupervisorMode(false);
                }}
              >
                <Typography>{status.mission.name}</Typography>
              </Box>
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              item
              xs={4}
            >
              <Typography>{status.assignedImages}</Typography>
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              item
              xs={4}
            >
              <Typography>{status.flaggedAssignedImages}</Typography>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </Accordion>
  );
}
