import { axiosInstance } from "utils/request";

interface IFilterActors {
  id: number | "Skyqraft" | "AI";
  count: {
    created: number;
    updated: number;
  };
  name: string;
}

export async function getFilterActors(projectID: number) {
  const response = await axiosInstance.get<{ actors: IFilterActors[] }>(
    `/filter/project/${projectID}/actors`
  );
  if (response.status !== 200) {
    throw new Error("Error fetching actors");
  }
  return response.data.actors;
}
