import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IState {
  menu: {
    show: boolean;
    x: number;
    y: number;
    lat: number;
    lng: number;
    hasImages: boolean;
    items: [];
  };
  boxSelect: boolean;
}

interface IShowMenu {
  x: number;
  y: number;
  lat: number;
  lng: number;
  hasImages: boolean;
  items: [];
}

let initialState: IState = {
  menu: {
    show: false,
    x: 0,
    y: 0,
    lat: 0,
    lng: 0,
    hasImages: false,
    items: [],
  },
  boxSelect: false,
};

const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    showMenu(state, action: PayloadAction<IShowMenu>) {
      state.menu = { ...action.payload, show: true };
    },
    toggleMenu(state, action: PayloadAction<boolean>) {
      state.menu.show = action.payload;
    },
    toggleBoxSelect(state, action: PayloadAction<boolean>) {
      state.boxSelect = action.payload;
    },
  },
});

export const { toggleMenu, showMenu, toggleBoxSelect } = viewSlice.actions;

export default viewSlice.reducer;
