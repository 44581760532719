import { combineReducers } from "redux";

// Import reducers
import viewReducer, { IState as IViewState } from "../view";
import uiReducer, {
  initialState as uiInitialState,
  IState as IUIState,
} from "./uireducer";
import userReducer, {
  initialState as userInitialState,
  IState as IUserState,
} from "./userreducer";
import objectReducer, {
  initialState as objectInitialState,
  IState as IObjectState,
} from "./objectreducer";
import imageViewerReducer, {
  initialState as imageViewerInitialState,
  IState as IImageViewerState,
} from "./imageViewerReducer";
import imageReducer, {
  initialState as imageInitialState,
  IState as IImageState,
} from "./imageReducer";
import toastReducer, {
  initialState as toastInitialState,
  IState as IToastState,
} from "./toastReducer";
import mapReducer, {
  initialState as mapInitialState,
  IState as IMapState,
} from "./mapReducer";
import statisticsReducer, {
  initialState as statisticsInitialState,
  IState as IStatisticsState,
} from "./statisticsReducer";
import metricsReducer, {
  initialState as metricsInitialState,
  IState as IMetricsState,
} from "./metricsReducer";
import missionReducer, {
  initialState as missionInitialState,
  IState as IMissionState,
} from "./missionReducer";
import imageObjectUploadReducer, {
  initialState as imageObjectUploadInitialState,
  IState as IImageObjectUploadState,
} from "./imageObjectUploadReducer";
import imageObjectDownloadReducer, {
  initialState as imageObjectDownloadInitialState,
  IState as IImageObjectDownloadState,
} from "./imageObjectDownloadReducer";
import imageUploadReducer, {
  initialState as imageUploadInitialState,
  IState as IImageUploadState,
} from "./imageUploadReducer";
import groupReducer, {
  initialState as groupInitialState,
  IState as IGroupState,
} from "./groupReducer";
import navigationReducer, {
  initialState as navigationInitialState,
  IState as INavigationState,
} from "./navigationReducer";
import lidarReducer, {
  initialState as lidarInitialState,
  IState as ILidarState,
} from "./lidarReducer";

export interface RootState {
  map: IMapState;
  view: IViewState;
  user: IUserState;
  objects: IObjectState;
  ui: IUIState;
  imageViewer: IImageViewerState;
  image: IImageState;
  toast: IToastState;
  statistics: IStatisticsState;
  metrics: IMetricsState;
  mission: IMissionState;
  imageObjectUpload: IImageObjectUploadState;
  imageObjectDownload: IImageObjectDownloadState;
  imageUpload: IImageUploadState;
  group: IGroupState;
  navigation: INavigationState;
  lidar: ILidarState;
}

const rootReducer = combineReducers({
  view: viewReducer,
  user: userReducer,
  objects: objectReducer,
  ui: uiReducer,
  imageViewer: imageViewerReducer,
  image: imageReducer,
  toast: toastReducer,
  map: mapReducer,
  statistics: statisticsReducer,
  metrics: metricsReducer,
  mission: missionReducer,
  imageObjectUpload: imageObjectUploadReducer,
  imageObjectDownload: imageObjectDownloadReducer,
  imageUpload: imageUploadReducer,
  group: groupReducer,
  navigation: navigationReducer,
  lidar: lidarReducer,
});

export const initialState = {
  ui: uiInitialState,
  user: userInitialState,
  object: objectInitialState,
  imageViewer: imageViewerInitialState,
  image: imageInitialState,
  toast: toastInitialState,
  map: mapInitialState,
  statistics: statisticsInitialState,
  metrics: metricsInitialState,
  mission: missionInitialState,
  imageObjectUpload: imageObjectUploadInitialState,
  imageObjectDownload: imageObjectDownloadInitialState,
  imageUpload: imageUploadInitialState,
  group: groupInitialState,
  navigation: navigationInitialState,
  lidar: lidarInitialState,
};

export default rootReducer;
