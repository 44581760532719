import { connect } from "react-redux";
import { RootState } from "state/store";
import Component from "./Canvas";

interface IStateProps {
  imagePoleID: number | null;
}

interface IDispatchProps {}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    imagePoleID: state.image?.current?.pole_id,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
