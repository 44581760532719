import React, { ReactElement } from "react";
import { Collapse } from "@mui/material";
import { getClientSeverityName } from "utils/utils";
import groupBy from "lodash/groupBy";

interface Props {
  region;
  issueSeverities;
  saveObject;
  index;
}

export default function EditSeverity({
  region,
  issueSeverities,
  saveObject,
  index,
}: Props): ReactElement {
  const shouldBeVisible =
    (region.editType === "severity" && region.editIndex === index) ||
    (!!region.highlighted &&
      !!!region.severities[index] &&
      !!region.types[index] &&
      !!region.is_defect[index]);

  let newIssueSeverities = [];

  issueSeverities.map((s) =>
    newIssueSeverities.push(getClientSeverityName(s.id))
  );
  // @ts-ignore
  newIssueSeverities = groupBy(newIssueSeverities, "client_severity_type_id");

  newIssueSeverities = Object.values(newIssueSeverities).map((e) => {
    return { ...e[0] };
  });

  newIssueSeverities.sort((a, b) => a.id - b.id);

  return (
    <Collapse in={shouldBeVisible}>
      <div className="severityContainer">
        {newIssueSeverities
          .filter((i) => i.id < 11)
          .map((s) => (
            <div
              key={s.id}
              className="severityItem"
              style={{
                backgroundColor:
                  region.severities[index] === s.id ? "#FDD" : "",
              }}
              onClick={() => {
                saveObject(
                  {
                    ...region,
                    severities: region.severities.map((value, i) =>
                      i === index ? s.id : value
                    ),
                    workflow_status: region.workflow_status.map((value, i) =>
                      i === index ? Math.max(value, 2) : value
                    ),
                    isNew: false,
                  },
                  "severity"
                );
              }}
            >
              <p>{s.name}</p>
            </div>
          ))}
      </div>
    </Collapse>
  );
}
