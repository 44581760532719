import React from "react";
import { Button } from "@mui/material";
import translations from "translations";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "hooks";
type Props = {
  jobs: {
    id;
    created;
    folder;
    aborted;
    running;
    successful;
    processed;
    skipped;
  }[];
};

export default function LogList({ jobs }: Props) {
  const { language } = useLanguage();
  const navigate = useNavigate();

  return (
    <table className={"jobTable"} style={{ flex: 1 }}>
      <thead>
        <tr>
          <th>ID</th>
          <th>
            {translations.Menu.Tools.ImageUpload.Import.Created[language]}
          </th>
          <th>{translations.Menu.Tools.ImageUpload.Import.Folder[language]}</th>
          <th>{translations.Menu.Tools.ImageUpload.Import.Status[language]}</th>
          <th>{translations.Menu.Tools.ImageUpload.Import.Images[language]}</th>
          <th>
            {translations.Menu.Tools.ImageUpload.Import.Skipped[language]}
          </th>
          <th>{translations.Menu.Tools.ImageUpload.Import.Logs[language]}</th>
        </tr>
      </thead>
      <tbody>
        {jobs &&
          jobs.map((job, id) => {
            return (
              <tr
                key={id}
                style={{
                  backgroundColor: job.running
                    ? "#FFD"
                    : job.aborted
                    ? "#FDD"
                    : job.successful
                    ? "#DFD"
                    : "#FB9",
                }}
              >
                <td>{job.id}</td>
                <td>{job.created}</td>
                <td>{job.folder}</td>
                <td>
                  {job.aborted
                    ? translations.Menu.Tools.ImageUpload.Import.Aborted[
                        language
                      ]
                    : job.running
                    ? translations.Menu.Tools.ImageUpload.Import.Running[
                        language
                      ]
                    : job.successful
                    ? translations.Menu.Tools.ImageUpload.Import.Successful[
                        language
                      ]
                    : translations.Menu.Tools.ImageUpload.Import
                        .PartiallySuccessful[language]}
                </td>
                <td>{job.processed}</td>
                <td>{job.skipped}</td>
                <td>
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => navigate(JSON.stringify(job.id))}
                  >
                    {
                      translations.Menu.Tools.ImageUpload.Import.SeeLogs[
                        language
                      ]
                    }
                  </Button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
