import { useContext } from "react";
import { Card, Typography } from "@mui/material";
import Loading from "views/Loading";
import { context } from "providers/NewDefectsProvider";

type Props = {
  severity: "1" | "2" | "3" | "4";
  severityMap: {
    "1": string;
    "2": string;
    "3": string;
    "4": string;
  };
};

export default function SeverityCountCard({ severity, severityMap }: Props) {
  const { data, loading } = useContext(context);

  const count = computeCount(data, severity);
  const title = getTitle(severityMap, severity);

  return (
    <Card sx={{ p: 2, flex: 1, position: "relative" }}>
      <Typography
        sx={{
          color: "text.secondary",
          fontSize: "small",
          textAlign: "center",
          py: 0,
          my: 0,
        }}
      >
        {title}
      </Typography>

      {loading ? (
        <Loading relative size={30} color="black" />
      ) : (
        <Typography sx={{ fontSize: "x-large", textAlign: "center", mt: 1 }}>
          {count}
        </Typography>
      )}
    </Card>
  );
}

function computeCount(data, severity) {
  if (data.length === 0) return 0;

  const listOfSeverityCounts = data.map(
    (projectCount) => projectCount[severity]
  );
  return listOfSeverityCounts.reduce(
    (prevValue, currValue) => prevValue + currValue
  );
}

function getTitle(severityMap, severity) {
  return severityMap[severity];
}
