import { Translated } from "./utils";

export const Image = Translated({ EN: "Image", SE: "Bild", NO: "Bilde" });
export const LidarImage = Translated({
  EN: "Lidar Image",
  SE: "Lidar Bild",
  NO: "Lidar Bilde",
});
export const RGBImage = Translated({
  EN: "RGB Image",
  SE: "RGB Bild",
  NO: "RGB Bilde",
});
export const ThermalImage = Translated({
  EN: "Thermal Image",
  SE: "VärmeBild",
  NO: "Termisk Bilde",
});
export const NoList = Translated({
  EN: "No lists available",
  SE: "Inga listor tillgängliga",
  NO: "Ingen lister tilgjengelig",
});
export const PreviewImagePrevDefect = Translated({
  EN: "This is only a preview from last years inspection.",
  SE: "Detta är endast en förhandsgranskning från förra årets inspektion.",
  NO: "Dette er bare en forhåndsvisning fra fjorårets inspeksjon.",
});
