export const COLOR_OPTIONS = [
  "temperature",
  "hot",
  "inferno",
  "magma",
  "plasma",
  "warm",
  "electric",
  "jet",
  "hsv",
  "cool",
  "spring",
  "summer",
  "autumn",
  "winter",
  "bone",
  "copper",
  "greys",
  "YIGnBu",
  "greens",
  "YIOrRd",
  "bluered",
  "RdBu",
  "picnic",
  "rainbow",
  "portland",
  "blackbody",
  "earth",
  "viridis",
  "cool",
  "rainbow-soft",
  "bathymetry",
  "cdom",
  "chlorophyll",
  "density",
  "freesurface-blue",
  "freesurface-red",
  "oxygen",
  "par",
  "phase",
  "salinity",
  "turbidity",
  "velocity-blue",
  "velocity-green",
  "cubehelix",
];
