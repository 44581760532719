import React from "react";
import NormalController from "./NormalController";
import "./style.scss";

type Props = {
  width: number;
};

export default function ViewerController({ width }: Props) {
  return <NormalController width={width} />;
}
