import { Button, FormGroup, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { authorizedPost } from "utils/request";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";

export default function NewGroup() {
  const navigate = useNavigate();
  const [groupName, setGroupName] = useState<string>("");
  const [externalID, setExternalID] = useState<string>("");
  const { updateGroups } = useOutletContext<{
    updateGroups: Function;
  }>();

  function onCreateGroup() {
    authorizedPost<{ id: number; name: string; default_external_id?: string }>(
      "/group/new",
      {
        groupName,
        externalID,
      }
    )
      .then((group) => {
        updateGroups();
        navigate(`/group/${group.id}`);
      })
      .catch(() => {
        toast.error("Failed to create group");
      });
  }
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" component="h2">
        Create a new group
      </Typography>
      <Typography color="text.secondary">
        A group is defined as a collection of regions and projects. It can often
        be associated with a company.
      </Typography>
      <Typography color="text.secondary">
        Add the following details to create the group.
      </Typography>
      <FormGroup sx={{ maxWidth: 600, my: 2 }}>
        <TextField
          onChange={(e) => setGroupName(e.target.value)}
          value={groupName}
          variant="standard"
          label="Name of group"
          required
          sx={{ mb: 2 }}
        />
        <TextField
          variant="standard"
          label="Default External ID (optional)"
          onChange={(e) => setExternalID(e.target.value)}
          value={externalID}
        />
      </FormGroup>
      <Button
        variant="contained"
        color="success"
        onClick={onCreateGroup}
        disabled={groupName.length < 3}
      >
        Create group
      </Button>
    </Paper>
  );
}
