import { connect } from "react-redux";
import IssueCategories from "./IssueCategories";
import { RootState } from "state/store";

interface IStateProps {
  issueCategoryCount: Array<any>;
}

interface IDispatchProps {}

interface IOwnProps {
  hideTitle?;
  sx?;
}

const mapStateToProps = (state: RootState) => {
  return {
    issueCategoryCount: state.statistics.issueCategoryCount,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(IssueCategories);
