import {
  authorizedPost,
  authorizedPut,
  authorizedGet,
  authorizedDelete,
} from "utils/request";

export async function addClientIssueSeverities(
  missionGroup,
  severityId,
  clientTypeId
) {
  const response = await authorizedPost(
    `/object/types/client_issue_severity/add`,
    {
      skyqraft_issue_severity_id: severityId,
      client_issue_severity_type_id: clientTypeId,
      group_id: missionGroup,
    }
  );
}

export async function addClientObject(missionGroup, objectId, clientTypeId) {
  const response = await authorizedPost(`/object/types/client_object/add`, {
    skyqraft_object_id: objectId,
    client_object_type_id: clientTypeId,
    group_id: missionGroup,
  });
}

export async function editClientIssueSeverities(clientTypeId, severityMapId) {
  const response = await authorizedPut(
    `/object/types/client_issue_severity/edit`,
    {
      client_issue_severity_type_id: clientTypeId,
      severityMapId: severityMapId,
    }
  );
}

export async function editClientObject(clientTypeId, clientMapId) {
  const response = await authorizedPut(`/object/types/client_object/edit`, {
    client_object_type_id: clientTypeId,
    clientMapId: clientMapId,
  });
}

export async function deleteClientObjectTypeMap(clientMapId) {
  const response = await authorizedDelete(
    `/object/types/client_object_type/delete/${clientMapId}`
  );
}

export async function deleteClientIssueSeverityTypeMap(clientMapId) {
  const response = await authorizedDelete(
    `/object/types/client_issue_severity_type/delete/${clientMapId}`
  );
}

export async function checkClientObjectTypeMap(clientMapId) {
  return await authorizedGet(
    `/object/types/client_object_type/check/map/${clientMapId}`
  );
}

export async function checkClientIssueSeverityTypeMap(clientMapId) {
  return await authorizedGet(
    `/object/types/client_issue_severity_type/check/map/${clientMapId}`
  );
}

export async function getClientIssueSeverityTypes(missionGroup) {
  return await authorizedGet(
    `/object/types/client_issue_severity_types?group=${missionGroup}`
  );
}

export async function getClientObjectTypes(missionGroup) {
  return await authorizedGet(
    `/object/types/client_object_types?group=${missionGroup}`
  );
}

export async function getClientCategories(missionGroup) {
  return await authorizedGet(
    `/object/types/client_categories?group=${missionGroup}`
  );
}

export async function addClientIssueSeverityType(
  missionGroup,
  clientName,
  external_type_id
) {
  return await authorizedPost(`/object/types/client_issue_severity_type/add`, {
    client_name: clientName,
    group_id: missionGroup,
    external_type_id: external_type_id,
  });
}

export async function addClientObjectType(
  missionGroup,
  clientName,
  color,
  external_type_id,
  category
) {
  return await authorizedPost(`/object/types/client_object_type/add`, {
    client_name: clientName,
    group_id: missionGroup,
    color: color,
    external_type_id: external_type_id,
    category: category,
  });
}

export async function addClientCategory(
  missionGroup,
  categoryName,
  external_category_id
) {
  return await authorizedPost(`/object/types/client_category/add`, {
    category_name: categoryName,
    group_id: missionGroup,
    external_category_id: external_category_id,
  });
}
