import { useState, useEffect } from "react";
import groupAPI from "api/group";

type IAdminGroup = Awaited<ReturnType<typeof groupAPI.getAdminGroups>>[number];

/**
 * A React hook that collects groups as admin
 *
 *  @example
 *  ```javascript
 *  const {groups, loading} = useAdminGroups();
 *  ```
 */
export function useAdminGroups(): {
  groups: IAdminGroup[];
  update: () => Promise<void>;
  loading: boolean;
} {
  const [groups, setGroups] = useState<IAdminGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  async function collectGroups() {
    setLoading(true);
    try {
      const groups = await groupAPI.getAdminGroups();
      setLoading(false);
      setGroups(groups);
    } catch (err) {
      setLoading(false);
      setGroups([]);
    }
  }

  useEffect(() => {
    collectGroups();
  }, []);

  return {
    groups,
    update: collectGroups,
    loading,
  };
}
