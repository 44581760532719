import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getFilterArea(
  searchParams: URLSearchParams
): FilterType["AREA"] {
  return searchParams.get(FilterKey.AREA) || "";
}

export function useAreaFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const area: FilterType["AREA"] = getFilterArea(searchParams);

  const setArea = (value: string) => {
    if (!value) {
      if (searchParams.has(FilterKey.AREA)) searchParams.delete(FilterKey.AREA);
    } else {
      searchParams.set(FilterKey.AREA, value);
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { area, setArea };
}
