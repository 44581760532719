import { StoryblokComponent } from "@storyblok/react";
import React from "react";

type Props = {
  content: any;
};

export default function CMSBlok({ content }: Props) {
  const attributes = content.attrs;
  const body = attributes.body;
  return body.map((blok, i) => <StoryblokComponent key={i} blok={blok} />);
}
