import { Typography } from "@mui/material";
import { useLanguage } from "hooks";
import translations from "translations";

export default function PageTitle() {
  const { language } = useLanguage();
  return (
    <Typography>
      {
        translations.MissionLanding.OrderProgress.OrderMission.PowerlineUpload
          .Description[language]
      }
    </Typography>
  );
}
