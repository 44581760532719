import { Stack } from "@mui/material";
import AddDetectionButton from "../components/AddDetectionButton";
import AddDefectButton from "../components/AddDefectButton";
import AddSteelworkButton from "../components/AddSteelworkButton";
import SaveButton from "../components/SaveButton";
import ExitButton from "../components/ExitButton";
import ResetButton from "../components/ResetButton";

export default function NavBar() {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        zIndex: 10,
        width: "100%",
        height: "45px",
        boxShadow: "3px 3px 3px 0px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        marginLeft={"15px"}
      >
        <AddDetectionButton />
        <AddDefectButton />
        <AddSteelworkButton />
      </Stack>
      <Stack direction="row" spacing={2}>
        <ResetButton />
        <SaveButton />
        <SaveButton exitOnSave />
        <ExitButton />
      </Stack>
    </Stack>
  );
}
