import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getFeedBayFilter(
  searchParams: URLSearchParams
): FilterType["FEED_BAY"] {
  const feedBayParams = searchParams.get(FilterKey.FEED_BAY);
  return feedBayParams ? feedBayParams.split(",") : [];
}

export function useFeedBayFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const feedBayFilter = getFeedBayFilter(searchParams);

  const setFeedBayFilter = (listFilter: string[]) => {
    if (listFilter.length === 0) {
      if (searchParams.has(FilterKey.FEED_BAY))
        searchParams.delete(FilterKey.FEED_BAY);
    } else {
      searchParams.set(FilterKey.FEED_BAY, listFilter.join(","));
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { feedBayFilter, setFeedBayFilter };
}
