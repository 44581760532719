import React from "react";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";

function GpsPositionObject(_: any) {
  return (
    <div className="gpsPositionMarker">
      <GpsFixedIcon fontSize="small" sx={{ color: "#ffff", opacity: 0.7 }} />
    </div>
  );
}

export default function GpsPositionMarker({ lat, lng }) {
  return lat && lng && <GpsPositionObject lat={lat} lng={lng} />;
}
