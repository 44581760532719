import { ImageMeta } from "interfaces";
import { connect } from "react-redux";
import { RootState } from "state/store";
import AnnotationPreview from "./AnnotationPreview";

interface IStateProps {
  image: number | null;
  showPipes: boolean;
  isThermal: boolean;
  imageMeta: ImageMeta;
}

interface IDispatchProps {}

interface IOwnProps {
  widthScale: number;
  reviewMode: ReviewMode;
}

export enum ReviewMode {
  None = 0,
  MachineReview = 1,
  SupervisorReview = 2,
  FalsePositiveReview = 3,
  SuperFalsePositiveReview = 4,
}

const mapStateToProps = (state: RootState) => {
  return {
    image: state.image?.current?.id,
    isThermal: false,
    imageMeta: undefined,
    showPipes: false,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AnnotationPreview);
