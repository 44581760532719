import { IClearanceSystem } from "interfaces";

export interface IState {
  clearanceSystems: IClearanceSystem[];
  clearanceSystemsLoading: boolean;
  lidarMarkersActive: boolean;
  clickedLidarMarker: null | number;
}

export const initialState: IState = {
  clearanceSystems: [],
  clearanceSystemsLoading: false,
  lidarMarkersActive: false,
  clickedLidarMarker: null,
};

const lidarReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case "SET_CLEARANCE_SYSTEMS": {
      return {
        ...state,
        clearanceSystems: action.payload,
      };
    }
    case "SET_CLICKED_LIDAR_MARKER": {
      return {
        ...state,
        clickedLidarMarker: action.payload,
      };
    }
    case "SET_CLEARANCE_SYSTEMS_LOADING": {
      return {
        ...state,
        clearanceSystemsLoading: action.payload,
      };
    }
    case "SET_LIDAR_MARKERS_ACTIVE": {
      return {
        ...state,
        lidarMarkersActive: action.payload,
      };
    }

    default:
      return state;
  }
};

export default lidarReducer;
