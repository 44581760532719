import React, { ReactElement, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { authorizedPost } from "utils/request";
import translations from "translations";
import { useLanguage } from "hooks";

export default function ZoneUpload(): ReactElement {
  const { language } = useLanguage();
  const [KMLfile, setKMLFile] = useState(null);

  const validateSize = (file) => {
    let size = 30000;
    let err = "";
    if (file.size > size) {
      err = file.type + "is too large, please pick a smaller file\n";
      alert(err);
    }
    return true;
  };

  const onFileChange = (e) => {
    var file = e.target.files[0];
    validateSize(e) && setKMLFile(file);
  };

  const uploadKmlFile = (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("file", KMLfile);
    const header = { "content-type": "multipart/form-data" };
    toast.info("Uploading KML File");
    authorizedPost(`/zone/upload`, data, header)
      .then(() => toast.success("Upload succeded. Reload page to see changes."))
      .catch(() => toast.error("Upload failed"));
  };

  return (
    <div>
      <h2>{translations.Menu.Tools.UploadZones[language]}</h2>
      <p>{translations.Menu.Tools.UploadZones.Description[language]}</p>
      <p>
        <i>{translations.Menu.Tools.UploadZones.Support[language]}</i>
      </p>
      <form onSubmit={uploadKmlFile}>
        <br />
        <hr />
        <Button
          variant="contained"
          className="primaryButton"
          component="label"
          style={{ marginLeft: 5, textTransform: "none" }}
        >
          <input
            type="file"
            accept=".kml, .zip"
            hidden
            onChange={onFileChange}
          />
          {translations.Menu.Tools.UploadZones.ChooseFile[language]}
        </Button>
        <Button
          type="submit"
          variant="contained"
          className="secondaryButton"
          disabled={!KMLfile}
          style={{ marginLeft: 5, textTransform: "none" }}
        >
          {translations.Menu.Tools.UploadZones.UploadZoneKml[language]}
        </Button>
      </form>
    </div>
  );
}
