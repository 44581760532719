import { createContext, useState } from "react";

export interface IMenuProviderContext {
  filterTab: number;
  setFilterTab: (tab: number) => void;
  filterActive: boolean;
  setFilterActive: (active: boolean) => void;
  defects: boolean;
  setDefects: (defects: boolean) => void;
}

export const FilterContext = createContext<IMenuProviderContext>({
  filterActive: false,
  setFilterActive: () => {},
  filterTab: 0,
  setFilterTab: () => {},
  defects: true,
  setDefects: () => {},
});

export function FilterProvider({ children }) {
  const [filterActive, setFilterActive] = useState(false);
  const [filterTab, setFilterTab] = useState(0);
  const [defects, setDefects] = useState(true);

  return (
    <FilterContext.Provider
      value={{
        filterActive,
        setFilterActive,
        defects,
        setDefects,
        filterTab,
        setFilterTab,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}
