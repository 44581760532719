import { connect } from "react-redux";
import DateBeforeProjectName from "./DateBeforeProjectName";
import { setShowDatesBeforeProjectName } from "state/actions";
import { RootState } from "state/store";

interface IStateProps {
  showDatesBeforeProjectName: boolean;
}

interface IDispatchProps {
  setShowDatesBeforeProjectName: typeof setShowDatesBeforeProjectName;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    showDatesBeforeProjectName: state.ui.showDatesBeforeProjectName,
  };
};

const mapDispatchToProps = {
  setShowDatesBeforeProjectName,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(DateBeforeProjectName);
