import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { ObjectType } from "interfaces";

import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

type Props = {
  newObjectCat: any;
  objectTypes: ObjectType[];
  updateType: Function;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* @ts-ignore */}
      <GridToolbarColumnsButton />
      {/* @ts-ignore */}
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

export default function Detections({
  objectTypes,
  newObjectCat,
  updateType,
}: Props) {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => updateType(params.value)}>
            <Edit />
          </IconButton>
          {params.value}
        </>
      ),
    },
    {
      field: "en_name",
      headerName: "English",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "se_name",
      headerName: "Swedish",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      description: "This column has a value getter and is not sortable.",
      width: 160,
      valueGetter: (e) => {
        let filtered = newObjectCat.filter((val) => val.id === e.row.category);
        return filtered[0].en_name;
      },
    },
    {
      field: "issue",
      headerName: "Defect",
      width: 160,
      flex: 1,
    },
    {
      field: "color",
      headerName: "Color",
      renderCell: (params) => (
        <div
          style={{
            width: 20,
            height: 20,
            backgroundColor: params.value,
            borderRadius: 5,
          }}
        />
      ),
      width: 100,
    },
  ];
  return (
    <Box sx={{ with: "100%", height: "100%" }}>
      <DataGrid
        rows={objectTypes}
        columns={columns}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        disableColumnMenu={true}
        sx={{ backgroundColor: "white", with: "100%", height: "100%" }}
      />
    </Box>
  );
}
