import { axiosInstance } from "utils/request";
import { FilterKey } from "hooks/filter/search_parameters/constants";
import { reviewModes } from "constants/imageReview";

interface IGetImageIds {
  projectId: number;
}
export async function getImageIds(args: IGetImageIds) {
  const { projectId } = args;
  const baseQuery = new URLSearchParams(window.location.search);
  const params: Record<string, string> = {};

  const queryToParamsMap = {
    [FilterKey.DETECTION]: "detection",
    [FilterKey.FLAGGED]: "flagged",
    [FilterKey.LIST]: "list",
    [FilterKey.ACTORS]: "actors",
    [FilterKey.SEVERITY]: "severity",
    [FilterKey.FEED_BAY]: "feed_bay",
    [FilterKey.AREA]: "area",
  };

  for (const [queryKey, paramKey] of Object.entries(queryToParamsMap)) {
    if (baseQuery.has(queryKey)) {
      params[paramKey] = baseQuery.get(queryKey);
    }
  }

  const { data } = await axiosInstance.get<number[]>(
    `/review/${reviewModes.FALSE_POSITIVE_BBS}/project/${projectId}/images`,
    {
      params,
    }
  );

  return data;
}
