import { connect } from "react-redux";
import AnnotationTool from "./Annotator";
import { RootState } from "state/store";
import { setCanSwitchImage } from "state/actions";

interface IStateProps {}
interface IDispatchProps {
  setCanSwitchImage: (value: boolean) => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = {
  setCanSwitchImage: setCanSwitchImage,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AnnotationTool);
