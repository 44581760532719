import { connect } from "react-redux";
import Component from "./MissionItem";
import { RootState } from "state/store";
import { changeMission, setMissionAttribute } from "state/actions";
import { Mission, MissionAttributes } from "interfaces";

interface IStateProps {
  demoMode: boolean;
  selectedCustomer: number;
  showDatesBeforeProjectName: boolean;
}

interface IOwnProps {
  mission: Mission;
}

interface IDispatchProps {
  setMissionAttribute: (
    missionID: number,
    attributes: MissionAttributes
  ) => void;
  changeMission: (number, boolean) => void;
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedCustomer: state.mission?.id ?? -1,
    demoMode: state.ui.demoMode,
    showDatesBeforeProjectName: state.ui.showDatesBeforeProjectName,
  };
};

const mapDispatchToProps = {
  changeMission,
  setMissionAttribute,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
