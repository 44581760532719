import React, { ReactElement } from "react";
import { authorizedGet } from "utils/request";
import { toast } from "react-toastify";
import translations from "translations";
import { Button } from "@mui/material";
import { useLanguage } from "hooks";

export default function DividePowerlines(): ReactElement {
  const { language } = useLanguage();
  const sendRequest = () => {
    toast.info(translations.Menu.Tools.Powerlines.Divide.Dividing[language]);
    authorizedGet("/powerline/divide").then(() => {
      toast.success(
        translations.Menu.Tools.Powerlines.Divide.DividingComplete[language]
      );
    });
  };

  return (
    <div>
      <h2>{translations.Menu.Tools.Powerlines.Divide.Title[language]}</h2>
      <p>{translations.Menu.Tools.Powerlines.Divide.Description[language]}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={sendRequest}
          variant="contained"
          className="primaryButton"
          fullWidth={true}
        >
          {translations.Menu.Tools.Powerlines.Divide.Button[language]}
        </Button>
      </div>
    </div>
  );
}
