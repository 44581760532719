import React, { useReducer, useEffect } from "react";
import MainLayout from "../MainLayout";
import SettingsProvider from "../SettingsProvider";
import combineReducers from "./reducers/combine-reducers.js";
import generalReducer from "./reducers/general-reducer.js";
import imageReducer from "./reducers/image-reducer.js";
import historyHandler from "./reducers/history-handler.js";
import { compareRegions } from "../Utils";
import { useEventCallback } from "utils";
import makeImmutable from "seamless-immutable";
import translations from "translations";
import store from "state/store";
import { sendFeedback } from "state/actions";

export var Annotator = function Annotator({
  image,
  customerId,
  annotations,
  onExit,
  issueCategories,
  detectedCategories,
  objectTypes,
  issueSeverities,
  user,
  showHumanMachine,
  setHumanMachine,
  zoomSpeed,
  setCanSwitchImage,
  annotatorObjectColor,
}) {
  const initialState = makeImmutable({
    selectedTool: "select",
    allObjectHuman: false,
    enabledTools: ["select", "create-box"],
    lastSavedRegions: annotations,
    regions: annotations,
    history: [],
    lastObjectType: null,
    user,
    issueCategories,
  });

  let [state, dispatchToReducer] = useReducer(
    historyHandler(combineReducers(imageReducer, generalReducer)),
    initialState
  );

  useEffect(() => {
    dispatchToReducer({
      type: "OVERWRITE_REGIONS",
      regions: annotations,
    });
  }, [image, annotations]);

  useEffect(() => {
    dispatchToReducer({
      type: "SET_ISSUE_CATEGORIES",
      issueCategories: issueCategories,
    });
  }, [issueCategories]);

  useEffect(() => {
    dispatchToReducer({
      type: "SET_OBJECT_TYPES",
      objectTypes: objectTypes,
    });
  }, [objectTypes]);

  useEffect(() => {
    dispatchToReducer({
      type: "UPDATE_USER",
      user,
    });
  }, [user]);

  var dispatch = useEventCallback(function (action) {
    if (action.type === "HEADER_BUTTON_CLICKED") {
      const somethingChanged = !compareRegions(state, setCanSwitchImage);
      const outerState = store.getState();
      if (["Done", "Save", "Complete"].includes(action.buttonName)) {
        //store.dispatch({type: "SET_CAN_SWITCH_IMAGE",payload: true,});
        const missions = outerState.user.missions;
        const customerId = parseInt(outerState.mission.id);
        const currentMission = missions.find((m) => m.id === customerId);
        let canExit = true;

        if (canExit) {
          dispatchToReducer({ type: "SAVE_REGIONS" });
          return onExit(state.regions, true, false);
        }
      } else if (["Exit", "Close"].includes(action.buttonName)) {
        if (somethingChanged) {
          if (
            window.confirm(
              translations.AnnotationTool.SureYouWantToLeave[
                outerState.user.language
              ]
            )
          ) {
            dispatchToReducer({ type: "RESET_REGIONS" });
            return onExit(state.lastSavedRegions, false, true);
          }
        } else {
          return onExit(state.lastSavedRegions, false, true);
        }
      } else if (action.buttonName === "Reset") {
        return dispatchToReducer({ type: "RESET_REGIONS" });
      }
    }

    if (action.type === "SELECT_REGION") {
      dispatchToReducer({
        type: "CHANGE_REGION",
        region: { ...action.region },
      });
    }

    dispatchToReducer(action);
  });

  return (
    <SettingsProvider>
      <MainLayout
        state={state}
        dispatch={dispatch}
        annotations={annotations}
        user={user}
        image={image}
        customerId={customerId}
        issueCategories={issueCategories}
        detectedCategories={detectedCategories}
        objectTypes={objectTypes}
        issueSeverities={issueSeverities}
        showHumanMachine={showHumanMachine}
        setHumanMachine={setHumanMachine}
        zoomSpeed={zoomSpeed}
        annotatorObjectColor={annotatorObjectColor}
      />
    </SettingsProvider>
  );
};
export default Annotator;
