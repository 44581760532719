import { connect } from "react-redux";
import ClusterSwitch from "./ClusterSwitch";
import { setClusterVisible } from "state/actions";
import { RootState } from "state/store";

interface IStateProps {
  clusterVisible: boolean;
}

interface IDispatchProps {
  setClusterVisible: typeof setClusterVisible;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    clusterVisible: state.ui.clusterVisible,
  };
};

const mapDispatchToProps = {
  setClusterVisible,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ClusterSwitch);
