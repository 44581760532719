import { Checkbox, FormControlLabel } from "@mui/material";
import { useDefectNotReportedFilter, useLanguage } from "hooks";
import translations from "translations";

export default function DefectNotReportedFilter() {
  const { defectNotReported, setDefectNotReported } =
    useDefectNotReportedFilter();
  const { language } = useLanguage();
  return (
    <FormControlLabel
      checked={defectNotReported}
      sx={{
        "&:hover": {
          backgroundColor: "#EDEDED",
        },
        p: 0,
        m: 0,
        py: 1,
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        setDefectNotReported(!e.target.checked)
      }
      control={<Checkbox />}
      label={translations.Filter.DefectNotReported[language]}
    />
  );
}
