import { Divider } from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import ClientNameSwitch from "components/Settings/ClientNameSwitch";
import ClusterSwitch from "components/Settings/ClusterSwitch";
import LanguageSelector from "components/Settings/LanguageSelector";
import MapType from "components/Settings/MapType";
import ProximitySwitch from "components/Settings/ProximitySwitch";
import ThumbnailSorting from "components/Settings/ThumbnailSorting";
import { ReactElement } from "react";
import AutoSpeedZoomInReviewModes from "./AutoSpeedZoomInReviewModes";
import ClusterDistance from "./ClusterDistance";
import DateBeforeProjectName from "./DateBeforeProjectName";
import RoleOverride from "./RoleOverride";
import ShowDsoTso from "./ShowDsoTso";
import SkyqraftHiddenSettings from "./SkyqraftHiddenSettings";
import ZoomSpeed from "./ZoomSpeed";
import "./style.scss";

export default function SettingsList(): ReactElement {
  return (
    <div id="settingsList">
      <LanguageSelector />
      <ClientNameSwitch />
      <MapType />
      <ZoomSpeed />
      <DateBeforeProjectName />
      <RoleWrapper keyName="clusterSwitch">
        <ClusterSwitch />
        <ThumbnailSorting />
      </RoleWrapper>
      <RoleWrapper keyName="advancedSettings">
        <ProximitySwitch />
      </RoleWrapper>
      <RoleWrapper keyName="advancedSettings">
        <ClusterDistance />
      </RoleWrapper>
      <RoleWrapper keyName="roleOverride" noOverride>
        <RoleOverride />
      </RoleWrapper>
      <RoleWrapper keyName="advancedFilter">
        <Divider sx={{ mx: 2, mt: 1 }}>Arkion Hidden on Images</Divider>
        <SkyqraftHiddenSettings />
      </RoleWrapper>
      <RoleWrapper keyName="changeReviewModeSettings">
        <AutoSpeedZoomInReviewModes />
      </RoleWrapper>
      <RoleWrapper keyName="internalSettings">
        <ShowDsoTso />
      </RoleWrapper>
    </div>
  );
}
