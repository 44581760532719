import { connect } from "react-redux";
import { RootState } from "state/store";

import Component from "./ReflightItem";

interface IStateProps {
  gmap: google.maps.Map;
}

interface IDispatchProps {}

interface IOwnProps {
  selection: google.maps.Rectangle;
}

const mapStateToProps = (state: RootState) => {
  return {
    gmap: state.map.gmap,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
