import { useState, ReactNode, useEffect } from "react";
import { IContextValue, context } from "./context";
import {
  IPoleComponent,
  IPoleTemplate,
  getAvailablePoleComponents,
  getPoleTemplates,
  addPoleComponent,
  deletePoleComponent,
  updatePoleComponent,
  addPoleComponentPlacement,
  updatePoleComponentPlacement,
  deletePoleComponentPlacement,
  addPoleTemplate,
  deletePoleTemplate,
  updatePoleTemplate,
  addPoleTemplateItem,
  updatePoleTemplateItem,
  deletePoleTemplateItem,
  getPoleStatus as getPoleStatusAPI,
  IPoleStatus,
  addPoleStatus as addPoleStatusAPI,
  deletePoleStatus as deletePoleStatusAPI,
  updatePoleStatusValue as updatePoleStatusValueAPI,
  getPoleValues as getPoleValuesAPI,
  IPoleSystem,
} from "views/PoleViewer/api";
import { useCurrentProject } from "hooks";

interface IProviderProps {
  children: ReactNode;
  poleID: number;
}

function SummaryProvider({ children, poleID }: IProviderProps) {
  const currentProject = useCurrentProject();
  const [components, setComponents] = useState<IPoleStatus[]>([]);
  const [loading, setLoading] = useState(false);
  const [poleValueSystems, setPoleValueSystems] = useState<IPoleSystem[]>([]);
  const [availableComponents, setAvailableComponents] = useState<
    IPoleComponent[]
  >([]);
  const [templates, setTemplates] = useState<IPoleTemplate[]>([]);

  useEffect(() => {
    updateComponents();
    getPoleValues();
    updateAvailableComponents();
    updateTemplates();
  }, [poleID, currentProject?.id]);

  function updateComponents() {
    setLoading(true);
    getPoleStatusAPI(poleID)
      .then(setComponents)
      .finally(() => {
        setLoading(false);
      });
  }
  async function updateAvailableComponents() {
    const response = await getAvailablePoleComponents(
      currentProject?.group?.id
    );
    setAvailableComponents(response);
    return response;
  }
  function updateTemplates() {
    getPoleTemplates(currentProject?.group?.id).then(setTemplates);
  }
  function addComponentToPole(
    componentID: number,
    available: IPoleComponent[] | null = null
  ) {
    // TODO: Add component to pole
    const existingComponents = components.map((c) => c.id);
    if (existingComponents.includes(componentID)) {
      return;
    }
    let aComponents = available;
    if (aComponents === null) {
      aComponents = availableComponents;
    }
    const aComponent = aComponents.find((c) => c.id === componentID);
    const emptyComponent = {
      ...aComponent,
      placements: [],
    };

    setComponents([...components, emptyComponent]);
  }
  async function createComponent(
    name: string,
    gradingSystem: number
  ): Promise<{ newID: number; availableComponents: IPoleComponent[] }> {
    // Create a component in the database
    // and then update the available components
    const response = await addPoleComponent(
      name,
      gradingSystem,
      currentProject?.group?.id
    );
    const availableComponents = await updateAvailableComponents();
    return {
      newID: response,
      availableComponents,
    };
  }
  function deleteComponent(componentID: number) {
    // Delete the pole componen in the database
    deletePoleComponent(componentID).then(() => {
      updateAvailableComponents();
      updateComponents();
    });
  }
  function updateComponent(componentID: number, name?: string) {
    // Update the pole component in the database
    // and then update the available components
    updatePoleComponent(componentID, name, currentProject?.group?.id).then(
      () => {
        updateAvailableComponents();
        updateComponents();
      }
    );
  }
  function addItemToPole(componentID: number, name: string) {
    addPoleComponentPlacement(
      componentID,
      name,
      null,
      currentProject?.group?.id
    ).then(() => {
      updateAvailableComponents();
      updateComponents();
    });
  }

  async function createComponentPlacement(
    componentID: number,
    name: string,
    imageRegexPattern: string | null = null
  ): Promise<{ newID: number; availableComponents: IPoleComponent[] }> {
    // Create a component in the database
    // and then update the available components
    const response = await addPoleComponentPlacement(
      componentID,
      name,
      imageRegexPattern,
      currentProject?.group?.id
    );
    const availableComponents = await updateAvailableComponents();
    return {
      newID: response,
      availableComponents,
    };
  }
  function updateComponentPlacement(
    componentID: number,
    placementID: number,
    name: string | null = null,
    regex_pattern: string | null = null
  ) {
    updatePoleComponentPlacement(
      componentID,
      placementID,
      name,
      regex_pattern,
      currentProject?.group?.id
    ).then(() => {
      updateAvailableComponents();
      updateComponents();
    });
  }
  function deleteComponentPlacement(componentID: number, placementID: number) {
    deletePoleComponentPlacement(componentID, placementID).then(() => {
      updateAvailableComponents();
      updateComponents();
    });
  }
  function createTemplate(name: string) {
    // Create a template in the database
    // and then update the templates
    addPoleTemplate(name, currentProject?.group?.id).then(() => {
      updateTemplates();
    });
  }
  function deleteTemplate(templateID: number) {
    // Delete the pole template in the database
    deletePoleTemplate(templateID).then(() => {
      updateTemplates();
    });
  }
  function updateTemplate(templateID: number, name: string | null) {
    updatePoleTemplate(templateID, name, currentProject?.group?.id).then(() => {
      updateTemplates();
    });
  }
  function addItemToTemplate(
    templateID: number,
    componentID: number,
    placementID: number
  ) {
    addPoleTemplateItem(templateID, componentID, placementID).then(() => {
      updateTemplates();
    });
  }
  function updateTemplateItem(
    templateID: number,
    templateItemID: number,
    componentID: number | null = null,
    placementID: number | null = null
  ) {
    updatePoleTemplateItem(
      templateID,
      templateItemID,
      componentID,
      placementID
    ).then(() => {
      updateTemplates();
    });
  }
  function deleteTemplateItem(templateID: number, templateItemID: number) {
    deletePoleTemplateItem(templateID, templateItemID).then(() => {
      updateTemplates();
    });
  }

  function getPoleStatus(poleID: number) {
    getPoleStatusAPI(poleID).then((poleStatus) => {
      setComponents(poleStatus);
    });
  }
  function addPoleStatus(
    poleID: number,
    poleComponentID: number,
    poleComponentPlacementID: number,
    valueID: number
  ) {
    addPoleStatusAPI(
      poleID,
      poleComponentID,
      poleComponentPlacementID,
      valueID
    ).then(() => {
      getPoleStatus(poleID);
    });
  }
  function deletePoleStatus(
    poleID: number,
    componentID: number,
    placementID: number
  ) {
    deletePoleStatusAPI(poleID, componentID, placementID).then(() => {
      getPoleStatus(poleID);
    });
  }
  function updatePoleStatusValue(
    poleID: number,
    componentID: number,
    placementID: number,
    valueID: number,
    imageID: number | null = null
  ) {
    updatePoleStatusValueAPI(
      poleID,
      componentID,
      placementID,
      valueID,
      imageID
    ).then(() => {
      getPoleStatus(poleID);
    });
  }
  function getPoleValues() {
    getPoleValuesAPI(currentProject?.group?.id).then((poleSystems) => {
      setPoleValueSystems(poleSystems);
    });
  }

  const payload: IContextValue = {
    components,
    availableComponents,
    poleValueSystems,
    templates,
    loading,
    updateComponents,
    updateAvailableComponents,
    createComponentPlacement,
    updateTemplates,
    addComponentToPole,
    createComponent,
    deleteComponent,
    updateComponent,
    addItemToPole,
    updateComponentPlacement,
    deleteComponentPlacement,
    createTemplate,
    deleteTemplate,
    updateTemplate,
    addItemToTemplate,
    updateTemplateItem,
    deleteTemplateItem,
    getPoleStatus,
    addPoleStatus,
    deletePoleStatus,
    updatePoleStatusValue,
    getPoleValues,
  };

  return <context.Provider value={payload}>{children}</context.Provider>;
}

export default SummaryProvider;
