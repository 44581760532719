import React from "react";
import { storyblokEditable } from "@storyblok/react";
import CMSRichTextContent from "./CMSRichTextContent";

type Props = {
  blok: any;
};

export default function CMSPage({ blok }: Props) {
  return (
    <div
      style={{
        maxWidth: 1000,
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {blok.content.content.map((item, index) => (
        <CMSRichTextContent key={index} content={item} />
      ))}
    </div>
  );
}
