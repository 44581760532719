import { Dialog, Divider } from "@mui/material";
import { useState } from "react";
import TopBar from "./TopBar";
import ImageContainer from "./ImageContainer";
import { usePoleImages } from "../hooks";
import Image from "./Image";
import { useParams } from "react-router-dom";

type IImageWithMeta = ReturnType<typeof usePoleImages>["images"][number];

type Props = {
  title: string;
  onClose: () => void;
  images: IImageWithMeta[];
  filterImageIDs: number[];
  addImage: (imageID: number) => void;
  removeImage: (imageID: number) => void;
};

export default function ImageDialog({
  title,
  onClose,
  images,
  filterImageIDs,
  addImage,
  removeImage,
}: Props) {
  const [filterActive, setFilterActive] = useState(false);
  const [filterValue, setFilterValue] = useState(false);
  const { image } = useParams();
  const imageId = Number.parseInt(image);

  const filteredImages = !filterActive
    ? images
    : images.filter((image) => {
        if (filterValue) {
          return filterImageIDs.includes(image.id);
        }
        return !filterImageIDs.includes(image.id);
      });

  return (
    <Dialog
      data-testid="image-connector-dialog"
      open={true}
      onClose={onClose}
      maxWidth="xl"
    >
      <TopBar title={title} onClose={onClose} />
      <Divider />
      <ImageContainer
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
      >
        {filteredImages.map((image) => (
          <Image
            image={image}
            currentImage={imageId === image.id}
            connected={filterImageIDs.includes(image.id)}
            onDisconnect={() => {
              removeImage(image.id);
            }}
            onConnect={() => {
              addImage(image.id);
            }}
          />
        ))}
      </ImageContainer>
    </Dialog>
  );
}
