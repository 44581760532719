import { Accordion } from "components/Accordion";
import MissionList from "../MissionList";
import { Mission } from "interfaces";
import translations from "translations";
import { useLanguage, useSelector } from "hooks";

type Props = {
  open?: boolean;
};

export default function DoneMissions({ open }: Props) {
  const missions = useSelector((state) => state.user.missions);
  const demoMode = useSelector((state) => state.ui.demoMode);
  const { language } = useLanguage();
  const allMissions = {};

  function checkMission(mission: Mission) {
    return (
      !(mission.id in allMissions) &&
      !mission.deleted &&
      mission?.status.name.toLowerCase() === "done" &&
      mission.name !== null
    );
  }

  const selectedMissions = missions.filter(checkMission);

  return (
    <Accordion
      open={open}
      title={translations.Landing.DoneMissions[language]}
      description={translations.Landing.DoneMissions.Description[language]}
      value={selectedMissions.length}
    >
      <MissionList missions={selectedMissions} demoMode={demoMode} />
    </Accordion>
  );
}
