import { connect } from "react-redux";
import Component from "./FolderUploadView";
import { RootState } from "state/store";
import { startBatchJob } from "state/actions";

interface IStateProps {}

interface IDispatchProps {
  startBatchJob: (
    rerun?: boolean,
    queue_enabled?: boolean,
    overwrite_db?: boolean
  ) => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = {
  startBatchJob,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
