import { useState, useEffect } from "react";
import { authorizedGet } from "utils/request";

interface Feedback {
  comment: string | null;
  created: string;
  created_by: number;
  feedback_type: string;
  id: number;
  image_id: number;
  resolved: string | null;
  resolved_by: string | null;
  customer_id: number;
}

interface IProps {
  mission?: number;
}

/**
 * A React hook that collects the imageFeedbacks
 */
export function useImageFeedbacks({
  mission,
}: IProps = {}): [Feedback[], boolean] {
  const [imageFeedbacks, setImageFeedbacks] = useState<Feedback[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Find the endpoint based on the input
    const endpoint = "/image/feedback";

    // Send a request
    setLoading(true);
    authorizedGet<{ images: Feedback[] }>(endpoint)
      .then((response) => {
        setLoading(false);
        setImageFeedbacks(
          mission
            ? response.images.filter(
                (f) => f.customer_id === mission && !f.resolved
              )
            : response.images.filter((f) => !f.resolved)
        );
      })
      .catch((err) => {
        setLoading(false);
        setImageFeedbacks([]);
        console.error("Failed to collect image feedbacks", err);
      });
  }, [mission]);

  return [imageFeedbacks, loading];
}
