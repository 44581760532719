import { AccordionDetails, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AssignmentList from "../AssignmentList";
import { useAssignmentStatus, useLanguage } from "hooks";
import DemoSafeName from "components/DemoSafeName";
import translations from "translations";
import { getProjectName } from "utils/utils";

type Props = {
  setSupervisorMode: (value: boolean) => void;
  setInspectorMode: (value: boolean) => void;
  changeAnnotatorColor: (value: boolean) => void;
  toggleDemoMode: (value: boolean) => void;
};

export default function AssignmentContent({
  setSupervisorMode,
  setInspectorMode,
  changeAnnotatorColor,
  toggleDemoMode,
}: Props) {
  const { language } = useLanguage();

  const [userAssignmentStatus, userLoading] = useAssignmentStatus({
    supervisor: true,
    grouping: "user",
  });
  const [missionAssignmentStatus, missionLoading] = useAssignmentStatus({
    supervisor: true,
    grouping: "mission",
  });
  const navigate = useNavigate();

  const activeMissionAssignments = missionAssignmentStatus.filter(
    (s) => s.mission.active
  );
  const inactiveMissionAssignments = missionAssignmentStatus.filter(
    (s) => !s.mission.active
  );
  return (
    <AccordionDetails>
      <AssignmentList
        loading={userLoading}
        title={translations.words.Users[language]}
        description={
          translations.Landing.Assignments.SeeUsersAssignment[language]
        }
        entity={translations.words.Users[language]}
        assignments={userAssignmentStatus.map((assignment) => ({
          ...assignment,
          name: assignment.user.name,
        }))}
      />
      <AssignmentList
        loading={missionLoading}
        title={translations.Landing.Assignments.ActiveMissions[language]}
        entity={translations.words.Mission[language]}
        description={
          translations.Landing.Assignments.ActiveMissions.Description[language]
        }
        assignments={activeMissionAssignments.map((assignment) => {
          const getProjectNameCurrentResult = getProjectName(
            assignment.mission.id
          );
          return {
            ...assignment,
            name: (
              <Box
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`${assignment.id}`);
                  setInspectorMode(false);
                  changeAnnotatorColor(false);
                  toggleDemoMode(false);
                  setSupervisorMode(true);
                }}
              >
                <DemoSafeName entityName={translations.words.Mission[language]}>
                  {getProjectNameCurrentResult.fullname}
                </DemoSafeName>
              </Box>
            ),
          };
        })}
      />
      <AssignmentList
        loading={missionLoading}
        title={translations.Landing.Assignments.InactiveMissions[language]}
        entity={translations.words.Mission[language]}
        description={
          translations.Landing.Assignments.InactiveMissions.Description[
            language
          ]
        }
        assignments={inactiveMissionAssignments.map((assignment) => {
          const getProjectNameCurrentResult = getProjectName(
            assignment.mission.id
          );
          return {
            ...assignment,
            name: (
              <Box
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`${assignment.id}`);
                  setInspectorMode(false);
                  changeAnnotatorColor(false);
                  toggleDemoMode(false);
                  setSupervisorMode(true);
                }}
              >
                <DemoSafeName entityName={translations.words.Mission[language]}>
                  {getProjectNameCurrentResult.fullname}
                </DemoSafeName>
              </Box>
            ),
          };
        })}
      />
    </AccordionDetails>
  );
}
