import { connect } from "react-redux";
import { findCurrentMission } from "utils/utils";
import Component from "./DefectReport";
import { RootState } from "state/store";

interface IStateProps {
  issueCategories;
  issueSeverities;
  PropLists: any[];
  demoMode: boolean;
  customerId: number;
  fixableDefects: boolean;
  skyqraftEmployee: boolean;
  projectName: string;
}

interface IDispatchProps {}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  const currentCustomer = state.mission?.id ?? -1;
  const currentMission = findCurrentMission(
    currentCustomer,
    state.user.missions
  );
  return {
    issueCategories: state.objects.issueCategories.filter(
      (cat) => cat.id !== null
    ),
    issueSeverities: state.objects.issueSeverities.filter((sev) =>
      [0, 1, 2, 3, 4, 11].includes(sev.id)
    ),
    PropLists: state.image.lists,
    demoMode: state.ui.demoMode,
    customerId: currentCustomer,
    fixableDefects: currentMission?.fixable_defects,
    visible: state.ui.showIssueReport,
    skyqraftEmployee: state.user.skyqraft_employee,
    projectName: currentMission?.name ?? "Project",
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
