export interface IState {
  uploadStatus: string;
  objectFile: any;
  uploadUsers: Array<any>;
  defaultUser: number;
}

export const initialState: IState = {
  uploadStatus: "",
  objectFile: null,
  uploadUsers: [],
  defaultUser: null,
};

const imageObjectUploadReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case "SET_UPLOAD_STATUS":
      return {
        ...state,
        uploadStatus: action.payload,
      };

    case "SET_OBJECT_FILE":
      return {
        ...state,
        objectFile: action.payload,
      };

    case "SET_UPLOAD_USERS":
      return {
        ...state,
        uploadUsers: action.payload,
      };

    case "SET_DEFAULT_USER":
      return {
        ...state,
        defaultUser: action.payload,
      };

    default:
      return state;
  }
};

export default imageObjectUploadReducer;
