import { authorizedGet } from "../../utils/request";

export const updateDefectMetrics = () => {
  return (dispatch) => {
    authorizedGet("/metrics/defect_metrics")
      .then((response) => {
        return dispatch({
          type: "UPDATE_DEFECT_METRICS",
          payload: response,
        });
      })
      .catch((e) => console.log("error", e));
  };
};
