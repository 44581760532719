import { axiosInstance } from "utils/request";

interface IDetectionCount {
  count: {
    id: number;
    count: number;
  }[];
}

export async function getDetectionCount(
  projectID: number,
  flightIDs: number[] = [],
  workflow_statuses: number[] = [],
	area: string = ""
) {
  const headers: Record<string, string> = {
    MissionID: `${projectID}`,
  };

  // Populate the search parameters in a clean way
  const params = new URLSearchParams();
  if (flightIDs.length > 0) {
    params.append("flight_ids", flightIDs.join(","));
  }

  if (workflow_statuses.length > 0) {
    params.append("workflow_statuses", workflow_statuses.join(","));
  }
	if(area.length > 0) {
		params.append("area", area);
	}

  // Make the request
  const response = await axiosInstance.get<IDetectionCount>(
    "/object/filter/count/inventory",
    {
      headers,
      params,
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch data");
  }
  const count = response.data.count;
  return count;
}
