import { FilterCountContext } from "providers/FilterCountProvider";
import { useContext } from "react";

export function useSavedFilters() {
  const {
    savedFilters,
    savedFiltersLoading,
    savedFiltersTabActive,
    setSavedFiltersTabActive,
    updateSavedFilters,
  } = useContext(FilterCountContext);

  return {
    savedFilters,
    loading: savedFiltersLoading,
    update: updateSavedFilters,
    tabActive: savedFiltersTabActive,
    setTabActive: setSavedFiltersTabActive,
  };
}
