import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useContext, useState } from "react";
import { TypeSelectFilter } from "components/Filter/FilterComponents";
import { context as FilterContext } from "providers/FilterProvider";
import { Link } from "react-router-dom";
import { useLanguage, useSidebar } from "hooks";
import translations from "translations";

function Explanation() {
  const [open, setOpen] = useState(false);
  const { closeSidebar } = useSidebar();
  const { language } = useLanguage();
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{ mx: 2, mt: 2 }}
      >
        {translations.Filter.WhatIsDefect[language]}
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {translations.Filter.WhatIsDefect.Title[language]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {translations.Filter.WhatIsDefect.Section1[language]}
          </DialogContentText>
          <DialogContentText sx={{ mb: 2 }}>
            {translations.Filter.WhatIsDefect.Section2[language]}
          </DialogContentText>
          <DialogContentText>
            {translations.Filter.WhatIsDefect.Section3[language]}{" "}
            <Link
              onClick={() => {
                closeSidebar();
                setOpen(false);
              }}
              to="/client/settings"
            >
              {translations.Filter.WhatIsDefect.ClientSettings[language]}
            </Link>
            ,
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            {translations.Filter.Close[language]}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function DefectSelector() {
  const { defects, setDefects } = useContext(FilterContext);
  const { language } = useLanguage();
  return (
    <Stack direction="row" pt={1} spacing={1} mx={2} mb={2}>
      <Button
        variant={defects ? "contained" : "outlined"}
        sx={{ flex: 1 }}
        onClick={() => setDefects(true)}
      >
        {translations.Filter.Defects[language]}
      </Button>
      <Button
        variant={defects ? "outlined" : "contained"}
        sx={{ flex: 1 }}
        onClick={() => setDefects(false)}
      >
        {translations.Filter.Objects[language]}
      </Button>
    </Stack>
  );
}

export default function ElectricFilters() {
  const { defects } = useContext(FilterContext);
  return (
    <Stack pb={2}>
      <DefectSelector />
      <TypeSelectFilter issue={defects} vegetation={false} />
      <Explanation />
    </Stack>
  );
}
