import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getDefectNotReportedFilter(
  searchParams: URLSearchParams
): FilterType["DEFECT_NOT_REPORTED"] {
  const defectNotReportedParams =
    searchParams.get(FilterKey.DEFECT_NOT_REPORTED) ?? "";
  return defectNotReportedParams === "true";
}

export function useDefectNotReportedFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const defectNotReported = getDefectNotReportedFilter(searchParams);

  const setDefectNotReported = (value: boolean) => {
    if (value) {
      if (searchParams.has(FilterKey.DEFECT_NOT_REPORTED))
        searchParams.delete(FilterKey.DEFECT_NOT_REPORTED);
    } else {
      searchParams.set(FilterKey.DEFECT_NOT_REPORTED, "true");
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { defectNotReported, setDefectNotReported };
}
