import { useGesture } from "@use-gesture/react";
import { MutableRefObject, useContext, useRef } from "react";
import { compose, scale, translate } from "transformation-matrix";
import { AnnotationContext, CanvasContext } from "../provider";
import { IContextValue } from "../provider/CanvasProvider";
import { convertPositionToPercentage } from "../utils";
import { IDragEvent, IPinchState, IWheelState } from "./types";
import { getCanvasBox, isPointInBox } from "./utils";
import { ISpecialAnnotation } from "../provider/AnnotationProvider/context";

interface IProps {
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
}

const DRAG_THRESHOLD = 5; // pixels

function findAnnotationAtMousePosition(
  canvas: IContextValue,
  annotations: ISpecialAnnotation[],
  clientX: number,
  clientY: number,
  canvasTop: number,
  canvasLeft: number
) {
  // Define it as a percentage of the canvas with respect to scaling matrix
  const { x, y } = convertPositionToPercentage(
    canvasTop,
    canvasLeft,
    canvas.imageDimensions,
    clientX,
    clientY,
    canvas.matrix
  );

  const filteredAnnotations = annotations.filter((box) => {
    const rotation = box.rotation;
    return (
      isPointInBox(
        box.x * canvas.imageDimensions.naturalWidth,
        box.y * canvas.imageDimensions.naturalHeight,
        box.w * canvas.imageDimensions.naturalWidth,
        box.h * canvas.imageDimensions.naturalHeight,
        rotation,
        x * canvas.imageDimensions.naturalWidth,
        y * canvas.imageDimensions.naturalHeight
      ) && box.highlighted === false
    );
  });

  if (filteredAnnotations.length === 0) {
    return null;
  }

  const smallestAnnotation = filteredAnnotations.reduce((prev, curr) => {
    return prev.w * prev.h < curr.w * curr.h ? prev : curr;
  }, filteredAnnotations[0]);

  return smallestAnnotation.id;
}

export function useInputListener({ canvasRef }: IProps) {
  // Collect variables from context
  const canvas = useContext(CanvasContext);
  const annotationContext = useContext(AnnotationContext);
  const initialMousePos = useRef([0, 0]);

  // Manage drags across the canvas
  function onDragStart(state: IDragEvent) {
    initialMousePos.current = state.values;
  }

  // Manage drags across the canvas
  function onDrag(state: IDragEvent) {
    const dx = state.values[0] - initialMousePos.current[0];
    const dy = state.values[1] - initialMousePos.current[1];
    const distance = Math.sqrt(dx * dx + dy * dy);

    if (distance > DRAG_THRESHOLD) {
      canvas.setDragged(true);

      const matrixTranslation = translate(-state.delta[0], -state.delta[1]);
      const newMat = compose(canvas.matrix, matrixTranslation);
      canvas.setMatrix(newMat);
    } else {
      canvas.setDragged(false);
    }
  }

  // Manage click events (often clicks are used to deselect annotations)
  function onClick(state) {
    if (canvas.dragged) {
      return;
    }
    if (
      canvas.mode === canvas.modeOptions.ADD_DEFECT ||
      canvas.mode === canvas.modeOptions.ADD_DETECTION
    )
      return;
    // Figure out where the click happened
    const { left, top } = getCanvasBox(canvasRef);
    const { clientX, clientY } = state.event;

    annotationContext.setSelectedAnnotation(
      findAnnotationAtMousePosition(
        canvas,
        annotationContext.annotations,
        clientX,
        clientY,
        top,
        left
      )
    );
  }

  function onMouseMove(state) {
    if (
      canvas.mode === canvas.modeOptions.ADD_DEFECT ||
      canvas.mode === canvas.modeOptions.ADD_DETECTION ||
      canvas.mode === canvas.modeOptions.ADD_STEELWORK
    )
      return;
    // Figure out where the click happened
    const { left, top } = getCanvasBox(canvasRef);
    const { clientX, clientY } = state.event;

    annotationContext.setHoveredAnnotation(
      findAnnotationAtMousePosition(
        canvas,
        annotationContext.annotations,
        clientX,
        clientY,
        top,
        left
      )
    );
  }
  // Manage zooming in and out
  function onWheel(state: IWheelState) {
    if (!canvasRef.current) return;
    const { left, top } = getCanvasBox(canvasRef);

    // get mouse position
    const currentMouseX = state.event.clientX - left;
    const currentMouseY = state.event.clientY - top;

    // Define scale value based on Y scroll
    let scaleValue = 1 + (canvas.zoomSpeed / 100) * state.direction[1];

    //check the current scale and limit it to 0.1 - 10
    const currentScale = canvas.matrix.a;
    if (currentScale * scaleValue < 0.01) scaleValue = 0.01 / currentScale;
    if (currentScale * scaleValue > 3) scaleValue = 3 / currentScale;

    // Translate, zoom and then translate back
    const matrixManipulations = [
      translate(currentMouseX, currentMouseY),
      scale(scaleValue),
      translate(-currentMouseX, -currentMouseY),
    ];

    // Save the new matrix
    const newMatrix = compose(canvas.matrix, ...matrixManipulations);
    canvas.setMatrix(newMatrix);
  }

  function onPinch(state: IPinchState) {
    if (!canvasRef.current) return;
    // Extract some mouse values
    const { left, top } = getCanvasBox(canvasRef);
    const currentMouseX = state.origin[0] - left;
    const currentMouseY = state.origin[1] - top;

    // Figure out how much to rescale
    let scaleValue = 1 + (canvas.zoomSpeed / 100) * Math.sign(-state.delta[0]);

    //check the current scale and limit it to 0.1 - 10
    const currentScale = canvas.matrix.a;
    if (currentScale * scaleValue < 0.01) scaleValue = 0.01 / currentScale;
    if (currentScale * scaleValue > 3) scaleValue = 3 / currentScale;

    // Translate, zoom and then translate back
    const matrixManipulations = [
      translate(currentMouseX, currentMouseY),
      scale(scaleValue),
      translate(-currentMouseX, -currentMouseY),
    ];
    const newMatrix = compose(canvas.matrix, ...matrixManipulations);
    canvas.setMatrix(newMatrix);
  }

  useGesture(
    {
      onDragStart,
      onDrag,
      onClick,
      onMouseMove,
    },
    {
      target: canvasRef,
      enabled:
        canvas.mode !== canvas.modeOptions.ADD_DEFECT &&
        canvas.mode !== canvas.modeOptions.ADD_DETECTION &&
        canvas.mode !== canvas.modeOptions.ADD_STEELWORK,
    }
  );
  useGesture(
    {
      onWheel,
      onPinch,
    },
    {
      target: canvasRef,
      enabled: true,
    }
  );
}
