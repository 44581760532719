import { createContext, useEffect } from "react";
import { io } from "socket.io-client";
import { EventHint, captureException } from "@sentry/react";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { getSocketEndpoint } from "authentication/apiDetails";

type Callback = (data?: unknown) => void;
type SocketType = {
  onConnect: (key: string, cb: Callback) => void;
  onDisconnect: (key: string, cb: Callback) => void;
  onEvent: (event: string, key: string, cb: Callback) => void;
  removeListeners: (key: string) => void;
  socket: ReturnType<typeof io>;
};
type SocketListener = Record<string, Callback>;

export const SocketContext = createContext<SocketType>({
  onConnect: () => {},
  onDisconnect: () => {},
  onEvent: () => {},
  removeListeners: () => {},
  socket: null,
});

const onConnectListeners = {} as SocketListener;
const onDisconnectListeners = {} as SocketListener;
const onEventListeners = {} as Record<string, SocketListener>;

let socket: ReturnType<typeof io> | null = null;
let onConnect = (key: string, cb: () => unknown) => {};
let onDisconnect = (key: string, cb: () => unknown) => {};
let onEvent = (event: string, key: string, cb: () => unknown) => {};
let removeListeners = (key: string) => {};

export function SocketProvider({ children }) {
  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const endpoint = getSocketEndpoint();

  if (endpoint && loggedIn && !socket) {
    // Initialize socket and callbacks

    socket = io(endpoint, {
      withCredentials: true,
      transports: ["websocket"], // Only use websocket transport since longpolling requires sticky sessions
    });

    socket.on("connect_error", (error) => {
      // Capture the exception
      const exceptionHint: EventHint = {
        event_id: "socketIO.connect_error",
        originalException: error,
        data: {
          activeSocket: socket.active,
        },
      };
      captureException(error, exceptionHint);
    });

    socket.on("connect", () => {
      console.log("socket connected with id ", socket.id);

      for (const onConnectCallback of Object.values(onConnectListeners)) {
        onConnectCallback();
      }
    });

    socket.on("disconnect", (reason) => {
      console.log("socket disconnected", reason);

      if (reason === "io server disconnect") {
        // Explicit disconnection by the server.
        // There will be no automatic reconnect attempt so we reconnect manually.
        // See: https://socket.io/docs/v4/client-api/#Event-%E2%80%98disconnect%E2%80%99
        socket.connect();
      }

      for (const onDisconnectCallback of Object.values(onDisconnectListeners)) {
        onDisconnectCallback();
      }
    });

    onConnect = (key: string, cb: () => void) => {
      onConnectListeners[key] = cb;

      if (socket.connected) {
        cb();
      }
    };

    onDisconnect = (key: string, cb: () => void) => {
      onDisconnectListeners[key] = cb;
    };

    onEvent = (event: string, key: string, cb: () => void) => {
      if (!onEventListeners[event]) {
        onEventListeners[event] = {};
      }
      onEventListeners[event][key] = cb;

      if (!socket.hasListeners(event)) {
        socket.on(event, (data) => {
          for (const onEventCallback of Object.values(
            onEventListeners[event]
          )) {
            onEventCallback(data);
          }
        });
      }
    };

    removeListeners = (key: string) => {
      for (const event of Object.keys(onEventListeners)) {
        delete onEventListeners[event][key];
      }
      delete onConnectListeners[key];
      delete onDisconnectListeners[key];
    };
  }

  useEffect(() => {
    return () => {
      socket?.disconnect?.();
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        onConnect,
        onDisconnect,
        onEvent,
        removeListeners,
        socket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}
