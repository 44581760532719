import { connect } from "react-redux";
import KMLDownload from "./KMLDownload";
import { RootState } from "state/store";
import { downloadPowerlineData } from "state/actions";

interface IStateProps {
  map: google.maps.Map | null;
}

interface IDispatchProps {
  downloadPowerlineData: (type: "reflight" | "all") => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    map: state.map.gmap,
  };
};

const mapDispatchToProps = {
  downloadPowerlineData,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(KMLDownload);
