import { Clear } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";

type Props = {
  title: string;
  onClose: () => void;
};

export default function TopBar({ title, onClose }: Props) {
  return (
    <Stack
      data-testid="image-connector-dialog-title-bar"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ p: 2 }}
    >
      <Typography variant="h6" data-testid="image-connector-dialog-title-text">
        {title}
      </Typography>
      <IconButton
        data-testid="image-connector-dialog-close-button"
        aria-label="close"
        datatype="ImageConnectorDialogCloseButton"
        onClick={onClose}
      >
        <Clear />
      </IconButton>
    </Stack>
  );
}
