import { Component } from "react";
import ReactImageAnnotate from "react-image-annotate";
import RoleWrapper from "components/RoleHOC/wrapper";
import { authorizedPost, authorizedGet } from "utils/request";
import { Mission } from "interfaces";
import hasAccess from "utils/authTools";

interface Props {
  image;
  objectTypes;
  issueCategories;
  detectedCategories;
  issueSeverities;
  customerId;
  showBBOX;
  setFlagged: (image: number, value: boolean, callback: () => void) => void;
  user;
  showAnnotator;
  showHumanMachine;
  setHumanMachine;
  updateMarkerData;
  zoomSpeed: number;
  annotatorObjectColor: boolean;
  sendFeedback: (
    image_id: number,
    feedback_type: number,
    comment: string,
    callback?: () => void,
    onError?: () => void
  ) => void;
  missions: Mission[];
}
interface State {
  annotations;
  dataLoaded;
  keyExtra;
  currentlySaving: boolean;
}

class AnnotationTool extends Component<Props, State> {
  state = {
    annotations: [],
    dataLoaded: false,
    keyExtra: 1000,
    currentlySaving: false,
  };

  componentDidMount = () => {
    this.updateAnnotations();
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.customerId !== prevProps.customerId ||
      this.props.image?.id !== prevProps.image?.id ||
      this.props.annotatorObjectColor !== prevProps.annotatorObjectColor ||
      this.props.issueCategories !== prevProps.issueCategories
    ) {
      this.updateAnnotations();
    }
  };

  onExit = async (regions, save_data: boolean, exit_tool: boolean) => {
    const { annotations } = this.state;
    const { image } = this.props;

    if (!this.state.currentlySaving && save_data) {
      this.setState({
        currentlySaving: true,
      });
      // Unpack the old regions so we don't change any old data accidentally.
      const newRegions = [...regions];
      const oldRegions = [...annotations];
      // Extract region IDs
      const newRegionsIDs = new Set(newRegions.map((r) => r.id));
      const oldRegionsIDs = new Set(oldRegions.map((r) => r.id));

      // Find regions that were deleted
      const deletedRegions = oldRegions.filter((r) => !newRegionsIDs.has(r.id));
      // Find regions that were created
      const createdRegions = newRegions.filter((r) => !oldRegionsIDs.has(r.id));
      // Find regions that were updated
      const deletedIds = deletedRegions.map((r) => r.id);
      const createdIds = createdRegions.map((r) => r.id);

      // Create the API payload
      const annotationChanges = [];

      // Add deleted regions to the payload
      for (const id of deletedIds) {
        annotationChanges.push({
          action: "delete",
          id: id,
        });
      }

      for (const r of newRegions) {
        let objectAction = "update";
        if (createdIds.includes(r.id)) {
          objectAction = "add";
        }
        const annotationChangesEntry = {
          action: objectAction,
          id: r.id,
          x: r.x,
          y: r.y,
          width: r.w,
          height: r.h,
          defect_fixed: !!r.fixed,
          date_fixed: r.fixed,
          date_reported: r.reported,
          object_has_no_defects: !!r.objectHasNoDefect,
          types: [],
        };

        const oldObjectIndex = oldRegions.findIndex((a) => a.id === r.id);

        for (let i = 0; i < r.types.length; i++) {
          const comment = r.type_comment[i];
          const type_id = r.types[i];
          const severity = r.severities[i];
          const processed = r.processed[i];
          const skyqraftHidden = r.skyqraft_hidden[i];
          const workflowStatus = r.workflow_status[i];
          // @ts-ignore
          const imageObjectTypeID = r.type_id[i];

          // Find old data
          const oldObjectData = oldRegions.find((a) => a.id === r.id);
          const oldComment = oldObjectData?.type_comment[i];
          const oldType = oldObjectData?.types[i];
          const oldSeverity = oldObjectData?.severities[i];
          const oldProcessed = oldObjectData?.processed[i];
          const oldSkyqraftHidden = oldObjectData?.skyqraft_hidden[i];
          const oldWorkflowStatus = oldObjectData?.workflow_status[i];
          // @ts-ignore
          const oldImageObjectTypeID = oldObjectData?.type_id[i];

          let typeAction = "";
          if (oldObjectIndex === -1) {
            typeAction = "add";
          } else {
            if (
              oldComment !== comment ||
              oldType !== type_id ||
              oldSeverity !== severity ||
              oldProcessed !== processed ||
              oldSkyqraftHidden !== skyqraftHidden ||
              oldWorkflowStatus !== workflowStatus ||
              oldImageObjectTypeID !== imageObjectTypeID
            ) {
              typeAction = "update";
            }
          }
          if (typeAction !== "") {
            annotationChangesEntry.types.push({
              action: typeAction,
              id: imageObjectTypeID,
              type: type_id,
              workflow_status: workflowStatus,
              severity: severity,
              skyqraft_hidden: skyqraftHidden,
              comment: comment,
            });
          }
        }
        annotationChanges.push(annotationChangesEntry);

        //Check for deleted types
        if (oldObjectIndex !== -1) {
          if (r.types.length < oldRegions[oldObjectIndex].types.length) {
            const typesToDeleteIds = oldRegions[oldObjectIndex].type_id.filter(
              (element) => !r.type_id.includes(element)
            );
            for (const u of typesToDeleteIds) {
              annotationChangesEntry.types.push({
                action: "delete",
                id: u,
              });
            }
          }
        }
      }

      // Send the payload to the API
      if (annotationChanges.length > 0) {
        await authorizedPost(`/image/${image.id}/annotations`, {
          annotations: annotationChanges,
        });
      }

      this.setState({
        currentlySaving: false,
      });

      const { objectTypes } = this.props;

      const baseQuery = new URLSearchParams(window.location.search);
      const query = baseQuery.toString();
      authorizedGet<{ annotations }>(`/image/${image.id}/annotations?${query}`)
        .then(({ annotations }) => {
          const UserHasAccess = hasAccess("flagImageForReannotation");

          if (UserHasAccess) {
            this.props.setFlagged(image.id, false, () => {});
          }

          this.setState({
            annotations: annotations.map((row) => ({
              ...row,
              isLocked: !!row.reported,
              visible: true,
              categories: row.types.map(
                (ot) => objectTypes.find((o) => o.id === ot)?.category
              ),
              is_defect: row.types.map(
                (ot) => objectTypes.filter((o) => o.id === ot)[0]?.issue
              ),
            })),
            keyExtra: this.state.keyExtra + 1,
            dataLoaded: true,
          });
        })
        .catch(() => {});
    }

    this.props.updateMarkerData();
    if (!this.state.currentlySaving && exit_tool) {
      this.props.showAnnotator();
    }
  };

  updateAnnotations = () => {
    const { image, objectTypes } = this.props;
    if (!image?.id) {
      return null;
    }
    const baseQuery = new URLSearchParams(window.location.search);
    const query = baseQuery.toString();

    this.setState({ dataLoaded: false }, () => {
      authorizedGet<{ annotations }>(`/image/${image.id}/annotations?${query}`)
        .then(({ annotations }) => {
          const objects = {
            annotations: annotations.map((row) => {
              const types = row.types.map((ot) =>
                objectTypes.find((o) => ot === o.id)
              );
              return {
                ...row,
                isLocked: !!row.reported,
                visible: true,
                categories: types.map((t) => t?.category),
                is_defect: types.map((t) => t?.issue),
              };
            }),
            keyExtra: this.state.keyExtra + 1,
            dataLoaded: true,
          };
          this.setState(objects);
        })
        .catch(() => {});
    });
  };

  render() {
    const {
      image,
      user,
      showBBOX,
      customerId,
      showHumanMachine,
      setHumanMachine,
      issueCategories,
      detectedCategories,
      objectTypes,
      issueSeverities,
      annotatorObjectColor,
    } = this.props;
    const { keyExtra, dataLoaded, annotations } = this.state;
    let keyValue = image?.id + 10 * showBBOX;
    if (user.language === "EN") {
      keyValue = keyValue + 15 + keyExtra;
    }
    return dataLoaded && objectTypes.length > 0 ? (
      <RoleWrapper keyName="annotationTool">
        <ReactImageAnnotate
          key={keyValue}
          image={image}
          zoomSpeed={this.props.zoomSpeed}
          customerId={customerId}
          annotations={annotations.filter(
            (region) =>
              region.types[0] &&
              objectTypes.find((ot) => ot.id === region.types[0])
          )}
          showHumanMachine={showHumanMachine}
          setHumanMachine={setHumanMachine}
          issueCategories={issueCategories.map((object) => {
            return {
              ...object,
              name:
                user.language === "SE" && object.se_name
                  ? object?.se_name
                  : object?.en_name,
            };
          })}
          detectedCategories={detectedCategories.map((object) => {
            return {
              ...object,
              name:
                user.language === "SE" && object.se_name
                  ? object.se_name
                  : object.en_name,
            };
          })}
          objectTypes={objectTypes.map((object) => {
            return {
              ...object,
              name:
                user.language === "SE" && object.se_name
                  ? object?.se_name
                  : object?.en_name,
            };
          })}
          issueSeverities={issueSeverities.map((object) => {
            return {
              ...object,
              name:
                user.language === "SE" && object.se_name
                  ? object.se_name
                  : object.en_name,
            };
          })}
          onExit={this.onExit}
          user={user}
          annotatorObjectColor={annotatorObjectColor}
        />
      </RoleWrapper>
    ) : (
      <div />
    );
  }
}

export default AnnotationTool;
