import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getListFilter(
  searchParams: URLSearchParams
): FilterType["LIST"] {
  const listParams = searchParams.get(FilterKey.LIST);
  return listParams ? listParams.split(",").map(Number) : [];
}

export function useListFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const listFilter = getListFilter(searchParams);

  const setListFilter = (listFilter: FilterType["LIST"]) => {
    if (listFilter.length === 0) {
      if (searchParams.has(FilterKey.LIST)) searchParams.delete(FilterKey.LIST);
    } else {
      searchParams.set(FilterKey.LIST, listFilter.join(","));
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { listFilter, setListFilter };
}
