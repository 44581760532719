import { connect } from "react-redux";
import ProximitySwitch from "./ProximitySwitch";
import { setClusterMethod } from "state/actions";
import { RootState } from "state/store";

interface IStateProps {
  clusterMethod: string;
}

interface IDispatchProps {
  setClusterMethod: typeof setClusterMethod;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    clusterMethod: state.imageViewer.clusterMethod,
  };
};

const mapDispatchToProps = {
  setClusterMethod,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ProximitySwitch);
