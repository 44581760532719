import { createContext } from "react";
import { IDefectCount } from "./utils";

export interface IContextValue {
  data: IDefectCount[];
  loading: boolean;
  error?: string;
  update: () => void;
}

// Set up a context that is importable
const context = createContext<IContextValue>({
  data: [],
  update: () => {},
  loading: false,
  error: null,
});

export { context };
