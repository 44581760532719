import { Typography, Grid, Divider, Stack } from "@mui/material";
import { Accordion } from "components";
import ProgressBar from "views/Upload/Images/ProgressBar/ProgressBar";
import translations from "translations";
import { round } from "utils";
import { useLanguage } from "hooks";

type Props = {
  open?: boolean;
  loading: boolean;
  title: string;
  description: string;
  entity: string;
  assignments: {
    name: any;
    progress: {
      analysis: {
        assigned: number;
        completed: number;
      };
      criticalAnalysis: {
        assigned: number;
        completed: number;
      };
    };
  }[];
};

export default function AssignmentList({
  open,
  title,
  description,
  entity,
  assignments,
  loading,
}: Props) {
  const { language } = useLanguage();
  return (
    <Accordion
      open={open}
      title={title}
      description={description}
      value={assignments.length}
    >
      <Grid container spacing={2}>
        <Grid item xs={4} display="flex">
          <Typography fontWeight="bold">{entity}</Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center">
          <Typography fontWeight="bold">
            {translations.Landing.Assignments.Analysis[language]}
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="center">
          <Typography fontWeight="bold">
            {translations.Landing.Assignments.CriticalAnalysis[language]}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }} />
      <Stack divider={<Divider />}>
        {assignments.map((status, i) => (
          <Grid
            key={`${status.name}_${i}`}
            container
            spacing={2}
            sx={{ py: 1 }}
          >
            <Grid display="flex" alignItems="center" item xs={4}>
              {status.name}
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              item
              xs={4}
            >
              <ProgressBar
                disabled={status.progress.analysis.assigned === 0}
                percentage={
                  status.progress.analysis.completed /
                  status.progress.analysis.assigned
                }
              />
              <Typography>
                {status.progress.analysis.assigned > 0
                  ? `${round(
                      status.progress.analysis.completed / 1000,
                      1
                    )} km ${translations.words.Of[language]} ${round(
                      status.progress.analysis.assigned / 1000,
                      1
                    )} km`
                  : translations.Landing.Assignments.NotAssigned[language]}
              </Typography>
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              item
              xs={4}
            >
              <ProgressBar
                disabled={status.progress.criticalAnalysis.assigned === 0}
                percentage={
                  status.progress.criticalAnalysis.completed /
                  status.progress.criticalAnalysis.assigned
                }
              />
              <Typography>
                {status.progress.criticalAnalysis.assigned > 0
                  ? `${round(
                      status.progress.criticalAnalysis.completed / 1000,
                      1
                    )} km ${translations.words.Of[language]} ${round(
                      status.progress.criticalAnalysis.assigned / 1000,
                      1
                    )} km`
                  : translations.Landing.Assignments.NotAssigned[language]}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </Accordion>
  );
}
