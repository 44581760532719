import { Button } from "@mui/material";
import { useSelector } from "hooks";
import tokml from "tokml";

export async function convertToGeoJSON(
  map: google.maps.Map,
  filter: (feature: google.maps.Data.Feature) => boolean
) {
  const mapData = { type: "FeatureCollection", features: [] };

  const promises = [];
  if (!map) {
    return null;
  }
  // biome-ignore lint/complexity/noForEach: map.data is a google.maps.Data object
  map.data.forEach((feature: google.maps.Data.Feature) => {
    // Check if we need the feature
    if (!filter(feature)) {
      return;
    }
    // Add to the list of promises
    promises.push(
      new Promise((resolve, reject) =>
        feature.toGeoJson((value) => {
          resolve(value);
        })
      )
    );
  });
  mapData.features = await Promise.all(promises);
  return mapData;
}

export async function convertToKml(
  map: google.maps.Map,
  filter: (feature: google.maps.Data.Feature) => boolean
) {
  const mapData = await convertToGeoJSON(map, filter);
  if (mapData) {
    return tokml(mapData);
  } else {
    return null;
  }
}

type Props = {
  onSave: (kmlData) => void;
  disabled?: boolean;
};

export default function SaveButton({ onSave, disabled }: Props) {
  const gmap = useSelector((state) => state.map.gmap);

  async function savePowerlines() {
    const kmlData = await convertToKml(
      gmap,
      (feature: google.maps.Data.Feature) =>
        !feature.getProperty("isBoundingBox")
    );
    onSave(kmlData);
  }

  return (
    <Button onClick={savePowerlines} disabled={disabled}>
      Save
    </Button>
  );
}
