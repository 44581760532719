import { IMatrix } from "../types";

interface IProps {
  canvas: HTMLCanvasElement;
  matrix: IMatrix;
  path: string[];
}

export function drawStrawman({ canvas, matrix, path }: IProps) {
  const clientWidth = canvas.clientWidth;
  const clientHeight = canvas.clientHeight;

  if (canvas.getContext && clientWidth && clientHeight) {
    canvas.width = clientWidth;
    canvas.height = clientHeight;
    //canvas.height = clientHeight;
    // Get the drawing mechanism in order
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.lineWidth = 1 / matrix.a;
      ctx.strokeStyle = "rgba(0,0,0)"; // Transparency follows zoom
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Compute top left and size
      const topLeft = {
        x: -matrix.e / matrix.a,
        y: -matrix.f / matrix.d,
      };
      const size = {
        w: clientWidth / matrix.a,
        h: clientWidth / matrix.a,
      };

      ctx.beginPath();
      // Draw the strawman
      for (const p of path) {
        const [action, xString, yString] = p.split(" ");
        const y = parseFloat(yString);
        const x = parseFloat(xString);

        // Set position with respect to offset (mat.e, mat.f) and scale (mat.a, mat.d)
        const xPos = topLeft.x + x * size.w;
        const yPos = topLeft.y + y * size.h;

        if (action.toUpperCase() === "M") {
          ctx.moveTo(xPos, yPos);
        } else if (action.toUpperCase() === "L") {
          ctx.lineTo(xPos, yPos);
        }
      }
      ctx.stroke();
    }
  }
}
