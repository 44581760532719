import { axiosInstance } from "utils/request";
import { AccessLevel } from "interfaces/user";

interface IGroup {
  id: number;
  name: string;
  access_levels: AccessLevel[];
}

interface IAdminGroup extends IGroup {
  projects: string[];
  regions: string[];
}

interface IGroupDetailed extends IGroup {
  access_levels: AccessLevel[];
  users: {
    id: number;
    email: string;
    name: string;
    access_level: AccessLevel;
  }[];
  missions: {
    id: number;
    name: string;
  }[];
}

/**
 * Fetches a group by its ID
 * @param {number} groupID The ID of the group to fetch
 * @returns {Promise<IGroupDetailed>} A promise that resolves to the group
 * @throws {Error} An error if the request fails
 *
 * @example
 * const group = await getGroup(1);
 * console.log(group);
 */
export async function getGroup(groupID: number): Promise<IGroupDetailed> {
  const response = await axiosInstance.get<{ group: IGroupDetailed }>(
    `/group/${groupID}`
  );
  if (response.status === 200) {
    return response.data.group;
  } else {
    throw new Error("Failed to fetch group");
  }
}

/**
 * Fetches all groups
 * @returns {Promise<IGroup[]>} A promise that resolves to an array of groups
 * @throws {Error} An error if the request fails
 *
 * @example
 * const groups = await getAllGroups();
 * console.log(groups);
 * // Output: [{ id: 1, name: "Group 1", access_level: "admin" }, ...]
 */
export async function getAllGroups(): Promise<IGroup[]> {
  const response = await axiosInstance.get<{ groups: IGroup[] }>("/group");
  if (response.status === 200) {
    return response.data.groups;
  } else {
    throw new Error("Failed to fetch groups");
  }
}

/**
 * Fetches all groups that the current user is an admin of
 * @returns {Promise<IGroup[]>} A promise that resolves to an array of groups
 * @throws {Error} An error if the request fails
 *
 * @example
 * const groups = await getAdminGroups();
 * console.log(groups);
 * // Output: [{ id: 1, name: "Group 1", access_level: "admin" }, ...]
 */
export async function getAdminGroups() {
  const response = await axiosInstance.get<{ groups: IAdminGroup[] }>(
    "/admin/group"
  );
  if (response.status === 200) {
    return response.data.groups;
  } else {
    throw new Error("Failed to fetch groups");
  }
}

export default {
  getAllGroups,
  getAdminGroups,
  getGroup,
} as const;
