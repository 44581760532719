import { useFlags } from "launchdarkly-react-client-sdk";
import ImageReport from "./ImageReport";

export default function FlagProtectedImageReport() {
  // Render conditionally on feature flags
  const { imageReport } = useFlags();

  // https://app.launchdarkly.com/projects/default/flags/image-report
  if (!imageReport) {
    return null;
  }

  return <ImageReport />;
}
