import React, { useRef, useMemo } from "react";
import { useEventCallback } from "utils";

type Props = {
  imagePosition;
  imageSrc;
  zIndex: number;
  onLoad?: Function;
};

export default function Image({
  imagePosition,
  imageSrc,
  onLoad,
  zIndex,
}: Props) {
  var imageRef = useRef();

  const onImageLoaded = useEventCallback(function (event) {
    var imageElm = event.currentTarget;
    if (onLoad)
      onLoad({
        naturalWidth: imageElm.naturalWidth,
        naturalHeight: imageElm.naturalHeight,
        imageElm: imageElm,
      });
  });

  var stylePosition = useMemo(
    function () {
      var width = imagePosition.bottomRight.x - imagePosition.topLeft.x;
      var height = imagePosition.bottomRight.y - imagePosition.topLeft.y;
      return {
        imageRendering: "pixelated",
        left: imagePosition.topLeft.x || 0,
        top: imagePosition.topLeft.y || 0,
        width: isNaN(width) ? 0 : width,
        height: isNaN(height) ? 0 : height,
      };
    },
    [
      imagePosition.topLeft.x,
      imagePosition.topLeft.y,
      imagePosition.bottomRight.x,
      imagePosition.bottomRight.y,
    ]
  );

  return (
    <img
      src={imageSrc}
      alt="source"
      ref={imageRef}
      // @ts-ignore
      style={{
        ...stylePosition,
        zIndex: zIndex,
        position: "absolute",
      }}
      onLoad={onImageLoaded}
      onError={(e) => {
        // @ts-ignore
        e.target.onerror = null;
        // @ts-ignore
        e.target.src = "/missing.png";
      }}
    />
  );
}
