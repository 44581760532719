import { Switch } from "@mui/material";
import MenuItem from "components/MenuItem";
import { useDispatch, useLanguage, useSelector } from "hooks";
import { setShowDsoTso } from "state/actions";
import { RootState } from "state/reducers";
import translations from "translations";

function ShowDsoTso() {
  const dispatch = useDispatch();
  const { language } = useLanguage();
	const showDsoTso = useSelector((state: RootState) => state.user.show_dso_tso);
  return (
    <MenuItem
      id="showDsoTsoSwitch"
      onClick={() => dispatch(setShowDsoTso(!showDsoTso))}
    >
      <p>{translations.Menu.Settings.ShowDsoTso[language]}</p>
      <Switch color="primary" checked={showDsoTso} />
    </MenuItem>
  );
}
export default ShowDsoTso;
