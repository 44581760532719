import {
  authorizedDelete,
  authorizedGet,
  authorizedPost,
  authorizedPut,
} from "utils/request";
import { IGanttItem } from "components/GanttChart";

export async function getProjectGanttData(projectID: number) {
  // Collect the data from the API
  const endpoint = `/project/${projectID}/management/gantt`;
  const response = await authorizedGet<{
    project_id: number;
    gantt_data: IGanttItem[];
  }>(endpoint);

  // Return the data
  if (!!!response) {
    throw new Error("Failed to fetch gantt data");
  }
  return response;
}

export async function setGanttItem(
  projectID: number,
  itemID: number,
  name: string = null,
  start_date: string = null,
  end_date: string = null,
  display_order: number = null
) {
  let payload = {};

  // Prepare the payload
  if (!!name) {
    payload = {
      ...payload,
      name,
    };
  }
  if (!!start_date) {
    payload = {
      ...payload,
      start_date,
    };
  }
  if (!!end_date) {
    payload = {
      ...payload,
      end_date,
    };
  }
  if (!!display_order) {
    payload = {
      ...payload,
      display_order,
    };
  }

  // Check that payload is not empty
  if (Object.keys(payload).length === 0) {
    throw new Error("No data to update");
  }

  // Collect the data from the API
  const endpoint = `/project/${projectID}/management/gantt/${itemID}`;
  const response = await authorizedPut<string>(endpoint, payload);

  // Return the data
  if (!!!response) {
    throw new Error("Failed to update gantt data");
  }
  return response;
}

export async function addGanttItem(
  projectID: number,
  name: string,
  start_date: string,
  end_date: string,
  display_order: number
) {
  // Prepare the payload
  const payload = {
    name,
    start_date,
    end_date,
    display_order,
  };

  // Collect the data from the API
  const endpoint = `/project/${projectID}/management/gantt`;
  const response = await authorizedPost<string>(endpoint, payload);

  // Return the data
  if (!!!response) {
    throw new Error("Failed to create gantt data");
  }
  return response;
}

export async function deleteGanttItem(projectID: number, itemID: number) {
  // Collect the data from the API
  const endpoint = `/project/${projectID}/management/gantt/${itemID}`;
  const response = await authorizedDelete(endpoint);

  // Return the data
  if (!!!response) {
    throw new Error("Failed to delete gantt data");
  }
  return response;
}
