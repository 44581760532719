import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  useSeverityCount,
  useSeverityFilter,
  useLanguage,
  useSelector,
} from "hooks";
import { LoadingDots } from "components/LoadingDots";
import translations from "translations";
import { getClientSeverityName } from "utils/utils";
import { groupBy, uniqBy, isEqual } from "lodash";
import { useSearchParams } from "react-router-dom";

interface ClientSeverityLevel {
  client_severity_type_id: number;
  count: number;
  id: number;
  name: string;
}

export default function DefectFilter() {
  const { severityFilter, setSeverityFilter } = useSeverityFilter();
  const { counts, loading } = useSeverityCount();
  const { language } = useLanguage();
  const [searchParams] = useSearchParams();
  const issueSeverities = useSelector((state) => state.objects.issueSeverities);
  const foundSeverity = searchParams.get("severity");

  const criticalCount = counts.find((s) => s.id === 1)?.count || 0;
  const issueCount = counts.find((s) => s.id === 2)?.count || 0;
  const shouldBeFixedCount = counts.find((s) => s.id === 3)?.count || 0;
  const goodToKnowCount = counts.find((s) => s.id === 4)?.count || 0;

  // These are the severity levels
  const critical = 1;
  const issue = 2;
  const shouldBeFixed = 3;
  const goodToKnow = 4;

  // This is how we group the severity levels
  const defectsLevelIds = [critical, issue];
  const remarksLevelIds = [shouldBeFixed, goodToKnow];

  // This is a helper function to get the count of a severity level
  const getCount = (id) => {
    if (!loading) {
      return counts.find((s) => s.id === id)?.count || 0;
    }
    return "...";
  };

  // This is to adapt the list to the client's severity levels
  const issueSeveritiesClientData: ClientSeverityLevel[] = issueSeverities.map(
    (e) => {
      const clientName = getClientSeverityName(e.id);
      return { ...clientName, count: getCount(e.id) };
    }
  );

  // This is to group the severity levels by the client's severity level so that we don't show duplicates
  const newIssueSeveritiesGrouped = groupBy(
    issueSeveritiesClientData,
    "client_severity_type_id"
  );

  const newIssueSeveritiesGroupedReduced = Object.values(
    newIssueSeveritiesGrouped
  ).map((e: ClientSeverityLevel[]) => {
    let count: number | string = "...";
    if (!loading) {
      count = e.reduce((accumulator, c) => {
        return accumulator + c.count;
      }, 0);
    }
    const selectIds = e.map((f) => f.id);
    return { ...e[0], count, selectIds };
  });

  const newIssueSeveritiesSorted = newIssueSeveritiesGroupedReduced.sort(
    (a, b) => {
      return a.id - b.id;
    }
  );

  //These are for handling the logic for grouped severity levels
  const alignmentIds = newIssueSeveritiesSorted.map((e) => e.selectIds[0]);
  const activeAlignments = severityFilter.filter((f) =>
    alignmentIds.includes(f)
  );
  const [alignment, setAlignment] = useState(activeAlignments);

  useEffect(() => {
    if (newIssueSeveritiesSorted.length > 0) {
      const filteredIssuSeverities = alignment?.map((e) =>
        newIssueSeveritiesSorted.find((f) => f.selectIds.includes(e))
      );

      const filteredIssuSeveritiesUniq = uniqBy(filteredIssuSeverities, "id");

      const selectedIds =
        filteredIssuSeveritiesUniq?.flatMap((f) => f.selectIds) || [];

      if (foundSeverity || selectedIds.length) {
        const sortedIDs = selectedIds.sort((a, b) => a - b);
        const foundIDs = foundSeverity
          ? foundSeverity
              .split(",")
              .map((s) => Number.parseInt(s))
              .sort((a, b) => a - b)
          : [];
        if (!isEqual(sortedIDs, foundIDs)) {
          setSeverityFilter(selectedIds);
        }
      }
    }
  }, [alignment]);

  //This is for if the filter gets reset
  useEffect(() => {
    if (severityFilter.length === 0) {
      setAlignment(severityFilter);
    }
  }, [foundSeverity]);

  return (
    <Stack px={1} flex={1}>
      <Typography sx={{ ml: 1, mt: 1 }} variant="h6" fontSize={17}>
        {translations.Filter.Defects[language]}
        {loading ? <LoadingDots /> : ` (${criticalCount + issueCount})`}
      </Typography>

      <ToggleButtonGroup
        orientation="vertical"
        aria-label="severity level"
        color="error"
        size="small"
        value={alignment}
        onChange={(e, value) => setAlignment(value)}
        sx={{ mx: 1 }}
      >
        {newIssueSeveritiesSorted.map((e) =>
          defectsLevelIds.includes(e.id) ? (
            <ToggleButton key={e.id} value={e.id}>
              {`${getClientSeverityName(e.id).name} (${e.count})`}
            </ToggleButton>
          ) : null
        )}
      </ToggleButtonGroup>

      <Typography sx={{ mx: 1, mt: 1 }} variant="h6" fontSize={17}>
        {translations.Filter.Remarks[language]}
        {loading ? (
          <LoadingDots />
        ) : (
          ` (${shouldBeFixedCount + goodToKnowCount})`
        )}
      </Typography>

      <ToggleButtonGroup
        orientation="vertical"
        aria-label="severity level"
        color="warning"
        size="small"
        value={alignment}
        onChange={(e, value) => setAlignment(value)}
        sx={{ mx: 1 }}
      >
        {newIssueSeveritiesSorted.map((e) =>
          remarksLevelIds.includes(e.id) ? (
            <ToggleButton value={e.id} key={e.id}>
              {`${getClientSeverityName(e.id).name} (${e.count})`}
            </ToggleButton>
          ) : null
        )}
      </ToggleButtonGroup>
    </Stack>
  );
}
