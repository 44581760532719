import { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Restore } from "@mui/icons-material";
import { AnnotationContext } from "../provider";
import { regionsAreEqual } from "./AnnotationsList/Utils";

export default function ResetButton() {
  const { resetAnnotations, annotations, originalAnnotations } =
    useContext(AnnotationContext);
  let somethingChanged = !regionsAreEqual(annotations, originalAnnotations);

  return (
    <Tooltip title={"reset"} arrow placement="top" disableInteractive>
      <IconButton
        onClick={() => resetAnnotations()}
        disabled={!somethingChanged}
      >
        <Restore />
      </IconButton>
    </Tooltip>
  );
}
