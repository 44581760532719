import React from "react";
import RoleWrapper from "components/RoleHOC/wrapper";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { Delete } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useLanguage } from "hooks";
type Props = {
  hideImage: (mission: number | string, image: number | string) => void;
};

export default function HideImage({ hideImage }: Props) {
  const params = useParams();
  const { language } = useLanguage();
  return (
    <RoleWrapper keyName="imageVisibility">
      <MenuItem
        onClick={() => hideImage(params.mission, params.image)}
        id="image-hide-for-users"
        tooltip={translations.ImageViewer.Hide[language]}
      >
        <Delete />
      </MenuItem>
    </RoleWrapper>
  );
}
