export interface MissionDraft {
  mission_type?: number;
  drone_operator?: string;
  date_flight?: string;
  thermal_analysis?: boolean;
  corrosion_analysis?: boolean;
  lidar_analysis?: boolean;
  image_analysis?: boolean;
  order_comment?: string;
  year?: number;
  group_id?: number;
  region?: number;
  geoJSON?: any;
  name?: string;
  created: number;
  email_list_ids: number[];
  invoice_reference: string;
}

export function saveDrafts(drafts: MissionDraft[]) {
  localStorage.setItem("missionDrafts", JSON.stringify(drafts));
}

export function getDrafts() {
  let previousDraftsString = localStorage.getItem("missionDrafts");
  let previousDrafts: MissionDraft[] = [];
  if (!!previousDraftsString) previousDrafts = JSON.parse(previousDraftsString);
  return previousDrafts;
}

export function saveOrderDraft(data: MissionDraft) {
  // Check for existing drafts
  let previousDrafts: MissionDraft[] = getDrafts();

  // Add current draft to the list
  previousDrafts.push(data);

  // Save the draft
  saveDrafts(previousDrafts);
}

export function deleteOrderDraft(draft) {
  let previousDrafts: MissionDraft[] = getDrafts();
  let newDrafts = previousDrafts.filter((d) => d.created !== draft.created);
  saveDrafts(newDrafts);
}
