import { useSelector } from "hooks";
import { provider as WorkSessionProvider } from "providers/WorkSessionProvider";

export default function WorkSessionProviderWrapper({ children }) {
  const userIsAnnotator = useSelector((state) => state.user.annotator);
  const userIsSuperuser = useSelector((state) => state.user.superuser);
  if (!(userIsSuperuser || userIsAnnotator)) {
    return children;
  } else {
    return <WorkSessionProvider>{children}</WorkSessionProvider>;
  }
}
