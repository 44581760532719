import { CSSProperties, ReactNode, useRef } from "react";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import "./style.scss";

interface IProps {
  children;
  onClick?: Function;
  dataTestID?: string;
  id?: string;
  className?: string;
  passive?: boolean;
  style?: CSSProperties;
  light?: boolean;
  disabled?: boolean;
  tooltipInteractive?: boolean;
  active?: boolean;
  tooltip?: ReactNode;
  onContextMenu?: any;
  value?: string | number;
  tooltipPlacement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
}

type State = {};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    padding: 0,
    fontSize: 11,
    "& .MuiTooltip-arrow": {
      color: theme.palette.common.white,
      boxShadow: "black",
    },
  },
}));

export default function MenuItem({
  children,
  onClick,
  light,
  id,
  className,
  active,
  passive,
  tooltip,
  dataTestID,
  tooltipInteractive,
  disabled,
  onContextMenu,
  value,
  tooltipPlacement,
  style,
}: IProps) {
  const myRef = useRef<HTMLDivElement>();
  const component = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      data-testid={dataTestID}
      className={`menuItem${!!passive || !!disabled ? "" : " hoverable"}${
        active ? " active" : ""
      }${!!className ? ` ${className}` : ""}${!!disabled ? " disabled" : ""}`}
      onClick={(e) => {
        e.target = myRef.current;
        !!onClick && !!!disabled && onClick(e);
      }}
      id={id}
      ref={myRef}
      onContextMenu={onContextMenu ? onContextMenu : undefined}
      data-value={!!value ? value : undefined}
      style={{ ...style }}
    >
      {children}
    </Stack>
  );

  if (tooltip) {
    if (!!light) {
      return (
        <LightTooltip
          disableInteractive={!tooltipInteractive}
          arrow
          placement={tooltipPlacement ?? "top"}
          title={tooltip}
        >
          {component}
        </LightTooltip>
      );
    } else {
      return (
        <Tooltip
          disableInteractive={!tooltipInteractive}
          arrow
          placement={tooltipPlacement ?? "top"}
          title={tooltip}
        >
          {component}
        </Tooltip>
      );
    }
  } else {
    return component;
  }
}
