import { connect } from "react-redux";
import PreviewSlider from "./PreviewSlider";
import { RootState } from "state/store";
import {
  setThumbnailRegexPattern,
  setThumbnailPoleItemPlacement,
} from "state/actions";

const mapStateToProps = (state: RootState) => {
  return {
    image: state.image?.current ?? null,
    customerId: state.mission?.id ?? -1,
    objectTypes: state.objects.objectTypes,
    bbox: true,
    skyqraft_employee: state.user.skyqraft_employee,
    clusterHidden: !state.ui.clusterVisible,
    clusterMethod: state.imageViewer.clusterMethod,
    distance: state.imageViewer.distance,
    annotatorObjectColor: state.user.annotator_color,
    openedMarkers: state.map.openedMarkers,
    thumbnailRegexPattern: state.image.thumbnailRegexPattern,
    imageSortingMethod: state.imageViewer.imageSortingMethod,
    thumbnailPoleItemPlacement: state.image.thumbnailPoleItemPlacement,
  };
};

const mapDispatchToProps = {
  setThumbnailRegexPattern,
  setThumbnailPoleItemPlacement,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewSlider);
