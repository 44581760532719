import { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Clear, Edit } from "@mui/icons-material";
import { CanvasContext } from "../provider";

export default function EditBoxButton() {
  const { mode, setMode } = useContext(CanvasContext);
  const editMode = mode === "edit";
  return (
    <Tooltip
      title={editMode ? "Leave edit mode" : "Enter edit mode"}
      arrow
      placement="top"
      disableInteractive
    >
      <IconButton onClick={() => setMode(editMode ? "view" : "edit")}>
        {mode === "edit" ? <Clear /> : <Edit />}
      </IconButton>
    </Tooltip>
  );
}
