import { connect } from "react-redux";
import MachineDetectionCategoriesChart from "./MachineDetectionCategoriesChart";
import { RootState } from "state/store";

interface IStateProps {
  truePositive: Array<any>;
  falsePositive: Array<any>;
  falseNegative: Array<any>;
  totalPoles: number;
}

interface IDispatchProps {}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    truePositive: state.statistics.truePositive,
    falsePositive: state.statistics.falsePositive,
    falseNegative: state.statistics.falseNegative,
    totalPoles: state.statistics.totalPoles,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(MachineDetectionCategoriesChart);
