import { useRef } from "react";
import { Matrix } from "transformation-matrix-js";

var getDefaultMat = function getDefaultMat() {
  return Matrix.from(1, 0, 0, 1, -10, -10);
};
function getDistance(pointA, pointB) {
  const xDiff = pointA.x - pointB.x;
  const yDiff = pointA.y - pointB.y;
  const distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
  return distance;
}
export default (function (_ref) {
  var canvasEl = _ref.canvasEl,
    changeMat = _ref.changeMat,
    changeDragging = _ref.changeDragging,
    zoomStart = _ref.zoomStart,
    changeZoomStart = _ref.changeZoomStart,
    changeZoomEnd = _ref.changeZoomEnd,
    layoutParams = _ref.layoutParams,
    zoomWithPrimary = _ref.zoomWithPrimary,
    dragWithPrimary = _ref.dragWithPrimary,
    mat = _ref.mat,
    _onMouseMove = _ref.onMouseMove,
    _onMouseUp = _ref.onMouseUp,
    _onMouseDown = _ref.onMouseDown,
    dragging = _ref.dragging,
    createWithPrimary = _ref.createWithPrimary,
    zoomSpeed = _ref.zoomSpeed;
  var mousePosition = useRef({
    x: 0,
    y: 0,
  });
  var prevMousePosition = useRef({
    x: 0,
    y: 0,
  });
  var extraTouchPosition = useRef({
    x: 0,
    y: 0,
  });
  var prevExtraTouchPosition = useRef({
    x: 0,
    y: 0,
  });

  var zoomIn = function zoomIn(direction, point) {
    var _ref2 = [point.x, point.y],
      mx = _ref2[0],
      my = _ref2[1];
    var scale =
      typeof direction === "object"
        ? direction.to / mat.a
        : 1 + (zoomSpeed / 100) * direction; // NOTE: We're mutating mat here

    mat.translate(mx, my).scaleU(scale);
    if (mat.a > 2) mat.scaleU(2 / mat.a);
    if (mat.a < 0.05) mat.scaleU(0.05 / mat.a);
    mat.translate(-mx, -my);
    changeMat(mat.clone());
  };

  var mouseEvents = {
    onMouseMove: function onMouseMove(e) {
      var _canvasEl$current$get = canvasEl.current.getBoundingClientRect(),
        left = _canvasEl$current$get.left,
        top = _canvasEl$current$get.top;

      prevMousePosition.current.x = mousePosition.current.x;
      prevMousePosition.current.y = mousePosition.current.y;
      mousePosition.current.x = e.clientX - left;
      mousePosition.current.y = e.clientY - top;
      var projMouse = mat.applyToPoint(
        mousePosition.current.x,
        mousePosition.current.y
      );

      if (zoomWithPrimary && zoomStart) {
        changeZoomEnd(projMouse);
      }

      var _layoutParams$current = layoutParams.current,
        iw = _layoutParams$current.iw,
        ih = _layoutParams$current.ih;

      _onMouseMove({
        x: projMouse.x / iw,
        y: projMouse.y / ih,
        mouseIsDown: !!e.buttons,
      });

      if (dragging && !createWithPrimary) {
        mat.translate(
          prevMousePosition.current.x - mousePosition.current.x,
          prevMousePosition.current.y - mousePosition.current.y
        );
        changeMat(mat.clone());
      }

      e.preventDefault();
    },
    onMouseDown: function onMouseDown(e) {
      var specialEvent =
        arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      e.preventDefault();

      if (createWithPrimary) {
        changeDragging(false);
      }

      // eslint-disable-next-line
      if (e.button === 1 || (e.button === 0 && dragWithPrimary)) {
        return changeDragging(true);
      }

      var projMouse = mat.applyToPoint(
        mousePosition.current.x,
        mousePosition.current.y
      );

      if (zoomWithPrimary && e.button === 0) {
        changeZoomStart(projMouse);
        changeZoomEnd(projMouse);
        return;
      }

      if (e.button === 0) {
        if (specialEvent.type === "resize-box") {
          // onResizeBox()
        }

        if (specialEvent.type === "move-region") {
          // onResizeBox()
        }

        var _layoutParams$current2 = layoutParams.current,
          iw = _layoutParams$current2.iw,
          ih = _layoutParams$current2.ih;
      }
    },
    onTouchMove: function onTouchMove(e) {
      e.preventDefault();
      e.stopPropagation();
      var _canvasEl$current$get = canvasEl.current.getBoundingClientRect(),
        left = _canvasEl$current$get.left,
        top = _canvasEl$current$get.top;
      if (e.touches.length === 1) {
        if (e.target.classList.value === "colorIndicator") {
          return;
        }

        prevMousePosition.current.x = mousePosition.current.x;
        prevMousePosition.current.y = mousePosition.current.y;
        mousePosition.current.x = e.touches[0].clientX - left;
        mousePosition.current.y = e.touches[0].clientY - top;
        var projMouse = mat.applyToPoint(
          mousePosition.current.x,
          mousePosition.current.y
        );

        let _layoutParams$current = layoutParams.current;
        let iw = _layoutParams$current?.iw || 1;
        let ih = _layoutParams$current?.ih || 1;

        _onMouseMove({
          x: projMouse.x / iw,
          y: projMouse.y / ih,
        });

        mat.translate(
          prevMousePosition.current.x - mousePosition.current.x,
          prevMousePosition.current.y - mousePosition.current.y
        );
        changeMat(mat.clone());
      } else if (e.touches.length === 2) {
        prevMousePosition.current.x = mousePosition.current.x;
        prevMousePosition.current.y = mousePosition.current.y;
        mousePosition.current.x = e.touches[0].clientX - left;
        mousePosition.current.y = e.touches[0].clientY - top;

        prevExtraTouchPosition.current.x = extraTouchPosition.current.x;
        prevExtraTouchPosition.current.y = extraTouchPosition.current.y;
        extraTouchPosition.current.x = e.touches[1].clientX - left;
        extraTouchPosition.current.y = e.touches[1].clientY - top;

        const distanceOld = getDistance(
          prevMousePosition.current,
          prevExtraTouchPosition.current
        );
        const distanceNew = getDistance(
          mousePosition.current,
          extraTouchPosition.current
        );
        const center = {
          x: (mousePosition.current.x + extraTouchPosition.current.x) / 2,
          y: (mousePosition.current.y + extraTouchPosition.current.y) / 2,
        };
        if (distanceOld > distanceNew) {
          zoomIn(1, center);
        } else {
          zoomIn(-1, center);
        }
      }
    },
    onTouchStart: function onTouchStart(e) {
      e.preventDefault();
      e.stopPropagation();
      var _canvasEl$current$get = canvasEl.current.getBoundingClientRect(),
        left = _canvasEl$current$get.left,
        top = _canvasEl$current$get.top;
      if (e.touches.length === 1) {
        // Handle single touch
        mousePosition.current.x = e.touches[0].clientX - left;
        mousePosition.current.y = e.touches[0].clientY - top;

        var projMouse = mat.applyToPoint(
          mousePosition.current.x,
          mousePosition.current.y
        );

        let _layoutParams$current2 = layoutParams.current;
        let iw = _layoutParams$current2?.iw || 1;
        let ih = _layoutParams$current2?.ih || 1;

        _onMouseDown({
          x: projMouse.x / iw,
          y: projMouse.y / ih,
        });
      } else if (e.touches.length === 2) {
        mousePosition.current.x = e.touches[0].clientX - left;
        mousePosition.current.y = e.touches[0].clientY - top;
        prevMousePosition.current.x = e.touches[0].clientX - left;
        prevMousePosition.current.y = e.touches[0].clientY - top;
        extraTouchPosition.current.x = e.touches[0].clientX - left;
        extraTouchPosition.current.y = e.touches[0].clientY - top;
        prevExtraTouchPosition.current.x = e.touches[0].clientX - left;
        prevExtraTouchPosition.current.y = e.touches[0].clientY - top;
      }
    },
    onTouchEnd: function onTouchEnd(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.touches.length === 0) {
        prevMousePosition.current = { x: 0, y: 0 };
        prevExtraTouchPosition.current = { x: 0, y: 0 };
        mousePosition.current = { x: 0, y: 0 };
        extraTouchPosition.current = { x: 0, y: 0 };
      } else if (e.touches.length === 1) {
        const currentPoint = {
          x: e.touches[0].pageX,
          y: e.touches[0].pageY,
        };

        const distanceA = getDistance(currentPoint, mousePosition);
        const distanceB = getDistance(currentPoint, extraTouchPosition);
        if (distanceA > distanceB) {
          prevExtraTouchPosition.current = { x: 0, y: 0 };
          extraTouchPosition.current = { x: 0, y: 0 };
        } else {
          prevMousePosition.current = prevExtraTouchPosition.current;
          mousePosition.current = extraTouchPosition.current;
          prevExtraTouchPosition.current = { x: 0, y: 0 };
          extraTouchPosition.current = { x: 0, y: 0 };
        }
      }
    },
    onMouseUp: function onMouseUp(e) {
      e.preventDefault();
      var projMouse = mat.applyToPoint(
        mousePosition.current.x,
        mousePosition.current.y
      );

      if (zoomStart) {
        var _zoomEnd = projMouse;

        if (
          Math.abs(zoomStart.x - _zoomEnd.x) < 10 &&
          Math.abs(zoomStart.y - _zoomEnd.y) < 10
        ) {
          if (mat.a < 1) {
            zoomIn(
              {
                to: 1,
              },
              mousePosition.current
            );
          } else {
            zoomIn(
              {
                to: 0.25,
              },
              mousePosition.current
            );
          }
        } else {
          var _layoutParams$current3 = layoutParams.current,
            iw = _layoutParams$current3.iw,
            ih = _layoutParams$current3.ih;

          if (zoomStart.x > _zoomEnd.x) {
            var _ref3 = [_zoomEnd.x, zoomStart.x];
            zoomStart.x = _ref3[0];
            _zoomEnd.x = _ref3[1];
          }

          if (zoomStart.y > _zoomEnd.y) {
            var _ref4 = [_zoomEnd.y, zoomStart.y];
            zoomStart.y = _ref4[0];
            _zoomEnd.y = _ref4[1];
          } // The region defined by zoomStart and zoomEnd should be the new transform

          var scale = Math.min(
            (_zoomEnd.x - zoomStart.x) / iw,
            (_zoomEnd.y - zoomStart.y) / ih
          );
          if (scale < 0.05) scale = 0.05;
          if (scale > 10) scale = 10;
          var newMat = getDefaultMat()
            .translate(zoomStart.x, zoomStart.y)
            .scaleU(scale);
          changeMat(newMat.clone());
        }

        changeZoomStart(null);
        changeZoomEnd(null);
      }
      // eslint-disable-next-line
      if (
        e.button === 1 ||
        (e.button === 0 && dragWithPrimary && !createWithPrimary)
      ) {
        return changeDragging(false);
      }

      if (e.button === 0) {
        var _layoutParams$current4 = layoutParams.current,
          _iw = _layoutParams$current4.iw,
          _ih = _layoutParams$current4.ih;

        _onMouseUp({
          x: projMouse.x / _iw,
          y: projMouse.y / _ih,
        });
        changeDragging(false);
      }
    },
    onWheel: function onWheel(e) {
      var direction = e.deltaY > 0 ? 1 : e.deltaY < 0 ? -1 : 0;
      zoomIn(direction, mousePosition.current); // e.preventDefault()
    },
  };
  return {
    mouseEvents: mouseEvents,
    mousePosition: mousePosition,
  };
});
