import RoleWrapper from "components/RoleHOC/wrapper";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { GetApp } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { BASE_URI } from "config";
import { useLanguage } from "hooks";

export default function DownloadImage() {
  const params = useParams();
  const { language } = useLanguage();

  function onClick() {
    const link = document.createElement("a");
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute("download", `image_${params.image}`);
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noreferrer");
    link.href = `${BASE_URI}/image/${params.image}?cid=${params.mission}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <RoleWrapper keyName="downloadImage">
      <MenuItem
        tooltip={translations.ImageViewer.Download[language]}
        onClick={onClick}
        id="image-download"
      >
        <GetApp />
      </MenuItem>
    </RoleWrapper>
  );
}
