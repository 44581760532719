import { Mission } from "interfaces";

import { Box, Chip, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { getProjectName } from "utils/utils";
import { useSidebar } from "hooks";

type Props = {
  mission: Mission;
  showDatesBeforeProjectName: boolean;
  inRegion?: boolean;
};

export default function MissionItem({ mission, inRegion }: Props) {
  const { closeSidebar } = useSidebar();
  const getProjectNameResult = getProjectName(mission?.id);

  return (
    <Link
      onClick={closeSidebar}
      to={`${mission.id}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Stack
        direction="row"
        flex={1}
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        py={1}
        sx={{
          "&:hover": {
            backgroundColor: "#F5F5F5",
          },
        }}
      >
        <Stack direction="row" alignItems="center">
          <Box
            sx={{
              height: "50%",
              width: 16,
              borderBottom: inRegion ? "1px solid #E3E3E3" : "none",
              mr: 1,
            }}
          />

          <Typography>{getProjectNameResult.fullname}</Typography>
        </Stack>
        <Chip label={mission?.deleted ? "Deleted" : mission?.status.name} />
      </Stack>
    </Link>
  );
}
