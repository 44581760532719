import { IReviewObjectList } from "state/reducers/objectreducer";
import { workflowStatuses, severities } from "constants/imageReview";
import type { IAnnotation } from "views/AnnotationTool/api";

/**
 * Returns the image id at the index or `undefined` if the list is empty.
 */
export function getWrappedEntryInReviewList(
  list: IReviewObjectList,
  index: number
) {
  // Wrap the index to simplify moving to the next and previous at the ends.
  // If you are at the end of the review list, this makes sure you are put back at the start if you try to go the next.
  // as well as the reverse at the start.
  // I.e in a list with 3 entries. Trying to go to index 3 puts you at 3 % 3 == 0.
  // Trying to go to -1 puts you at (-1 + 3) % 3 == 2.
  const wrappedIndex = (index + list.imageIds.length) % list.imageIds.length;

  const imageIdAtIndex = list.imageIds[wrappedIndex];

  return imageIdAtIndex;
}

export function updateMatchingAnnotation(args: {
  annotations: IAnnotation[];
  typeId: number;
  severity?: (typeof severities)[keyof typeof severities];
  workflow_status?: (typeof workflowStatuses)[keyof typeof workflowStatuses];
  type?: number;
  skyqraft_hidden?: boolean;
}) {
  const { annotations, typeId, type } = args;

  return annotations.map((annotation) => {
    if (!annotation.type_id.includes(typeId)) {
      return annotation;
    }
    const typeIdIndex = annotation.type_id.findIndex((id) => id === typeId);

    const updateArrayAtIndex = (array, newValue) =>
      newValue !== undefined
        ? array.map((item, index) => (index === typeIdIndex ? newValue : item))
        : array;

    const severities = updateArrayAtIndex(annotation.severities, args.severity);
    const workflow_status = updateArrayAtIndex(
      annotation.workflow_status,
      args.workflow_status
    );
    const types = updateArrayAtIndex(annotation.types, type);
    const skyqraft_hidden = updateArrayAtIndex(
      annotation.skyqraft_hidden,
      args.skyqraft_hidden
    );

    return {
      ...annotation,
      severities,
      workflow_status,
      types,
      skyqraft_hidden,
    };
  });
}
