import {
  authorizedGet,
  authorizedPost,
  authorizedDelete,
  authorizedPut,
} from "utils/request";
import { setImageList } from "state/actions";
import { RootState } from "state/store";

export function setDetectionDefectToggle(value: string) {
  return {
    type: "SET_DETECTION_DEFECT_TOGGLE",
    payload: value,
  };
}

export function getLists(missionID: number) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const projects = state.user.missions;
    const project = projects.find((p) => p.id === missionID);
    if (!project) {
      return;
    }
    const header: Record<string, string> = {};
    if (missionID) {
      header.MissionID = `${missionID}`;
    }

    const response = await authorizedGet<{ lists }>(
      "/list/all",
      header,
      {},
      true
    );
    if (response) {
      dispatch(setImageList(response.lists));
    }
  };
}

export function addToList(projectID: number, listId: number, imageId: number) {
  return async (dispatch) => {
    await authorizedPost(`/list/${listId}/add`, { imageId });
    dispatch(getLists(projectID));
  };
}

export function removeFromList(
  projectID: number,
  listId: number,
  imageId: number
) {
  return async (dispatch) => {
    await authorizedDelete(`/list/${listId}/${imageId}/remove`);
    dispatch(getLists(projectID));
  };
}

export function createList(projectID: number, listName: string, publicList) {
  return async (dispatch) => {
    await authorizedPost("/list/create", {
      listName,
      public: publicList,
    });
    dispatch(getLists(projectID));
  };
}

export function deleteList(
  projectID: number,
  listId: number,
  callback: () => void = null
) {
  return async (dispatch) => {
    await authorizedDelete(`/list/${listId}/remove`);
    dispatch(getLists(projectID));
    callback?.();
  };
}

export function updateList(projectID: number, listId: number, value) {
  return async (dispatch) => {
    await authorizedPut(`/list/${listId}/update`, value);
    dispatch(getLists(projectID));
  };
}
