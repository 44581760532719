import { useState, useEffect } from "react";
import { authorizedGet } from "utils/request";

interface IUser {
  id: number;
  name: string;
  email: string;
}

/**
 * A React hook that collects a specfic user as admin
 *
 *  @example
 *  ```javascript
 *  const [users, updateUsers, loading] = useUserAdmin();
 *  ```
 */
export function useUserAdmin(): [IUser[], () => void, boolean] {
  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const endpoint = "/admin/user";

  function collectUsers() {
    setLoading(true);
    authorizedGet<{ users: IUser[] }>(endpoint)
      .then((response) => {
        setLoading(false);
        setUsers(response.users);
      })
      .catch((err) => {
        setLoading(false);
        setUsers([]);
        console.error("Failed to collect responsible annotator stats", err);
      });
  }

  useEffect(collectUsers, []);

  return [users, collectUsers, loading];
}
