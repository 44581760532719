interface ICopyLinkToClipboard {
  protocol?: string;
  origin?: string;
  pathname?: string;
  query?: URLSearchParams;
}

export function copyLinkToClipboard(props: ICopyLinkToClipboard) {
  const {
    origin = window.location.origin,
    pathname = "",
    query = new URLSearchParams(),
  } = props;

  const url = new URL(`${origin}${pathname}`);
  query.forEach((value, key) => {
    url.searchParams.append(key, value);
  });

  navigator.clipboard.writeText(url.toString());
}
