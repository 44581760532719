import { createContext } from "react";
import { IMatrix, IStartPoint } from "../../types";

import { compose, scale } from "transformation-matrix";

const defaultMatrix = compose(scale(1.2, 1.2));

export interface IContextValue {
  mode: "add" | "edit" | "view";
  setMode: (mode: "add" | "edit" | "view") => void;
  matrix: IMatrix;
  setMatrix: (matrix: IMatrix) => void;
  selectedAnnotation: number | null;
  setSelectedAnnotation: (selectedAnnotation: number | null) => void;
}

// Set up a context that is importable
const context = createContext<IContextValue>({
  mode: "view",
  setMode: () => {},
  matrix: defaultMatrix,
  setMatrix: () => {},
  selectedAnnotation: null,
  setSelectedAnnotation: () => {},
});

export { context };
