import { Add } from "@mui/icons-material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Button, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { EventHint, captureException } from "@sentry/react";
import { Accordion } from "components";
import { IProject } from "hooks";
import { useLidarLinkableProjects } from "hooks/projectAdmin/useLidarLinkableProjects";
import { useLinkedLidarProjects } from "hooks/projectAdmin/useLinkedLidarProjects";
import { useState } from "react";
import { useOutletContext, useParams } from "react-router";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { axiosInstance } from "utils/request";

export default function LidarSymLinks() {
  const params = useParams();

  const { projects } = useOutletContext<{
    projects: IProject[];
  }>();

  const currentProjectID = parseInt(params.project);
  const currentProject = projects.find((p) => p.id === currentProjectID);

  const [linkableProjects, updateLinkableProjects, loadingLinkableProjects] =
    useLidarLinkableProjects(currentProjectID);
  const [selectedLinkableLidar, setSelectedLinkableLidar] = useState<
    number | null
  >();

  const [linkedProjects, getLinkedProjects] =
    useLinkedLidarProjects(currentProjectID);

  async function linkLidarProject() {
    const targetProject = linkableProjects.find(
      (project) => project.id === selectedLinkableLidar
    );
    if (!targetProject) {
      return;
    }

    if (
      window.confirm(
        `Are you sure you want to link all lidar files from project "${targetProject.name}" to "${currentProject.name}"`
      )
    ) {
      try {
        const response = await axiosInstance.put<{ links_added: number }>(
          `/lidar/link_project/${currentProject.id}/${selectedLinkableLidar}`
        );

        toast.success(`Linked ${response.data.links_added} scenes to this project.`);
        updateLinkableProjects();
        getLinkedProjects();
      } catch (error) {
        // Capture the exception
        const exceptionHint: EventHint = {
          event_id:
            "projectadministration.projectpage.lidarsynlinks.linkLidarProject",
          originalException: error,
          data: {
            projectID: currentProject.id,
          },
        };

        captureException(error, exceptionHint);
        toast.error("Failed to link project.");
      }
    }
  }

  async function unlinkLidarProject(project_id: number) {
    const targetProject = projects.find(
      (project) => project.id === project_id
    );
    if (!targetProject) {
      return;
    }

    if (
      window.confirm(`Are you sure you want unlink all scenes from ${targetProject.name} - ${targetProject.year} (ID: ${targetProject.id})?`)
    ) {
      try {
        const response = await axiosInstance.put<{ unlinked_scenes: number }>(
          `/lidar/unlink_project/${currentProject.id}/${project_id}`
        );

        toast.success(`Unlinked ${response.data.unlinked_scenes} scenes from this project.`);
        updateLinkableProjects();
        getLinkedProjects();
      } catch (error) {
        // Capture the exception
        const exceptionHint: EventHint = {
          event_id:
            "projectadministration.projectpage.lidarsynlinks.unlinkLidarProject",
          originalException: error,
          data: {
            projectID: currentProject.id,
          },
        };

        captureException(error, exceptionHint);
        toast.error("Failed to unlink project.");
      }
    }
  }

  const linkedProjectsComponent = linkedProjects.map((linkedProject) => {
    const project = projects.find(
      (project) => linkedProject.project_id === project.id
    );

    const scenes = linkedProject.scenes.map((scene) => <p>{scene}</p>);
    return (
      <Accordion
        title={`${project.name} - ${project.year} (ID: ${project.id})`}
        description=""
        open={false}
      >
        <Button onClick={() => unlinkLidarProject(project.id)}> <RemoveCircleIcon/> Unlink Project </Button>
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>

        </div>
        {scenes}
      </Accordion>
    );
  });

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography sx={{ mb: 2 }}>Lidar Symbolic Links</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ReactSelect
            styles={{
              // @ts-ignore
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                marginLeft: 8,
              }),
              // @ts-ignore
              control: (provided) => ({
                ...provided,
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderRadius: 0,
                borderColor: "rgba(0, 0, 0, 0.4)",
                marginLeft: 8,
              }),
              // @ts-ignore
              valueContainer: (provided) => ({
                ...provided,
                padding: 0,
              }),
              // @ts-ignore
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
              }),
            }}
            value={linkableProjects
              .map((a) => ({ value: a.id, label: a.name }))
              .find((a) => a.value === selectedLinkableLidar)}
            onChange={(e) => {
              // @ts-ignore
              setSelectedLinkableLidar(e.value);
            }}
            isLoading={loadingLinkableProjects}
            // @ts-ignore
            options={linkableProjects.map((a) => ({
              value: a.id,
              label: `${a.name} - ${a.year} (ID: ${a.id})`,
            }))}
          />
          <Tooltip
            title="Link project."
            style={{ cursor: "pointer", padding: 2 }}
          >
            <Add onClick={() => linkLidarProject()} />
          </Tooltip>
        </div>

        <Typography sx={{ mb: 2 }}>Linked Projects</Typography>
        {linkedProjectsComponent}
      </Stack>
    </Paper>
  );
}
