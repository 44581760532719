import { connect } from "react-redux";
import ObjectTypeManager from "./ObjectTypeManager";
import { RootState } from "state/store";
import { ObjectType, IssueCategory, ObjectCategory } from "interfaces";

interface IStateProps {
  objectTypes: ObjectType[];
  issueCategories: IssueCategory[];
  detectedCategories: IssueCategory[];
  objectCategories: ObjectCategory[];
  language: string;
}

interface IDispatchProps {}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    objectTypes: state.objects.objectTypes.filter(
      (t) => !t.skyqraft_only || state.user.skyqraft_employee
    ),
    issueCategories: state.objects.issueCategories,
    detectedCategories: state.objects.detectedCategories,
    objectCategories: state.objects.objectCategories,
    language: state.user.language,
  };
};

const mapDispatchToProps = {};

// @ts-ignore
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  // @ts-ignore
  mapStateToProps, // @ts-ignore
  mapDispatchToProps // @ts-ignore
)(ObjectTypeManager);
