function getPixelCoordinates(latLng: google.maps.LatLng, map: google.maps.Map) {
  var numTiles = 1 << map.getZoom();
  var projection = map.getProjection();
  var worldCoordinate = projection.fromLatLngToPoint(latLng);
  var pixelCoordinate = new google.maps.Point(
    worldCoordinate.x * numTiles,
    worldCoordinate.y * numTiles
  );

  var topLeft = new google.maps.LatLng(
    map.getBounds().getNorthEast().lat(),
    map.getBounds().getSouthWest().lng()
  );

  var topLeftWorldCoordinate = projection.fromLatLngToPoint(topLeft);
  var topLeftPixelCoordinate = new google.maps.Point(
    topLeftWorldCoordinate.x * numTiles,
    topLeftWorldCoordinate.y * numTiles
  );

  return new google.maps.Point(
    pixelCoordinate.x - topLeftPixelCoordinate.x,
    pixelCoordinate.y - topLeftPixelCoordinate.y
  );
}

function setMapMenu(
  x: number,
  y: number,
  lat: number,
  lng: number,
  visible: boolean,
  hasImages: boolean,
  items: JSX.Element | JSX.Element[]
) {
  return {
    type: "SET_MENU",
    payload: {
      show: visible,
      x: x,
      y: y,
      lat: lat,
      lng: lng,
      hasImages,
      items: items,
    },
  };
}

export function showMenu(
  event,
  hasImages: boolean,
  items: JSX.Element | JSX.Element[],
  map: google.maps.Map
) {
  const { latLng } = event;

  if (latLng) {
    const coords = getPixelCoordinates(latLng, map);
    return setMapMenu(
      coords.x,
      coords.y,
      latLng.lat(),
      latLng.lng(),
      true,
      hasImages,
      items
    );
  }
}
