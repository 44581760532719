import React from "react";
import { MenuItem, Typography, ListItemIcon } from "@mui/material";
import { Done, Clear } from "@mui/icons-material";
import { authorizedPost } from "utils/request";
import {
  featureIsInSelection,
  featureIsPowerline,
  featureIsInFilteredFeedbay,
  assertNewFeatureState,
} from "utils/functions";
import store from "state/store";
import { toast } from "react-toastify";
import { updatePowerlineColor } from "state/actions";
import { useFeedBayFilter } from "hooks/filter/search_parameters/useFeedBayFilter";
import { useFilterPlay } from "hooks/filter/useFilterPlay";

interface IProps {
  selection: google.maps.Rectangle;
  gmap: google.maps.Map;
}

export default function HasImages({ selection, gmap }: IProps) {
  const { feedBayFilter } = useFeedBayFilter();
  const { filterActive } = useFilterPlay();

  function onClick(value) {
    const selectionBounds = selection.getBounds().toJSON();

    // Construct database post request
    const body = {
      hasImages: value,
      ...selectionBounds,
    };

    //Send data to databse
    authorizedPost(`/powerline/hasImages`, body)
      .then(() => {
        let inspectionFeatures = [];
        if (!!!gmap) return;
        gmap.data.forEach((feature) => {
          if (
            featureIsPowerline(feature) &&
            featureIsInSelection(feature, selection) &&
            featureIsInFilteredFeedbay(feature, feedBayFilter, filterActive) &&
            assertNewFeatureState(feature, "hasImages")
          ) {
            inspectionFeatures.push(feature);
          }
        });
        // We use store.dispatch, otherwise we will get circular dependencies.
        store.dispatch(
          // @ts-ignore
          updatePowerlineColor(inspectionFeatures, "hasImages", value, gmap)
        );
      })
      .catch(() => {
        toast.error("Failed to update powerlines");
      });
  }
  return (
    <MenuItem>
      <ListItemIcon
        onClick={() => {
          onClick(true);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Done />
      </ListItemIcon>
      <ListItemIcon
        onClick={() => {
          onClick(false);
        }}
        style={{ minWidth: "auto", marginRight: "10px" }}
      >
        <Clear />
      </ListItemIcon>
      <Typography>Powerline has images</Typography>
    </MenuItem>
  );
}
