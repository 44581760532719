import { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Checkbox,
  Chip,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { IOption } from "./OptionItem";
import { LoadingDots } from "components/LoadingDots";

export interface IOptionCategory extends IOption {
  options: IOption[];
  sorting_order: number;
}

interface IProps {
  category: IOptionCategory;
  onSelect: (option: IOption) => void;
  selected: boolean;
  partiallySelected: boolean;
  children?: React.ReactNode;
  loading?: boolean;
}

export default function CategoryItem({
  category,
  onSelect,
  selected,
  partiallySelected,
  children,
  loading,
}: IProps) {
  const [expanded, setExpanded] = useState(false);
  const childrenCount = category.options.reduce(
    (acc, option) => acc + option.count,
    0
  );
  return (
    <Paper sx={{ borderRadius: 0 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => {
          setExpanded(!expanded);
        }}
        sx={{
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#F7F7F7",
          },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Checkbox
            indeterminate={partiallySelected}
            checked={selected}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onSelect(category);
            }}
          />

          <Chip label={loading ? <LoadingDots /> : childrenCount} />
          <Typography>{category.name}</Typography>
        </Stack>
        <IconButton>
          <ExpandMore
            style={{
              transform: expanded ? "rotate(90deg)" : "rotate(0deg)",
              transition: "transform 0.2s",
            }}
          />
        </IconButton>
      </Stack>
      <Collapse in={expanded}>
        <Stack sx={{ background: "#F0F0F0", p: 0 }}>{children}</Stack>
      </Collapse>
    </Paper>
  );
}
