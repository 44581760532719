import { Edit, Save, Undo } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { EventHint, captureException } from "@sentry/react";
import RoleWrapper from "components/RoleHOC/wrapper";
import {
  IProject,
  useDispatch,
  useGroupAreas,
  useLanguage,
  useSelector,
} from "hooks";
import { useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import ReactSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { getEmailList } from "state/actions";
import translations from "translations";
import {
  authorizedDelete,
  authorizedPut,
  getLambdaAxiosWithAuth,
} from "utils/request";
import { axiosInstance } from "utils/request";
import Loading from "views/Loading";
import EmailList from "../EmailList";
import LidarSymLinks from "./LidarSymLinks";

export default function ProjectPage() {
  const params = useParams();
  const currentGroupID = parseInt(params.group);
  const { projects, regions, updateGroup } = useOutletContext<{
    projects: IProject[];
    updateGroup: Function;
    regions;
  }>();
  const { language } = useLanguage();
  const currentProjectID = parseInt(params.project);
  const currentProject = projects.find((p) => p.id === currentProjectID);
  const [areas, addArea, loading] = useGroupAreas(currentGroupID);
  const [loadingPowerlines, setLoadingPowerlines] = useState<boolean>(false);
  const [loadingPoles, setLoadingPoles] = useState<boolean>(false);

  const dispatch = useDispatch();
  const emailList = useSelector((state) => state.mission.emailList);

  function saveData() {
    let data = {};
    if (name !== currentProject.name) {
      data["name"] = name;
    }
    if (area !== currentProject.area?.id) {
      data["area"] = area;
    }
    if (droneOperator !== currentProject.drone_operator) {
      data["drone_operator"] = droneOperator;
    }
    if (region !== currentProject.region?.id) {
      data["region"] = region;
    }
    if (status !== currentProject.status?.id) {
      data["status"] = status;
    }
    if (contractNumber !== currentProject.contract_number) {
      data["contract_number"] = contractNumber;
    }
    if (imageAnalysis !== currentProject.image_analysis) {
      data["image_analysis"] = imageAnalysis;
    }
    if (corrosionAnalysis !== currentProject.corrosion_analysis) {
      data["corrosion_analysis"] = corrosionAnalysis;
    }
    if (lidarAnalysis !== currentProject.lidar_analysis) {
      data["lidar_analysis"] = lidarAnalysis;
    }
    if (thermalAnalysis !== currentProject.thermal_analysis) {
      data["thermal_analysis"] = thermalAnalysis;
    }
    if (invoiceReference !== currentProject.invoice_reference) {
      data["invoice_reference"] = invoiceReference;
    }
    if (orderComment !== currentProject.order_comment) {
      data["order_comment"] = orderComment;
    }
    if (dateFlight !== currentProject.date_flight) {
      data["date_flight"] = dateFlight;
    }
    if (dateOrderAccepted !== currentProject.date_order_accepted) {
      data["date_order_accepted"] = dateOrderAccepted;
    }
    if (dateOrderPlaced !== currentProject.date_order_placed) {
      data["date_order_placed"] = dateOrderPlaced;
    }
    if (fixableDefects !== currentProject.fixable_defects) {
      data["fixable_defects"] = fixableDefects;
    }
    if (processableDefects !== currentProject.processable_defects) {
      data["processable_defects"] = processableDefects;
    }
    if (deleted !== currentProject.deleted) {
      data["deleted"] = deleted;
    }
    if (timestamp !== currentProject.timestamp) {
      data["timestamp"] = timestamp;
    }
    if (year !== currentProject.year) {
      data["year"] = year;
    }
    if (emailListChanged) {
      const requestURI = `/mission/${currentProjectID}/email_list`;
      try {
        axiosInstance.post(requestURI, { email_list: emailsChosen });
        toast.success(
          translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
            .EmailAddedSuccessfully[language]
        );
        dispatch(getEmailList(currentProjectID));
      } catch (error) {
        const exceptionHint: EventHint = {
          event_id: "actions.EmailList.post.request",
          originalException: error,
        };
        captureException(error, exceptionHint);
        toast.error(
          translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
            .FailedToAddEmail[language]
        );
      }
    }
    if (Object.keys(data).length > 0) {
      // Send the data
      authorizedPut(`/admin/project/${currentProjectID}`, data)
        .then(() => {
          updateGroup();
          toast.success("Project updated");
        })
        .catch(() => {
          toast.error("Failed to update project");
        });
    } else if (!emailListChanged) {
      toast.error("No changes found");
    }
  }

  function resetData() {
    setName(currentProject.name);
    setRegion(currentProject.region?.id);
    setStatus(currentProject.status?.id);
    setArea(currentProject.area?.id);
    setDroneOperator(currentProject.drone_operator);
    setInvoiceReference(currentProject.invoice_reference);
    setDroneOperator(currentProject.drone_operator);
    setContractNumber(currentProject.contract_number);
    setImageAnalysis(currentProject.image_analysis);
    setCorrosionAnalysis(currentProject.corrosion_analysis);
    setLidarAnalysis(currentProject.lidar_analysis);
    setThermalAnalysis(currentProject.thermal_analysis);
    setOrderComment(currentProject.order_comment);
    setDateFlight(
      new Date(currentProject.date_flight).toISOString().split("T")[0]
    );
    setDateOrderAccepted(
      new Date(currentProject.date_order_accepted).toISOString().split("T")[0]
    );
    setDateOrderPlaced(
      new Date(currentProject.date_order_placed).toISOString().split("T")[0]
    );
    setFixableDefects(currentProject.fixable_defects);
    setProcessableDefects(currentProject.processable_defects);
    setDeleted(currentProject.deleted);
    setTimestamp(currentProject.timestamp);
    setYear(currentProject.year);
    setEmailsChosen(emailList.map((email) => email.id));
  }

  useEffect(() => {
    resetData();
    dispatch(getEmailList(currentProjectID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectID, currentProject]);

  useEffect(() => {
    const emailListIds = emailList.map((email) => email.id);
    setEmailsChosen(emailListIds);
  }, [emailList]);

  const [name, setName] = useState<string>(currentProject.name);
  const [droneOperator, setDroneOperator] = useState<string>(
    currentProject.drone_operator
  );
  const [region, setRegion] = useState<number | null>(
    currentProject.region?.id
  );
  const [status, setStatus] = useState<number | null>(
    currentProject.status?.id
  );
  const [area, setArea] = useState<number | null>(currentProject.area?.id);
  const [contractNumber, setContractNumber] = useState<string>(
    currentProject.contract_number
  );
  const [imageAnalysis, setImageAnalysis] = useState<boolean>(
    currentProject.image_analysis
  );
  const [corrosionAnalysis, setCorrosionAnalysis] = useState<boolean>(
    currentProject.corrosion_analysis
  );
  const [lidarAnalysis, setLidarAnalysis] = useState<boolean>(
    currentProject.lidar_analysis
  );
  const [thermalAnalysis, setThermalAnalysis] = useState<boolean>(
    currentProject.thermal_analysis
  );
  const [invoiceReference, setInvoiceReference] = useState<string>(
    currentProject.invoice_reference
  );
  const [orderComment, setOrderComment] = useState<string>(
    currentProject.order_comment
  );

  const [dateFlight, setDateFlight] = useState<string>(
    new Date(currentProject.date_flight).toISOString().split("T")[0]
  );
  const [dateOrderAccepted, setDateOrderAccepted] = useState<string>(
    new Date(currentProject.date_order_accepted).toISOString().split("T")[0]
  );
  const [dateOrderPlaced, setDateOrderPlaced] = useState<string>(
    new Date(currentProject.date_order_placed).toISOString().split("T")[0]
  );
  const [fixableDefects, setFixableDefects] = useState<boolean>(
    currentProject.fixable_defects
  );
  const [processableDefects, setProcessableDefects] = useState<boolean>(
    currentProject.processable_defects
  );
  const [deleted, setDeleted] = useState<boolean>(currentProject.deleted);
  const [timestamp, setTimestamp] = useState<string>(currentProject.timestamp);
  const [year, setYear] = useState<number>(currentProject.year);

  const [emailsChosen, setEmailsChosen] = useState<number[]>([]);

  const emailListChanged = useMemo(() => {
    const emailIds = emailList.map((email) => email.id);
    if (emailsChosen.length !== emailIds.length) {
      return true;
    }
    const chosenInEmailIds = emailsChosen.every((id) => emailIds.includes(id));
    const emailIdsInChosen = emailIds.every((id) => emailsChosen.includes(id));
    return !(chosenInEmailIds && emailIdsInChosen);
  }, [emailList, emailsChosen]);

  const somethingChanged =
    name !== currentProject.name ||
    droneOperator !== currentProject.drone_operator ||
    region !== currentProject.region?.id ||
    status !== currentProject.status?.id ||
    contractNumber !== currentProject.contract_number ||
    imageAnalysis !== currentProject.image_analysis ||
    corrosionAnalysis !== currentProject.corrosion_analysis ||
    lidarAnalysis !== currentProject.lidar_analysis ||
    area !== currentProject.area?.id ||
    thermalAnalysis !== currentProject.thermal_analysis ||
    invoiceReference !== currentProject.invoice_reference ||
    orderComment !== currentProject.order_comment ||
    dateFlight !== currentProject.date_flight ||
    dateOrderAccepted !== currentProject.date_order_accepted ||
    dateOrderPlaced !== currentProject.date_order_placed ||
    fixableDefects !== currentProject.fixable_defects ||
    processableDefects !== currentProject.processable_defects ||
    deleted !== currentProject.deleted ||
    timestamp !== currentProject.timestamp ||
    year !== currentProject.year ||
    emailListChanged;

  async function deleteAllPowerlines() {
    if (
      window.confirm(
        "Are you sure you want to delete all powerlines? This action cannot be undone."
      )
    ) {
      setLoadingPowerlines(true);
      const lambdaAxios = await getLambdaAxiosWithAuth();
      const response = await lambdaAxios.delete(
        `/admin/project/${currentProjectID}/powerline`
      );
      if (response.status === 200) {
        toast.success("Powerlines deleted");
        setLoadingPowerlines(false);
      } else {
        toast.error("Failed to delete powerlines");
        setLoadingPowerlines(false);
      }
    }
  }
  function deleteAllPoles() {
    if (
      window.confirm(
        "Are you sure you want to delete all structures? This action cannot be undone."
      )
    ) {
      setLoadingPoles(true);
      authorizedDelete(`/admin/project/${currentProjectID}/pole`)
        .catch(() => {
          toast.error("Failed to delete structures");
        })
        .finally(() => {
          setLoadingPoles(false);
        });
    }
  }

  return (
    <Box overflow="auto">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={2}
      >
        <Typography variant="h5" component="h2">
          Project {currentProject.name}
        </Typography>
        <Button
          onClick={deleteAllPowerlines}
          endIcon={loadingPowerlines ? <Loading relative size="20px" /> : null}
          disabled={loadingPowerlines}
        >
          Delete all powerlines
        </Button>
        <Tooltip title="Deleting all structures has been disabled temporarily">
          <Button
            onClick={deleteAllPoles}
            endIcon={loadingPoles ? <Loading relative size="20px" /> : null}
            disabled
          >
            Delete all structures
          </Button>
        </Tooltip>
        <Stack direction="row" alignItems="center">
          <IconButton onClick={saveData} disabled={!somethingChanged}>
            <Save />
          </IconButton>
          <IconButton onClick={resetData} disabled={!somethingChanged}>
            <Undo />
          </IconButton>
        </Stack>
      </Stack>

      <FormGroup>
        <Stack spacing={2} marginBottom={"50px"}>
          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Typography sx={{ mb: 2 }}>
                {`Basic Properties for project with ID : ${currentProject.id}`}
              </Typography>
              <FormControl>
                <InputLabel shrink htmlFor="name">
                  Name
                </InputLabel>
                <Input
                  name="name"
                  sx={{ ml: 1 }}
                  endAdornment={
                    name === currentProject.name ? undefined : <Edit />
                  }
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <InputLabel shrink htmlFor="year">
                  Year
                </InputLabel>
                <Input
                  value={year}
                  // @ts-ignore
                  onChange={(e) => setYear(e.target.value)}
                  name="year"
                  endAdornment={
                    year === currentProject.year ? undefined : <Edit />
                  }
                  type="number"
                  sx={{ ml: 1 }}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="region" shrink>
                  Region
                </InputLabel>
                <Select
                  name="region"
                  endAdornment={
                    region === currentProject.region?.id ? undefined : <Edit />
                  }
                  sx={{ ml: 1 }}
                  variant="standard"
                  value={region}
                  // @ts-ignore
                  onChange={(e) => setRegion(e.target.value)}
                >
                  <MenuItem value={null}>No region</MenuItem>
                  {regions.map((r, i) => (
                    <MenuItem key={`${i}`} value={r.id}>
                      {r.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="region" shrink>
                  Area
                </InputLabel>

                <ReactSelect
                  styles={{
                    // @ts-ignore
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      marginLeft: 8,
                    }),
                    // @ts-ignore
                    control: (provided) => ({
                      ...provided,
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      borderRadius: 0,
                      borderColor: "rgba(0, 0, 0, 0.4)",
                      marginLeft: 8,
                    }),
                    // @ts-ignore
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: 0,
                    }),
                  }}
                  onCreateOption={(option) => {
                    addArea(option).then((area) => {
                      setArea(area.id);
                    });
                  }}
                  value={areas
                    .map((a) => ({ value: a.id, label: a.name }))
                    .find((a) => a.value === area)}
                  onChange={(e) => {
                    // @ts-ignore
                    setArea(e.value);
                  }}
                  isLoading={loading}
                  // @ts-ignore
                  options={areas.map((a) => ({
                    value: a.id,
                    label: a.name,
                  }))}
                />
              </FormControl>

              <FormControl>
                <InputLabel shrink htmlFor="status">
                  Project status
                </InputLabel>
                <Select
                  name="status"
                  endAdornment={
                    status === currentProject.status?.id ? undefined : <Edit />
                  }
                  sx={{ ml: 1 }}
                  variant="standard"
                  value={status}
                  // @ts-ignore
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={1}> Pending</MenuItem>
                  <MenuItem value={3}> Flying</MenuItem>
                  <MenuItem value={4}> Analysis</MenuItem>
                  <MenuItem value={5}> Report</MenuItem>
                  <MenuItem value={6}> Done</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel shrink htmlFor="drone_operator">
                  Drone operator
                </InputLabel>
                <Input
                  value={droneOperator}
                  onChange={(e) => setDroneOperator(e.target.value)}
                  name="drone_operator"
                  endAdornment={
                    droneOperator ===
                    currentProject.drone_operator ? undefined : (
                      <Edit />
                    )
                  }
                  sx={{ ml: 1 }}
                />
              </FormControl>
              <FormGroup sx={{ pl: 1 }}>
                <FormControlLabel
                  control={<Checkbox checked={imageAnalysis} />}
                  onChange={(e) => setImageAnalysis(!imageAnalysis)}
                  label={`Image Analysis${
                    imageAnalysis === currentProject.image_analysis
                      ? ""
                      : " (updated)"
                  }`}
                />
                <FormControlLabel
                  control={<Checkbox checked={corrosionAnalysis} />}
                  onChange={(e) => setCorrosionAnalysis(!corrosionAnalysis)}
                  label={`Corrosion Analysis ${
                    corrosionAnalysis === currentProject.corrosion_analysis
                      ? ""
                      : " (updated)"
                  }`}
                />
                <FormControlLabel
                  control={<Checkbox checked={lidarAnalysis} />}
                  onChange={(e) => setLidarAnalysis(!lidarAnalysis)}
                  label={`Lidar Analysis ${
                    lidarAnalysis === currentProject.lidar_analysis
                      ? ""
                      : " (updated)"
                  }`}
                />
                <FormControlLabel
                  control={<Checkbox checked={thermalAnalysis} />}
                  onChange={(e) => setThermalAnalysis(!thermalAnalysis)}
                  label={`Thermal Analysis ${
                    thermalAnalysis === currentProject.thermal_analysis
                      ? ""
                      : " (updated)"
                  }`}
                />
              </FormGroup>
            </Stack>
          </Paper>
          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Typography sx={{ mb: 2 }}>Billing properties</Typography>
              <FormControl>
                <InputLabel shrink htmlFor="contract_number">
                  Contract Number
                </InputLabel>
                <Input
                  name="contract_number"
                  sx={{ ml: 1 }}
                  endAdornment={
                    contractNumber ===
                    currentProject.contract_number ? undefined : (
                      <Edit />
                    )
                  }
                  value={contractNumber}
                  onChange={(e) => setContractNumber(e.target.value)}
                />
              </FormControl>

              <FormControl>
                <InputLabel shrink htmlFor="invoice_reference">
                  Invoice Reference
                </InputLabel>
                <Input
                  endAdornment={
                    invoiceReference ===
                    currentProject.invoice_reference ? undefined : (
                      <Edit />
                    )
                  }
                  value={invoiceReference}
                  onChange={(e) => setInvoiceReference(e.target.value)}
                  name="invoice_reference"
                  sx={{ ml: 1 }}
                />
              </FormControl>
              <FormControl>
                <InputLabel shrink htmlFor="order_comment">
                  Order comment
                </InputLabel>
                <Input
                  endAdornment={
                    orderComment ===
                    currentProject.order_comment ? undefined : (
                      <Edit />
                    )
                  }
                  value={orderComment}
                  onChange={(e) => setOrderComment(e.target.value)}
                  name="order_comment"
                  sx={{ ml: 1 }}
                />
              </FormControl>
            </Stack>
          </Paper>
          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Typography sx={{ mb: 2 }}>Date properties</Typography>

              <FormControl>
                <InputLabel htmlFor="date_flight" shrink>
                  Date of flight
                </InputLabel>
                <Input
                  type="date"
                  value={dateFlight}
                  onChange={(e) => setDateFlight(e.target.value)}
                  name="date_flight"
                  sx={{ ml: 1 }}
                  endAdornment={
                    dateFlight === currentProject.date_flight ? undefined : (
                      <Edit />
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="date_order_accepted" shrink>
                  Date order was accepted
                </InputLabel>
                <Input
                  value={dateOrderAccepted}
                  onChange={(e) => setDateOrderAccepted(e.target.value)}
                  type="date"
                  name="date_order_accepted"
                  sx={{ ml: 1 }}
                  endAdornment={
                    dateOrderAccepted ===
                    currentProject.date_order_accepted ? undefined : (
                      <Edit />
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="date_order_placed" shrink>
                  Date order was placed
                </InputLabel>
                <Input
                  value={dateOrderPlaced}
                  onChange={(e) => setDateOrderPlaced(e.target.value)}
                  type="date"
                  name="date_order_placed"
                  sx={{ ml: 1 }}
                  endAdornment={
                    dateOrderPlaced ===
                    currentProject.date_order_placed ? undefined : (
                      <Edit />
                    )
                  }
                />
              </FormControl>
            </Stack>
          </Paper>
          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Typography sx={{ mb: 2 }}>Advanced properties</Typography>
              <FormControl>
                <InputLabel htmlFor="project_type" shrink>
                  Project Type
                </InputLabel>
                <Input
                  name="project_type"
                  disabled
                  sx={{ ml: 1 }}
                  value={currentProject.type?.name}
                />
              </FormControl>
              <FormGroup sx={{ pl: 1 }}>
                <FormControlLabel
                  name="fixable_defects"
                  control={<Checkbox checked={fixableDefects} />}
                  onChange={(e) => setFixableDefects(!fixableDefects)}
                  label={`Fixable Defects ${
                    fixableDefects === currentProject.fixable_defects
                      ? ""
                      : " (updated)"
                  }`}
                />
                <FormControlLabel
                  name="processable_defects"
                  control={<Checkbox checked={processableDefects} />}
                  onChange={(e) => setProcessableDefects(!processableDefects)}
                  label={`Processable defects${
                    processableDefects === currentProject.processable_defects
                      ? ""
                      : " (updated)"
                  }`}
                />
                <FormControlLabel
                  name="deleted"
                  control={<Checkbox checked={deleted} />}
                  onChange={(e) => setDeleted(!deleted)}
                  label={`Deleted ${
                    deleted === currentProject.deleted ? "" : " (updated)"
                  }`}
                />
              </FormGroup>

              <FormControl>
                <InputLabel shrink htmlFor="timestamp">
                  Timestamp
                </InputLabel>
                <Input
                  value={timestamp}
                  type="date"
                  onChange={(e) => setTimestamp(e.target.value)}
                  name="timestamp"
                  endAdornment={
                    timestamp === currentProject.timestamp ? undefined : (
                      <Edit />
                    )
                  }
                  sx={{ ml: 1 }}
                />
              </FormControl>
            </Stack>
          </Paper>

          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Typography sx={{ mb: 2 }}>
                {
                  translations.MissionLanding.OrderProgress.PlanningPhase
                    .EmailList[language]
                }
              </Typography>
              <EmailList
                value={emailsChosen}
                onChange={(emails) => setEmailsChosen(emails)}
                done={false}
                group={currentGroupID}
              />
            </Stack>
          </Paper>

          <RoleWrapper keyName="linkLidarProjects">
            <LidarSymLinks />
          </RoleWrapper>
        </Stack>
      </FormGroup>
    </Box>
  );
}
