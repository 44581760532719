import React from "react";
import { VisibilityOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useBbox } from "hooks";

export default function ToggleBBIcon() {
  const { showBoxes, hideBbox } = useBbox();

  const toggleBoundingBox = () => {
    hideBbox(showBoxes);
  };

  return (
    <Tooltip
      title="Toggle visibility for bounding boxes. Does not change anything on the image"
      placement="bottom"
      arrow
    >
      <VisibilityOutlined
        sx={{ color: "#ffff" }}
        onClick={toggleBoundingBox}
        data-testid="reviewTools.toggleBoundingBox"
      />
    </Tooltip>
  );
}
