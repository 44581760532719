import React, { useRef, useState, useLayoutEffect } from "react";
import { Matrix } from "transformation-matrix-js";
import Crosshairs from "../Crosshairs";
import useWindowSize from "../hooks/use-window-size.js";
import useMouse from "./use-mouse";
import useProjectRegionBox from "./use-project-box";
import useExcludePattern from "../hooks/use-exclude-pattern";
import { useRafState } from "react-use";
import RegionSelectAndTransformBoxes from "../RegionSelectAndTransformBoxes";
import { useEventCallback } from "utils";
import RegionShapes from "../RegionShapes";
import { BASE_URI } from "config";
import SpeedImage from "../SpeedImage";
import "./style.scss";

const getDefaultMat = function getDefaultMat() {
  return Matrix.from(1, 0, 0, 1, -10, -10);
};

export const ImageCanvas = function ImageCanvas(_ref) {
  const regions = _ref.regions;
  const zoomSpeed = _ref.zoomSpeed;
  const image = _ref.image;
  const setSelectedType = _ref.setSelectedType;
  const customerId = _ref.customerId;
  const user = _ref.user;
  const annotatorObjectColor = _ref.annotatorObjectColor;
  const _ref$onMouseMove = _ref.onMouseMove;
  const onMouseMove =
    _ref$onMouseMove === void 0 ? (p) => null : _ref$onMouseMove;
  const _ref$onMouseDown = _ref.onMouseDown;
  const onMouseDown =
    _ref$onMouseDown === void 0 ? (p) => null : _ref$onMouseDown;
  const _ref$onMouseUp = _ref.onMouseUp;
  const onMouseUp = _ref$onMouseUp === void 0 ? (p) => null : _ref$onMouseUp;
  const _ref$dragWithPrimary = _ref.dragWithPrimary;
  const dragWithPrimary =
    _ref$dragWithPrimary === void 0 ? false : _ref$dragWithPrimary;
  const _ref$zoomWithPrimary = _ref.zoomWithPrimary;
  const zoomWithPrimary =
    _ref$zoomWithPrimary === void 0 ? false : _ref$zoomWithPrimary;
  const _ref$createWithPrimar = _ref.createWithPrimary;
  const createWithPrimary =
    _ref$createWithPrimar === void 0 ? false : _ref$createWithPrimar;
  const regionClsList = _ref.regionClsList;
  const issueCategories = _ref.issueCategories;
  const detectedCategories = _ref.detectedCategories;
  const objectTypes = _ref.objectTypes;
  const issueSeverities = _ref.issueSeverities;
  const skyqraft_employee = _ref.skyqraft_employee;
  const showCrosshairs = _ref.showCrosshairs;
  const _ref$showHighlightBox = _ref.showHighlightBox;
  const showHighlightBox =
    _ref$showHighlightBox === void 0 ? true : _ref$showHighlightBox;
  const allowedArea = _ref.allowedArea;
  const onImageOrVideoLoaded = _ref.onImageOrVideoLoaded;
  const onChangeRegion = _ref.onChangeRegion;
  const allRegionsVerified = _ref.allRegionsVerified;
  const showHumanMachine = _ref.showHumanMachine;
  const setShowHumanMachine = _ref.setShowHumanMachine;
  const onBeginBoxTransform = _ref.onBeginBoxTransform;
  const onBeginMovePolygonPoint = _ref.onBeginMovePolygonPoint;
  const onAddPolygonPoint = _ref.onAddPolygonPoint;
  const onSelectRegion = _ref.onSelectRegion;
  const onBeginMovePoint = _ref.onBeginMovePoint;
  const onDeleteRegion = _ref.onDeleteRegion;
  const onChangeVideoTime = _ref.onChangeVideoTime;
  const onChangeVideoPlaying = _ref.onChangeVideoPlaying;

  const canvasEl = useRef(null);
  const layoutParams = useRef({});

  const [dragging, changeDragging] = useRafState(false);

  const [zoomStart, changeZoomStart] = useRafState(null);

  const [zoomEnd, changeZoomEnd] = useRafState(null);

  const [mat, changeMat] = useRafState(getDefaultMat());

  const [quality, setQuality] = useRafState(0);

  const windowSize = useWindowSize();

  const _useMouse = useMouse({
    canvasEl: canvasEl,
    dragging: dragging,
    mat: mat,
    layoutParams: layoutParams,
    changeMat: changeMat,
    zoomStart: zoomStart,
    zoomEnd: zoomEnd,
    changeZoomStart: changeZoomStart,
    changeZoomEnd: changeZoomEnd,
    createWithPrimary: createWithPrimary,
    changeDragging: changeDragging,
    zoomWithPrimary: zoomWithPrimary,
    dragWithPrimary: dragWithPrimary,
    onMouseMove: onMouseMove,
    onMouseDown: onMouseDown,
    onMouseUp: onMouseUp,
    regions: regions,
    onBeginBoxTransform: onBeginBoxTransform,
    zoomSpeed,
  });
  const mouseEvents = _useMouse.mouseEvents;
  const mousePosition = _useMouse.mousePosition;

  const projectRegionBox = useProjectRegionBox({
    layoutParams: layoutParams,
    mat: mat,
  });

  const [imageDimensions, changeImageDimensions] = useState();

  const imageLoaded = Boolean(imageDimensions?.naturalWidth);
  const onVideoOrImageLoaded = useEventCallback((_ref2) => {
    const naturalWidth = _ref2.naturalWidth;
    const naturalHeight = _ref2.naturalHeight;
    const duration = _ref2.duration;
    const dims = {
      naturalWidth: naturalWidth,
      naturalHeight: naturalHeight,
      duration: duration,
    };
    if (onImageOrVideoLoaded) onImageOrVideoLoaded(dims);
    changeImageDimensions(dims); // Redundant update to fix rerendering issues

    setTimeout(() => changeImageDimensions(dims), 10);
  });
  const excludePattern = useExcludePattern();
  const canvas = canvasEl.current;

  if (canvas && imageLoaded) {
    const clientWidth = canvas.clientWidth;
    const clientHeight = canvas.clientHeight;
    const fitScale = Math.max(
      imageDimensions.naturalWidth / (clientWidth - 20),
      imageDimensions.naturalHeight / (clientHeight - 20)
    );

    const _iw = imageDimensions.naturalWidth / fitScale;
    const _ih = imageDimensions.naturalHeight / fitScale;

    layoutParams.current = {
      iw: _iw,
      ih: _ih,
      fitScale: fitScale,
      canvasWidth: clientWidth,
      canvasHeight: clientHeight,
    };
  }

  useLayoutEffect(() => {
    if (!imageDimensions) return;
    const clientWidth = canvas.clientWidth;
    const clientHeight = canvas.clientHeight;
    canvas.width = clientWidth;
    canvas.height = clientHeight;
    const context = canvas.getContext("2d");
    context.save();
    context.transform.apply(
      context,
      Array.from(mat.clone().inverse().toArray())
    );
    const _layoutParams$current = layoutParams.current;
    const iw = _layoutParams$current.iw;
    const ih = _layoutParams$current.ih;

    if (allowedArea) {
      // Pattern to indicate the NOT allowed areas
      const x = allowedArea.x;
      const y = allowedArea.y;
      const w = allowedArea.w;
      const h = allowedArea.h;
      context.save();
      context.globalAlpha = 1;
      const outer = [
        [0, 0],
        [iw, 0],
        [iw, ih],
        [0, ih],
      ];
      const inner = [
        [x * iw, y * ih],
        [x * iw + w * iw, y * ih],
        [x * iw + w * iw, y * ih + h * ih],
        [x * iw, y * ih + h * ih],
      ];
      context.moveTo.apply(context, Array.from(outer[0]));
      for (const p of outer) {
        context.lineTo.apply(context, Array.from(p));
      }
      context.lineTo.apply(context, Array.from(outer[0]));
      context.closePath();
      inner.reverse();
      context.moveTo.apply(context, Array.from(inner[0]));
      for (const p of inner) {
        context.lineTo.apply(context, Array.from(p));
      }
      context.lineTo.apply(context, Array.from(inner[0]));
      context.fillStyle = excludePattern || "#f00";
      context.fill();
      context.restore();
    }

    context.restore();
  });
  const _layoutParams$current2 = layoutParams.current;
  const iw = _layoutParams$current2.iw;
  const ih = _layoutParams$current2.ih;
  const zoomBox =
    !zoomStart || !zoomEnd
      ? null
      : {
          ...mat.clone().inverse().applyToPoint(zoomStart.x, zoomStart.y),

          w: (zoomEnd.x - zoomStart.x) / mat.a,
          h: (zoomEnd.y - zoomStart.y) / mat.d,
        };
  if (zoomBox) {
    if (zoomBox.w < 0) {
      zoomBox.x += zoomBox.w;
      zoomBox.w *= -1;
    }

    if (zoomBox.h < 0) {
      zoomBox.y += zoomBox.h;
      zoomBox.h *= -1;
    }
  }

  const imagePosition = {
    topLeft: mat.clone().inverse().applyToPoint(0, 0),
    bottomRight: mat.clone().inverse().applyToPoint(iw, ih),
  };
  return React.createElement(
    "div",
    {
      style: {
        width: "100%",
        height: "100%",
        maxHeight: "calc(100% - 68px)",
        position: "relative",
        overflow: "hidden",
        cursor: createWithPrimary
          ? "crosshair"
          : dragging
            ? "grabbing"
            : dragWithPrimary
              ? "grab"
              : zoomWithPrimary
                ? mat.a < 1
                  ? "zoom-out"
                  : "zoom-in"
                : undefined,
      },
      onClick: (e) => {
        if (e.target.tagName === "CANVAS" || e.target.tagName === "IMG") {
          setSelectedType(0);
          for (const region of regions) {
            if (region.highlighted) {
              onChangeRegion({
                ...region,
                highlighted: region.isNew || false,
                editingLabels: region.isNew || false,
                isNew: false,
              });
            }
          }
        }
      },
      onMouseDown: (e) => {
        if (e.target.tagName === "CANVAS" || e.target.tagName === "IMG") {
          setSelectedType(0);
          for (const region of regions) {
            if (region.highlighted) {
              onChangeRegion({
                ...region,
                highlighted: region.isNew || false,
                editingLabels: region.isNew || false,
                isNew: false,
              });
            }
          }
        }

        const projMouse = mat.applyToPoint(
          mousePosition.current.x,
          mousePosition.current.y
        );
        onMouseDown({
          x: projMouse.x / iw,
          y: projMouse.y / ih,
        });
      },
    },
    showCrosshairs &&
      React.createElement(Crosshairs, {
        key: "crossHairs",
        mousePosition: mousePosition,
      }),
    imageLoaded &&
      !dragging &&
      React.createElement(RegionSelectAndTransformBoxes, {
        key: "regionSelectAndTransformBoxes",
        regions: regions,
        mouseEvents: mouseEvents,
        projectRegionBox: projectRegionBox,
        dragWithPrimary: dragWithPrimary,
        createWithPrimary: createWithPrimary,
        zoomWithPrimary: zoomWithPrimary,
        onBeginMovePoint: onBeginMovePoint,
        onSelectRegion: onSelectRegion,
        layoutParams: layoutParams,
        mat: mat,
        user: user,
        setSelectedType,
        onBeginBoxTransform: onBeginBoxTransform,
        onBeginMovePolygonPoint: onBeginMovePolygonPoint,
        onAddPolygonPoint: onAddPolygonPoint,
        showHighlightBox: showHighlightBox,
        allowedClasses: regionClsList,
        onChange: onChangeRegion,
        onDelete: onDeleteRegion,
        editing: true,
        objectTypes: objectTypes,
        issueCategories: issueCategories,
        detectedCategories: detectedCategories,
        issueSeverities: issueSeverities,
        skyqraft_employee: skyqraft_employee,
      }),

    React.createElement(
      "div",
      Object.assign(
        {
          style: {
            width: "100%",
            height: "100%",
          },
        },
        mouseEvents
      ),
      React.createElement(
        React.Fragment,
        null,
        React.createElement("canvas", {
          style: {
            opacity: 0.25,
          },
          className: "canvas",
          ref: canvasEl,
        }),
        React.createElement(RegionShapes, {
          mat: mat,
          imagePosition: imagePosition,
          regions: regions,
          onChangeRegion: onChangeRegion,
          allRegionsVerified: allRegionsVerified,
          showHumanMachine: showHumanMachine,
          setShowHumanMachine: setShowHumanMachine,
          objectTypes: objectTypes,
          issueCategories: issueCategories,
          annotatorObjectColor: annotatorObjectColor,
        }),
        !!image?.id &&
          React.createElement(SpeedImage, {
            mat: mat,
            setQuality,
            quality,
            dimensions: layoutParams.current,
            imagePosition: imagePosition,
            mouseEvents: mouseEvents,
            onLoad: onVideoOrImageLoaded,
            imageSrc: `${BASE_URI}/image/${image?.id}?cid=${customer}`,
            onChangeVideoTime: onChangeVideoTime,
            onChangeVideoPlaying: onChangeVideoPlaying,
            cdn: image?.cdn,
          })
      )
    ),
    React.createElement(
      "div",
      {
        className: "zoomIndicator",
      },
      `Zoom: ${((1 / mat.a) * 100).toFixed(0)}% - Quality: ${quality}%`
    )
  );
};
export default ImageCanvas;
