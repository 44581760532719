import { ReactElement, useEffect, useState } from "react";
import {
  ArrowCircleDown,
  ArrowCircleUp,
  Refresh,
  Close,
} from "@mui/icons-material";
import translations from "translations";
import {
  Typography,
  Tooltip,
  IconButton,
  Checkbox,
  Pagination,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage, useSelector, useDispatch } from "hooks";
import {
  getPreviousDefectsPoles,
  setPreviousDefectImages,
  setPreviousDefectPoles,
  setSelectedPreviousDefectImage,
} from "state/actions";
import { axiosInstance } from "utils/request";
import { captureException, EventHint } from "@sentry/browser";
import { toast } from "react-toastify";
import "./style.scss";
import PoleIcon from "./PoleIcon";

export default function ListViewPreviousDefects(): ReactElement {
  const { language } = useLanguage();
  const dispatch = useDispatch();

  const previousDefectPoles = useSelector(
    (state) => state.map.previousDefectPoles
  );

  const page = useSelector((state) => state.map.previousDefectPolesPage);
  const total = useSelector(
    (state) => state.map.previousDefectPolesCount.poles
  );
  const pages = useSelector(
    (state) => state.map.previousDefectPolesCount.pages
  );

  //sort primararly by severity and secondarily by pole id
  previousDefectPoles.sort((a, b) => {
    if (a.severity === b.severity) {
      return a.id - b.id;
    }
    return a.severity - b.severity;
  });

  const imageId = useSelector((state) => state.image?.current?.id);
  const gmap = useSelector((state) => state.map.gmap);
  const mapLoading = useSelector((state) => !!state.map.markerController);
  const currentImage = useSelector((state) => state.image.current);
  const polesLoading = useSelector((state) => state.map.polesLoading);

  const [selectedPole, setSelectedPole] = useState(null);
  const [localDefectPoles, setLocalDefectPoles] = useState([
    ...previousDefectPoles,
  ]);
  useEffect(() => {
    setLocalDefectPoles(previousDefectPoles);
  }, [previousDefectPoles]);
  const goToNextImage = () => {
    if (!mapLoading && !polesLoading) {
      if (selectedPole) {
        const index = previousDefectPoles.findIndex(
          (x) => x.id === selectedPole
        );
        if (index !== -1 && previousDefectPoles.length > index + 1) {
          const nextPole = previousDefectPoles[index + 1];
          onPoleClick(nextPole);
        }
      } else {
        onPoleClick(previousDefectPoles[0]);
      }
    }
  };

  const goToPreviousImage = () => {
    if (!mapLoading && !polesLoading) {
      if (selectedPole) {
        const index = previousDefectPoles.findIndex(
          (x) => x.id === selectedPole
        );
        if (index !== -1 && index - 1 >= 0) {
          const previousPole = previousDefectPoles[index - 1];
          onPoleClick(previousPole);
        }
      } else {
        onPoleClick(previousDefectPoles[0]);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 38) {
        // up arrow key
        event.preventDefault();
        goToPreviousImage();
      } else if (event.keyCode === 40) {
        // down arrow key
        event.preventDefault();
        goToNextImage();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      // remove the event listener when the component unmounts
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [imageId, mapLoading, previousDefectPoles]);

  const navigate = useNavigate();
  const params = useParams();
  const missionId: string | number = params.mission;

  const goToImage = (image) => {
    if (image?.id !== undefined) {
      gmap.panTo({ lat: image?.lat, lng: image?.lng });
      gmap.setZoom(18);
      const referenceWidth = 0.25;
      const slit = 1 - referenceWidth;
      const offset = referenceWidth - 0.5;
      const x = offset + slit / 2;
      gmap.panBy(-x * window.innerWidth, 0);
    }
  };

  useEffect(() => {
    if (previousDefectPoles.length > 0) {
      goToImage(currentImage);
    }
  }, [currentImage, previousDefectPoles]);

  const onPoleClick = (pole) => {
    setSelectedPole(pole.id);
    dispatch(setPreviousDefectImages(pole.previous_defect_images));
    dispatch(setSelectedPreviousDefectImage(pole.previous_defect_images[0]));
    if (pole.pole_image) {
      navigate(`/${missionId}/${pole.pole_image}${window.location.search}`);
    }
  };

  const headers: Record<string, string> = {
    MissionID: `${missionId}`,
  };
  async function markPreviousYearAsCopied(id) {
    try {
      await axiosInstance.put(
        `/poles/${id}`,
        { previous_defect_images_copied: true },
        { headers }
      );
      const updatedPoles = localDefectPoles.map((pole) =>
        pole.id === id ? { ...pole, previous_year_defects_copied: true } : pole
      );
      setLocalDefectPoles(updatedPoles);
    } catch (error) {
      const exceptionHint: EventHint = {
        event_id: "actions.pole.markPreviousYearAsCopied.request",
        originalException: error,
      };
      captureException(error, exceptionHint);
      toast.error("Failed to mark pervious years as copied.");
    }
  }

  return (
    <>
      <div className="listViewWrapper">
        <div className="poleImageTitleContainer">
          <Typography
            sx={{ color: "gray", fontSize: "14px", fontWeight: "bold" }}
          >
            {translations.PreviousDefectsListView[language]}
          </Typography>
        </div>
        <div className="poleImageButtonContainer">
          <Tooltip title={"Number of items"} placement="right" arrow>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              {total}{" "}
            </Typography>
          </Tooltip>

          <Tooltip
            title={"Refresh list to remove handled items"}
            placement="right"
            arrow
          >
            <IconButton
              aria-label="refresh"
              onClick={() => dispatch(getPreviousDefectsPoles(page, true))}
            >
              <Refresh />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={translations.ListView.GoToPreviousImage[language]}
            placement="right"
            arrow
          >
            <IconButton
              onClick={goToPreviousImage}
              disabled={mapLoading || polesLoading}
            >
              <ArrowCircleUp />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={translations.ListView.GoToNextImage[language]}
            placement="right"
            arrow
          >
            <IconButton
              onClick={goToNextImage}
              disabled={mapLoading || polesLoading}
            >
              <ArrowCircleDown />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={"Exit the previous year defect list"}
            placement="right"
            arrow
          >
            <IconButton
              aria-label="exit"
              onClick={() => {
                dispatch(setPreviousDefectPoles([]));
                dispatch(setPreviousDefectImages([]));
                dispatch(setSelectedPreviousDefectImage(null));
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </div>

        <div className="list">
          {localDefectPoles.map((pole) => (
            <div
              key={pole.id}
              className="listChild"
              style={{
                backgroundColor: pole.pole_image ? "" : "gray",
                borderRadius: "8px",
              }}
            >
              <Tooltip
                title={"No image connected to this structure for this project"}
                placement="right"
                arrow
                disableHoverListener={!!pole.pole_image}
              >
                <div
                  className="listViewPoleItem"
                  style={{
                    backgroundColor: pole.id === selectedPole && "#EDB97A",
                  }}
                  onMouseUp={() => pole.pole_image && onPoleClick(pole)}
                >
                  <div className="listItemStart">
                    <PoleIcon />
                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "14px",
                        paddingLeft: "10px",
                      }}
                    >
                      {pole.id}
                    </Typography>
                  </div>
                  <div className="listItemEnd">
                    <Tooltip title={"Severity Level"} placement="right" arrow>
                      <div className="listSeverityCount">{pole?.severity}</div>
                    </Tooltip>
                    <Tooltip title={"Mark as handled"} placement="right" arrow>
                      <Checkbox
                        checked={pole.previous_year_defects_copied}
                        onChange={() => markPreviousYearAsCopied(pole.id)}
                        inputProps={{ "aria-label": "controlled" }}
                        onMouseDown={(e) => e.stopPropagation()}
                        onMouseUp={(e) => e.stopPropagation()}
                      />
                    </Tooltip>
                  </div>
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
        <div className="paginationContainer">
          <div className="paginationWrapper">
            <Pagination
              count={pages}
              page={page}
              disabled={polesLoading}
              onChange={(e, value) => dispatch(getPreviousDefectsPoles(value))}
            />
          </div>
        </div>
      </div>
    </>
  );
}
