import { useContext } from "react";
import { IconButton, Box, TextField, CircularProgress } from "@mui/material";
import { Save } from "@mui/icons-material";
import { AnnotationContext } from "../provider";

import { useSearchParams } from "react-router-dom";

export default function PoleComment() {
  const { updatePoleComment, poleComment, loading } =
    useContext(AnnotationContext);

  const [searchParams] = useSearchParams();
  const poleID = parseInt(searchParams.get("pole"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <TextField
        label="Add comment"
        size="small"
        fullWidth
        value={poleComment}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          updatePoleComment(poleID, event.target.value);
        }}
      />

      <IconButton
        onClick={() => updatePoleComment(poleID, poleComment, false)}
        sx={{ width: "40px" }}
      >
        {loading ? (
          <CircularProgress
            size={20}
            sx={{ marginTop: "2px", marginLeft: "2px" }}
          />
        ) : (
          <Save />
        )}
      </IconButton>
    </Box>
  );
}
