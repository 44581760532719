import { Fragment } from "react";
import Image from "./Image";
import { getCDNURL } from "authentication/apiDetails";

type Props = {
  quality: "low" | "medium" | "high";
  imagePosition: {
    topLeft: {
      x: number;
      y: number;
    };
    bottomRight: {
      x: number;
      y: number;
    };
  };
  onLoad?: (payload) => void;
  cdn: string | null;
  canvas: HTMLCanvasElement;
};

const imageCount = {
  low: [1, 1],
  medium: [5, 5],
  high: [10, 10],
};

const zIndexes = {
  low: 1,
  medium: 2,
  high: 3,
};

export default function ImageLayer({
  quality,
  imagePosition,
  onLoad,
  cdn,
  canvas,
}: Props) {
  const [xImageCount, yImageCount] = imageCount[quality];
  const zIndex = zIndexes[quality];
  // Extract width and height of image and canvas
  const currentWidth = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const currentHeight = imagePosition.bottomRight.y - imagePosition.topLeft.y;

  if (
    quality === "medium" &&
    (currentWidth < 1200 || currentWidth > 15000 || Number.isNaN(currentWidth))
  ) {
    return <></>;
  }
  if (
    quality === "high" &&
    (currentWidth < 3000 || Number.isNaN(currentWidth))
  ) {
    return <></>;
  }

  const canvasRect = canvas?.getBoundingClientRect();
  const canvasWidth = canvasRect?.width;
  const canvasHeight = canvasRect?.height;

  return (
    <>
      {[...Array(xImageCount)].map((x, i) => {
        const xPercent = 1 / xImageCount;
        return (
          <Fragment key={`row-${i}`}>
            {[...Array(yImageCount)].map((y, j) => {
              const yPercent = 1 / yImageCount;
              const topLeft = {
                x: imagePosition.topLeft.x + i * xPercent * currentWidth,
                y: imagePosition.topLeft.y + j * xPercent * currentHeight,
              };
              const bottomRight = {
                x: imagePosition.topLeft.x + (i + 1) * yPercent * currentWidth,
                y: imagePosition.topLeft.y + (j + 1) * yPercent * currentHeight,
              };
              const isInView =
                topLeft.x >= -xPercent * currentWidth &&
                topLeft.y >= -yPercent * currentHeight &&
                bottomRight.x <= canvasWidth + xPercent * currentWidth &&
                bottomRight.y <= canvasHeight + yPercent * currentHeight;
              if (!isInView && quality !== "low") {
                return null;
              }

              const base = `${getCDNURL()}/image`;
              const cdn_components = cdn.split(".");
              const extension = cdn_components.pop();
              const path = cdn_components.join(".");
              const link = encodeURI(
                `${base}/${path}_${quality}_${i}_${j}.${extension}`
              );
              return (
                <Image
                  key={`${i}_${j}_${quality}`}
                  zIndex={zIndex}
                  imagePosition={{ topLeft, bottomRight }}
                  imageSrc={link}
                  onLoad={onLoad}
                />
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
}
