import { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AnnotationContext } from "../provider";

export default function ToggleVisibilityButton() {
  const { setAnnotations, annotations } = useContext(AnnotationContext);

  const setVisibility = (value: boolean) => {
    let newAnnotations = annotations.map((annotation) => {
      annotation.visible = value;
      return annotation;
    });
    setAnnotations(newAnnotations);
  };

  const checkVisibility = () => {
    let visible = true;
    annotations.map((annotation) => {
      if (annotation.visible === true) {
        visible = false;
      }
    });
    return visible;
  };

  return (
    <Tooltip
      title={checkVisibility() ? "Show all" : "Hide all"}
      arrow
      placement="top"
      disableInteractive
    >
      <IconButton
        onClick={() => setVisibility(checkVisibility())}
        style={{ width: "42px", height: "42px" }}
      >
        {checkVisibility() ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </Tooltip>
  );
}
