import { useState } from "react";
import { toast } from "react-toastify";
import { EventHint, captureException } from "@sentry/react";
import { axiosInstance } from "utils/request";
import { reviewModes } from "constants/imageReview";
import type { IAnnotation } from "views/AnnotationTool/api";

interface IGetAnnotations {
  imageId: number;
  reviewModeId: (typeof reviewModes)[keyof typeof reviewModes];
  projectId: number;
  params?: {
    severities?: string;
    types?: string;
  };
}
interface IUseGetReviewAnnotations {
  getAnnotations: (data: IGetAnnotations) => Promise<IAnnotation[]>;
  fetchingAnnotations: boolean;
}
export default function useGetReviewAnnotations(): IUseGetReviewAnnotations {
  const [fetchingAnnotations, setFetchingAnnotations] = useState(false);

  async function getAnnotations(data: IGetAnnotations) {
    setFetchingAnnotations(true);
    const { imageId, reviewModeId, projectId, params } = data;

    try {
      const { data } = await axiosInstance.get(
        `/image/${imageId}/review/${reviewModeId}/annotations`,
        {
          headers: { MissionID: projectId },
          params,
        }
      );
      return data.annotations;
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "imageReview.getAnnotations.request",
        originalException: error,
        data: {
          projectId,
        },
      };
      captureException(error, exceptionHint);
      toast.error("Could not get annotations to review");
    }
    setFetchingAnnotations(false);
  }

  return {
    getAnnotations,
    fetchingAnnotations,
  };
}
