import React, { useContext } from "react";
import { IGanttItem, context } from "../provider";
import { Box, Chip, Stack } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
type Props = {
  item: IGanttItem;
  startEpoch: number;
  endEpoch: number;
  onClick: (item: IGanttItem) => void;
  width: number;
  selected: boolean;
};

export default function Task({
  item,
  startEpoch,
  endEpoch,
  width,
  onClick,
  selected,
}: Props) {
  const { editing, moveItem } = useContext(context);
  const duration =
    new Date(item.end_date).getTime() - new Date(item.start_date).getTime();
  const epochLength = endEpoch - startEpoch;
  const percentage = duration / epochLength;
  const totalWidth = `${100 * percentage}%`;

  // Check percentage between initial and start dates
  const difference = new Date(item.start_date).getTime() - startEpoch;
  const leftOffset = `${(difference / epochLength) * 100}%`;
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-between"
      spacing={2}
    >
      <Stack
        maxWidth={width}
        minWidth={width}
        width={width}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {editing && (
          <Stack>
            <ArrowDropUp
              onClick={() => {
                moveItem(item, -1);
              }}
              sx={{
                marginBottom: -0.5,
                "&:hover": {
                  boxShadow: "0px 0px 10px rgba(100,100,100,0.4)",
                  cursor: "pointer",
                  borderRadius: "50%",
                },
              }}
            />
            <ArrowDropDown
              onClick={() => {
                moveItem(item, 1);
              }}
              sx={{
                marginTop: -0.5,
                "&:hover": {
                  boxShadow: "0px 0px 10px rgba(100,100,100,0.4)",
                  cursor: "pointer",
                  borderRadius: "50%",
                },
              }}
            />
          </Stack>
        )}
        <Chip
          sx={{ flex: 1 }}
          label={item.name}
          onClick={() => {
            onClick(item);
          }}
        />
      </Stack>
      <div
        style={{
          height: 40,
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            width: leftOffset,
          }}
        />
        <Box
          onClick={() => {
            onClick(item);
          }}
          style={{
            width: totalWidth,
            height: 20,
            borderRadius: 16,
            cursor: "pointer",
          }}
          sx={{
            background: selected ? "#0078EB" : "#339933",
            "&:hover": {
              background: "#55BB55",
            },
            transition: "background 0.1s linear",
          }}
        />
      </div>
    </Stack>
  );
}
