import React, { ReactElement } from "react";
import { Button } from "@mui/material";
import translations from "translations";
import { useLanguage } from "hooks";

interface IProps {
  downloadPowerlineData: (type: "reflight" | "all") => void;
}

export default function KMLDownload({
  downloadPowerlineData,
}: IProps): ReactElement {
  const { language } = useLanguage();
  return (
    <div className="powerline-download-container">
      <h2>{translations.Menu.Tools.Powerlines.Download.Title[language]}</h2>
      <p>{translations.Menu.Tools.Powerlines.Download.Description[language]}</p>
      <br />
      <hr />
      <Button
        onClick={() => downloadPowerlineData("all")}
        variant="contained"
        disabled
        className="primaryButton"
        style={{ marginLeft: 5, textTransform: "none" }}
      >
        {translations.Menu.Tools.Powerlines.Download.Button[language]}
      </Button>
      <Button
        onClick={() => downloadPowerlineData("reflight")}
        variant="contained"
        className="secondaryButton"
        style={{ marginLeft: 5, textTransform: "none" }}
      >
        {translations.Menu.Tools.Powerlines.Download.ButtonReflight[language]}
      </Button>
    </div>
  );
}
