import { useContext, useState, useEffect } from "react";
import { Tooltip, IconButton } from "@mui/material";
import { Warning, FlipToBack, Delete } from "@mui/icons-material";
import { AnnotationContext } from "../../../provider";
import translations from "translations";
import { authorizedGet } from "utils/request";
import { useLanguage } from "hooks";

type Props = {
  annotation;
  user;
  index;
};

interface ObjectHistory {
  created?: boolean;
  origin: string;
  timestamp: string;
  human: boolean;
  changes?: {
    x?: {
      old: number;
      new: number;
    };
    y?: {
      old: number;
      new: number;
    };
    width?: {
      old: number;
      new: number;
    };
    height?: {
      old: number;
      new: number;
    };
    comment?: {
      old: string;
      new: string;
    };
  };
}

export default function TypeConverterButton({
  annotation,
  user,
  index,
}: Props) {
  const { language } = useLanguage();
  const { updateAnnotation, deleteAnnotation } = useContext(AnnotationContext);

  function defectOnly(user) {
    return !(
      user.admin ||
      user.supervisor ||
      user.annotator ||
      user.skyqraft_employee
    );
  }

  const [objectHistory, setObjectHistory] = useState<ObjectHistory[]>([]);

  useEffect(() => {
    if (!annotation.id.includes("n")) {
      authorizedGet<{ object: ObjectHistory[] }>(
        `/object/${annotation.id}/history`
      )
        .then((response) => {
          setObjectHistory(response.object);
        })
        .catch(() => {
          console.error("Fetching history failed");
          setObjectHistory([]);
        });
    }
  }, [annotation.id]);

  const deleteItem = (index) => {
    if (annotation.types.length === 1) {
      deleteAnnotation(annotation.id);
    } else {
      updateAnnotation(annotation.id, {
        ...annotation,
        types: annotation.types.filter((value, i) => i !== index),
        severities: annotation.severities.filter((value, i) => i !== index),
        is_defect: annotation.is_defect.filter((value, i) => i !== index),
        categories: annotation.categories.filter((value, i) => i !== index),
        processed: annotation.processed.filter((value, i) => i !== index),
        type_id: annotation.type_id.filter((value, i) => i !== index),
        workflow_status: annotation.workflow_status.filter(
          (value, i) => i !== index
        ),
        skyqraft_hidden: annotation.skyqraft_hidden.filter(
          (value, i) => i !== index
        ),
        type_comment: annotation.type_comment.filter((value, i) => i !== index),
        editType: undefined,
        editIndex: undefined,
      });
    }
  };

  return (
    <>
      {(!defectOnly(user) || annotation.is_defect[index]) && (
        <Tooltip
          disableInteractive
          title={translations.AnnotationTool.deleteClass[language]}
          placement="top"
        >
          <IconButton
            disabled={
              !objectHistory[0]?.human &&
              annotation.types.length < 2 &&
              !annotation.id.includes("n")
            }
            onClick={() => deleteItem(index)}
            style={{ padding: 0, marginTop: 0, height: "24px", width: "24px" }}
          >
            <Delete
              htmlColor={
                !objectHistory[0]?.human &&
                annotation.types.length < 2 &&
                !annotation.id.includes("n")
                  ? ""
                  : "black"
              }
              sx={{ width: "20px" }}
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
