import styled from "@emotion/styled";
export default styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  left: 0px;
  top: 0px;
  display: grid;
  place-items: center;
`;
