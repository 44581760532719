import { useContext, useRef } from "react";
import ItemColumn from "../ItemColumn";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { context } from "./ComponentOptionsProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddComponent() {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const { createComponent, valueSystems } = useContext(context);

  function onFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name") as string;

    const valueSystemID = formData.get("valueSystem") as string;

    if (!name || name === "") {
      toast.error("The new name has to be at least 1 character long");
      return;
    }

    createComponent(name, parseInt(valueSystemID)).then((newComponentID) => {
      navigate(`/options/pole/status/component/${newComponentID}`);
    });
  }

  return (
    <ItemColumn
      title="Create a new component"
      description="Enter the properties of this component below"
      onSave={() =>
        onFormSubmit({ target: formRef.current, preventDefault: () => {} })
      }
    >
      <form onSubmit={onFormSubmit} ref={formRef}>
        <Stack spacing={2}>
          <FormControl>
            <Typography>Name</Typography>
            <TextField inputRef={nameRef} name="name" variant="standard" />
          </FormControl>
          <FormControl>
            <Typography>Value system</Typography>
            <Select
              name="valueSystem"
              variant="standard"
              fullWidth
              label="Value system"
            >
              {valueSystems.map((system) => (
                <MenuItem value={system.id}>{system.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack mt={2} spacing={1} direction="row">
          <Button type="submit" variant="contained" color="success">
            Create
          </Button>
        </Stack>
      </form>
    </ItemColumn>
  );
}
