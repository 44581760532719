export var colors = [
  "#f44336",
  "#2196f3",
  "#4caf50",
  "#ef6c00",
  "#795548",
  "#689f38",
  "#e91e63",
  "#9c27b0",
  "#3f51b5",
  "#009688",
  "#cddc39",
  "#607d8b",
];
var transparency = 0x88000000;

function reverseParseColor(rrggbb) {
  rrggbb = rrggbb.replace("#", "");
  var bbggrr = rrggbb.substr(4, 2) + rrggbb.substr(2, 2) + rrggbb.substr(0, 2);
  return parseInt(bbggrr, 16);
}

export var colorInts = colors.map(function (c) {
  return (reverseParseColor(c) | transparency) >>> 0;
});
export default colors;
