import React from "react";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { useLanguage } from "hooks";

const KEY = "bbox";
const ENABLED_VALUE = "0";
const DISABLED_VALUE = "1";

export default function BoundingBox() {
  const { language } = useLanguage();
  const [searchParams, setSearchParams] = useSearchParams();
  const toggleBoundingBox = () => {
    if (searchParams.has(KEY)) {
      searchParams.delete(KEY);
    } else {
      searchParams.append(KEY, ENABLED_VALUE);
    }
    setSearchParams(searchParams.toString(), { replace: true });
  };
  const bbox = parseInt(searchParams.get("bbox") ?? DISABLED_VALUE);
  return (
    <MenuItem
      tooltip={translations.ImageViewer.BoundingBoxes[language]}
      onClick={toggleBoundingBox}
      id="image-toggle-bbox"
    >
      {bbox ? <Visibility /> : <VisibilityOff />}
    </MenuItem>
  );
}
