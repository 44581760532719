import { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Clear, Add } from "@mui/icons-material";
import { CanvasContext } from "../provider";

export default function AddBoxButton() {
  const { mode, setMode } = useContext(CanvasContext);
  const addMode = mode === "add";
  return (
    <Tooltip
      title={addMode ? "Leave add mode" : "Enter add mode"}
      arrow
      placement="top"
      disableInteractive
    >
      <IconButton onClick={() => setMode(addMode ? "view" : "add")}>
        {mode === "add" ? <Clear /> : <Add />}
      </IconButton>
    </Tooltip>
  );
}
