import { useContext } from "react";
import { useGesture } from "@use-gesture/react";
import { MutableRefObject } from "react";
import { ISpecialAnnotation } from "../provider/AnnotationProvider/context";
import { AnnotationContext, CanvasContext } from "../provider";
import { convertPositionToPercentage } from "../utils";
import { getCanvasBox } from "./utils";

interface Point {
  x: number;
  y: number;
}

interface Box {
  x: number;
  y: number;
  w: number;
  h: number;
}

function computeRotatedBox(point1: Point, point2: Point, phi: number): Box {
  // Calculate the center point between the two given points
  const centerX = (point1.x + point2.x) / 2;
  const centerY = (point1.y + point2.y) / 2;

  // Calculate the new position of each point after rotation
  const rotatedPoint1 = rotatePoint(point1, { x: centerX, y: centerY }, phi);
  const rotatedPoint2 = rotatePoint(point2, { x: centerX, y: centerY }, phi);

  // Calculate the new position (x, y) of the box
  const minX = Math.min(rotatedPoint1.x, rotatedPoint2.x);
  const minY = Math.min(rotatedPoint1.y, rotatedPoint2.y);
  const maxX = Math.max(rotatedPoint1.x, rotatedPoint2.x);
  const maxY = Math.max(rotatedPoint1.y, rotatedPoint2.y);

  return {
    x: minX,
    y: minY,
    w: maxX - minX,
    h: maxY - minY,
  };
}

type EdgePosition =
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight"
  | "top"
  | "bottom"
  | "left"
  | "right";

interface IProps {
  boxRef: MutableRefObject<HTMLDivElement | null>;
  currentBox: ISpecialAnnotation;
  canvasWidth: number;
  edge: EdgePosition;
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
}

function rotatePoint(
  point: { x: number; y: number },
  center: { x: number; y: number },
  angle: number
) {
  const x = point.x - center.x;
  const y = point.y - center.y;
  const rotatedX = x * Math.cos(angle) - y * Math.sin(angle);
  const rotatedY = x * Math.sin(angle) + y * Math.cos(angle);
  return {
    x: rotatedX + center.x,
    y: rotatedY + center.y,
  };
}

function getOppositePoint(
  edge: EdgePosition,
  currentBox: ISpecialAnnotation,
  imageDimensions: { naturalWidth: number; naturalHeight: number }
): Point {
  switch (edge) {
    case "topLeft":
      return {
        x: (currentBox.x + currentBox.w) * imageDimensions.naturalWidth,
        y: (currentBox.y + currentBox.h) * imageDimensions.naturalHeight,
      };
    case "topRight":
      return {
        x: currentBox.x * imageDimensions.naturalWidth,
        y: (currentBox.y + currentBox.h) * imageDimensions.naturalHeight,
      };
    case "bottomLeft":
      return {
        x: (currentBox.x + currentBox.w) * imageDimensions.naturalWidth,
        y: currentBox.y * imageDimensions.naturalHeight,
      };
    case "bottomRight":
      return {
        x: currentBox.x * imageDimensions.naturalWidth,
        y: currentBox.y * imageDimensions.naturalHeight,
      };
    case "top":
      return {
        x: (currentBox.x + currentBox.w * 0.5) * imageDimensions.naturalWidth,
        y: (currentBox.y + currentBox.h) * imageDimensions.naturalHeight,
      };
    case "bottom":
      return {
        x: (currentBox.x + currentBox.w * 0.5) * imageDimensions.naturalWidth,
        y: currentBox.y * imageDimensions.naturalHeight,
      };
    case "left":
      return {
        x: (currentBox.x + currentBox.w) * imageDimensions.naturalWidth,
        y: (currentBox.y + currentBox.h * 0.5) * imageDimensions.naturalHeight,
      };
    case "right":
      return {
        x: currentBox.x * imageDimensions.naturalWidth,
        y: (currentBox.y + currentBox.h * 0.5) * imageDimensions.naturalHeight,
      };
    default:
      return { x: 0, y: 0 };
  }
}

export function useEdgeListener({
  boxRef,
  currentBox,
  canvasWidth,
  edge,
  canvasRef,
}: IProps) {
  const { updateAnnotation } = useContext(AnnotationContext);
  const { matrix, imageDimensions } = useContext(CanvasContext);

  useGesture(
    {
      onDrag: (state) => {
        // Define what happens when the edge of a box is dragged.
        // Extract the movement from useGesture state
        const angleInRadians = (currentBox.rotation * Math.PI) / 180;
        const boxCenter = {
          x: (currentBox.x + currentBox.w / 2) * imageDimensions.naturalWidth,
          y: (currentBox.y + currentBox.h / 2) * imageDimensions.naturalHeight,
        };
        const { left, top } = getCanvasBox(canvasRef);

        // Define it as a percentage of the canvas with respect to scaling matrix
        const { x, y } = convertPositionToPercentage(
          top,
          left,
          imageDimensions,
          state.xy[0],
          state.xy[1],
          matrix
        );
        const newBox = { ...currentBox };

        const rotatedMousePoint = {
          x: x * imageDimensions.naturalWidth,
          y: y * imageDimensions.naturalHeight,
        };

        const oppositePoint = rotatePoint(
          getOppositePoint(edge, currentBox, imageDimensions),
          boxCenter,
          angleInRadians
        );

        const rotatedBox = computeRotatedBox(
          rotatedMousePoint,
          oppositePoint,
          -angleInRadians
        );

        switch (edge) {
          case "topLeft":
          case "topRight":
          case "bottomLeft":
          case "bottomRight":
            newBox.x = rotatedBox.x / imageDimensions.naturalWidth;
            newBox.y = rotatedBox.y / imageDimensions.naturalHeight;
            newBox.w = rotatedBox.w / imageDimensions.naturalWidth;
            newBox.h = rotatedBox.h / imageDimensions.naturalHeight;
            break;
          case "top":
          case "bottom":
            newBox.y = rotatedBox.y / imageDimensions.naturalHeight;
            newBox.h = rotatedBox.h / imageDimensions.naturalHeight;
            break;
          case "left":
          case "right":
            newBox.x = rotatedBox.x / imageDimensions.naturalWidth;
            newBox.w = rotatedBox.w / imageDimensions.naturalWidth;
            break;
        }

        // Clamp values.
        if (newBox.x < 0) {
          newBox.x = 0;
          // If we don't reset height & width, the box will grow as you hit the edge.
          newBox.w = currentBox.w;
        }
        if (newBox.y < 0) {
          newBox.y = 0;
          newBox.h = currentBox.h;
        }

        if (newBox.w + newBox.x > 1) newBox.w = 1 - newBox.x;
        if (newBox.h + newBox.y > 1) newBox.h = 1 - newBox.y;

        updateAnnotation(currentBox.id, newBox);
      },
      onDragEnd: () => {
        updateAnnotation(currentBox.id, currentBox);
      },
    },
    { target: boxRef }
  );
}
