import { useContext } from "react";
import { useGesture } from "@use-gesture/react";
import { MutableRefObject } from "react";
import { AnnotationContext, CanvasContext } from "../provider";
import { convertChangeToPercentage } from "../utils";
import { IAnnotation } from "../api";

function normalize(value: number) {
  // Make sure the value is between
  // 0 and 1. Only crop the value.
  return Math.min(Math.max(value, 0), 1);
}

interface IProps {
  boxRef: MutableRefObject<HTMLDivElement | null>;
  currentBox: IAnnotation;
  canvasWidth: number;
  edge: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
}

export function useEdgeListener({
  boxRef,
  currentBox,
  canvasWidth,
  edge,
}: IProps) {
  const { updateAnnotation } = useContext(AnnotationContext);
  const { matrix } = useContext(CanvasContext);

  useGesture(
    {
      onDrag: (state) => {
        // Define what happens when the edge of a box is dragged.
        // Extract the movement from useGesture state
        const movementX = state.delta[0];
        const movementY = state.delta[1];

        // Check the change in terms of canvas percentages
        const { dx, dy } = convertChangeToPercentage(
          movementX,
          movementY,
          canvasWidth,
          matrix
        );
        let newBox = { ...currentBox };

        // Compute the size of the new box if the top edge is dragged
        if (edge.startsWith("top")) {
          newBox.y = normalize(newBox.y + dy);
          if (newBox.y > currentBox.y + currentBox.h) newBox.y = currentBox.y;
          newBox.h = normalize(currentBox.h + currentBox.y - newBox.y);
          if (newBox.h + newBox.y > 1) newBox.h = 1 - newBox.y;
        }

        // Compute the size of the new box if the bottom edge is dragged
        if (edge.startsWith("bottom")) {
          newBox.h = normalize(currentBox.h + dy);
          if (newBox.h + newBox.y > 1) newBox.h = 1 - newBox.y;
        }

        // Compute the size of the new box if the left edge is dragged
        if (edge.endsWith("Left")) {
          newBox.x = normalize(newBox.x + dx);
          if (newBox.x > currentBox.x + currentBox.w) newBox.x = currentBox.x;
          newBox.w = normalize(currentBox.w + currentBox.x - newBox.x);
          if (newBox.w + newBox.x > 1) newBox.w = 1 - newBox.x;
        }

        // Compute the size of the new box if the right edge is dragged
        if (edge.endsWith("Right")) {
          newBox.w = normalize(currentBox.w + dx);
          if (newBox.w + newBox.x > 1) newBox.w = 1 - newBox.x;
        }

        // Update the annotation
        updateAnnotation(currentBox.id, newBox, true);
      },
      onDragEnd: () => {
        updateAnnotation(currentBox.id, currentBox, false);
      },
    },
    { target: boxRef }
  );
}
