import { IconButton, Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

export default function EditBoxButton() {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Tooltip
      title="Close structure viewer"
      placement="left"
      arrow
      disableInteractive
    >
      <IconButton
        sx={{ marginLeft: "5px" }}
        onClick={() => {
          if (searchParams.has("box")) {
            searchParams.delete("box");
          }
          if (searchParams.has("pole")) {
            searchParams.delete("pole");
          }
          if (searchParams.has("addPoleItem")) {
            searchParams.delete("addPoleItem");
          }
          if (searchParams.has("poleStatus")) {
            searchParams.delete("poleStatus");
          }
          if (searchParams.has("componentID")) {
            searchParams.delete("componentID");
          }
          if (searchParams.has("placementID")) {
            searchParams.delete("placementID");
          }
          setSearchParams(searchParams);
        }}
      >
        <Close />
      </IconButton>
    </Tooltip>
  );
}
