import { useContext } from "react";
import { FilterCountContext } from "providers/FilterCountProvider";

export function useDefectCount() {
  const context = useContext(FilterCountContext);
  const { defect, defectLoading, setDefectTabActive } = context;

  return {
    counts: defect,
    loading: defectLoading,
    setActive: setDefectTabActive,
  };
}
