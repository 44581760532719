import React, { useRef, useCallback, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import ImageCanvas from "../ImageCanvas";
import Header from "../Header";
import { withHotKeys } from "react-hotkeys";
import { defectOnly } from "react-image-annotate/Annotator/reducers/general-reducer";
import { canSaveObject, regionsAreEqual } from "../Utils";
import "./style.scss";
import translations from "translations";
import { useLanguage } from "hooks";

function _objectWithoutPropertiesLoose(source, excluded) {
  // if the source object was null so there's nothing to return
  if (source == null) return {};

  // initialize an empty object to assign values to it later
  var target = {};

  // get an array of keys from the source object and loop through it
  var sourceKeys = Object.keys(source);

  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];

    // THIS IS WHERE IT HAPPENS: if the current key was present on the excluded array, so skip this iteration
    if (excluded.indexOf(key) >= 0) continue;

    // add the value with that key into the target object
    target[key] = source[key];
  }

  // return the target object
  return target;
}
function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  // This does the trick
  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;
  // If the object keys were Symbols it will append them too.
  // If we don't have any symbols this was unnecessary
  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }
  return target;
}

var HotkeyDiv = withHotKeys(function (_ref) {
  var hotKeys = _ref.hotKeys,
    children = _ref.children,
    divRef = _ref.divRef,
    props = _objectWithoutProperties(_ref, ["hotKeys", "children", "divRef"]);

  return React.createElement(
    "div",
    Object.assign(
      {},
      { ...hotKeys, ...props },
      {
        ref: divRef,
      }
    ),
    children
  );
});
export var MainLayout = function MainLayout({
  state,
  dispatch,
  image,
  customerId,
  issueCategories,
  detectedCategories,
  objectTypes,
  issueSeverities,
  user,
  showHumanMachine,
  setHumanMachine,
  zoomSpeed,
  annotatorObjectColor,
}) {
  const { language } = useLanguage();
  var memoizedActionFns = useRef({});

  var action = function action(type) {
    for (
      var _len = arguments.length,
        params = new Array(_len > 1 ? _len - 1 : 0),
        _key = 1;
      _key < _len;
      _key++
    ) {
      params[_key - 1] = arguments[_key];
    }

    var fnKey = "".concat(type, "(").concat(params.join(","), ")");
    if (memoizedActionFns.current[fnKey])
      return memoizedActionFns.current[fnKey];

    var fn = function fn() {
      for (
        var _len2 = arguments.length, args = new Array(_len2), _key2 = 0;
        _key2 < _len2;
        _key2++
      ) {
        args[_key2] = arguments[_key2];
      }

      return params.length > 0
        ? dispatch({
            type: type,
            ...params.reduce(function (acc, p, i) {
              // eslint-disable-next-line
              return (acc[p] = args[i]), acc;
            }, {}),
          })
        : dispatch({
            type: type,

            ...args[0],
          });
    };

    memoizedActionFns.current[fnKey] = fn;
    return fn;
  };

  const keyPress = (e) => {
    if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
      return;
    } else {
      if ([27].includes(e.keyCode) && e.type === "keydown") {
        dispatch({ type: "CANCEL" });
      } else if (e.key === "Delete" && e.type === "keydown") {
        dispatch({ type: "DELETE_HIGHLIGHTED_REGIONS" });
      } else if ([16, 17].includes(e.keyCode) && e.type === "keyup") {
        dispatch({ type: "TOGGLE_SHIFT", payload: false });
      } else if ([16, 17].includes(e.keyCode) && e.type === "keydown") {
        dispatch({ type: "TOGGLE_SHIFT", payload: true });
      } else if (["Digit1"].includes(e.code) && e.type === "keydown") {
        !defectOnly(user) &&
          dispatch({ type: "SELECT_TOOL", selectedTool: "detection" });
      } else if (["Digit2"].includes(e.code) && e.type === "keydown") {
        dispatch({ type: "SELECT_TOOL", selectedTool: "defect" });
      } else if (
        ["KeyS"].includes(e.code) &&
        e.ctrlKey &&
        e.type === "keydown"
      ) {
        const allObjectHuman = state.allObjectHuman || false;
        const canSave = state.regions.every((region) =>
          canSaveObject(region, objectTypes, issueCategories)
        );
        const somethingChanged = regionsAreEqual(
          state.regions,
          state.lastSavedRegions,
          () => {},
          state.allObjectHuman,
          true
        );
        if (!(!allObjectHuman && (!canSave || somethingChanged))) {
          e.preventDefault();
          e.stopPropagation();
          action("HEADER_BUTTON_CLICKED", "buttonName")("Save");
        } else {
          alert(translations.AnnotationTool.IncompleteObjects[language]);
          e.preventDefault();
          e.stopPropagation();
        }
      } else if (
        ["KeyA"].includes(e.code) &&
        e.ctrlKey &&
        e.type === "keydown"
      ) {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: "SELECT_ALL" });
      } else if (
        ["KeyC"].includes(e.code) &&
        e.ctrlKey &&
        e.type === "keydown"
      ) {
        dispatch({ type: "COPY_REGION" });
      } else if (
        ["KeyV"].includes(e.code) &&
        e.ctrlKey &&
        e.type === "keydown"
      ) {
        dispatch({ type: "PASTE_REGION" });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    document.addEventListener("keyup", keyPress);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("keyup", keyPress);
    };
  });

  var innerContainerRef = useRef();

  var refocusOnMouseEvent = useCallback(function (e) {
    if (!innerContainerRef.current) return;
    if (innerContainerRef.current.contains(document.activeElement)) return;

    if (innerContainerRef.current.contains(e.target)) {
      innerContainerRef.current.focus();
      e.target.focus();
    }
  }, []);

  const [selectedType, setSelectedType] = useState(0);

  return (
    <HotkeyDiv
      tabIndex={-1}
      divRef={innerContainerRef}
      onMouseDown={refocusOnMouseEvent}
      onMouseOver={refocusOnMouseEvent}
      onMouseUp={action("MOUSE_UP", "RESIZE_BOX")}
      allowChanges={true}
      className={"container"}
    >
      <div className="headerContainer">
        <Header
          onHeaderButtonClick={action("HEADER_BUTTON_CLICKED", "buttonName")}
          onToolButtonClick={action("SELECT_TOOL", "selectedTool")}
          state={state}
          issueCategories={issueCategories}
          objectTypes={objectTypes}
          issueSeverities={issueSeverities}
          allObjectHuman={state.allObjectHuman || false}
          user={user}
        />
      </div>
      <div className="workspace">
        <div className="sidebarContainer">
          <Sidebar
            regions={state.regions}
            savedRegions={state.lastSavedRegions}
            onSelectRegion={action("SELECT_REGION", "region")}
            onDeleteRegion={action("DELETE_REGION", "region")}
            onChangeRegion={action("CHANGE_REGION", "region")}
            onMLReviewChange={action(
              "REVIEW_REGION",
              "region",
              "index",
              "action",
              "categories"
            )}
            onDuplicateRegion={action("DUPLICATE_REGION", "region")}
            setSavedType={action("SET_LAST_USED_TYPE", "lastObjectType")}
            issueCategories={issueCategories}
            objectTypes={objectTypes}
            issueSeverities={issueSeverities}
            detectedCategories={detectedCategories}
            setSelectedType={setSelectedType}
            selectedType={selectedType}
            skyqraft_employee={user.skyqraft_employee}
            showHumanMachine={showHumanMachine}
            setShowHumanMachine={setHumanMachine}
            user={state.user}
            annotatorObjectColor={annotatorObjectColor}
          />
        </div>
        <div className="imageCanvasContainer">
          <ImageCanvas
            zoomSpeed={zoomSpeed}
            showTags={state.showTags}
            regions={state.regions}
            image={image}
            allRegionsVerified={state.allObjectHuman}
            customerId={customerId}
            createWithPrimary={state.selectedTool.includes("create")}
            dragWithPrimary={
              state.selectedTool === "pan" ||
              state.selectedTool === "select" ||
              (state.regions.every((r) => r.highlighted === false) &&
                state.selectedTool === "pan")
            }
            zoomWithPrimary={state.selectedTool === "zoom"}
            onMouseMove={action("MOUSE_MOVE")}
            onMouseDown={action("MOUSE_DOWN")}
            onMouseUp={action("MOUSE_UP", "location")}
            onChangeRegion={action("CHANGE_REGION", "region")}
            onBeginRegionEdit={action("OPEN_REGION_EDITOR", "region")}
            onCloseRegionEdit={action("CLOSE_REGION_EDITOR", "region")}
            onDeleteRegion={action("DELETE_REGION", "region")}
            onBeginBoxTransform={action(
              "BEGIN_BOX_TRANSFORM",
              "box",
              "directions"
            )}
            onSelectRegion={action("SELECT_REGION", "region", "location")}
            onBeginMovePoint={action("BEGIN_MOVE_POINT", "point")}
            onImageLoaded={action("IMAGE_LOADED", "image")}
            onImageOrVideoLoaded={action("IMAGE_OR_VIDEO_LOADED", "metadata")}
            skyqraft_employee={user.skyqraft_employee}
            issueCategories={issueCategories}
            detectedCategories={detectedCategories}
            setSelectedType={setSelectedType}
            selectedType={selectedType}
            showHumanMachine={showHumanMachine}
            user={state.user}
            setShowHumanMachine={setHumanMachine}
            objectTypes={objectTypes}
            issueSeverities={issueSeverities}
            annotatorObjectColor={annotatorObjectColor}
          />
        </div>
      </div>
    </HotkeyDiv>
  );
};
export default MainLayout;
