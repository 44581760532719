import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { Accordion } from "components";
import { useImageFeedbacks, useLanguage } from "hooks";
import { useNavigate, useParams } from "react-router-dom";

import translations from "translations";
import { orderBy } from "lodash";

export default function FlaggedImageList() {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const params = useParams();
  const missionID = parseInt(params.mission);
  const [imageFeedback, loading] = useImageFeedbacks({ mission: missionID });

  const sortedImageFeedback = orderBy(
    imageFeedback,
    // @ts-ignore
    [(obj) => new Date(obj.date), "feedback_type"],
    ["asc", "desc"]
  );

  return (
    <Accordion
      title={translations.Landing.ImageFeedback[language]}
      description={
        translations.Landing.ImageFeedback.SeeAllImageFeedbacks[language]
      }
      value={imageFeedback.length}
    >
      <Grid container spacing={2}>
        {!missionID && (
          <Grid item xs={2} display="flex">
            <Typography fontWeight="bold">
              {translations.Landing.ImageFeedback.clientId[language]}
            </Typography>
          </Grid>
        )}
        <Grid item xs={!missionID ? 3 : 2} display="flex">
          <Typography fontWeight="bold">
            {translations.Landing.ImageFeedback.imageId[language]}
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="start">
          <Typography fontWeight="bold">
            {translations.Landing.ImageFeedback.type[language]}
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="start">
          <Typography fontWeight="bold">
            {translations.Landing.ImageFeedback.comment[language]}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }} />
      <Stack divider={<Divider />}>
        {sortedImageFeedback.map((status, i) => (
          <Grid
            key={`${i}`}
            container
            spacing={2}
            sx={{ py: 1 }}
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/${status.customer_id}/${status.image_id}`);
            }}
          >
            {!missionID && (
              <Grid display="flex" alignItems="center" item xs={2}>
                <Box>
                  <Typography>{status.customer_id}</Typography>
                </Box>
              </Grid>
            )}

            <Grid
              display="flex"
              alignItems="center"
              item
              xs={!missionID ? 3 : 2}
            >
              <Box>
                <Typography>{status.image_id}</Typography>
              </Box>
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              item
              xs={3}
            >
              <Typography>{status.feedback_type}</Typography>
            </Grid>
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              item
              xs={4}
            >
              <Typography align="left">{status.comment}</Typography>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </Accordion>
  );
}
