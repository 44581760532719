import groupAPI from "api/group";

export type IGroup = Awaited<ReturnType<typeof groupAPI.getAllGroups>>[number];
export type IGroupDetailed = Awaited<ReturnType<typeof groupAPI.getGroup>>;

export function setGroups(groups: IGroup[]) {
  return {
    type: "SET_GROUPS",
    payload: groups,
  };
}

export function setAdminGroups(groups: IGroup[]) {
  return {
    type: "SET_ADMIN_GROUPS",
    payload: groups,
  };
}

export function setGroup(group: IGroupDetailed) {
  return {
    type: "SET_GROUP",
    payload: group,
  };
}

export function getGroups(
  callback: () => void = null,
  onError: (err) => void = null
) {
  return async (dispatch) => {
    try {
      const groups = await groupAPI.getAllGroups();
      dispatch(setGroups(groups));
      callback?.();
    } catch (err) {
      dispatch(setGroups([]));
      if (onError) {
        onError(err);
      }
    }
  };
}

export function getAdminGroups(
  callback: () => void = null,
  onError: (err) => void = null
) {
  return async (dispatch) => {
    try {
      const groups = await groupAPI.getAdminGroups();
      dispatch(setAdminGroups(groups));
      callback?.();
    } catch (err) {
      dispatch(setAdminGroups([]));
      if (onError) {
        onError(err);
      }
    }
  };
}

export function getGroup(
  group_id: number,
  callback: () => void = null,
  onError: (err) => void = null
) {
  return async (dispatch) => {
    try {
      const group = await groupAPI.getGroup(group_id);
      dispatch(setGroup(group));
      callback?.();
    } catch (err) {
      if (onError) {
        onError(err);
      }
    }
  };
}
