import { connect } from "react-redux";
import RoleOverride from "./RoleOverride";
import { overrideAccessLevel } from "state/actions";
import { RootState } from "state/store";
import { AccessLevel } from "interfaces";

interface IStateProps {
  accessLevelOverride: null | AccessLevel;
}

interface IDispatchProps {
  overrideAccessLevel: typeof overrideAccessLevel;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    accessLevelOverride: state.user.accessLevelOverride,
  };
};

const mapDispatchToProps = {
  overrideAccessLevel,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(RoleOverride);
