import { useState, useEffect, ReactNode } from "react";
import { IContextValue, context } from "./context";
import {
  addBoxToPole,
  addItemToBox,
  deleteBoxFromPole,
  deleteItemFromBox,
  getPole,
  IAnnotation,
  ISummaryItem,
  updateBox,
  updatePole,
  getPoleItemPlacementImages,
  addPoleItemPlacementImage,
  deletePoleItemPlacementImage,
} from "views/PoleViewer/api";

interface IProviderProps {
  children: ReactNode;
  poleID: number;
}

const defaultPath =
  "M 0.3595 0.9776,L 0.4492 0.1397,L 0.15 0.1397,L 0.4492 0.02,L 0.5689 0.02,L 0.8682 0.1397,L 0.5689 0.1397,L 0.6587 0.9776,L 0.3595 0.9776,L 0.633 0.7376,L 0.4079 0.5251,L 0.5911 0.3462,L 0.4492 0.1397,L 0.5689 0.02,L 0.5689 0.1397,L 0.4492 0.02,L 0.4492 0.1397,L 0.5689 0.1397,L 0.4271 0.3462,L 0.6102 0.5251,L 0.3852 0.7376,L 0.6587 0.9776";

function AnnotationProvider({ children, poleID }: IProviderProps) {
  // Set up some states to keep track of
  const [annotations, setAnnotations] = useState<IAnnotation[]>([]);
  const [summary, setSummary] = useState<ISummaryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [path, setPath] = useState<string[]>([]);
  const [poleComment, setPoleComment] = useState<string>("");
  const [poleItemPlacementImages, setPoleItemPlacementImages] = useState([]);

  // Handle the update data and state logic
  async function getPoleAnnotations(poleID: number) {
    // Update the state
    setLoading(true);
    const response = await getPole(poleID);
    const annotationsResponse = response.boxes;
    const summaryResponse = response.summary;
    const poleType = response.poleType;
    const path = poleType?.svg;
    setAnnotations(annotationsResponse);
    setSummary(summaryResponse);
    setPath(path?.split(",") ?? defaultPath.split(","));
    setPoleComment(response?.comment || "");
    setLoading(false);
  }

  async function getItemPlacementImages(placementID: number) {
    setLoading(true);
    const response = await getPoleItemPlacementImages(poleID, placementID);
    setPoleItemPlacementImages(response);
    setLoading(false);
  }

  async function addItemPlacementImage(
    poleID: number,
    placementID: number,
    imageID: number
  ) {
    setLoading(true);
    await addPoleItemPlacementImage(poleID, placementID, imageID);
    await getItemPlacementImages(placementID);
    setLoading(false);
  }

  async function deleteItemPlacementImage(
    poleID: number,
    placementID: number,
    imageID: number
  ) {
    setLoading(true);
    await deletePoleItemPlacementImage(poleID, placementID, imageID);
    await getItemPlacementImages(placementID);
    setLoading(false);
  }

  useEffect(() => {
    getPoleAnnotations(poleID);
  }, [poleID]);

  function update() {
    getPoleAnnotations(poleID);
  }

  async function addAnnotation(data: IAnnotation) {
    // Update the state
    await addBoxToPole(poleID, data);
    await getPoleAnnotations(poleID);
  }

  async function deleteAnnotation(annotationID: number) {
    // Update the state
    await deleteBoxFromPole(poleID, annotationID);
    await getPoleAnnotations(poleID);
  }

  async function updateAnnotation(
    annotationID: number,
    data: IAnnotation,
    temporary = true
  ) {
    if (temporary) {
      const newAnnotations = annotations.map((annotation) => {
        if (annotation.id === annotationID) {
          return data;
        }
        return annotation;
      });
      setAnnotations(newAnnotations);
    } else {
      // Update the annotation in the server
      // and then update the data
      await updateBox(poleID, data);
      await getPoleAnnotations(poleID);
    }
    // Update the state
  }

  async function updatePoleComment(
    poleID: number,
    comment: string | null = null,
    temporary = true
  ) {
    if (temporary) {
      setPoleComment(comment || "");
    } else {
      setLoading(true);
      await updatePole(poleID, comment);
      await getPoleAnnotations(poleID);
      setLoading(false);
    }
  }

  async function addItemToBoxState(
    poleID: number,
    boxID: number,
    imageID: number
  ) {
    await addItemToBox(poleID, boxID, imageID);
    await getPoleAnnotations(poleID);
  }

  async function deleteBoxItem(poleID: number, boxID: number, itemID: number) {
    await deleteItemFromBox(poleID, boxID, itemID);
    await getPoleAnnotations(poleID);
  }

  const payload: IContextValue = {
    data: annotations,
    summary: summary,
    update,
    loading,
    error: undefined,
    path,
    addAnnotation: addAnnotation,
    updateAnnotation: updateAnnotation,
    deleteAnnotation: deleteAnnotation,
    addItemToBox: addItemToBoxState,
    deleteBoxItem,
    poleComment,
    updatePoleComment,
    getItemPlacementImages,
    poleItemPlacementImages,
    addItemPlacementImage,
    deleteItemPlacementImage,
  };

  return <context.Provider value={payload}>{children}</context.Provider>;
}

export default AnnotationProvider;
