export default {
  NoProjectSelected: {
    EN: "No project was selected. Please select a project first",
    NO: "Ingen prosjekt ble valgt. Vennligst velg et prosjekt først",
    SE: "Inget projekt valdes. Vänligen välj ett projekt först",
  },
  NoImageLists: {
    EN: "No image lists were found for this project. Please add one in the filter menu",
    NO: "Ingen bildelister ble funnet for dette prosjektet. Vennligst legg til en i filtermenyen",
    SE: "Inga bildlistor hittades för detta projekt. Lägg till en i filtermenyn",
  },
  ImageReport: {
    EN: "Image report",
    NO: "Bilde rapport",
    SE: "Bild rapport",
  },
  ImageReportDescription: {
    EN: "Generate a PDF from a list of images. Due to the comptutational cost of images, the reports are limited to 100 images. If larger reports are required, please contact Arkion and we will help out.",
    NO: "Generer en PDF fra en liste over bilder. På grunn av beregningskostnaden for bilder, er rapportene begrenset til 100 bilder. Hvis større rapporter er nødvendig, vennligst kontakt Arkion og vi vil hjelpe til.",
    SE: "Generera en PDF från en lista med bilder. På grund av beräkningskostnaden för bilder är rapporterna begränsade till 100 bilder. Om större rapporter krävs, vänligen kontakta Arkion så hjälper vi till.",
  },
  GenerateReport: {
    EN: "Generate Report",
    NO: "Generer Rapport",
    SE: "Generera Rapport",
  },
  ImageLists: {
    EN: "Image Lists",
    NO: "Bilde List",
    SE: "Bild Listor",
  },
  Images: {
    EN: "images",
    NO: "bilder",
    SE: "bilder",
  },
} as const;
