import { Stack, Typography } from "@mui/material";
import { useLanguage } from "hooks";
import translations from "translations";

export default function ProjectMenuTitle() {
  const { language } = useLanguage();

  return (
    <Stack direction="row" spacing={1}>
      <Stack direction="column" alignItems="center">
        <Typography variant="h5">
          {translations.Menu.Missions.Mission[language]}
        </Typography>
        <Typography color="text.secondary">
          {translations.Menu.Missions.SubHeader[language]}
        </Typography>
      </Stack>
    </Stack>
  );
}
