import { getTranslation } from "utils/translations";
import useLanguage from "./useLanguage";

// Get type for first argument in getTranslation
type GetTranslationArg = Parameters<typeof getTranslation>[0];

function useTranslation(translationKey: GetTranslationArg) {
  const { language } = useLanguage();
  const translation = getTranslation(translationKey, language);
  return translation;
}

export default useTranslation;
