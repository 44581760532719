import { toast } from "react-toastify";
import { EventHint, captureException } from "@sentry/react";
import { axiosInstance } from "utils/request";

interface IUpdateAnnotations {
  projectId: number;
  ids: number[];
  update: {
    severity?: number;
    workflow_status?: number;
    skyqraft_hidden?: boolean;
  };
  onSuccess: () => void;
}
export async function updateAnnotations(data: IUpdateAnnotations) {
  const { projectId, ids, update, onSuccess } = data;

  try {
    await axiosInstance.patch(
      "/review/annotations",
      {
        ids,
        ...update,
      },
      {
        headers: { MissionID: projectId },
      }
    );
    onSuccess();
  } catch (error) {
    console.error(error);
    const exceptionHint: EventHint = {
      event_id: "image.api.updateAnnotations.request",
      originalException: error,
      data: update,
    };
    captureException(error, exceptionHint);
    toast.error("Could not update annoations");
  }
}

interface IFlagImageForReannotation {
  imageId: number;
  onSuccess: () => void;
}
export async function flagImageForReannotation(
  data: IFlagImageForReannotation
) {
  const { imageId, onSuccess } = data;

  try {
    await axiosInstance.post(`/image/${imageId}/flag`);
    onSuccess();
  } catch (error) {
    console.error(error);
    const exceptionHint: EventHint = {
      event_id: "image.api.flagImageForReannotation.request",
      originalException: error,
    };
    captureException(error, exceptionHint);
    toast.error("Could not flag image for reannotation");
  }
}
