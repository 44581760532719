import React, { useEffect, useState, useMemo } from "react";
import {
  Stack,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import Select from "react-select";
import translations from "translations";
import { Add, ContentCopy } from "@mui/icons-material";
import { useSelector, useLanguage } from "hooks";
import { RoleWrapper } from "components";
import { addNonUserEmail, getNonUserEmails, getUserEmails } from "./utils";
import { toast } from "react-toastify";

type Props = {
  value;
  onChange: (emails: number[]) => void;
  done: boolean;
  group: number;
};

export default function EmailList({ value, onChange, done, group }: Props) {
  const { language } = useLanguage();
  const [options, setOptions] = useState([]);
  const emailList = useSelector((state) => state.mission.emailList);

  useEffect(() => {
    const fetchEmails = async () => {
      if (group) {
        const nonUserOptions = (await getNonUserEmails(group, language)) || [];
        const userOptions = (await getUserEmails(group, language)) || [];

        setOptions((prevOptions) => [
          ...prevOptions.filter(
            (option) => option.label !== "Non-Users" && option.label !== "Users"
          ),
          ...nonUserOptions,
          ...userOptions,
        ]);
      }
    };

    fetchEmails();
  }, [group]);

  const [newEmail, setNewEmail] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewEmail("");
  };

  const handleSubmit = async (email: string) => {
    try {
      await addNonUserEmail(group, email, language);
      const nonUserOptions = await getNonUserEmails(group, language);
      setOptions((prevOptions) => [
        ...prevOptions.filter((option) => option.label !== "Non-Users"),
        ...nonUserOptions,
      ]);
      setDialogOpen(false);
      setNewEmail("");
    } catch (error) {
      toast.error("Failed to add email");
    }
  };

  const memoizedSelectedOptions = useMemo(() => {
    const returnvalue = [];
    value?.map((id) => {
      const user_email = options[0]?.options.find(
        (option) => option.value === id
      );
      const non_user_email = options[1]?.options.find(
        (option) => option.value === id
      );
      user_email && returnvalue.push(user_email);
      non_user_email && returnvalue.push(non_user_email);
    });
    return returnvalue;
  }, [value, options]);

  const disabled = done || !group;

  const [copySuccess, setCopySuccess] = useState("");

  const handleCopyEmails = () => {
    const emailString = emailList.map((option) => option.email).join("; ");
    navigator.clipboard
      .writeText(emailString)
      .then(() =>
        setCopySuccess(
          translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
            .EmailAddressesCopied[language]
        )
      )
      .catch((err) =>
        setCopySuccess(
          translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
            .FailedToCopyEmails[language]
        )
      );
    setTimeout(() => setCopySuccess(""), 3000);
  };

  return (
    <Stack spacing={1}>
      {!group && (
        <Typography variant={"h6"}>
          {
            translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
              .PleaseChooseACustomer[language]
          }
        </Typography>
      )}
      <Typography>
        {
          translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
            .Description[language]
        }
      </Typography>
      <div>
        <Select
          value={memoizedSelectedOptions}
          closeMenuOnSelect={false}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          options={options}
          isMulti
          onChange={(options) => {
            onChange(options.map((o) => o.value));
          }}
          isDisabled={disabled}
        />
      </div>

      <Button
        variant="outlined"
        startIcon={<Add />}
        onClick={handleDialogOpen}
        disabled={disabled}
      >
        {
          translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
            .AddNewEmail[language]
        }
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {
            translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
              .AddNewEmail[language]
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
                .EmailDialogDescription[language]
            }
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={() => handleSubmit(newEmail)}>Add</Button>
        </DialogActions>
      </Dialog>

      {done && (
        <RoleWrapper keyName="projectAdmin">
          <Button
            variant="outlined"
            startIcon={<ContentCopy />}
            onClick={handleCopyEmails}
          >
            {copySuccess.length > 0
              ? copySuccess
              : translations.MissionLanding.OrderProgress.PlanningPhase
                  .EmailList.CopySelectedEmails[language]}
          </Button>
        </RoleWrapper>
      )}
    </Stack>
  );
}
