import Canvas from "./components/Canvas";
import {
  AnnotationProvider,
  CanvasProvider,
  SummaryProvider,
} from "./provider";
import { Box } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import PoleBox from "./PoleBox";
import PoleItemPlacementImages from "./PoleItemPlacementImages";

export default function PoleViewer() {
  const [params] = useSearchParams();
  const location = useLocation();
  const poleID = parseInt(params.get("pole"));
  const boxID = parseInt(params.get("box"));
  const connectPlacementImages = params.has("ConnectPlacementImages");
  const annotationToolOpen = location.pathname.includes("annotate");

  const MenuOpenKey = "poleStatus";
  const menuOpen =
    params.has(MenuOpenKey) && params.get(MenuOpenKey) === "true";

  if (Number.isNaN(poleID) || annotationToolOpen) {
    return null;
  }
  const width = 400;
  return (
    <Box
      sx={{
        position: "absolute",
        top: 56,
        bottom: 0,
        width,
        right: 0,
        background: "rgba(255,255,255,0.7)",
        overflow: !menuOpen ? "hidden" : "scroll",
        zIndex: 1000,
      }}
    >
      <CanvasProvider>
        <AnnotationProvider poleID={poleID}>
          <SummaryProvider poleID={poleID}>
            <Canvas width={width} menuOpen={menuOpen} />
            {boxID > 0 && <PoleBox />}
            {connectPlacementImages && <PoleItemPlacementImages />}
          </SummaryProvider>
        </AnnotationProvider>
      </CanvasProvider>
    </Box>
  );
}
