import React, { useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  Divider,
  Stack,
  Typography,
  Container,
  TextField,
  Input,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import {
  Edit,
  Save,
  Close,
  Done,
  ErrorOutline,
  Delete,
  LinkOff,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useLanguage } from "hooks";
import { getTranslation } from "utils/translations";

import {
  getClientObjectTypes,
  addClientObjectType,
  addClientObject,
  deleteClientObjectTypeMap,
  checkClientObjectTypeMap,
  getClientCategories,
  addClientCategory,
} from "./api";
import { get } from "js-cookie";

type Props = {
  objectTypes: any;
  getClientObjects: Function;
  clientObjects;
  selectedGroup: number | null;
};

export default function ObjectTypes({
  objectTypes,
  getClientObjects,
  clientObjects,
  selectedGroup,
}: Props) {
  const { language } = useLanguage();

  const titles = [
    "",
    "Id",
    getTranslation("Name", language),
    getTranslation("InternalId", language),
    getTranslation("Color", language),
    getTranslation("CategoryId", language),
    getTranslation("CategoryName", language),
    getTranslation("CategoryInternalId", language),
    getTranslation("Edit", language),
  ];

  const [editId, setEditId] = useState(null);
  const [newValues, setNewValues] = useState(null);
  const [selectedClientType, setSelectedClientType] = useState(null);
  const [saveFeedback, setSaveFeedback] = useState("");
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);
  const [categoryValues, setCategoryValues] = useState([]);
  const [newCategoryValues, setNewCategoryValues] = useState(null);
  const [deleteType, setDeleteType] = useState("");

  //filter out only defects. If Objects also are needed then just change this row.
  let filteredObjects = objectTypes.filter((e) => e.id !== null && e.issue);

  // add clinetObject values to the filteredObjects
  filteredObjects = filteredObjects.map((e) => {
    const clientObject = clientObjects.filter(
      (obj) => obj.skyqraft_object_type_id === e.id
    )[0];
    if (!!clientObject) {
      return {
        ...e,
        client_id: clientObject.id,
        client_object_type_id: clientObject.client_object_type_id,
        client_category_id: clientObject.category_id,
        client_category_se_name: clientObject.category_se_name,
        client_category_en_name: clientObject.category_en_name,
        client_category_external_id: clientObject.category_external_id,
        client_name: clientObject.client_name,
        client_color: clientObject.color,
        client_external_type_id: clientObject.external_type_id,
        client_group_id: clientObject.group_id,
        client_skyqraft_object_type_id: clientObject.skyqraft_object_type_id,
      };
    }
    return {
      ...e,
      client_id: null,
      client_object_type_id: null,
      client_category_id: null,
      client_category_se_name: null,
      client_category_en_name: null,
      client_name: null,
      client_color: null,
      client_external_type_id: null,
      client_group_id: null,
      client_skyqraft_object_type_id: null,
    };
  });

  function renderSaveIcon() {
    if (saveFeedback === "") {
      return <Save />;
    }
    if (saveFeedback === "loading") {
      return (
        <CircularProgress
          size={20}
          sx={{ marginTop: "2px", marginLeft: "2px" }}
        />
      );
    }
    if (saveFeedback === "saved") {
      return <Done htmlColor="green" />;
    }
    if (saveFeedback === "error") {
      return <ErrorOutline htmlColor="red" />;
    }
  }

  function renderSelect(obj) {
    return (
      <FormControl>
        <InputLabel>ID</InputLabel>
        <Select
          value={selectedClientType}
          onChange={(e) => {
            if (e.target.value === obj.client_object_type_id) {
              setNewValues({
                ...newValues,
                clientName: obj.client_name,
                clientColor: obj.client_color,
                clientInternalId: obj.client_external_type_id,
              });
            } else {
              setNewValues(null);
            }

            setSelectedClientType(e.target.value);
          }}
          sx={{ minWidth: "100px" }}
          renderValue={(value) => {
            const selectedOption = clientObjects.find(
              (option) => option.client_object_type_id === value
            );
            return selectedOption ? selectedOption.client_object_type_id : "";
          }}
        >
          {clientObjects
            .filter(
              (e, i, a) =>
                a.findIndex(
                  (t) => t.client_object_type_id === e.client_object_type_id
                ) === i
            )
            .map((option) => (
              <MenuItem key={option.id} value={option.client_object_type_id}>
                {`ID: ${
                  option.client_object_type_id
                } - ${`${option.client_name} (${option.external_type_id})`}`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }

  function renderCategorySelect(obj) {
    return (
      <FormControl>
        <InputLabel>{getTranslation("CategoryId", language)}</InputLabel>
        <Select
          value={selectedCategoryType}
          onChange={(e) => {
            if (e.target.value === obj.id) {
              setNewCategoryValues({
                ...newValues,
                categoryName: obj.client_name,
                id: obj.client_color,
                categoryInternalId: obj.client_external_type_id,
              });
            } else {
              setNewCategoryValues(null);
            }

            setSelectedCategoryType(e.target.value);
          }}
          sx={{ minWidth: "100px" }}
          renderValue={(value) => {
            const selectedOption = categoryValues.find(
              (option) => option.id === value
            );
            return selectedOption ? selectedOption.id : "";
          }}
        >
          {categoryValues
            .filter((e, i, a) => a.findIndex((t) => t.id === e.id) === i)
            .map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {`ID: ${
                  option.id
                } - ${`${option.name} (${option.external_category_id})`}`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }

  function exitEditMode() {
    setEditId(null);
    setNewValues(null);
    setSelectedClientType(null);
    setNewCategoryValues(null);
    setSelectedCategoryType(null);
  }

  function enterEditMode(obj) {
    setEditId(obj.id);
    setSelectedClientType(obj.client_object_type_id);
    if (obj.client_category_id < 0) {
      setSelectedCategoryType(-obj.client_category_id);
      setNewCategoryValues({
        CategoryName: obj.client_category_se_name,
        category_external_id: obj.client_category_external_id,
      });
    } else {
      setNewCategoryValues({
        CategoryName: null,
        category_external_id: null,
      });
    }
    setNewValues({
      clientName: obj.client_name,
      clientColor: obj.client_color,
      clientInternalId: obj.client_external_type_id,
    });
  }

  function deleteAndUpdateType(obj, clientTypeId) {
    deleteClientObjectTypeMap(obj.client_id)
      .then(() => {
        addNewType(obj, clientTypeId);
      })
      .catch((e) => {
        setSaveFeedback("error");
        setTimeout(() => {
          setSaveFeedback("");
        }, 3000);
      });
  }

  function addNewType(obj, clientTypeId) {
    addClientObject(selectedGroup, obj.id, clientTypeId)
      .then((e) => {
        getClientObjects(selectedGroup).then(() => {
          setSaveFeedback("saved");
          setTimeout(() => {
            exitEditMode();
            setSaveFeedback("");
          }, 800);
        });
      })
      .catch((e) => {
        setSaveFeedback("error");
        setTimeout(() => {
          setSaveFeedback("");
        }, 3000);
      });
  }

  function checkCategory(obj) {
    //First case if the selectedCategoryType is not null and not equal to the obj.client_category_id
    if (
      selectedCategoryType !== null &&
      selectedCategoryType !== -obj.client_category_id
    ) {
      return save(obj, selectedCategoryType);
    }

    //Second case if the selectedCategoryType is null and not equal to the obj.client_category_id
    if (
      selectedCategoryType === null ||
      selectedCategoryType === -obj.client_category_id
    ) {
      //check if there is any newCategoryValues
      if (
        newCategoryValues.CategoryName !== null ||
        newCategoryValues.category_external_id !== null
      ) {
        //if there is newCategoryValues then add the category
        return addClientCategory(
          selectedGroup,
          newCategoryValues.CategoryName,
          newCategoryValues.category_external_id
        )
          .then((e) => {
            const categoriesResponse = getClientCategories(selectedGroup);
            categoriesResponse.then((c) => {
              //@ts-ignore
              setCategoryValues(c.categories);
              //@ts-ignore
              return save(obj, e.id);
            });
          })
          .catch((e) => {
            setSaveFeedback("error");
            setTimeout(() => {
              setSaveFeedback("");
            }, 3000);
          });
      }

      //check to se if obj.client_category_id is negative
      if (obj.client_category_id < 0) {
        //if negative then add the category

        return save(obj, -obj.client_category_id);
      }
      //if not negative then just save
      return save(obj, null);
    }
  }

  function save(obj, categoryId: string | number | null = "not checked") {
    setSaveFeedback("loading");

    if (categoryId === "not checked") {
      return checkCategory(obj);
    }
    if (
      selectedClientType !== null &&
      selectedClientType !== obj.client_object_type_id &&
      categoryId === null
    ) {
      if (!!obj.client_id) {
        deleteAndUpdateType(obj, selectedClientType);
      } else {
        addNewType(obj, selectedClientType);
      }
    }
    if (
      selectedClientType !== null &&
      selectedClientType !== obj.client_object_type_id &&
      categoryId !== null
    ) {
      const values = clientObjects.find(
        (c) => c.client_object_type_id === selectedClientType
      );
      addNewClientObjectType(
        obj,
        values.client_name,
        values.color,
        values.external_type_id,
        categoryId
      );
    } else {
      const color =
        newValues?.clientColor === null ? obj?.color : newValues?.clientColor;
      addNewClientObjectType(
        obj,
        newValues.clientName,
        color,
        newValues.clientInternalId,
        categoryId
      );
    }
  }

  function addNewClientObjectType(obj, name, color, internalId, categoryId) {
    //Add new client object type
    addClientObjectType(selectedGroup, name, color, internalId, categoryId)
      .then((e) => {
        //remove old object type id there was a old one
        //@ts-ignore
        if (e?.id !== null) {
          if (!!obj.client_id) {
            //@ts-ignore
            deleteAndUpdateType(obj, e?.id);
          } else {
            //@ts-ignore
            addNewType(obj, e?.id);
          }
        } else {
          setSaveFeedback("saved");
          setTimeout(() => {
            exitEditMode();
            setSaveFeedback("");
          }, 800);
        }
      })
      .catch((e) => {
        setSaveFeedback("error");
        setTimeout(() => {
          setSaveFeedback("");
        }, 3000);
      });
  }

  function renderLogic(obj) {
    if (editId === obj.id) {
      if (
        selectedClientType === obj.client_object_type_id ||
        selectedClientType === null
      ) {
        return "edit";
      }
      return "display";
    }
    return "normal";
  }

  function renderCategoryLogic(obj) {
    if (editId === obj.id) {
      if (
        selectedClientType !== obj.client_object_type_id &&
        selectedClientType !== null
      ) {
        return "display 2";
      }
      if (
        selectedCategoryType === -obj.client_category_id ||
        selectedCategoryType === null
      ) {
        return "edit";
      }
      return "display";
    }
    return "normal";
  }

  useEffect(() => {
    //get the client_id and use it in the getDeleteType function
    if (editId !== null) {
      const obj = filteredObjects.find((e) => e.id === editId);
      if (obj.client_object_type_id !== null) {
        getDeleteType(obj.client_object_type_id);
      }
    }
  }, [editId, filteredObjects]);

  useEffect(() => {
    if (selectedGroup !== null) {
      const categoriesResponse = getClientCategories(selectedGroup);
      categoriesResponse.then((e) => {
        //@ts-ignore
        setCategoryValues(e.categories);
      });
    }
  }, [selectedGroup]);

  function getDeleteType(id) {
    if (id !== null) {
      const mapCount = checkClientObjectTypeMap(id);
      mapCount.then((e) => {
        //@ts-ignore
        e.number_of_mapped_types > 1
          ? setDeleteType("Disconnect")
          : setDeleteType("Delete");
      });
    }
  }

  return (
    <Box sx={{ with: "100vw", height: "100%" }}>
      <Container>
        {/* Titles Row */}
        <div style={{ display: "flex", marginBottom: 10 }}>
          {titles.map((title) => (
            <>
              <div key={title} style={{ flex: 1, textAlign: "center" }}>
                <Typography variant="subtitle1">{title}</Typography>
              </div>
            </>
          ))}
        </div>

        {/* List Content */}
        {filteredObjects.map((obj) => (
          <Paper key={obj.id} style={{ marginBottom: 10 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Typography variant="body2">Arkion</Typography>
                </div>
                <Divider orientation="vertical" flexItem />
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Typography variant="body2">{obj.id}</Typography>
                </div>

                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Typography variant="h6" sx={{ fontSize: "13px" }}>
                    {
                      obj[
                        `${(language === "NO"
                          ? "EN"
                          : language
                        ).toLowerCase()}_name`
                      ]
                    }
                  </Typography>
                </div>

                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  ---
                </div>

                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Typography variant="body2">{obj.color}</Typography>
                </div>

                <Divider orientation="vertical" flexItem />

                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  ---
                </div>

                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Typography variant="body2">
                    {
                      obj[
                        `category_${(language === "NO"
                          ? "EN"
                          : language
                        ).toLowerCase()}_name`
                      ]
                    }
                  </Typography>
                </div>

                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  ---
                </div>

                <Divider orientation="vertical" flexItem />

                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  ---
                </div>
              </div>
              <Divider orientation="horizontal" flexItem />

              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  <Typography variant="body2">
                    {getTranslation("Client", language)}
                  </Typography>
                </div>

                <Divider orientation="vertical" flexItem />

                {/* Client ID */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  {editId === obj.id ? (
                    <>{renderSelect(obj)}</>
                  ) : (
                    <Typography variant="body2">
                      {obj.client_object_type_id}
                    </Typography>
                  )}
                </div>

                {/* Client name */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  {renderLogic(obj) === "edit" && (
                    <TextField
                      value={newValues?.clientName}
                      defaultValue={obj.client_name}
                      label={getTranslation("Name", language)}
                      onChange={(e) =>
                        setNewValues({
                          ...newValues,
                          clientName: e.target.value,
                        })
                      }
                    />
                  )}

                  {renderLogic(obj) === "display" && (
                    <Typography variant="body2">
                      {
                        clientObjects.find(
                          (c) => c.client_object_type_id === selectedClientType
                        )?.client_name
                      }
                    </Typography>
                  )}
                  {renderLogic(obj) === "normal" && (
                    <Typography variant="body2">{obj.client_name}</Typography>
                  )}
                </div>

                {/* Client external type ID */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  {renderLogic(obj) === "edit" && (
                    <TextField
                      value={newValues?.client_external_type_id || undefined}
                      defaultValue={obj.client_external_type_id}
                      label={getTranslation("InternalId", language)}
                      onChange={(e) =>
                        setNewValues({
                          ...newValues,
                          clientInternalId: e.target.value,
                        })
                      }
                    />
                  )}
                  {renderLogic(obj) === "display" && (
                    <Typography variant="body2">
                      {
                        clientObjects.find(
                          (c) => c.client_object_type_id === selectedClientType
                        )?.external_type_id
                      }
                    </Typography>
                  )}
                  {renderLogic(obj) === "normal" && (
                    <Typography variant="body2">
                      {obj.client_external_type_id}
                    </Typography>
                  )}
                </div>

                {/* Client color */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",

                    padding: 8,
                  }}
                >
                  {renderLogic(obj) === "edit" && (
                    <Input
                      type="color"
                      sx={{ width: 25 }}
                      value={newValues?.client_color}
                      defaultValue={obj.client_color}
                      onChange={(e) =>
                        setNewValues({
                          ...newValues,
                          clientColor: e.target.value,
                        })
                      }
                    />
                  )}
                  {renderLogic(obj) === "display" && (
                    <Input
                      type="color"
                      sx={{ width: 25 }}
                      value={
                        clientObjects.find(
                          (c) => c.client_object_type_id === selectedClientType
                        )?.color
                      }
                      disabled
                    />
                  )}

                  {renderLogic(obj) === "normal" && (
                    <Input
                      type="color"
                      sx={{ width: 25 }}
                      value={obj.client_color}
                      disabled
                    />
                  )}
                </div>

                <Divider orientation="vertical" flexItem />

                {/* Client Select Category ID */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  {editId === obj.id ? (
                    selectedClientType === obj.client_object_type_id ? (
                      <>{renderCategorySelect(obj)}</>
                    ) : (
                      <Typography variant="body2">
                        {clientObjects.find(
                          (c) => c.client_object_type_id === selectedClientType
                        )?.category_id < 0
                          ? -clientObjects.find(
                              (c) =>
                                c.client_object_type_id === selectedClientType
                            )?.category_id
                          : "---"}
                      </Typography>
                    )
                  ) : (
                    <Typography variant="body2">
                      {obj.client_category_id < 0
                        ? -obj.client_category_id
                        : "---"}
                    </Typography>
                  )}
                </div>

                {/* Client category */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  {renderCategoryLogic(obj) === "edit" && (
                    <TextField
                      value={newCategoryValues?.CategoryName}
                      defaultValue={
                        obj.category_id < 0 ? obj.client_category_se_name : ""
                      }
                      label={getTranslation("CategoryName", language)}
                      onChange={(e) =>
                        setNewCategoryValues({
                          ...newCategoryValues,
                          CategoryName: e.target.value,
                        })
                      }
                    />
                  )}
                  {renderCategoryLogic(obj) === "display" && (
                    <Typography variant="body2">
                      {
                        categoryValues.find(
                          (c) => c.id === selectedCategoryType
                        )?.name
                      }
                    </Typography>
                  )}
                  {renderCategoryLogic(obj) === "display 2" && (
                    <Typography variant="body2">
                      {
                        clientObjects.find(
                          (c) =>
                            c.client_object_type_id === selectedClientType &&
                            c.category_id < 0
                        )?.category_se_name
                      }
                    </Typography>
                  )}
                  {renderCategoryLogic(obj) === "normal" && (
                    <Typography variant="body2">
                      {obj.client_category_id < 0
                        ? obj.client_category_en_name
                        : "---"}
                    </Typography>
                  )}
                </div>

                {/* Client category internal id */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: 8,
                  }}
                >
                  {renderCategoryLogic(obj) === "edit" && (
                    <TextField
                      value={newCategoryValues?.category_external_id}
                      defaultValue={obj.client_category_external_id}
                      label={getTranslation("CategoryInternalId", language)}
                      onChange={(e) =>
                        setNewCategoryValues({
                          ...newCategoryValues,
                          category_external_id: e.target.value,
                        })
                      }
                    />
                  )}
                  {renderCategoryLogic(obj) === "display" && (
                    <Typography variant="body2">
                      {
                        categoryValues.find(
                          (c) => c.id === selectedCategoryType
                        )?.external_category_id
                      }
                    </Typography>
                  )}
                  {renderCategoryLogic(obj) === "display 2" && (
                    <Typography variant="body2">
                      {
                        clientObjects.find(
                          (c) =>
                            c.client_object_type_id === selectedClientType &&
                            c.category_id < 0
                        )?.category_external_id
                      }
                    </Typography>
                  )}
                  {renderCategoryLogic(obj) === "normal" && (
                    <Typography variant="body2">
                      {obj.client_category_id < 0
                        ? obj.client_category_external_id
                        : "---"}
                    </Typography>
                  )}
                </div>

                <Divider orientation="vertical" flexItem />

                {/* Edit and save buttons */}
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",

                    padding: 8,
                  }}
                >
                  <IconButton
                    onClick={(e) => {
                      editId === obj.id ? exitEditMode() : enterEditMode(obj);
                    }}
                    disabled={editId !== null && editId !== obj.id}
                  >
                    {editId === obj.id ? <Close /> : <Edit />}
                  </IconButton>
                  {editId === obj.id && (
                    <>
                      <IconButton
                        onClick={() => {
                          save(obj);
                        }}
                      >
                        {renderSaveIcon()}
                      </IconButton>

                      {!!obj.client_id &&
                        selectedClientType === obj.client_object_type_id && (
                          <IconButton
                            onClick={() => {
                              setSaveFeedback("loading");
                              deleteClientObjectTypeMap(obj.client_id)
                                .then(() => {
                                  getClientObjects(selectedGroup).then(() => {
                                    setSaveFeedback("saved");
                                    setTimeout(() => {
                                      exitEditMode();
                                      setSaveFeedback("");
                                    }, 800);
                                  });
                                })
                                .catch((e) => {
                                  setSaveFeedback("error");
                                  setTimeout(() => {
                                    setSaveFeedback("");
                                  }, 3000);
                                });
                            }}
                          >
                            {deleteType === "Delete" ? (
                              <Tooltip
                                title={getTranslation("Delete", language)}
                                placement="right"
                                arrow
                              >
                                <Delete />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={getTranslation("Disconnect", language)}
                                placement="right"
                                arrow
                              >
                                <LinkOff />
                              </Tooltip>
                            )}
                          </IconButton>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Paper>
        ))}
      </Container>
    </Box>
  );
}
