import Provider, { context } from "./provider";
import GanttChart from "./GanttChart";

interface IProps {
  projectID: number;
}

export default function GanttChartWrapper({ projectID }: IProps) {
  return (
    <Provider projectID={projectID}>
      <GanttChart />
    </Provider>
  );
}

export type { IGanttItem } from "./provider";
export { context };
