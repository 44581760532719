import { useSelector, useDispatch } from "hooks";
import { clearBlockerModal, setBlockerModalMessage } from "state/actions/ui";

export function useModalBlocker() {
  // The modal blocker will be a div that will cover the entire screen
  // to prevent the user from interacting with the rest of the app

  // This blocker is mainly used to block the user from working
  // on the application if something has broken and the user
  // needs to reload their application
  const dispatch = useDispatch();
  const modalBlocker = useSelector((state) => state.ui.modalBlocker);

  function clearBlocker() {
    dispatch(clearBlockerModal());
  }

  function setBlocker(title: string, message: string) {
    dispatch(setBlockerModalMessage(title, message));
  }

  return { modalBlocker, clearBlocker, setBlocker };
}
