import { useSelector } from "react-redux";
import { IState as userState } from "state/reducers/userreducer";
import { IState as UIState } from "state/reducers/uireducer";
import { useTranslation } from "./settings";
import md5 from "md5-hash";

export function useProjectName(projectID: number) {
  const projectTranslation = useTranslation("Project");
  const availableProjects = useSelector(
    (state: { user: userState }) => state.user.missions
  );
  const demoMode = useSelector((state: { ui: UIState }) => state.ui.demoMode);

  const currentProject = availableProjects.find(
    (project) => project.id === projectID
  );

  const showDatesBeforeProjectName = useSelector(
    (state: { ui: UIState }) => state.ui.showDatesBeforeProjectName
  );

  if (!availableProjects) {
    return {
      fullname: "Loading...",
      name: "Loading...",
      year: "",
      region: "",
    };
  }

  if (!currentProject) {
    return {
      fullname: "No project found",
      name: "Not found",
      year: "",
      region: "",
    };
  }

  let fullname = `${currentProject.name}: ${currentProject.year}`;
  const name = `${currentProject.name}`;
  const year = `${currentProject.year}`;
  let region = "";
  if (currentProject.region !== null) {
    region = `${currentProject.region?.name}`;
  }

  if (demoMode) {
    const hashName = md5(fullname).substring(0, 4);
    fullname = `${projectTranslation} ${hashName}: ${currentProject.year}`;
  }

  if (!showDatesBeforeProjectName) {
    fullname = name;
  }

  return {
    fullname,
    name,
    year,
    region,
  };
}
