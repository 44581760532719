import { context as MenuContext, Sidebar } from "providers/MenuProvider";
import { useContext } from "react";

export function useSidebar() {
  const context = useContext(MenuContext);
  const { sidebar, setSidebar } = context;

  function openProjects() {
    setSidebar(Sidebar.Projects);
  }
  function openFilters() {
    setSidebar(Sidebar.Filters);
  }
  function openTools() {
    setSidebar(Sidebar.Tools);
  }
  function openStatistics() {
    setSidebar(Sidebar.Statistics);
  }
  function openSettings() {
    setSidebar(Sidebar.Settings);
  }
  function closeSidebar() {
    setSidebar(Sidebar.None);
  }
  return {
    sidebar,
    openProjects,
    openFilters,
    openTools,
    openStatistics,
    openSettings,
    closeSidebar,
  };
}
