import { ExpandMore } from "@mui/icons-material";
import { AccordionSummary, Chip, Stack, Typography } from "@mui/material";

type Props = {
  value?: number;
  title: string;
  description: string;
  dataTestId?: string;
};

export default function Summary({
  value,
  title,
  description,
  dataTestId,
}: Props) {
  return (
    <AccordionSummary
      expandIcon={<ExpandMore />}
      {...(dataTestId ? { "data-testid": dataTestId } : {})}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        flex={1}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          {value !== undefined ? <Chip label={value} /> : null}
          <Typography sx={{ flex: 1, flexShrink: 0 }}>{title}</Typography>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          display={{
            xs: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          }}
        >
          <Typography sx={{ color: "text.secondary" }}>
            {description}
          </Typography>
        </Stack>
      </Stack>
    </AccordionSummary>
  );
}
