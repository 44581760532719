import { connect } from "react-redux";
import MapListButton from "./MapListButton";
import { RootState } from "state/store";
import { setListView } from "state/actions";

interface IStateProps {
  showListView: boolean;
}

interface IDispatchProps {
  setListView: (value: boolean) => void;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    showListView: state.ui.showListView,
  };
};

const mapDispatchToProps = {
  setListView,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(MapListButton);
