import React, { ReactElement } from "react";
import { Box } from "@mui/material";
import translations from "translations";
import RoleWrapper from "components/RoleHOC/wrapper";
import { Outlet, useLocation } from "react-router-dom";
import FullPage from "components/FullPage";
import { useLanguage } from "hooks";

export default function PowerlinePanel(): ReactElement {
  const { language } = useLanguage();
  const location = useLocation();
  let currentTab = 0;

  if (location.pathname.endsWith("bluify")) {
    currentTab = 0;
  }
  if (location.pathname.endsWith("divide")) {
    currentTab = 1;
  }
  if (location.pathname.endsWith("poles")) {
    currentTab = 2;
  }
  if (location.pathname.endsWith("download")) {
    currentTab = 3;
  }
  return (
    <RoleWrapper keyName="powerlinePanel">
      <Box
        sx={{
          direction: "row",
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          zIndex: 100,
        }}
      >
        <FullPage
          title={translations.Inbox.Reports.Title[language]}
          tab={currentTab}
          tabs={[
            {
              label: translations.Menu.Tools.Powerlines.Bluify[language],
              link: "bluify",
            },
            {
              label: translations.Menu.Tools.Powerlines.Divide[language],
              link: "divide",
            },
            {
              label: translations.Menu.Tools.Powerlines.Poles[language],
              link: "poles",
            },
            {
              label: translations.Menu.Tools.Powerlines.Download[language],
              link: "download",
            },
          ]}
        >
          <Outlet />
        </FullPage>
      </Box>
    </RoleWrapper>
  );
}
