import { connect } from "react-redux";
import ZoomSpeed from "./ZoomSpeed";
import { setZoomSpeed } from "state/actions";
import { RootState } from "state/store";

interface IStateProps {
  zoomSpeed: number;
}

interface IDispatchProps {
  setZoomSpeed: typeof setZoomSpeed;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    zoomSpeed: state.imageViewer.zoomSpeed,
  };
};

const mapDispatchToProps = {
  setZoomSpeed,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ZoomSpeed);
