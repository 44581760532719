import { ReactNode, useState, useEffect, useRef } from "react";
import { useRafState } from "react-use";
import { compose, scale, translate } from "transformation-matrix";
import { IMatrix } from "../../types";
import { IContextValue, context, modeOptions, ModeType } from "./context";
import { useParams } from "react-router-dom";
import { getTopLeft } from "views/AnnotationTool/utils";

const defaultMatrix = compose(translate(-5, -50), scale(1.52, 1.52));
const horizontalImageMarginSize = 100.0;
const bottomImageMarginSize = 60.0;

function calculateDefaultScaleMatrix(
  clientWidth: number,
  clientHeight: number,
  naturalWidth: number,
  naturalHeight: number
) {
  // Calculate maximum scale allowed on height and then use ratio to convert it to the width scale.
  const maxScale = naturalHeight / (clientHeight - bottomImageMarginSize);

  const scaleValue = (naturalWidth + horizontalImageMarginSize) / clientWidth;

  // Counterintuitively smaller scale values make the image bigger.
  return compose(
    translate(horizontalImageMarginSize / 2 + naturalWidth),
    scale(Math.max(scaleValue, maxScale)),
    translate(-clientWidth)
  );
}

interface IProviderProps {
  children: ReactNode;
}

function CanvasProvider({ children }: IProviderProps) {
  // Set up some states to keep track of
  const pathParams = useParams();
  const imageId = parseInt(pathParams.image);
  const [matrix, setMatrix] = useState<IMatrix>(defaultMatrix);
  const [mode, setModeState] = useState<ModeType>(modeOptions.VIEW);
  const [dragged, setDragged] = useState(false);

  const [zoomSpeed, setZoomSpeed] = useState(17);

  const [imageDimensions, changeImageDimensions] = useState({
    naturalWidth: undefined,
    naturalHeight: undefined,
  });
  const [canvasWidth, setCanvasWidth] = useRafState(900);
  const [canvasHeight, setCanvasHeight] = useRafState(900);

  const canvasRef = useRef(null);

  /**
   * The top left corner of the image relative to the canvas.
   */
  const imageTopLeft = getTopLeft(matrix);
  const imageBottomRight = {
    x: imageTopLeft.x + imageDimensions.naturalWidth / matrix.a,
    y: imageTopLeft.y + imageDimensions.naturalHeight / matrix.d,
  };
  const imageLoaded = Boolean(imageDimensions?.naturalWidth);

  function setMode(mode: ModeType) {
    setModeState(mode);
  }

  // Reset matrix to defaultMatrix on imageId change or component unmount
  useEffect(() => {
    return () => {
      setMatrix(defaultMatrix);
    };
  }, [imageId]);

  function resetMatrix() {
    setMatrix(
      calculateDefaultScaleMatrix(
        canvasRef.current.clientWidth,
        canvasRef.current.clientHeight,
        imageDimensions.naturalWidth,
        imageDimensions.naturalHeight
      )
    );
  }

  const payload: IContextValue = {
    mode,
    setMode,
    modeOptions,
    matrix,
    setMatrix,
    resetMatrix,
    imageDimensions,
    changeImageDimensions,
    zoomSpeed,
    setZoomSpeed,
    dragged,
    setDragged,
    canvasWidth,
    canvasHeight,
    setCanvasWidth,
    setCanvasHeight,
    imageTopLeft,
    imageBottomRight,
    imageLoaded,
    canvasRef,
  };

  return <context.Provider value={payload}>{children}</context.Provider>;
}

export default CanvasProvider;
