import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";

export function getNewDefectsFilter(
  searchParams: URLSearchParams
): FilterType["NEW"] {
  const newDefectsParam = searchParams.get(FilterKey.NEW) ?? "";
  return newDefectsParam === "true";
}

export function useNewDefectsFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const newDefects = getNewDefectsFilter(searchParams);

  const setNewDefects = (value: boolean) => {
    if (value) {
      if (searchParams.has(FilterKey.NEW)) searchParams.delete(FilterKey.NEW);
    } else {
      searchParams.set(FilterKey.NEW, "true");
    }
    if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
      searchParams.delete(FilterKey.FILTER_ACTIVE);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { newDefects, setNewDefects };
}
