import { Collapse } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import ImageList from "components/ImageLists";
import { useSelector } from "hooks";

export default function ListPopup() {
  const [searchParams] = useSearchParams();
  const showImageList = useSelector((state) => state.ui.showImageList);
  return (
    <Collapse in={showImageList}>
      <ImageList compact />
    </Collapse>
  );
}
