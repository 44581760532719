import { useContext } from "react";
import { FilterCountContext } from "providers/FilterCountProvider";

export function useWorkflowCount() {
  const context = useContext(FilterCountContext);
  const { workflow, workflowLoading, setWorkflowTabActive } = context;

  return {
    counts: workflow,
    loading: workflowLoading,
    setActive: setWorkflowTabActive,
  };
}
