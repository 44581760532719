import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { RoleWrapper } from "components";
import { MachineDetectedFilter } from "components/Filter/FilterComponents";
import { PolePreviousDefectFilter } from "components/Filter/FilterComponents";
import SeverityLevelFilter from "components/Filter/FilterComponents/SeverityLevelFilter";
import WorkflowFilter from "components/Filter/FilterComponents/WorkflowStatusFilter";
import { useState } from "react";
import translations from "translations";
import { useLanguage } from "hooks";

function Explanation() {
  const [open, setOpen] = useState(false);
  const { language } = useLanguage();
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{ mx: 2, mt: 2 }}
      >
        {translations.Filter.WhatAreTheseNumbers[language]}
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {translations.Filter.WhatAreTheseNumbers.Title[language]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {translations.Filter.WhatAreTheseNumbers.Section1[language]}
          </DialogContentText>
          <DialogContentText sx={{ mb: 2 }}>
            {translations.Filter.WhatAreTheseNumbers.Section2[language]}
          </DialogContentText>
          <DialogContentText>
            {translations.Filter.WhatAreTheseNumbers.Section3[language]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            {translations.Filter.Close[language]}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function SeverityFilters() {
  const { language } = useLanguage();
  return (
    <Stack>
      <SeverityLevelFilter />
      <Explanation />
      <RoleWrapper keyName="advancedFilter">
        <Divider sx={{ mt: 2 }}>
          {translations.Filter.AdvancedFilters[language]}
        </Divider>
        <WorkflowFilter />
        <MachineDetectedFilter />
        <PolePreviousDefectFilter />
      </RoleWrapper>
    </Stack>
  );
}
