import { connect } from "react-redux";
import Component from "./PoleItemPlacementImages";
import { RootState } from "state/store";
import { IssueSeverity, ObjectType, WorkflowStatus } from "interfaces";

interface IStateProps {
  issueSeverities: IssueSeverity[];
  objectTypes: ObjectType[];
  workflowStatus: WorkflowStatus[];
}

interface IDispatchProps {}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    issueSeverities: state.objects.issueSeverities,
    objectTypes: state.objects.objectTypes.filter(
      (t) => !t.skyqraft_only || state.user.skyqraft_employee
    ),
    workflowStatus: state.objects.workflowStatus,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
