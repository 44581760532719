import { axiosInstance } from "utils/request";

interface IFlightCount {
  count: {
    id: number;
    count: number;
    uploadLocation: string;
    project: {
      id: number;
      name: string;
    };
  }[];
}

export async function getFlightCount(
  projectID: number,
  workflow_statuses: number[] = [],
  severities: number[] = [],
  detections: number[] = [],
	area: string = ""
) {
  const headers: Record<string, string> = {
    MissionID: `${projectID}`,
  };

  // Populate the search parameters in a clean way
  const params = new URLSearchParams();
  if (workflow_statuses.length > 0) {
    params.append("workflow_status", workflow_statuses.join(","));
  }
  if (severities.length > 0) {
    params.append("severity", severities.join(","));
  }
  if (detections.length > 0) {
    params.append("detection", detections.join(","));
  }
	if(area.length > 0) {
		params.append("area", area);
	}


  // Make the request
  const response = await axiosInstance.get<IFlightCount>(
    "/object/filter/count/flight",
    {
      headers,
      params,
    }
  );

  if (response.status !== 200) {
    throw new Error("Failed to fetch data");
  }
  const count = response.data.count;
  return count;
}
