export const annotationActions = {
  APPROVE: "approve",
  APPROVE_HIDE: "approve-hide",
  DENY: "deny",
  REQUEST: "request",
};

export const orderImagesByOptions = {
  CONFIDENCE_DESC: "confidence_desc",
  CONFIDENCE_ASC: "confidence_asc",
};
