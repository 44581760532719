import { useContext, useEffect, useState } from "react";
import { Context } from "./context";
import { getImage } from "./utils";
import { useCurrentProject } from "hooks";

export function useSearchValue() {
  const { searchValue, setSearchValue } = useContext(Context);

  return {
    searchValue,
    setSearchValue,
  };
}

export function usePagination() {
  const { pageSize, setPageSize, totalCount, page, setPage } =
    useContext(Context);
  return {
    pageSize,
    setPageSize,
    totalCount,
    page,
    setPage,
  };
}

export function useData() {
  const { data, loading } = useContext(Context);
  return { data, loading };
}

export function useMetaData() {
  const { headers, statuses } = useContext(Context);
  return { headers, statuses };
}

export function useSelection() {
  const {
    selectedPoleId,
    selectedComponentId,
    setSelectedPoleId,
    setSelectedComponentId,
  } = useContext(Context);
  return {
    selectedPoleId,
    selectedComponentId,
    setSelectedPoleId,
    setSelectedComponentId,
  };
}

export function useImageOfPole() {
  type ImageType = Awaited<ReturnType<typeof getImage>>;
  const [image, setImage] = useState<ImageType | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const currentProject = useCurrentProject();

  const { selectedPoleId, selectedComponentId } = useContext(Context);

  async function updateImage(
    projectId: number,
    poleId: number,
    componentId: number
  ) {
    // Fetch image from server
    setLoading(true);
    setError(null);
    try {
      const image = await getImage(projectId, poleId, componentId);
      setImage(image);
    } catch (e) {
      // No need to trigger sentry, since it is
      // an expected error
      if (e.response?.status === 404) {
        setError(`No image found for pole ${selectedPoleId}`);
      } else {
        setError("An error occurred while fetching image");
      }
      setImage(null);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (currentProject?.id && selectedPoleId && selectedComponentId) {
      updateImage(currentProject.id, selectedPoleId, selectedComponentId);
    }
  }, [currentProject?.id, selectedPoleId, selectedComponentId]);

  return { image, loading, error };
}
