import { axiosInstance } from "utils/request";
import { FilterKey } from "hooks/filter/search_parameters/constants";
import {
  workflowStatuses,
  severities,
  reviewModes,
} from "constants/imageReview";
import { annotationActions } from "./constants";

interface IGetImageIds {
  projectId: number;
  orderBy: string;
}
export async function getImageIds(args: IGetImageIds) {
  const { projectId, orderBy } = args;
  const baseQuery = new URLSearchParams(window.location.search);
  const params: Record<string, string> = {};

  const queryToParamsMap = {
    [FilterKey.DETECTION]: "detection",
    [FilterKey.FLAGGED]: "flagged",
    [FilterKey.LIST]: "list",
    [FilterKey.ACTORS]: "actors",
    [FilterKey.SEVERITY]: "severity",
    [FilterKey.FEED_BAY]: "feed_bay",
    [FilterKey.AREA]: "area",
  };

  for (const [queryKey, paramKey] of Object.entries(queryToParamsMap)) {
    if (baseQuery.has(queryKey)) {
      params[paramKey] = baseQuery.get(queryKey);
    }
  }
  params.order_by = orderBy;

  const { data } = await axiosInstance.get<number[]>(
    `/review/${reviewModes.MACHINE_OUTPUT}/project/${projectId}/images`,
    {
      headers: { MissionID: projectId },
      params,
    }
  );

  return data;
}

export function getUpdateAnnotationActionPayload(
  action: (typeof annotationActions)[keyof typeof annotationActions]
): {
  workflow_status?: number;
  skyqraft_hidden?: boolean;
  severity?: number;
} {
  switch (action) {
    case annotationActions.APPROVE:
      return {
        workflow_status: workflowStatuses.TRUE_POSITIVE,
        skyqraft_hidden: false,
      };
    case annotationActions.APPROVE_HIDE:
      return {
        workflow_status: workflowStatuses.TRUE_POSITIVE,
        skyqraft_hidden: true,
      };
    case annotationActions.DENY:
      return {
        workflow_status: workflowStatuses.FALSE_POSITIVE,
        severity: severities.MACHINE_DETECTED,
      };
    case annotationActions.REQUEST:
      return {
        workflow_status: workflowStatuses.REVIEW_REQUESTED,
        skyqraft_hidden: false,
      };
  }
}
