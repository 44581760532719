import { useContext, useEffect, useRef } from "react";
import ItemColumn from "../ItemColumn";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { context } from "./ComponentOptionsProvider";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

type paramTypes = Record<"component_id", string>;

export default function EditComponent() {
  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const { components, updateComponent, deleteComponent, valueSystems } =
    useContext(context);
  const params = useParams<paramTypes>();
  const componentID = parseInt(params.component_id);

  const component = components.find(
    (component) => component.id === componentID
  );

  useEffect(() => {
    // Update the name value when swapping component
    if (component && nameRef.current) {
      nameRef.current.value = component.name;
    }
  }, [component]);

  function onFormSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name") as string;
    const valueSystemID = parseInt(formData.get("valueSystem") as string);
    let newName = null;
    let newValueSystem = null;

    if (!name || name === "") {
      toast.error("The new name has to be at least 1 character long");
      return;
    }
    if (!valueSystemID) {
      toast.error("You have to select a value system");
      return;
    }

    if (name !== component?.name) {
      newName = name;
    }
    if (valueSystemID !== component?.value_system_id) {
      newValueSystem = valueSystemID;
    }

    updateComponent(componentID, name, valueSystemID);
  }

  function onDelete() {
    const confirmation = window.confirm(
      "Are you sure you want to delete this component? All templates using this component will be altered. All placements connected to this component will also be deleted."
    );
    if (confirmation) {
      deleteComponent(componentID);
    }
  }

  if (!component || !component.name) {
    return (
      <ItemColumn
        title="Component not found"
        description="The specified component could not be found."
      />
    );
  }

  return (
    <ItemColumn
      title={component ? component.name : "Unknown component"}
      description="Edit the properties of this component below"
      onSave={() =>
        onFormSubmit({ target: formRef.current, preventDefault: () => {} })
      }
    >
      <form onSubmit={onFormSubmit} ref={formRef}>
        <Stack spacing={2}>
          <FormControl>
            <Typography>Name</Typography>
            <TextField
              defaultValue={component.name}
              inputRef={nameRef}
              name="name"
              variant="standard"
            />
          </FormControl>
          <FormControl>
            <Typography>Value system</Typography>
            <Select
              name="valueSystem"
              variant="standard"
              fullWidth
              label="Value system"
              defaultValue={component.value_system_id}
            >
              {valueSystems.map((system) => (
                <MenuItem value={system.id}>{system.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack mt={2} spacing={1} direction="row">
          <Button type="submit" variant="contained" color="success">
            Save
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      </form>
    </ItemColumn>
  );
}
