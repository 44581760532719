import { Filter } from "@mui/icons-material";
import { Chip, Stack, Typography } from "@mui/material";
import { FILTERSTATUS } from "../constants";
import { useLanguage } from "hooks";
import { translations } from "../translations";

interface IProps {
  filterActive: boolean;
  setFilterActive: (value: boolean) => void;
  filterValue: boolean;
  setFilterValue: (value: boolean) => void;
}

type FilterTypes = (typeof FILTERSTATUS)[keyof typeof FILTERSTATUS];

export default function FilterButtons({
  filterActive,
  setFilterActive,
  filterValue,
  setFilterValue,
}: IProps) {
  const { language } = useLanguage();
  let currentFilter: FilterTypes = FILTERSTATUS.INACTIVE;
  if (filterActive) {
    if (filterValue) {
      currentFilter = FILTERSTATUS.CONNECTED;
    } else {
      currentFilter = FILTERSTATUS.NOTCONNECTED;
    }
  }

  let color: "default" | "info" | "error" = "default";
  switch (currentFilter) {
    case FILTERSTATUS.CONNECTED:
      color = "info";
      break;
    case FILTERSTATUS.NOTCONNECTED:
      color = "error";
      break;
    default:
      color = "default";
  }

  function onClick() {
    switch (currentFilter) {
      case FILTERSTATUS.CONNECTED:
        setFilterValue(false);
        break;
      case FILTERSTATUS.NOTCONNECTED:
        setFilterValue(false);
        setFilterActive(false);
        break;
      case FILTERSTATUS.INACTIVE:
        setFilterActive(true);
        setFilterValue(true);
        break;
      default:
        setFilterActive(false);
        setFilterValue(false);
    }
  }

  const filterTextMap = {
    [FILTERSTATUS.INACTIVE]: translations.NoFilterActive[language],
    [FILTERSTATUS.CONNECTED]: translations.ShowingConnectedImages[language],
    [FILTERSTATUS.NOTCONNECTED]:
      translations.ShowingDisconnectedImages[language],
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={1}
      mb={1}
    >
      <Chip
        data-testid="image-connector-filter-button"
        label="Connected"
        icon={<Filter />}
        color={color}
        onClick={onClick}
      />

      <Typography
        data-testid={`image-connector-filter-explanation-${currentFilter}`}
      >
        {filterTextMap[currentFilter]}
      </Typography>
    </Stack>
  );
}
