/*
This might be a larger file,
but it is simply the same pattern repeated for each count.

We have a context that provides the counts
and loading state for each count type.

We then have a hook that consumes this context
and provides the counts and loading state
to the component that needs it.

This allows the component to be decoupled
from the context and the hook
and allows for easier testing of the component.

This pattern can be repeated for any count type
and allows for easy extension of the counts
without having to modify the component
that consumes the counts.

This pattern can also be extended to other
contexts and hooks that provide data
to the components.

*/
import {
  getDefectCount,
  getDetectionCount,
  getFilterActors,
  getFlightCount,
  getSeverityCount,
  getWorkflowCount,
  getSavedFilters,
} from "api";
import {
  useAreaFilter,
  useCurrentProject,
  useFlightFilter,
  useObjectTypeFilter,
  useSeverityFilter,
  useWorkflowFilter,
} from "hooks";

import { createContext, useCallback, useEffect, useState } from "react";

type SeverityCount = Awaited<ReturnType<typeof getSeverityCount>>[number];
type WorkflowCount = Awaited<ReturnType<typeof getWorkflowCount>>[number];
type DefectCount = Awaited<ReturnType<typeof getDefectCount>>[number];
type DetectionCount = Awaited<ReturnType<typeof getDetectionCount>>[number];
type FlightCount = Awaited<ReturnType<typeof getFlightCount>>[number];
type ActorCount = Awaited<ReturnType<typeof getFilterActors>>[number];
type ISavedFilter = Awaited<ReturnType<typeof getSavedFilters>>[number];

export interface IFilterCountContext {
  severity: SeverityCount[];
  workflow: WorkflowCount[];
  defect: DefectCount[];
  detection: DetectionCount[];
  flight: FlightCount[];
  actor: ActorCount[];
  savedFilters: ISavedFilter[];
  updateSavedFilters: () => void;
  severityLoading: boolean;
  workflowLoading: boolean;
  defectLoading: boolean;
  detectionLoading: boolean;
  flightLoading: boolean;
  actorLoading: boolean;
  savedFiltersLoading: boolean;
  severityTabActive: boolean;
  workflowTabActive: boolean;
  defectTabActive: boolean;
  detectionTabActive: boolean;
  flightTabActive: boolean;
  actorTabActive: boolean;
  savedFiltersTabActive: boolean;
  setSeverityTabActive: (active: boolean) => void;
  setWorkflowTabActive: (active: boolean) => void;
  setDefectTabActive: (active: boolean) => void;
  setDetectionTabActive: (active: boolean) => void;
  setFlightTabActive: (active: boolean) => void;
  setActorTabActive: (active: boolean) => void;
  setSavedFiltersTabActive: (active: boolean) => void;
}

export const FilterCountContext = createContext<IFilterCountContext>({
  severity: [],
  workflow: [],
  defect: [],
  detection: [],
  flight: [],
  actor: [],
  savedFilters: [],
  updateSavedFilters: () => {},
  severityLoading: false,
  workflowLoading: false,
  defectLoading: false,
  detectionLoading: false,
  flightLoading: false,
  actorLoading: false,
  savedFiltersLoading: false,
  severityTabActive: false,
  workflowTabActive: false,
  defectTabActive: false,
  detectionTabActive: false,
  flightTabActive: false,
  actorTabActive: false,
  savedFiltersTabActive: false,
  setSeverityTabActive: () => {},
  setWorkflowTabActive: () => {},
  setDefectTabActive: () => {},
  setDetectionTabActive: () => {},
  setFlightTabActive: () => {},
  setActorTabActive: () => {},
  setSavedFiltersTabActive: () => {},
});

export function FilterCountProvider({ children }) {
  const currentProject = useCurrentProject();

  const [severityLoading, setSeverityLoading] = useState(false);
  const [workflowLoading, setWorkflowLoading] = useState(false);
  const [defectLoading, setDefectLoading] = useState(false);
  const [detectionLoading, setDetectionLoading] = useState(false);
  const [flightLoading, setFlightLoading] = useState(false);
  const [actorLoading, setActorLoading] = useState(false);
  const [savedFiltersLoading, setSavedFiltersLoading] = useState(false);

  const [severityTabActive, setSeverityTabActive] = useState(false);
  const [workflowTabActive, setWorkflowTabActive] = useState(false);
  const [defectTabActive, setDefectTabActive] = useState(false);
  const [detectionTabActive, setDetectionTabActive] = useState(false);
  const [flightTabActive, setFlightTabActive] = useState(false);
  const [actorTabActive, setActorTabActive] = useState(false);
  const [savedFiltersTabActive, setSavedFiltersTabActive] = useState(false);

  const [shouldUpdateSeverity, setShouldUpdateSeverity] = useState(false);
  const [shouldUpdateWorkflow, setShouldUpdateWorkflow] = useState(false);
  const [shouldUpdateDefect, setShouldUpdateDefect] = useState(false);
  const [shouldUpdateDetection, setShouldUpdateDetection] = useState(false);
  const [shouldUpdateFlight, setShouldUpdateFlight] = useState(false);
  const [shouldUpdateActor, setShouldUpdateActor] = useState(false);
  const [shouldUpdateSavedFilters, setShouldUpdateSavedFilters] =
    useState(false);

  const [severity, setSeverity] = useState<SeverityCount[]>([]);
  const [workflow, setWorkflow] = useState<WorkflowCount[]>([]);
  const [defect, setDefect] = useState<DefectCount[]>([]);
  const [detection, setDetection] = useState<DetectionCount[]>([]);
  const [flight, setFlight] = useState<FlightCount[]>([]);
  const [actor, setActor] = useState<ActorCount[]>([]);
  const [savedFilters, setSavedFilters] = useState<ISavedFilter[]>([]);

  // Extract flight IDs
  const { flightFilter } = useFlightFilter();
  const { workflowFilter } = useWorkflowFilter();
  const { objectTypeFilter } = useObjectTypeFilter();
  const { severityFilter } = useSeverityFilter();
  const { area } = useAreaFilter();

  // Fetch the counts without any dependencies
  // such that the consumer of the hook
  // can call refetch to get the latest counts
  // without having to worry about props
  const updateSeverityCount = useCallback(
    (projectID: number) => {
      // We need a project
      if (!severityTabActive) return;
      if (severity.length > 0) return;
      if (severityLoading) return;

      // We cleared it. Now we are updating
      setSeverityLoading(true);
      const promise = getSeverityCount(
        projectID,
        flightFilter,
        workflowFilter,
        objectTypeFilter,
        area
      );

      promise
        .then(setSeverity)
        .catch(console.error)
        .finally(() => setSeverityLoading(false));
    },
    [
      flightFilter,
      workflowFilter,
      objectTypeFilter,
      severityTabActive,
      severity.length,
      severityLoading,
      area,
    ]
  );
  const updateWorkflowCount = useCallback(
    (projectID: number) => {
      // We need a project
      if (!workflowTabActive) return;
      if (workflow.length > 0) return;
      if (workflowLoading) return;

      setWorkflowLoading(true);
      const promise = getWorkflowCount(
        projectID,
        flightFilter,
        severityFilter,
        objectTypeFilter,
        area
      );

      promise
        .then(setWorkflow)
        .catch(console.error)
        .finally(() => setWorkflowLoading(false));
    },
    [
      flightFilter,
      severityFilter,
      objectTypeFilter,
      workflowTabActive,
      workflow.length,
      workflowLoading,
      area,
    ]
  );
  const updateDefectCount = useCallback(
    (projectID: number) => {
      // We need a project
      if (!defectTabActive) return;
      if (defect.length > 0) return;
      if (defectLoading) return;

      setDefectLoading(true);
      const promise = getDefectCount(
        projectID,
        severityFilter,
        flightFilter,
        workflowFilter,
        area
      );

      promise
        .then(setDefect)
        .catch(console.error)
        .finally(() => setDefectLoading(false));
    },
    [
      flightFilter,
      severityFilter,
      workflowFilter,
      defectTabActive,
      defect.length,
      defectLoading,
      area,
    ]
  );
  const updateDetectionCount = useCallback(
    (projectID: number) => {
      // We need a project
      if (!detectionTabActive) return;
      if (detection.length > 0) return;
      if (detectionLoading) return;

      setDetectionLoading(true);
      const promise = getDetectionCount(
        projectID,
        flightFilter,
        workflowFilter,
        area
      );

      promise
        .then(setDetection)
        .catch(console.error)
        .finally(() => setDetectionLoading(false));
    },
    [
      flightFilter,
      workflowFilter,
      detectionTabActive,
      detection.length,
      detectionLoading,
      area,
    ]
  );
  const updateFlightCount = useCallback(
    (projectID: number) => {
      // We need a project
      if (!flightTabActive) return;
      if (flight.length > 0) return;
      if (flightLoading) return;

      setFlightLoading(true);
      const promise = getFlightCount(
        projectID,
        workflowFilter,
        severityFilter,
        objectTypeFilter,
        area
      );

      promise
        .then(setFlight)
        .catch(console.error)
        .finally(() => setFlightLoading(false));
    },
    [
      workflowFilter,
      severityFilter,
      objectTypeFilter,
      flightTabActive,
      flight.length,
      flightLoading,
      area,
    ]
  );
  const updateActorCount = useCallback(
    (projectID: number) => {
      // We need a project
      if (!actorTabActive) return;
      if (actor.length > 0) return;
      if (actorLoading) return;

      setActorLoading(true);
      const promise = getFilterActors(projectID);

      promise
        .then(setActor)
        .catch(console.error)
        .finally(() => setActorLoading(false));
    },
    [actorTabActive, actor.length, actorLoading]
  );
  const updateSavedFilters = useCallback(() => {
    if (savedFiltersLoading) return;

    setSavedFiltersLoading(true);
    const promise = getSavedFilters();

    promise
      .then(setSavedFilters)
      .catch(console.error)
      .finally(() => setSavedFiltersLoading(false));
  }, [savedFilters.length, savedFiltersLoading]);

  // Set the update flag whenever
  // a filter dependency changes.
  // We should also clear the old counts
  // such that we don't show incorrect
  // counts to the user

  const flightFilterString = JSON.stringify(flightFilter);
  const workflowFilterString = JSON.stringify(workflowFilter);
  const objectTypeFilterString = JSON.stringify(objectTypeFilter);
  const severityFilterString = JSON.stringify(severityFilter);
  const areaFilterString = JSON.stringify(area);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want trigger anyways
  useEffect(() => {
    setSeverity([]);
    setShouldUpdateSeverity(true);
    setWorkflow([]);
    setShouldUpdateWorkflow(true);
    setDefect([]);
    setShouldUpdateDefect(true);
    setDetection([]);
    setShouldUpdateDetection(true);
    setFlight([]);
    setShouldUpdateFlight(true);
    setActor([]);
    setShouldUpdateActor(true);
    setSavedFilters([]);
    setShouldUpdateSavedFilters(true);
  }, [currentProject?.id]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want trigger on filter change
  useEffect(() => {
    setSeverity([]);
    setShouldUpdateSeverity(true);
  }, [
    flightFilterString,
    workflowFilterString,
    objectTypeFilterString,
    areaFilterString,
  ]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want trigger on filter change
  useEffect(() => {
    setWorkflow([]);
    setShouldUpdateWorkflow(true);
  }, [
    flightFilterString,
    severityFilterString,
    objectTypeFilterString,
    areaFilterString,
  ]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want trigger on filter change
  useEffect(() => {
    setDefect([]);
    setShouldUpdateDefect(true);
  }, [
    flightFilterString,
    workflowFilterString,
    severityFilterString,
    areaFilterString,
  ]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want trigger on filter change
  useEffect(() => {
    setDetection([]);
    setShouldUpdateDetection(true);
  }, [flightFilterString, workflowFilterString, areaFilterString]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want trigger on filter change
  useEffect(() => {
    setFlight([]);
    setShouldUpdateFlight(true);
  }, [
    workflowFilterString,
    severityFilterString,
    objectTypeFilterString,
    areaFilterString,
  ]);
  useEffect(() => {
    setActor([]);
    setShouldUpdateActor(true);
  }, []);
  useEffect(() => {
    setSavedFilters([]);
    setShouldUpdateSavedFilters(true);
  }, []);

  // Trigger an update of the counts
  // whenever a tab is activated and/or
  // the shouldUpdate flag is set
  useEffect(() => {
    if (!shouldUpdateSeverity) return;
    if (!severityTabActive) return;
    if (!currentProject?.id) return;
    setShouldUpdateSeverity(false);
    updateSeverityCount(currentProject.id);
  }, [
    shouldUpdateSeverity,
    severityTabActive,
    currentProject?.id,
    updateSeverityCount,
  ]);
  useEffect(() => {
    if (!shouldUpdateWorkflow) return;
    if (!workflowTabActive) return;
    if (!currentProject?.id) return;
    setShouldUpdateWorkflow(false);
    updateWorkflowCount(currentProject.id);
  }, [
    shouldUpdateWorkflow,
    workflowTabActive,
    currentProject?.id,
    updateWorkflowCount,
  ]);
  useEffect(() => {
    if (!shouldUpdateDefect) return;
    if (!defectTabActive) return;
    if (!currentProject?.id) return;
    setShouldUpdateDefect(false);
    updateDefectCount(currentProject.id);
  }, [
    shouldUpdateDefect,
    defectTabActive,
    currentProject?.id,
    updateDefectCount,
  ]);
  useEffect(() => {
    if (!shouldUpdateDetection) return;
    if (!detectionTabActive) return;
    if (!currentProject) return;
    setShouldUpdateDetection(false);
    updateDetectionCount(currentProject.id);
  }, [
    shouldUpdateDetection,
    detectionTabActive,
    currentProject?.id,
    updateDetectionCount,
  ]);
  useEffect(() => {
    if (!shouldUpdateFlight) return;
    if (!flightTabActive) return;
    if (!currentProject?.id) return;
    setShouldUpdateFlight(false);
    updateFlightCount(currentProject.id);
  }, [
    shouldUpdateFlight,
    flightTabActive,
    currentProject?.id,
    updateFlightCount,
  ]);
  useEffect(() => {
    if (!shouldUpdateActor) return;
    if (!actorTabActive) return;
    if (!currentProject?.id) return;
    setShouldUpdateActor(false);
    updateActorCount(currentProject.id);
  }, [shouldUpdateActor, actorTabActive, currentProject?.id, updateActorCount]);
  useEffect(() => {
    if (!shouldUpdateSavedFilters) return;
    if (!savedFiltersTabActive) return;
    setShouldUpdateSavedFilters(false);
    updateSavedFilters();
  }, [shouldUpdateSavedFilters, savedFiltersTabActive, updateSavedFilters]);

  return (
    <FilterCountContext.Provider
      value={{
        severity,
        workflow,
        defect,
        detection,
        flight,
        actor,
        savedFilters,
        updateSavedFilters,
        severityLoading,
        workflowLoading,
        defectLoading,
        detectionLoading,
        flightLoading,
        actorLoading,
        savedFiltersLoading,
        severityTabActive,
        workflowTabActive,
        defectTabActive,
        detectionTabActive,
        flightTabActive,
        actorTabActive,
        savedFiltersTabActive,
        setSeverityTabActive,
        setWorkflowTabActive,
        setDefectTabActive,
        setDetectionTabActive,
        setFlightTabActive,
        setActorTabActive,
        setSavedFiltersTabActive,
      }}
    >
      {children}
    </FilterCountContext.Provider>
  );
}
