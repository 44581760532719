import { useState, useEffect } from "react";
import { assignmentStatus } from "api";

interface IProps {
  mission?: number;
  supervisor?: boolean;
  grouping?: "assignment" | "user" | "mission";
}

type IAssignmentStatus = Awaited<
  ReturnType<typeof assignmentStatus.get>
>[number];

export function useAssignmentStatus({
  mission,
  supervisor,
  grouping = "mission",
}: IProps = {}): [IAssignmentStatus[], boolean] {
  const [assignments, setAssignments] = useState<IAssignmentStatus[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Send a request
    setLoading(true);
    assignmentStatus
      .get({ supervisor, projectID: mission, grouping })
      .then((assignments) => {
        setLoading(false);
        setAssignments(assignments);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Failed to collect assignments", err);
      });
  }, [mission, grouping, supervisor]);

  return [assignments, loading];
}
