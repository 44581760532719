import { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import isEqual from "lodash/isEqual";
import { FilterKey, FilterType } from "./constants";

export function getObjectTypeFilter(
	searchParams: URLSearchParams,
): FilterType["DETECTION"] {
	const objectTypeParams = searchParams.get(FilterKey.DETECTION);
	return objectTypeParams ? objectTypeParams.split(",").map(Number) : [];
}

export function useObjectTypeFilter() {
	const [searchParams, setSearchParams] = useSearchParams();
	const objectTypesRef = useRef(getObjectTypeFilter(searchParams));

	const objectTypes = useMemo(() => {
		const updatedObjectTypes = getObjectTypeFilter(searchParams);

		if (!isEqual(updatedObjectTypes, objectTypesRef.current)) {
			objectTypesRef.current = updatedObjectTypes;
		}
		return objectTypesRef.current;
	}, [searchParams]);

	function setObjectTypes(objectTypes: number[]) {
		if (objectTypes.length === 0) {
			if (searchParams.has(FilterKey.DETECTION))
				searchParams.delete(FilterKey.DETECTION);
		} else {
			searchParams.set(FilterKey.DETECTION, objectTypes.join(","));
		}
		if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
			searchParams.delete(FilterKey.FILTER_ACTIVE);
		}
		setSearchParams(searchParams, { replace: true });
	}

	return {
		objectTypeFilter: objectTypes,
		setObjectTypes,
	};
}
