import React, { useState, useEffect } from "react";
import { Input, Switch } from "@mui/material";
import Select from "react-select";
import FormControlLabel from "@mui/material/FormControlLabel";

type Props = {
  getNewData;
};

const defaultValues = {
  number: 1337,
  text: "",
  color: "#FF0000",
};

export default function GetData({ getNewData }: Props) {
  const [canSave, setCanSave] = useState(true);

  const defaultData = getNewData.fields.reduce((acc, field) => {
    acc[field.slug] = field?.value ?? acc[field.type];
    return acc;
  }, {});

  const [data, setData] = useState(defaultData);

  function updateField(slug, value) {
    setData({
      ...data,
      [slug]: value,
    });
  }

  useEffect(() => {
    const value = getNewData.fields
      .map((field) => {
        if ("isValid" in field) {
          return field.isValid(data[field.slug]);
        } else {
          return true;
        }
      })
      .every((f) => f);
    setCanSave(value);
  }, [data, getNewData.fields, setCanSave]);

  const submit = () => {
    getNewData.onSubmit(data);
  };

  const saveData = (e) => {
    if (e.key === "Enter") {
      if (canSave) {
        submit();
      } else {
        alert("The inserted values are not valid. Please check all red boxes.");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", saveData);
    return () => {
      window.removeEventListener("keydown", saveData);
    };
  });

  return (
    <div className="popup-wrapper">
      <div className="popup-container">
        <h2>{getNewData.title}</h2>
        <div className="field-row">
          {getNewData.fields.map((item) => {
            if (item.type === "select") {
              return (
                <div key={item.slug}>
                  <p>{item.name}</p>
                  <Select
                    className="MuiInputBase-fullWidth MuiInput-fullWidth"
                    isSearchable
                    name={item.slug}
                    options={item.options.filter((e) =>
                      data.issue === undefined || e.issue === undefined
                        ? true
                        : e.issue.toString() === data.issue.toString()
                    )}
                    value={item.options.find(
                      (i) => i.value === data[item.slug]
                    )}
                    defaultValue={item.options[0].value}
                    onChange={(e) => updateField(item.slug, e.value)}
                    styles={{
                      // @ts-ignore
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "100%",
                      }),
                    }}
                  />
                </div>
              );
            } else {
              return (
                <div className="split" key={item.slug}>
                  <p>{item.name}</p>
                  <Input
                    type={item.type}
                    value={data[item.slug]}
                    disableUnderline={item.type === "color"}
                    fullWidth
                    error={
                      "isValid" in item ? !item.isValid(data[item.slug]) : false
                    }
                    onChange={(e) => updateField(item.slug, e.target.value)}
                  />
                </div>
              );
            }
          })}
        </div>
        <div className="split">
          <button className="cancel" onClick={getNewData.onCancel}>
            Cancel
          </button>
          <button className="submit" onClick={submit} disabled={!canSave}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
