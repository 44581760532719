import React, { ReactElement } from "react";
import { Image, ExpandLess, ExpandMore } from "@mui/icons-material";
import translations from "translations";
import { Typography, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ListViewMarker } from "interfaces";
import LazyLoadImage from "../../../image/LazyLoadImage";
import { useLanguage } from "hooks";

interface Props {
  setListViewExpandImageId: Function;
  expandImageId;
  objectTypes;
  imageId: number | null;
  marker: ListViewMarker;
  gmap: google.maps.Map;
}

export default function ImageSection({
  marker,
  setListViewExpandImageId,
  expandImageId,
  objectTypes,
  imageId,
  gmap,
}: Props): ReactElement {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const params = useParams();
  let missionId: string | number = params.mission;

  let goToImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.location.pathname.includes("/annotate")) {
      navigate(`/${missionId}/${marker.id}/annotate${window.location.search}`);
    } else {
      navigate(`/${missionId}/${marker.id}${window.location.search}`);
    }

    gmap.panTo({ lat: marker.lat, lng: marker.lng });
    gmap.setZoom(18);
    let referenceWidth = 0.25;
    const slit = 1 - referenceWidth;
    const offset = referenceWidth - 0.5;
    const x = offset + slit / 2;
    gmap.panBy(-x * window.innerWidth, 0);
  };

  return (
    <>
      <div
        className="listViewItem"
        style={{
          backgroundColor: imageId === marker.id && "#efdfcc",
          padding: "2px 0",
          borderRadius: "8px",
        }}
        onClick={(e) => goToImage(e)}
      >
        <div className="listItemStart">
          <Image htmlColor="white" />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              paddingLeft: "10px",
            }}
          >
            {marker.id}
          </Typography>
        </div>

        <div className="listItemEnd">
          <Tooltip
            title={translations.ListView.NumberOfDefects[language]}
            placement="left"
            arrow
          >
            <div className="listSeverityCount">{marker.severity_count}</div>
          </Tooltip>
          <div
            onClick={(e) => {
              e.stopPropagation();
              expandImageId.includes(marker.id)
                ? setListViewExpandImageId(
                    expandImageId.filter((e) => e !== marker.id)
                  )
                : setListViewExpandImageId([...expandImageId, marker.id]);
            }}
          >
            {expandImageId.includes(marker.id) ? (
              <ExpandLess sx={{ paddingRight: "5px" }} />
            ) : (
              <ExpandMore sx={{ paddingRight: "5px" }} />
            )}
          </div>
        </div>
      </div>

      {expandImageId.includes(marker.id) && (
        <div className="listViewImageSection">
          <div
            style={{
              position: "relative",
              margin: "6px",
            }}
            className={`clusterPreviewImage ${marker.id} ${
              true ? "imageOverlay" : ""
            }`}
          >
            <LazyLoadImage
              onLoad={() => {}}
              onClick={(e) => {
                goToImage(e);
              }}
              defaultWidth={133}
              alt="A preview"
              image={marker.id}
              skyqraft_employee={true}
              src={marker.src}
              canLoad={true}
              objectTypes={objectTypes}
              bbox={true}
              annotatorObjectColor={false}
            />
          </div>
        </div>
      )}
    </>
  );
}
