import { useSearchParams } from "react-router-dom";
import { setDateOfKey } from "./utils";
import { FilterKey, FilterType } from "./constants";

export function getDateImageUploadedFilter(
  searchParams: URLSearchParams
): FilterType["DATE_IMAGE_UPLOADED"] {
  const dateImageUploadedParams =
    searchParams.get(FilterKey.DATE_IMAGE_UPLOADED) ?? "";
  const splitDates = dateImageUploadedParams
    .split(",")
    .filter((d) => d.length > 0);
  let dateImageUploaded: FilterType["DATE_IMAGE_UPLOADED"] = null;
  if (splitDates.length === 1) {
    splitDates.push(splitDates[0]);
    dateImageUploaded = new Date(splitDates[0]);
  } else if (splitDates.length === 2) {
    dateImageUploaded = [new Date(splitDates[0]), new Date(splitDates[1])];
  } else if (splitDates.length > 2) {
    console.error("Too many dates in the dateImageUploaded filter");
    dateImageUploaded = [new Date(splitDates[0]), new Date(splitDates[1])];
  }
  return dateImageUploaded;
}

export function useDateImageUploadedFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  // Collect the search parameters
  const dateImageUploaded = getDateImageUploadedFilter(searchParams);

  // Convert the key function to each specific key
  function setDateImageUploaded(startDate: Date | null, endDate: Date | null) {
    setDateOfKey(
      searchParams,
      setSearchParams,
      FilterKey.DATE_IMAGE_UPLOADED,
      startDate,
      endDate
    );
  }

  // Export the functions and filters
  return {
    dateImageUploaded,
    setDateImageUploaded,
  };
}
