import { useSearchParams } from "react-router-dom";
import { setDateOfKey } from "./utils";
import { FilterKey, FilterType } from "./constants";

export function getDateDefectFixedFilter(
  searchParams: URLSearchParams
): FilterType["DATE_DEFECT_FIXED"] {
  const dateDefectFixedParams =
    searchParams.get(FilterKey.DATE_DEFECT_FIXED) ?? "";

  const splitDates = dateDefectFixedParams
    .split(",")
    .filter((d) => d.length > 0);
  let dateDefectFixed: FilterType["DATE_DEFECT_FIXED"] = null;
  if (splitDates.length === 1) {
    splitDates.push(splitDates[0]);
    dateDefectFixed = new Date(splitDates[0]);
  } else if (splitDates.length === 2) {
    dateDefectFixed = [new Date(splitDates[0]), new Date(splitDates[1])];
  } else if (splitDates.length > 2) {
    console.error("Too many dates in the dateDefectFixed filter");
    dateDefectFixed = [new Date(splitDates[0]), new Date(splitDates[1])];
  }

  return dateDefectFixed;
}

export function useDateDefectFixedFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  // Collect the search parameters
  const dateDefectFixed = getDateDefectFixedFilter(searchParams);

  // Convert the key function to each specific key
  function setDateDefectFixed(startDate: Date | null, endDate: Date | null) {
    setDateOfKey(
      searchParams,
      setSearchParams,
      FilterKey.DATE_DEFECT_FIXED,
      startDate,
      endDate
    );
  }

  // Export the functions and filters
  return {
    dateDefectFixed,
    setDateDefectFixed,
  };
}
