export function setInBubble(inBubble: boolean) {
  return {
    type: "SET_IN_BUBBLE",
    payload: inBubble,
  };
}
export function setExitURL(exitURL: string) {
  return {
    type: "SET_EXIT_URL",
    payload: exitURL,
  };
}

export function setInitialURL(initialURL: string) {
  return {
    type: "SET_INITIAL_URL",
    payload: initialURL,
  };
}
