import { useRef, useEffect, useContext, useState } from "react";
import { useRafState } from "react-use";
import { useInputListener } from "../../hooks";
import { Alert, Box, Stack, Tab, Tabs } from "@mui/material";
import CanvasBox from "../CanvasBox";
import { drawStrawman } from "../../utils";
import AddBoxButton from "../AddBoxButton";
import { AnnotationContext, CanvasContext } from "../../provider";
import EditBoxButton from "../EditBoxButton";
import { useNewBoxListener } from "../../hooks/newBoxListener";
import ExitButton from "../ExitButton";
import PoleContentWindow from "../PoleContents/PoleContentWindow";
import { useParams, useSearchParams } from "react-router-dom";

interface IBox {
  x: number;
  y: number;
  width: number;
  height: number;
}
const zoomSpeed = 3;

interface IProps {
  width: number;
  imagePoleID: number | null;
  menuOpen: boolean;
}

export function Canvas({ width, imagePoleID, menuOpen }: IProps) {
  const annotationData = useContext(AnnotationContext);
  const params = useParams();
  const currentImage = parseInt(params.image);
  const annotations = annotationData.data;
  const path = annotationData.path;
  const { matrix } = useContext(CanvasContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const poleID = parseInt(searchParams.get("pole"));

  const canvasEl = useRef<HTMLCanvasElement | null>(null);

  const [currentBox, setCurrentBox] = useRafState<IBox>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      const canvas = canvasEl.current;
      if (!canvas) return;
      drawStrawman({
        canvas,
        matrix,
        path,
      });
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    const canvas = canvasEl.current;
    if (!canvas) return;
    drawStrawman({
      canvas,
      matrix,
      path,
    });
  }, [width]);

  // Let the custom hook manage matrix
  // manipulations so we can navigate
  // properly around the strawman
  useInputListener({
    canvasRef: canvasEl,
    zoomSpeed,
  });
  useNewBoxListener({
    canvasRef: canvasEl,
    setCurrentBox,
  });

  // Draw the strawman of the pole
  // once the canvas is loaded
  useEffect(() => {
    const canvas = canvasEl.current;
    if (!canvas) return;
    drawStrawman({
      canvas,
      matrix,
      path,
    });
  }, [canvasEl, matrix, path, menuOpen]);

  // Extract some
  const canvas = canvasEl.current;
  const canvasWidth = canvas?.clientWidth ?? 0;
  const canvasHeight = canvas?.clientHeight ?? 0;
  const [value, setValue] = useState(0);

  //update the tab value depenifing on the url search params
  useEffect(() => {
    if (searchParams.has("poleStatus")) {
      setValue(0);
    } else {
      setValue(1);
    }
  }, [searchParams]);

  return (
    <>
      <Tabs
        value={value}
        aria-label="tabs"
        centered
        sx={{ zIndex: 1000, position: "relative" }}
      >
        <Tab
          label="Component Edit"
          onClick={() => {
            searchParams.set("poleStatus", "true");
            setSearchParams(searchParams);
          }}
        />
        <Tab
          label="Strawman View"
          onClick={() => {
            searchParams.delete("poleStatus");
            setSearchParams(searchParams);
          }}
        />
        <ExitButton />
      </Tabs>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        <Stack
          sx={{
            zIndex: 10,
            top: 0,
            left: 0,
            position: "absolute",
            width: "100%",
            height: "fit-content",
          }}
        >
          {poleID !== imagePoleID &&
            !!currentImage &&
            annotationData.loading && (
              <Alert severity="warning">
                The current image is not connected to this structure!
              </Alert>
            )}
          {!menuOpen && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              sx={{
                p: poleID !== imagePoleID && !!currentImage ? 1 : 2,
                pr: poleID !== imagePoleID && !!currentImage ? 2 : 2,
              }}
            >
              <EditBoxButton />
              <AddBoxButton />
            </Stack>
          )}
        </Stack>
        {!menuOpen ? (
          <div>
            <canvas
              style={{
                width: "100%",
                height: "calc(100vh - 56px)",
                position: "relative",
                zIndex: 2,
                touchAction: "none",
              }}
              id="imageCanvas"
              ref={canvasEl}
            />
            {canvas &&
              canvasWidth &&
              canvasHeight &&
              annotations &&
              annotations.map((annotation) => (
                <CanvasBox
                  key={annotation.id}
                  annotation={annotation}
                  canvasWidth={canvasWidth}
                />
              ))}
            {currentBox.width > 0 &&
              currentBox.height > 0 &&
              currentBox.y &&
              currentBox.x && (
                <CanvasBox
                  annotation={{
                    id: width,
                    x: currentBox.x,
                    y: currentBox.y,
                    w: currentBox.width,
                    h: currentBox.height,
                    items: [],
                  }}
                  canvasWidth={canvasWidth}
                />
              )}
          </div>
        ) : (
          <PoleContentWindow />
        )}
      </Box>
    </>
  );
}
export default Canvas;
