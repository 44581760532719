import RoleWrapper from "components/RoleHOC/wrapper";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { List } from "@mui/icons-material";
import { useDispatch, useLanguage, useSelector } from "hooks";
import { setImageListVisibility } from "state/actions";

export default function Lists() {
  const dispatch = useDispatch();
  const imageListVisibility = useSelector((state) => state.ui.showImageList);
  const { language } = useLanguage();
  const toggleLists = () => {
    dispatch(setImageListVisibility(!imageListVisibility));
  };

  return (
    <RoleWrapper keyName="addImageToList">
      <MenuItem
        tooltip={translations.ImageViewer.List[language]}
        onClick={toggleLists}
        id="image-assign-to-list"
      >
        <List />
      </MenuItem>
    </RoleWrapper>
  );
}
