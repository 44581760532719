import { authorizedPut } from "utils/request";

export async function resetViewedDefects(projectId: null | number = null) {
  let response = null;
  if (projectId === null) {
    response = await authorizedPut(`/mission/defects/viewed`);
  } else {
    response = await authorizedPut(`/mission/${projectId}/defects/viewed`);
  }
  return response;
}
