import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useTranslation } from "hooks";
import TextBar from "./TextBar";

interface IProps {
  onClick: (imageIndex: number) => void;
  currentIndex: number;
  count: number;
  loading?: boolean;
}
export default function ImageCounter(props: IProps) {
  const { onClick, currentIndex, count, loading } = props;

  const [open, setOpen] = useState(false);

  const jumpToImageText = useTranslation("JumpToImage");
  const cancelText = useTranslation("Cancel");
  const confirmText = useTranslation("Confirm");

  if (loading) {
    return <div className="reviewImagesCount">Loading...</div>;
  }

  return (
    <>
      <TextBar
        onClick={() => setOpen(true)}
        data-testid="reviewTools.ImageCounter.imagesCount"
      >
        {currentIndex + 1}/{count}
      </TextBar>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            // Remove 1 because arrays start at 0 but our displayed numbering starts at 1.
            const index = Number.parseInt(formData.get("index").toString()) - 1;

            onClick(index);
            setOpen(false);
          },
        }}
      >
        <DialogTitle>{jumpToImageText}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="index"
            label="Index"
            type="number"
            fullWidth
            variant="standard"
            InputProps={{
              inputProps: {
                "data-testid": "reviewTools.ImageCounter.jumpToIndex",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{cancelText}</Button>
          <Button type="submit">{confirmText}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
