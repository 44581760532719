import React from "react";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { Fireplace } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { ImageMeta } from "interfaces";
import { useLanguage } from "hooks";

type Props = {
  imageMeta: ImageMeta;
  thermalData: any;
};

export default function Thermal({ imageMeta, thermalData }: Props) {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const location = useLocation();
  const isThermal = location.pathname.includes("/thermal");
  return !!imageMeta?.has_temperatures ? (
    <MenuItem
      tooltip={translations.ImageViewer.ShowTemp[language]}
      onClick={() => {
        if (isThermal) {
          navigate(`.${location.search}`);
        } else {
          navigate(`thermal${location.search}`);
        }
      }}
      id="image-toggle-thermal"
    >
      <Fireplace
        htmlColor={!!!thermalData ? "#888" : isThermal ? "#008000" : "#212121"}
      />
    </MenuItem>
  ) : (
    <></>
  );
}
