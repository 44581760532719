import MenuItem from "components/MenuItem";
import { Typography } from "@mui/material";
import Select from "react-select";
import translations from "translations";
import { AccessLevel } from "interfaces";
import { overrideAccessLevel } from "state/actions";
import store from "state/store";
import { useLanguage } from "hooks";

interface Props {
  accessLevelOverride: null | AccessLevel;
}

export default function RoleOverride({ accessLevelOverride }: Props) {
  const { language } = useLanguage();
  const options = [
    { value: null, label: "None" },
    { value: "owner", label: "owner" },
    { value: "admin", label: "admin" },
    { value: "skyqraft_employee", label: "skyqraft_employee" },
    { value: "superuser", label: "superuser" },
    { value: "supervisor", label: "supervisor" },
    { value: "annotator", label: "annotator" },
    { value: "customer", label: "customer" },
    { value: "uploader", label: "uploader" },
    { value: "readonly", label: "readonly" },
    { value: "api", label: "api" },
  ];

  return (
    <MenuItem passive id="roleOverride">
      <Typography>
        {translations.Menu.Settings.RoleOverride[language]}
      </Typography>
      <Select
        className="pr-10 pl-10 grow"
        name={"Role Override"}
        options={options}
        value={
          options.find((o) => o.value === accessLevelOverride) ?? options[0]
        }
        onChange={(e) => {
          store.dispatch(overrideAccessLevel(e.value as AccessLevel));
        }}
      />
    </MenuItem>
  );
}
