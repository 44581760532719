import React, { useState, useContext } from "react";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { context } from "providers/NewDefectsProvider";
import { resetViewedDefects } from "./actions";
import translations from "translations";
import { useLanguage } from "hooks";

type Props = {
  projectID: number;
};

export default function ProjectOptionsMenu({ projectID }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { update, loading } = useContext(context);
  const { language } = useLanguage();

  function onMenuOpen(e) {
    setAnchorEl(e.currentTarget);
  }
  function onMenuClose() {
    setAnchorEl(null);
  }
  return (
    <>
      <IconButton disabled={loading} onClick={onMenuOpen}>
        <MoreHoriz />
      </IconButton>

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onMenuClose}>
        <MenuItem
          onClick={async () => {
            await resetViewedDefects(projectID);
            update();
            onMenuClose();
          }}
        >
          <Typography>
            {translations.Landing.NewDefects.MarkAllDefectsAsViewed[language]}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
