import React from "react";
import RoleWrapper from "components/RoleHOC/wrapper";
import MenuItem from "components/MenuItem";
import translations from "translations";
import { Edit } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLanguage } from "hooks";

export default function Annotations() {
  const { language } = useLanguage();
  const location = useLocation();
  const isAnnotate = location.pathname.includes("/annotate");

  const searchParams = new URLSearchParams(location.search);

  const searchString = searchParams.toString();
  const search = searchString ? `?${searchString}` : "";

  return (
    <RoleWrapper keyName="annotationTool">
      <MenuItem
        tooltip={translations.ImageViewer.Edit[language]}
        id="image-open-annotation-tool"
      >
        <Link
          to={`${isAnnotate ? "." : "annotate"}${search}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Edit />
        </Link>
      </MenuItem>
    </RoleWrapper>
  );
}
