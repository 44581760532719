import React, { ReactNode } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import SyncLoader from "react-spinners/SyncLoader";
import { Clear } from "@mui/icons-material";

type Props = {
  title: string;
  value: ReactNode;
  loading: boolean;
  success: boolean;
};

export default function CounterTemplate({
  title,
  value,
  loading,
  success,
}: Props) {
  return (
    <Paper elevation={3} sx={{ p: 4 }}>
      <Stack
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        height={"100%"}
      >
        <Typography color="text.secondary" alignItems="center">
          {title}
        </Typography>
        {loading ? (
          <SyncLoader />
        ) : success ? (
          <Typography variant="h4" fontWeight={700} color="#404040">
            {value}
          </Typography>
        ) : (
          <Clear htmlColor="red" fontSize="large" />
        )}
        <div />
      </Stack>
    </Paper>
  );
}
