import { IconButton, Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";
import { unstable_useBlocker } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import { AnnotationContext } from "../provider";
import { regionsAreEqual } from "./AnnotationsList/Utils";

const exitWithoutSavingMessage = "Do you want to exit without saving?";

export default function ExitButton() {
  const { annotations, originalAnnotations, exit } =
    useContext(AnnotationContext);
  const somethingChanged = !regionsAreEqual(annotations, originalAnnotations);

  const unloadEventHandlerRef = useRef(null);
  const disableRouteAssert = useRef(false);

  const assertExit = () => {
    if (!somethingChanged || disableRouteAssert.current) {
      return true;
    }
    if (window.confirm(exitWithoutSavingMessage)) {
      disableRouteAssert.current = true;
      return true;
    }
  };

  unstable_useBlocker(() => {
    return !assertExit(); // return true to block route navigation
  });

  useEffect(() => {
    unloadEventHandlerRef.current = (e) => {
      if (somethingChanged) {
        e.preventDefault(); // triggers the browser confirm dialog
      }
    };
    // handles both reload and closing of tab
    window.addEventListener("beforeunload", unloadEventHandlerRef.current);

    return () => {
      window.removeEventListener("beforeunload", unloadEventHandlerRef.current);
    };
  }, [somethingChanged]);

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", unloadEventHandlerRef.current);
    };
  }, []);

  return (
    <Tooltip title="Exit" placement="top" arrow disableInteractive>
      <IconButton
        onClick={() => {
          if (assertExit()) {
            exit();
          }
        }}
      >
        <Close />
      </IconButton>
    </Tooltip>
  );
}
