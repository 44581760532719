import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AnnotationContext } from "../provider";
import { PoleItemPlacementConnector } from "../ImageConnectorDialog";

export default function PoleItemPlacementImages() {
  const [searchParams, setSearchParams] = useSearchParams();
  const placementID = parseInt(searchParams.get("placementID"));

  const annotationState = useContext(AnnotationContext);

  useEffect(() => {
    if (placementID) {
      annotationState.getItemPlacementImages(placementID);
    }
  }, [placementID]);

  return <PoleItemPlacementConnector />;
}
