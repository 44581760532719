import { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { authorizedGet } from "utils/request";

export default function PoleGroupCatcher() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  useEffect(() => {
    const pole_id = params.pole;
    const group_id = parseInt(params.group);

    authorizedGet<{ images: { id: number; project: number }[] }>(
      `/poles/group/${group_id}/${pole_id}/images`
    ).then((response) => {
      if (!response) {
        toast.error("Unable to load images for this structure");
        navigate(`/${location.search}`);
        return;
      }
      const images = response.images;
      if (images.length === 0) {
        navigate(`/${location.search}`);
      } else {
        const targetImage = images[0];
        navigate(`/${targetImage.project}/${targetImage.id}${location.search}`);
      }
    });
  }, [location]);
  return <></>;
}
