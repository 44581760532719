import { useState, useEffect } from "react";
import { Button, Stack, Typography } from "@mui/material";
import translations from "translations";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
import RoleWrapper from "components/RoleHOC/wrapper";
import { getDrafts } from "views/MissionLanding/OrderProgress/steps/OrderMission/utils";
import { useLanguage, useSelector } from "hooks";

export default function WelcomeToSkyqraft() {
  const user = useSelector((state) => state.user);
  const { language } = useLanguage();
  const [drafts, saveDrafts] = useState([]);
  useEffect(() => {
    saveDrafts(getDrafts());
  }, []);

  return (
    <Stack
      sx={{ p: 2, pl: 2, pb: 2 }}
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <div>
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <Typography variant="h5" data-testid="welcome-user">
            {translations.words.Welcome[language]}{" "}
            {!user ? "" : user.name ? user.name : ""}
          </Typography>
        </Stack>
        <Stack display={{ xs: "none", md: "block" }}>
          <Typography fontSize="medium" data-testid="welcome-user-email">
            {!user?.email ? "" : `${user.email}`}
          </Typography>
        </Stack>
      </div>

      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        display={{
          xs: "none",
          md: "flex",
        }}
      >
        {drafts.length > 0 && (
          <Typography sx={{ color: "text.secondary", fontSize: "small" }}>
            {`You have ${drafts.length} draft${
              drafts.length === 1 ? "" : "s"
            } saved`}
          </Typography>
        )}
        <RoleWrapper keyName="newMission">
          <Link
            to="order"
            style={{ textDecoration: "none", color: "inherit" }}
            data-testid="create-project-link"
          >
            <Button
              color="success"
              variant="contained"
              startIcon={<Add htmlColor="white" />}
              sx={{
                color: "white",
                textTransform: "none",
              }}
            >
              <Typography style={{ color: "white" }}>
                {translations.Menu.Missions.AddMission[language]}
              </Typography>
            </Button>
          </Link>
        </RoleWrapper>
      </Stack>
    </Stack>
  );
}
