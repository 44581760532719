import { connect } from "react-redux";
import PoleImageButton from "./PoleImageButton";
import { RootState } from "state/store";
import { setListViewPoleImageToggle } from "state/actions";

interface IStateProps {
  poleImageToggle: boolean;
}

interface IDispatchProps {
  setListViewPoleImageToggle: Function;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    poleImageToggle: state.map.listViewPagePoleImageToggle,
  };
};

const mapDispatchToProps = {
  setListViewPoleImageToggle,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PoleImageButton);
