import { connect } from "react-redux";
import MapTypeSelector from "./MapTypeSelector";
import { RootState } from "state/store";
import { setMapType } from "state/actions";

interface IStateProps {
  mapType: string;
  gmap: google.maps.Map;
  demoMode: boolean;
}

interface IDispatchProps {
  setMapType: typeof setMapType;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    mapType: state.map.type,
    gmap: state.map.gmap,
    demoMode: state.ui.demoMode,
  };
};

const mapDispatchToProps = {
  setMapType,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(MapTypeSelector);
