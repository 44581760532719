import { IMatrix } from "../types";

interface IPoint {
  x: number;
  y: number;
}
interface ISize {
  w: number;
  h: number;
}

export function getTopLeft(mat: IMatrix): IPoint {
  return {
    x: -mat.e / mat.a,
    y: -mat.f / mat.d,
  };
}

export function getBottomRight(mat: IMatrix): IPoint {
  const width = 1 / mat.a; // Width of the transformed space
  const height = 1 / mat.d; // Height of the transformed space

  return {
    x: getTopLeft(mat).x + width,
    y: getTopLeft(mat).y + height,
  };
}

export function getScaledCanvasSize(
  referenceSize: number,
  mat: IMatrix
): ISize {
  return {
    w: referenceSize / mat.a,
    h: referenceSize / mat.a,
  };
}

export function convertPositionToPercentage(
  canvasTop: number,
  canvasLeft: number,
  imageDimensions: { naturalWidth: number; naturalHeight: number },
  clientX: number,
  clientY: number,
  matrix: IMatrix
) {
  const matrixOffset = {
    x: matrix.e / matrix.a,
    y: matrix.f / matrix.d,
  };

  const canvasLocation = {
    x: clientX - canvasLeft + matrixOffset.x,
    y: clientY - canvasTop + matrixOffset.y,
  };

  return {
    x: canvasLocation.x / (imageDimensions.naturalWidth / matrix.a),
    y: canvasLocation.y / (imageDimensions.naturalHeight / matrix.d),
  };
}

export function convertChangeToPercentage(
  dx: number,
  dy: number,
  canvasWidth: number,
  imageDimensions: { naturalWidth: number; naturalHeight: number },
  matrix: IMatrix
) {
  return {
    dx: dx / (imageDimensions.naturalWidth / matrix.a),
    dy: dy / (imageDimensions.naturalHeight / matrix.d),
  };
}

export function getBoxFromPoints(startPoint: IPoint, endPoint: IPoint) {
  if (endPoint.x < 0) endPoint.x = 0.001;
  if (endPoint.y < 0) endPoint.y = 0.001;
  if (endPoint.x > 1) endPoint.x = 1;
  if (endPoint.y > 1) endPoint.y = 1;

  const topLeft = {
    x: Math.min(startPoint.x, endPoint.x),
    y: Math.min(startPoint.y, endPoint.y),
  };
  const bottomRight = {
    x: Math.max(startPoint.x, endPoint.x),
    y: Math.max(startPoint.y, endPoint.y),
  };

  return {
    x: topLeft.x,
    y: topLeft.y,
    width: bottomRight.x - topLeft.x,
    height: bottomRight.y - topLeft.y,
  };
}
