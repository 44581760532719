import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import translations from "translations";
import { useAssignmentStatus, useLanguage, useDispatch } from "hooks";
import DemoSafeName from "components/DemoSafeName";
import AssignmentList from "../AssignmentList";
import { getProjectName } from "utils/utils";
import {
  setInspectorMode,
  changeAnnotatorColor,
  toggleDemoMode,
  setSupervisorMode,
} from "state/actions";

type Props = {
  open?: boolean;
};

export default function CurrentAssignments({ open }: Props) {
  const dispatch = useDispatch();
  const [assignments, assignmentsLoading] = useAssignmentStatus({
    grouping: "mission",
  });

  const navigate = useNavigate();
  const { language } = useLanguage();

  return (
    <AssignmentList
      open={open}
      loading={assignmentsLoading}
      title={translations.Landing.Assignments[language]}
      description={translations.Landing.Assignments.Description[language]}
      assignments={assignments.map((assignment) => {
        const getProjectNameCurrentResult = getProjectName(
          assignment.mission.id
        );
        return {
          ...assignment,
          name: (
            <Box
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`${assignment.mission.id}`);
                dispatch(setInspectorMode(true));
                dispatch(changeAnnotatorColor(false));
                dispatch(toggleDemoMode(false));
                dispatch(setSupervisorMode(false));
              }}
            >
              <DemoSafeName entityName={translations.words.Mission[language]}>
                {getProjectNameCurrentResult.fullname}
              </DemoSafeName>
            </Box>
          ),
        };
      })}
      entity={translations.words.Mission[language]}
    />
  );
}
