import { Divider, Stack } from "@mui/material";
import {
  ImageListFilter,
  SavedFiltersFilter,
} from "components/Filter/FilterComponents";
import translations from "translations";
import { useLanguage } from "hooks";

export default function SavedFilters() {
  const { language } = useLanguage();
  return (
    <Stack mx={2} pb={2}>
      <Divider sx={{ py: 1 }}>
        {translations.Filter.SavedFilters[language]}
      </Divider>
      <SavedFiltersFilter />
      <Divider sx={{ py: 1 }}>
        {translations.Filter.ListFilters[language]}
      </Divider>
      <ImageListFilter />
    </Stack>
  );
}
