import { useContext } from "react";
import { SocketContext } from "providers/SocketProvider";

export default function useSocket() {
  const socketContext = useContext(SocketContext);

  if (!socketContext) {
    throw new Error("useSocket has to be used within <SocketProvider>");
  }

  return socketContext;
}
