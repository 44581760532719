import { connect } from "react-redux";
import { RootState } from "state/store";
import {
  setThumbnailRegexPattern,
  setThumbnailPoleItemPlacement,
} from "state/actions";
import Component from "./PolePlacementItem";
import { IPoleComponentStatusItem } from "views/PoleViewer/api";

interface IStateProps {
  thumbnailRegexPattern: string | null;
  thumbnailPoleItemPlacement: number | null;
}

interface IDispatchProps {
  setThumbnailRegexPattern: (pattern: string | null) => void;
  setThumbnailPoleItemPlacement: (id: number | null) => void;
}

interface IOwnProps {
  placement: IPoleComponentStatusItem;
  componentID: number;
  valueSystem: number;
}

const mapStateToProps = (state: RootState) => {
  return {
    thumbnailRegexPattern: state.image.thumbnailRegexPattern,
    thumbnailPoleItemPlacement: state.image.thumbnailPoleItemPlacement,
  };
};

const mapDispatchToProps = {
  setThumbnailRegexPattern,
  setThumbnailPoleItemPlacement,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
