import { RootState } from "state/store";
import {
  setOverlays,
  clearOverlay,
  setZoneVisibility,
  setLidarMarkerVisibility,
  setClearanceVisibility,
} from "state/actions";
import AreaAssignment from "components/AreaAssignment";
import { showMenu } from "./menu";
import { inspectionAssignment } from "api";

export const setInspectorMode = (value: boolean, callback: Function = null) => {
  return async function (dispatch, getState) {
    const state: RootState = getState();
    const mission = state.mission;
    if (!value) {
      dispatch(clearAnnotatorAssignments());
    } else {
      if (!!mission.id) dispatch(updateAnnotatorAssignments());
      dispatch(setZoneVisibility(false));
      dispatch(setClearanceVisibility(false));
      dispatch(setLidarMarkerVisibility(false));
    }
    dispatch({
      type: "SET_INSPECTOR_MODE",
      payload: value,
    });
  };
};

export function clearAnnotatorAssignments() {
  return async function (dispatch, getState) {
    const state: RootState = getState();
    const overlays: google.maps.Polygon[] = state.map.overlays;
    overlays.forEach((overlay) => dispatch(clearOverlay(overlay)));
    dispatch(setOverlays([]));
  };
}

export function updateAnnotatorAssignments() {
  return async function (dispatch, getState) {
    const state: RootState = getState();
    const overlays: google.maps.Polygon[] = state.map.overlays;
    overlays.forEach((overlay) => dispatch(clearOverlay(overlay)));
    dispatch(setOverlays([]));
    dispatch(loadAnnotatorAssignments());
  };
}

function overlayListener(overlay: google.maps.Polygon, map: google.maps.Map) {
  return async function (dispatch) {
    overlay.addListener("contextmenu", (e) => {
      let menuItems: JSX.Element[] = [];
      menuItems.push(<AreaAssignment key="areaAssignment" area={overlay} />);
      dispatch(showMenu(e, true, menuItems, map));
    });

    overlay.addListener("mouseover", (e) => {
      google.maps.event.trigger(map, "mouseover", e);
    });

    overlay.addListener("mousemove", (e) => {
      // Send event to map
      google.maps.event.trigger(map, "mousemove", e);
    });
  };
}

export function loadAnnotatorAssignments() {
  return async function (dispatch, getState) {
    inspectionAssignment.get({ supervisor: false }).then((areas) => {
      if (areas) {
        if (!!!window.google) {
          return;
        }
        const state: RootState = getState();
        const map: google.maps.Map = state.map.gmap;
        let newOverlays = [...state.map.overlays];
        areas.forEach((assignment) => {
          const newPolygon = new google.maps.Polygon({
            paths: assignment.coordinates.map(
              ({ lat, lng }) => new google.maps.LatLng(lat, lng)
            ),
            editable: false,
            map: map,
            fillColor: assignment.color,
            strokeColor: assignment.color,
            fillOpacity: 0.1,
          });
          newPolygon.set("id", assignment.id);
          newPolygon.set("annotator", assignment.annotator);
          newPolygon.set("analysis", assignment.analysis);
          newPolygon.set("criticalAnalysis", assignment.criticalAnalysis);
          dispatch(overlayListener(newPolygon, map));
          newOverlays.push(newPolygon);
        });
        dispatch(setOverlays(newOverlays));
      }
    });
  };
}
