import { useSearchParams } from "react-router-dom";
import { setDateOfKey } from "./utils";
import { FilterKey, FilterType } from "./constants";

export function getDateDefectProcessedFilter(
  searchParams: URLSearchParams
): FilterType["DATE_DEFECT_PROCESSED"] {
  const dateDefectProcessedParams =
    searchParams.get(FilterKey.DATE_DEFECT_PROCESSED) ?? "";
  const splitDates = dateDefectProcessedParams
    .split(",")
    .filter((d) => d.length > 0);
  let dateDefectProcessed: FilterType["DATE_DEFECT_PROCESSED"] = null;
  if (splitDates.length === 1) {
    splitDates.push(splitDates[0]);
    dateDefectProcessed = new Date(splitDates[0]);
  } else if (splitDates.length === 2) {
    dateDefectProcessed = [new Date(splitDates[0]), new Date(splitDates[1])];
  } else if (splitDates.length > 2) {
    console.error("Too many dates in the dateDefectProcessed filter");
    dateDefectProcessed = [new Date(splitDates[0]), new Date(splitDates[1])];
  }
  return dateDefectProcessed;
}

export function useDateDefectProcessedFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  // Collect the search parameters
  const dateDefectProcessed = getDateDefectProcessedFilter(searchParams);

  // Convert the key function to each specific key
  function setDateDefectProcessed(
    startDate: Date | null,
    endDate: Date | null
  ) {
    setDateOfKey(
      searchParams,
      setSearchParams,
      FilterKey.DATE_DEFECT_PROCESSED,
      startDate,
      endDate
    );
  }

  // Export the functions and filters
  return {
    dateDefectProcessed,
    setDateDefectProcessed,
  };
}
