import { Mission } from "interfaces";
import { connect } from "react-redux";
import { RootState } from "state/store";
import Component from "./RegionItem";

interface IStateProps {
  demoMode: boolean;
}

interface IDispatchProps {}

interface IOwnProps {
  name: string;
  missions: Mission[];
}

const mapStateToProps = (state: RootState) => {
  return {
    demoMode: state.ui.demoMode,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
