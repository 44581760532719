import { ErrorOutline, Replay } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Stack,
  Typography,
} from "@mui/material";
import { useModalBlocker } from "hooks";

export default function ModalBlocker() {
  const { modalBlocker } = useModalBlocker();

  return (
    modalBlocker && (
      <Dialog open={!!modalBlocker} onClose={() => {}}>
        <Stack
          alignItems="center"
          spacing={1}
          direction="row"
          pt={2}
          px={2}
          data-testid="modal-blocker"
        >
          <ErrorOutline htmlColor="red" />
          <Typography variant="h5" component="h2">
            {modalBlocker.title}
          </Typography>
        </Stack>
        <DialogContent>
          <DialogContentText>{modalBlocker.message}</DialogContentText>
        </DialogContent>
        <Stack alignItems="center" pb={2}>
          <Button
            startIcon={<Replay />}
            data-testid="modal-blocker-reload"
            variant="outlined"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload page
          </Button>
        </Stack>
      </Dialog>
    )
  );
}
