import { authorizedGet } from "utils/request";

export interface IDefectCount {
  id: number;
  name: string;
  "1": number;
  "2": number;
  "3": number;
  "4": number;
}

interface IResponse {
  projects: IDefectCount[];
}

export async function collectNewDefects(
  projectID: number | null,
  resetCache: boolean
) {
  // Create the endpoint in which to collect data
  let endpoint = `/mission/defects/new`;
  if (!!projectID) {
    endpoint = `/mission/${projectID}/defects/new`;
  }
  if (resetCache) {
    endpoint += "?reset_cache=true";
  }

  // Collect the data we want
  const response = await authorizedGet<IResponse>(endpoint);
  if (!response?.projects) {
    throw new Error("Failed to get response from Arkion");
  }

  // We only want defects that has any severity level
  const projects = response.projects;
  const filteredProjects = projects.filter(
    (p) => p["1"] > 0 || p["2"] > 0 || p["3"] > 0 || p["4"] > 0
  );
  return filteredProjects;
}
