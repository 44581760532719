import { connect } from "react-redux";
import { RootState } from "state/store";
import { getLists, getImageTypes } from "state/actions";
import Component from "./NewReport";
import { Mission } from "interfaces";

interface IStateProps {
  issueCategories;
  missions: Mission[];
}

interface IOwnProps {}

interface IDispatchProps {
  getLists: (missionID?: number) => void;
  getImageTypes: (missionID: number) => void;
}

const mapStateToProps = (state: RootState) => {
  return {
    issueCategories: state.objects.issueCategories,
    missions: state.user.missions,
  };
};

const mapDispatchToProps = {
  getLists,
  getImageTypes,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
