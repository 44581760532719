import React, { ReactElement, useContext } from "react";
import { Collapse } from "@mui/material";
import { isDetection } from "react-image-annotate/Annotator/reducers/general-reducer";
import groupBy from "lodash/groupBy";
import hasAccess from "utils/authTools";
import { AnnotationContext } from "../../../provider";

interface Props {
  region;
  objectTypes;
  saveObject;
  index;
}

export default function EditObjectType({
  region,
  objectTypes,
  saveObject,
  index,
}: Props): ReactElement {
  const { setSavedObjectTypeId } = useContext(AnnotationContext);
  let shouldBeVisible =
    (region.editType === "detection" && region.editIndex === index) ||
    (!!region.highlighted &&
      !!!region.types[index] &&
      !!!region.is_defect[index]);

  let userHasAccess = hasAccess("annotationToolEditObjects");

  if (!userHasAccess && isDetection(region)) {
    shouldBeVisible = false;
  }

  let newObjectTypes = groupBy(objectTypes, "client_object_type_id");
  // @ts-ignore
  newObjectTypes = Object.values(newObjectTypes).map((n) => n[0]);
  // @ts-ignore
  let detectedCategoriesNew = newObjectTypes?.filter((t) => !t.issue);

  detectedCategoriesNew = groupBy(detectedCategoriesNew, "category_id");

  detectedCategoriesNew = Object.values(detectedCategoriesNew);

  detectedCategoriesNew = detectedCategoriesNew.map((e) => e[0]);

  let sortedCategories = detectedCategoriesNew.sort((a, b) => {
    if (a.category_sorting < b.category_sorting) {
      return 1;
    }
    if (a.category_sorting > b.category_sorting) {
      return -1;
    }
    return 0;
  });

  let detectedCatIds = sortedCategories.map((e) => e.category_id);

  let groupedByIssueCategory = groupBy(
    // @ts-ignore
    newObjectTypes?.filter(
      (ot) => ot.category_id && detectedCatIds.includes(ot.category_id)
    ),
    "category_id"
  );

  let groupedKeys = Object.keys(groupedByIssueCategory).map((k) => parseInt(k));

  sortedCategories = sortedCategories.filter((e) =>
    groupedKeys.includes(e.category_id) ? true : false
  );

  return (
    <Collapse in={shouldBeVisible}>
      <div className="issueTypeContainer">
        <div className="issueCategoryContainer">
          {shouldBeVisible &&
            sortedCategories
              .map((k) => k.category_id)
              .map((category, categoryIndex) => {
                return (
                  <div
                    key={categoryIndex}
                    style={{
                      backgroundColor:
                        region.categories[index] === category ? "#FDD" : "",
                    }}
                    className="issueCategoryItem"
                    onClick={() => {
                      saveObject(
                        {
                          ...region,
                          categories: region.categories.map((value, i) =>
                            i === index ? category : value
                          ),
                          workflow_status: region.workflow_status.map(
                            (value, i) => (i === index ? 2 : value)
                          ),
                          types: region.types.map((value, i) =>
                            i === index ? null : value
                          ),
                        },
                        "detection"
                      );
                    }}
                  >
                    <p>
                      {category
                        ? sortedCategories.find(
                            (item) => item.category_id === category
                          )?.category_name
                        : ""}
                    </p>
                  </div>
                );
              })}
        </div>

        <div className="issueItemContainer">
          {shouldBeVisible &&
            Object.keys(groupedByIssueCategory)
              .filter((category) => {
                return parseInt(category) === region.categories[index];
              })
              .map((category) => {
                return groupedByIssueCategory[category]
                  .sort((a, b) => {
                    const nameA = a.name;
                    const nameB = b.name;

                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((obj, i) => (
                    <div
                      key={i}
                      style={{
                        backgroundColor:
                          region.types[index] === obj.id ? "#FDD" : "",
                      }}
                      className="issueTypeItem"
                      onClick={() => {
                        // Do not change type if it already exists
                        if (region.types.includes(obj.id)) {
                          alert("Classification already exists");
                          return;
                        }

                        setSavedObjectTypeId(obj.id);

                        saveObject(
                          {
                            ...region,
                            types: region.types.map((value, i) =>
                              i === index ? obj.id : value
                            ),
                            workflow_status: region.workflow_status.map(
                              (value, i) => (i === index ? 2 : value)
                            ),
                            editingLabels: false,
                            isNew: false,
                            editIndex: undefined,
                            editType: undefined,
                          },
                          "detection"
                        );
                      }}
                    >
                      <p> {obj.name}</p>
                    </div>
                  ));
              })}
        </div>
      </div>
    </Collapse>
  );
}
