import { ISpecialAnnotation } from "views/AnnotationTool/provider/AnnotationProvider/context";
import { checkSteelworkConnection } from "views/AnnotationTool/api";

export type ISteelworkConnection = Awaited<
  ReturnType<typeof checkSteelworkConnection>
>;

interface IEditLinks {
  parent_link: number | null;
  child_link: number | null;
}

//This is triggered if you have a member without an item_id
export function checkItemIdMissing(annotation: ISpecialAnnotation) {
  if (!annotation?.steelwork?.item_id) {
    return true;
  }
  return false;
}

//This is triggered if you have a member without a direction
export function checkDirectionMissing(annotation: ISpecialAnnotation) {
  if (!annotation?.steelwork?.direction?.id) {
    return true;
  }
  return false;
}

//This is triggered if you have two members with the same values on the same image
export function checkSameImage(
  annotation: ISpecialAnnotation,
  annotations: ISpecialAnnotation[]
) {
  const sameAnnotation = annotations
    .filter((a) => a.id !== annotation.id)
    .some((a) => {
      return (
        a?.steelwork?.item_id === annotation?.steelwork?.item_id &&
        a?.steelwork?.direction?.id === annotation?.steelwork?.direction?.id &&
        a?.steelwork?.second_direction?.id ===
          annotation?.steelwork?.second_direction?.id
      );
    });
  if (sameAnnotation) {
    return true;
  }
  return false;
}

// This is triggered if you have a linked member, edit so link values dont
// match (without saving). And then type in the original values again. This brings the link back in the UI
export function checkBringBackLinkOnRevert(
  checkConnection: ISteelworkConnection,
  annotation: ISpecialAnnotation,
  originalAnnotation: ISpecialAnnotation,
  editLinks: IEditLinks
) {
  if (
    originalAnnotation?.steelwork?.parent_link?.id ||
    originalAnnotation?.steelwork?.child_link?.id
  ) {
    if (
      checkConnection.item_id === annotation.steelwork.item_id &&
      checkConnection.direction_id === annotation.steelwork.direction.id &&
      checkConnection.second_direction_id ===
        annotation.steelwork.second_direction.id
    ) {
      return {
        parent_link: originalAnnotation?.steelwork?.parent_link,
        child_link: originalAnnotation?.steelwork?.child_link,
      };
    }
  }
  return editLinks;
}

// This is triggered if you have a none linked member, click the link button (without saving)
// And then edit so the link values don't match (without saving). This removes the link in the UI
export function checkRemoveLinkOnRevert(
  checkConnection: ISteelworkConnection,
  annotation: ISpecialAnnotation,
  editLinks: IEditLinks
) {
  if (
    annotation.steelwork.parent_link?.id ||
    annotation.steelwork.child_link?.id
  ) {
    if (
      checkConnection.item_id !== annotation.steelwork.item_id ||
      checkConnection.direction_id !== annotation.steelwork.direction?.id ||
      (!!checkConnection.second_direction_id &&
        checkConnection.second_direction_id !==
          annotation.steelwork.second_direction?.id)
    ) {
      return { parent_link: null, child_link: null };
    }
  }

  return editLinks;
}

//This is triggered if you have a linked member, edit so link values dont match (without saving).
export function checkChangeWillUnlink(
  checkConnection: ISteelworkConnection,
  originalAnnotation: ISpecialAnnotation
) {
  if (
    originalAnnotation?.steelwork?.parent_link?.id ||
    originalAnnotation?.steelwork?.child_link?.id
  ) {
    if (
      checkConnection.item_id !== originalAnnotation?.steelwork?.item_id ||
      checkConnection.direction_id !==
        originalAnnotation?.steelwork.direction.id ||
      checkConnection.second_direction_id !==
        originalAnnotation?.steelwork?.second_direction.id
    ) {
      return true;
    }
  }
  return false;
}

//This is triggered if the values you are trying to use are already in use by two
//other members on the same pole
export function checkAlreadyLinked(
  checkConnection: ISteelworkConnection,
  originalAnnotation: ISpecialAnnotation
) {
  if (checkConnection?.already_linked) {
    if (
      originalAnnotation?.steelwork?.parent_link?.id ===
        checkConnection?.steelwork_id ||
      originalAnnotation?.steelwork?.child_link?.id ===
        checkConnection.steelwork_id
    ) {
      return false;
    } else {
      return true;
    }
  }
}

//This is triggered if the values you are trying to use are already in use by one
//other member on the same pole
export function checkLinkOrChange(
  checkConnection: ISteelworkConnection,
  annotation: ISpecialAnnotation,
  originalAnnotation: ISpecialAnnotation
) {
  if (checkConnection?.exists) {
    if (
      originalAnnotation?.steelwork?.parent_link?.id ===
        checkConnection.steelwork_id ||
      originalAnnotation?.steelwork?.child_link?.id ===
        checkConnection.steelwork_id
    ) {
      return false;
    }
    if (annotation.steelwork.parent_link?.id === checkConnection.steelwork_id) {
      return false;
    }
    if (annotation.steelwork.child_link?.id === checkConnection.steelwork_id) {
      return false;
    } else {
      return true;
    }
  }
}
