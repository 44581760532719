import { useLanguage, useSidebar } from "hooks";

import { ArrowLeft } from "@mui/icons-material";
import { Stack, Button } from "@mui/material";
import translations from "translations";

export default function ArrowBackItem({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { closeSidebar } = useSidebar();
  const { language } = useLanguage();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Button variant="text" startIcon={<ArrowLeft />} onClick={closeSidebar}>
        {translations.Menu.Close[language]}
      </Button>
      {children}
    </Stack>
  );
}
