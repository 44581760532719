import { Translated } from "./utils";

export const Clear = Translated({ EN: "Clear", SE: "Rensa", NO: "Tøm" });
export const Close = Translated({ EN: "Close", SE: "Stäng", NO: "Lukk" });
export const Save = Translated({ EN: "Save", SE: "Spara", NO: "Lagre" });
export const SetDate = Translated({
  EN: "Set Date",
  SE: "Sätt Datum",
  NO: "Sett Dato",
});
export const ClickOnATabAbove = Translated({
  EN: "Click on a tab above",
  SE: "Klicka på en flik ovan",
  NO: "Klikk på en fane over",
});
export const ShowMore = Translated({
  EN: "Show More",
  SE: "Visa Mer",
  NO: "Vis Mer",
});
export const ShowLess = Translated({
  EN: "Show Less",
  SE: "Visa Mindre",
  NO: "Vis Mindre",
});
