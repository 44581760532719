import { useSelector as useReduxSelector } from "react-redux";
import { useMemo } from "react";
import { RootState } from "state/store";

// The Selected property allows the specified
// type to be returned from the selecting function.

export function useSelector<Selected = unknown>(
  selectingFunction: (state: RootState) => Selected
): Selected {
  const value = useReduxSelector(selectingFunction);

  return useMemo(() => value, [value]);
}
