export interface IState {
  message: string;
  open: boolean;
}

export const initialState: IState = {
  message: "Zoom In to see markers.",
  open: false,
};

const toastReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case "SET_TOAST_MESSAGE": {
      return {
        ...state,
        message: action.payload,
        open: true,
      };
    }
    case "CLEAR_TOAST_MESSAGE": {
      return {
        message: "",
        open: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default toastReducer;
