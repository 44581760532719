import { useFlags } from "launchdarkly-react-client-sdk";
import FlowDirectionArrow, { IProps } from "./FlowDirectionArrow";

export default function FlagProtectedFlowDirectionArrow(props: IProps) {
  // Render conditionally on feature flags
  const { electricityFlowDirection } = useFlags();

  // https://app.launchdarkly.com/projects/default/flags/electricity-flow-direction
  if (!electricityFlowDirection) {
    return null;
  }

  return <FlowDirectionArrow {...props} />;
}
