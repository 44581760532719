import { Button } from "@mui/material";
import { useSelector } from "hooks";
import tokml from "tokml";
import { IKeyPayload } from "./MetaSelector";

type LineString = {
  type: "LineString";
  coordinates: [number, number][];
  properties: {
    [key: string]: string | null;
  };
};

type Point = {
  type: "Point";
  coordinates: [number, number];
  properties: {
    [key: string]: string | null;
  };
};

export type ArkionGeoJson = {
  type: "FeatureCollection";
  features: LineString[] | Point[];
  properties: {
    poleClientIdKey?: string | null;
    poleNoteKey?: string | null;
    powerlineClientIdKey?: string | null;
    powerlineFeedBayKey?: string | null;
    powerlineFeedStationKey?: string | null;
    powerlineTopologyKey?: string | null;
  };
};

export async function convertToGeoJSON(
  map: google.maps.Map,
  filter: (feature: google.maps.Data.Feature) => boolean
) {
  const mapData: ArkionGeoJson = {
    type: "FeatureCollection",
    features: [],
    properties: {},
  };

  const promises = [];
  if (!map) {
    return null;
  }
  // biome-ignore lint/complexity/noForEach: map.data is a google.maps.Data object
  map.data.forEach((feature: google.maps.Data.Feature) => {
    // Check if we need the feature
    if (!filter(feature)) {
      return;
    }
    // Add to the list of promises
    promises.push(
      new Promise((resolve, reject) =>
        feature.toGeoJson((value) => {
          resolve(value);
        })
      )
    );
  });
  mapData.features = await Promise.all(promises);
  return mapData;
}

export async function convertToKml(
  map: google.maps.Map,
  filter: (feature: google.maps.Data.Feature) => boolean
) {
  const mapData = await convertToGeoJSON(map, filter);
  if (mapData) {
    return tokml(mapData);
  } else {
    return null;
  }
}

type Props = {
  onSave: (kmlData) => void;
  keys: IKeyPayload;
  disabled?: boolean;
};

export default function SaveButton({ onSave, keys, disabled }: Props) {
  const gmap = useSelector((state) => state.map.gmap);
  async function savePowerlines() {
    const geoJSON = await convertToGeoJSON(
      gmap,
      (feature: google.maps.Data.Feature) =>
        !feature.getProperty("isBoundingBox")
    );
    geoJSON.properties = {
      poleClientIdKey: keys.poleClientID,
      poleNoteKey: keys.poleNote,
      powerlineClientIdKey: keys.powerlineClientID,
      powerlineFeedBayKey: keys.powerlineFeedBay,
      powerlineFeedStationKey: keys.powerlineFeedStation,
      powerlineTopologyKey: keys.powerlineTopologyColor,
    };
    onSave(geoJSON);
  }

  return (
    <Button onClick={savePowerlines} disabled={disabled}>
      Save
    </Button>
  );
}
