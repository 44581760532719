import { useSearchParams } from "react-router-dom";
import { FilterKey, FilterType } from "./constants";
import { isLidarFilterPopulated } from "hooks/filter/useFilterPopulated";
export function getLidarMarkerValue(
  searchParams: URLSearchParams
): FilterType["LIDAR_MARKERS"] {
  const filterValue =
    isLidarFilterPopulated(searchParams) &&
    searchParams.get(FilterKey.LIDAR_MARKERS) !== "false";

  return filterValue;
}

export function useLidarMarkerFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const lidarMarkerFilter = getLidarMarkerValue(searchParams);

  const setLidarMarkerValue = (show: boolean) => {
    if (show) {
      if (searchParams.has(FilterKey.LIDAR_MARKERS))
        searchParams.delete(FilterKey.LIDAR_MARKERS);
    } else {
      searchParams.set(FilterKey.LIDAR_MARKERS, "false");
    }
    setSearchParams(searchParams, { replace: true });
  };

  return { lidarMarkerFilter, setLidarMarkerValue };
}
