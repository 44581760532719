import { round } from "utils/functions";
import {
  Typography,
  Stack,
  Button,
  Paper,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import translations from "translations";
import { useLanguage, useSelector } from "hooks";
import { useState } from "react";
import { getFileLength, highlightFile, removeFile } from "./utils";

export type GridData = Awaited<ReturnType<typeof getFileLength>>;

type Props = {
  fileCounts: GridData;
  updateFileSize: (propertyTag) => void;
  disabled?: boolean;
  poleKeys: string[];
  powerlineKeys: string[];
  propertyTag: string;
  setPropertyTag: (tag: string) => void;
};

export default function MapCount({
  fileCounts,
  updateFileSize,
  poleKeys,
  powerlineKeys,
  disabled,
  propertyTag,
  setPropertyTag,
}: Props) {
  const [highlightedFile, setHighlightedFile] = useState<string | null>(null);

  const { language } = useLanguage();
  const gmap = useSelector((state) => state.map.gmap);

  // Join poleKeys and powerlineKeys into one array
  // where each element only appears once
  // and is sorted alphabetically
  const allKeys = [...poleKeys, ...powerlineKeys].sort();
  const uniqueKeys = new Set(allKeys);
  const sortedKeys = Array.from(uniqueKeys).sort();

  return (
    <Stack spacing={1} px={2}>
      <Typography variant="h6" component="h2">
        See the powerline length and structure count for each property
      </Typography>
      <Select
        variant="standard"
        onChange={(e: SelectChangeEvent<string>) =>
          setPropertyTag(e.target.value)
        }
      >
        {sortedKeys.map((key) => (
          <MenuItem key={key} value={key}>
            {key}
          </MenuItem>
        ))}
      </Select>
      {fileCounts.map((file, i) => (
        <Paper key={`${i}`} elevation={10} sx={{ p: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Stack direction="column">
              <Typography fontWeight="bold">{file.name}</Typography>
              <Typography>
                Powerline: <i>{round(file.distance, 2)} km</i>
              </Typography>
              <Typography>
                Structures:{" "}
                <i>
                  {file.poles} {translations.words.Poles[language]}
                </i>
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Button
                disabled={disabled}
                onClick={() => {
                  const useKey = file.name === highlightedFile ? "" : file.name;
                  highlightFile(gmap, useKey, propertyTag);
                  setHighlightedFile(useKey);
                }}
              >
                {highlightedFile === file.name
                  ? translations.MissionLanding.OrderProgress.OrderMission
                      .PowerlineUpload.UnHighlight[language]
                  : translations.MissionLanding.OrderProgress.OrderMission
                      .PowerlineUpload.Highlight[language]}
              </Button>
              <Button
                disabled={disabled}
                onClick={() => {
                  removeFile(gmap, file.name, propertyTag);
                  updateFileSize(propertyTag);
                }}
                color="error"
              >
                {
                  translations.MissionLanding.OrderProgress.OrderMission
                    .PowerlineUpload.Remove[language]
                }
              </Button>
            </Stack>
          </Stack>
        </Paper>
      ))}
    </Stack>
  );
}
