import { Accordion } from "components";
import { useLanguage } from "hooks";
import translations from "translations";
import AssignmentContent from "./AssignmentContent";
import { useState } from "react";
import { useDispatch } from "hooks";
import {
  setSupervisorMode,
  setInspectorMode,
  changeAnnotatorColor,
  toggleDemoMode,
} from "state/actions";

type Props = {
  open?: boolean;
};

export default function AssignmentOverview({ open }: Props) {
  const dispatch = useDispatch();
  const { language } = useLanguage();
  const [accordionOpen, setAccordionOpen] = useState<boolean>(!!open);

  return (
    <Accordion
      title={translations.Landing.Assignments.AssignmentOverview[language]}
      description={
        translations.Landing.Assignments.AssignmentOverview.Description[
          language
        ]
      }
      onClose={() => setAccordionOpen(false)}
      onOpen={() => setAccordionOpen(true)}
    >
      {accordionOpen && (
        <AssignmentContent
          setSupervisorMode={(value) => dispatch(setSupervisorMode(value))}
          setInspectorMode={(value) => dispatch(setInspectorMode(value))}
          changeAnnotatorColor={(value) =>
            dispatch(changeAnnotatorColor(value))
          }
          toggleDemoMode={(value) => dispatch(toggleDemoMode(value))}
        />
      )}
    </Accordion>
  );
}
