import { Button, Chip, MenuItem, Stack, Tooltip } from "@mui/material";
import { Mission } from "interfaces";
import { Link } from "react-router-dom";
import translations from "translations";
import DemoSafeName from "components/DemoSafeName";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLanguage } from "hooks";
import { getProjectName } from "utils/utils";

type Props = {
  mission: Mission;
};

export default function MissionItem({ mission }: Props) {
  const isSmallScreen = useMediaQuery("(max-width:400px)");
  const { language } = useLanguage();
  const getProjectNameCurrentResult = getProjectName(mission?.id);

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ p: 1 }}
    >
      <Stack
        direction="row"
        flex={1}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <div
          style={{ width: isSmallScreen ? "90px" : "", wordWrap: "break-word" }}
        >
          <DemoSafeName entityName={translations.words.Mission[language]}>
            {getProjectNameCurrentResult.fullname || ""}
          </DemoSafeName>
        </div>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          spacing={1}
        >
          <Chip
            label={
              !!mission?.deleted
                ? translations.words.Deleted[language]
                : mission?.status.name
            }
          />

          <Tooltip
            title={
              <>
                <MenuItem>
                  Length: {Math.round(mission.stats.grid_length / 1000)} km
                </MenuItem>
                <MenuItem>
                  Images: {Math.round(mission.stats.grid_images / 1000)} km
                </MenuItem>
                <MenuItem>
                  Analyzed: {Math.round(mission.stats.grid_analyzed / 1000)} km
                </MenuItem>
              </>
            }
          >
            <Chip
              label={`${Math.round(mission.stats.grid_length / 1000)} km`}
            />
          </Tooltip>
        </Stack>
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
        <Link
          to={`${mission.id}`}
          style={{ color: "white", textDecoration: "none" }}
          data-testid={`missionItem.${mission.id}.link`}
        >
          <Button variant="contained" size="small">
            {translations.Landing.SeeMap[language]}
          </Button>
        </Link>
        <Link
          to={`${mission.id}/landing`}
          style={{ color: "white", textDecoration: "none" }}
        >
          <Button variant="contained" size="small">
            {translations.Landing.SeeMission[language]}
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
}
