import { FilterKey } from "./constants";

export function dateToString(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // Make sure year is 4 digits,
  // month is 2 digits and day is 2 digits
  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
}

export function setDateOfKey(
  searchParams: URLSearchParams,
  setSearchParams: (
    searchParams: URLSearchParams,
    options?: { replace: boolean }
  ) => void,
  key: string,
  startDate: Date | null,
  endDate: Date | null
) {
  if (startDate === null && endDate === null) {
    if (searchParams.has(key)) searchParams.delete(key);
  }

  if (startDate instanceof Date && endDate === null) {
    searchParams.set(key, dateToString(startDate));
  }

  if (startDate instanceof Date && endDate instanceof Date) {
    searchParams.set(
      key,
      `${dateToString(startDate)},${dateToString(endDate)}`
    );
  }

  if (searchParams.has(FilterKey.FILTER_ACTIVE)) {
    searchParams.delete(FilterKey.FILTER_ACTIVE);
  }

  setSearchParams(searchParams, { replace: true });
}
