import React from "react";
import { Grid, Paper, Typography, Stack } from "@mui/material";
import { Outlet, useOutletContext } from "react-router-dom";
import Loading from "views/Loading";
import { Link } from "react-router-dom";

export default function MissionInbox() {
  const { reports, updateTrigger, reportsLoading } = useOutletContext<{
    reports;
    updateTrigger;
    reportsLoading;
  }>();

  // Collect PDFs for selected mission
  // Render previews of PDFs
  // Enable click to view pdf via popup
  return (
    <Grid container rowSpacing={2} columnSpacing={2} style={{ width: "100%" }}>
      {reportsLoading ? (
        <Grid item xs={12}>
          <Loading />
        </Grid>
      ) : (
        reports.map((report) => (
          <Grid key={report.id} item xs={4}>
            <Link
              to={`/${report.project.id}/inbox/${report.id}`}
              replace
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Paper sx={{ p: 2 }}>
                <Typography>{report.name}</Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography color="text.secondary">
                    {report.timestamp}
                  </Typography>
                  <Typography color="text.secondary">{report.size}</Typography>
                </Stack>
              </Paper>
            </Link>
          </Grid>
        ))
      )}
      <Outlet context={updateTrigger} />
    </Grid>
  );
}
