import { useContext } from "react";
import { Tooltip, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { AnnotationContext } from "../../../provider";
import translations from "translations";
import hasAccess from "utils/authTools";
import { useLanguage } from "hooks";

type Props = {
  annotation;
  isDefect;
};

export default function AddType({ annotation, isDefect }: Props) {
  const { updateAnnotation } = useContext(AnnotationContext);
  const { language } = useLanguage();
  let userHasAccess = hasAccess("annotationToolEditObjects");

  if (!userHasAccess && !isDefect) return null;
  return (
    <Tooltip
      title={
        translations.AnnotationTool[isDefect ? "addDefect" : "addDetection"][
          language
        ]
      }
      placement="top"
    >
      <IconButton
        onClick={() =>
          updateAnnotation(annotation.id, {
            ...annotation,
            types: [...annotation.types, null],
            severities: [...annotation.severities, null],
            workflow_status: [...annotation.workflow_status, 2],
            type_comment: [...annotation.type_comment, ""],
            processed: [...annotation.processed, null],
            type_id: [...annotation.type_id, null],
            categories: [...annotation.categories, null],
            is_defect: [...annotation.is_defect, isDefect],
            skyqraft_hidden: [...annotation.skyqraft_hidden, false],
          })
        }
        style={{ padding: 5 }}
      >
        <Add htmlColor={isDefect ? "#FF4444" : "#119911"} />
      </IconButton>
    </Tooltip>
  );
}
