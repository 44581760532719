import { connect } from "react-redux";
import { RootState } from "state/store";
import { updateGridStatistics, updateDefectMetrics } from "state/actions";
import Component from "./PowerlineLidarLength";

interface IStateProps {
  customerId: number;
}

interface IDispatchProps {
  updateGridStatistics: typeof updateGridStatistics;
  updateDefectMetrics: typeof updateDefectMetrics;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    customerId: state.mission?.id ?? -1,
  };
};

const mapDispatchToProps = {
  updateGridStatistics,
  updateDefectMetrics,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
