import React from "react";
import { styled, Typography } from "@mui/material";
import { RadioButtonUnchecked } from "@mui/icons-material";

interface ISidebarButtonProps {
  icon?: React.ReactNode;
  label?: string;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  background?: string;
  color?: string;
  hoverColor?: string;
  hightlightedColor?: string;
  highlighted?: boolean;
  dataTestId?: string;
}

const ActiveIndicator = styled("div")({
  position: "absolute",
  width: "6px",
  height: "6px",
  borderRadius: "50%",
  background: "#14a619",
  right: 4,
  top: 4,
});

const Button = styled("button")<ISidebarButtonProps>(
  ({
    background,
    color,
    hightlightedColor,
    highlighted,
    disabled,
    hoverColor,
  }) => ({
    display: "flex",
    width: "50px",
    position: "relative",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "8px",
    borderRadius: 0,
    border: 0,
    outline: 0,
    cursor: disabled ? undefined : "pointer",
    background: disabled
      ? "#e7e7e7"
      : highlighted
        ? hightlightedColor
        : background || "transparent",
    color: disabled ? "#999" : color || "#404040",
    transition: "background-color 0.3s ease",
    "&:hover": {
      background: disabled ? "#e7e7e7" : hoverColor,
    },
  })
);

export default function SidebarButton({
  icon = <RadioButtonUnchecked />,
  label,
  onClick,
  active = false,
  disabled = false,
  background = "#ffffff",
  color = "#404040",
  highlighted = false,
  hoverColor = "#bde2ff",
  hightlightedColor = "#deebfe",
  dataTestId,
}: ISidebarButtonProps) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      background={background}
      color={color}
      hoverColor={hoverColor}
      hightlightedColor={hightlightedColor}
      highlighted={highlighted}
      {...(dataTestId && { "data-testid": dataTestId })}
    >
      {active && <ActiveIndicator />}
      {icon}
      {label && <Typography sx={{ m: 0, p: 0 }}>{label}</Typography>}
    </Button>
  );
}
