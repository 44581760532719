import React from "react";
import { CloseOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

export default function CloseReviewToolIcon() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Tooltip title="Exit review mode" placement="bottom" arrow>
      <CloseOutlined
        sx={{ color: "#ffff" }}
        onClick={() => {
          navigate(`..${location.search}`);
        }}
        data-testid="reviewTools.close"
      />
    </Tooltip>
  );
}
