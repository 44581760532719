import { createContext, useState, useEffect } from "react";
import { useCurrentProject } from "hooks";
import {
  getStatusData,
  getStatusTypes,
  getTotalStatusCount,
  IData,
  IStatus,
} from "./utils";
import { captureException, EventHint } from "@sentry/react";

interface IContext {
  searchValue: string;
  setSearchValue: (value: string) => void;
  pageSize: number;
  setPageSize: (value: number) => void;
  loading: boolean;
  data: IData[];
  headers: string[];
  statuses: IStatus[];
  selectedPoleId: number;
  selectedComponentId: number;
  setSelectedPoleId: (value: number) => void;
  setSelectedComponentId: (value: number) => void;
  totalCount: number;
  page: number;
  setPage: (value: number) => void;
}

export const Context = createContext<IContext>({
  searchValue: "",
  setSearchValue: () => {},
  pageSize: 25,
  setPageSize: () => {},
  loading: false,
  data: [],
  headers: [],
  statuses: [],
  selectedPoleId: 0,
  selectedComponentId: 0,
  setSelectedPoleId: () => {},
  setSelectedComponentId: () => {},
  totalCount: 0,
  page: 1,
  setPage: () => {},
});

function extractHeaders(data: IData[]) {
  const headers = new Set<string>();
  for (const pole of data) {
    for (const overview of pole.overview) {
      headers.add(overview.component.name);
    }
  }
  const sortedHeaders = Array.from(headers).sort();
  return sortedHeaders;
}

export default function Provider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const currentProject = useCurrentProject();
  const [data, setData] = useState<IData[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<IStatus[]>([]);
  const [pageSize, setPageSize] = useState(25);
  const [selectedPoleId, setSelectedPoleId] = useState(0);
  const [selectedComponentId, setSelectedComponentId] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);

  async function updateStatusData(
    projectId,
    pageSize,
    pageNumber,
    searchValue
  ) {
    setLoading(true);

    try {
      // Create promises
      const totalCountPromise = getTotalStatusCount(projectId, searchValue);
      const responsePromise = getStatusData(
        projectId,
        pageSize,
        pageSize * (pageNumber - 1),
        searchValue
      );
      const statusTypePromise = getStatusTypes(projectId);

      // Wait for both promises to resolve
      const [totalCount, response, statusTypes] = await Promise.all([
        totalCountPromise,
        responsePromise,
        statusTypePromise,
      ]);

      // Set the data
      setTotalCount(totalCount);
      setHeaders(extractHeaders(response));
      setStatuses(statusTypes);
      setData(response);
    } catch (error) {
      const exceptionHint: EventHint = {
        event_id: "polestatusheatmap.provider.getStatusData",
        originalException: error,
        data: {
          projectId: projectId,
          pageSize: pageSize,
          pageNumber: pageNumber,
        },
      };

      captureException(error, exceptionHint);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (currentProject?.id) {
      updateStatusData(currentProject.id, pageSize, page, searchValue);
    }
  }, [currentProject?.id, pageSize, page, searchValue]);

  useEffect(() => {
    // Reset page number when page size changes
    setPage(1);
  }, [pageSize]);

  return (
    <Context.Provider
      value={{
        searchValue,
        setSearchValue,
        pageSize,
        setPageSize,
        loading,
        data,
        headers,
        statuses,
        selectedPoleId,
        selectedComponentId,
        setSelectedPoleId,
        setSelectedComponentId,
        totalCount,
        page,
        setPage,
      }}
    >
      {children}
    </Context.Provider>
  );
}
