import React from "react";
import MenuItem from "components/MenuItem";
import { Input, Typography } from "@mui/material";
import translations from "translations";
import { useLanguage } from "hooks";

interface Props {
  clusterDistance: number;
  setClusterDistance;
}

export default function ClusterDistance({
  clusterDistance,
  setClusterDistance,
}: Props) {
  const { language } = useLanguage();
  return (
    <MenuItem passive id="clusterDistance">
      <Typography>
        {translations.Menu.Settings.ClusterDistance[language]}
      </Typography>
      <Input
        style={{
          width: 66,
          marginRight: 10,
        }}
        type="number"
        value={clusterDistance}
        onChange={(e) => {
          setClusterDistance(e.target.value);
        }}
      />
    </MenuItem>
  );
}
