import { useSearchParams } from "react-router-dom";
import { setDateOfKey } from "./utils";
import { FilterKey, FilterType } from "./constants";
import { useMemo } from "react";

export function getDateImageCaptureFilter(
  searchParams: URLSearchParams
): FilterType["DATE_IMAGE_CAPTURE"] {
  const dateImageCaptureParams =
    searchParams.get(FilterKey.DATE_IMAGE_CAPTURE) ?? "";
  const splitDates = dateImageCaptureParams
    .split(",")
    .filter((d) => d.length > 0);
  let dateImageCapture: FilterType["DATE_IMAGE_CAPTURE"] = null;
  if (splitDates.length === 1) {
    splitDates.push(splitDates[0]);
    dateImageCapture = new Date(splitDates[0]);
  } else if (splitDates.length === 2) {
    dateImageCapture = [new Date(splitDates[0]), new Date(splitDates[1])];
  } else if (splitDates.length > 2) {
    console.error("Too many dates in the dateImageCapture filter");
    dateImageCapture = [new Date(splitDates[0]), new Date(splitDates[1])];
  }
  return dateImageCapture;
}

export function useDateImageCaptureFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const foundValueAsString = searchParams.get(FilterKey.DATE_IMAGE_CAPTURE);

  const dateImageCapture = useMemo(
    () => getDateImageCaptureFilter(searchParams),
    [foundValueAsString]
  );

  // Convert the key function to each specific key
  function setDateImageCapture(startDate: Date | null, endDate: Date | null) {
    setDateOfKey(
      searchParams,
      setSearchParams,
      FilterKey.DATE_IMAGE_CAPTURE,
      startDate,
      endDate
    );
  }

  // Export the functions and filters
  return {
    dateImageCapture,
    setDateImageCapture,
  };
}
