import { connect } from "react-redux";
import FolderView from "./FolderView";
import { RootState } from "state/store";
import { setJobs, setSubFolders } from "state/actions";

interface IStateProps {
  jobs: Array<any>;
  subFolders: Array<any>;
}

interface IDispatchProps {
  setJobs: (value) => void;
  setSubFolders: (value) => void;
}

interface IOwnProps {
  disabled?: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    jobs: state.imageUpload.jobs,
    subFolders: state.imageUpload.subFolders,
  };
};

const mapDispatchToProps = {
  setJobs,
  setSubFolders,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(FolderView);
