import { connect } from "react-redux";
import ProximitySwitch from "./ClientNameSwitch";
import { setClusterMethod, changeClientNaming } from "state/actions";
import { RootState } from "state/store";

interface IStateProps {
  clientNaming: boolean;
}

interface IDispatchProps {
  changeClientNaming: typeof changeClientNaming;
}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    clientNaming: state.user.client_naming,
  };
};

const mapDispatchToProps = {
  changeClientNaming,
};
export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ProximitySwitch);
