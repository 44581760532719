import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Select from "react-select";
import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import { IUser, useLanguage } from "hooks";
import { Delete, Save } from "@mui/icons-material";
import { toast } from "react-toastify";
import { authorizedPut } from "utils/request";
import Loading from "views/Loading";
import RoleWrapper from "components/RoleHOC/wrapper";
import translations from "translations";
import { accessLevels, accessLevelsHidden } from "./accessLevels";

export default function UserGroupPage() {
  const { language } = useLanguage();
  const params = useParams();
  const currentGroupID = parseInt(params.user_group);
  const { updateUser, updateUsers, user } = useOutletContext<{
    updateUsers: () => void;
    updateUser: () => void;
    user: IUser;
  }>();
  const navigate = useNavigate();
  const groupAccess = user.groups.find((g) => g.id === currentGroupID);
  const [accessLevel, setAccessLevel] = useState<number>(
    groupAccess.accessLevel
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setAccessLevel(groupAccess.accessLevel);
  }, [groupAccess.accessLevel]);

  async function giveAccess() {
    // Notify the user that we are starting a request
    setLoading(true);

    // Add the user (nothing happens if they exist)
    try {
      // Inform the user of what's going on

      // Prepare the API call
      const endpoint = `/admin/user/${user.id}/invite/group`;
      const payload: Record<string, number> = {
        accessLevel: accessLevel,
        group: groupAccess.id,
      };
      // Give user access
      await authorizedPut(endpoint, payload);

      // Give the new user access

      updateUser();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Failed to update user", err);
      toast.error(
        `${translations.words.UpdatePermissionsFor[language]} ${user.name} ${translations.words.Failed[language]}`
      );
    }
  }

  async function removeAccess() {
    const confirmation = window.confirm(
      `${translations.UserAdmin.RemoveAccessConfirm[language]} ${user.name} ${translations.words.on[language]} ${translations.words.Customer[language]} ${groupAccess.name}?`
    );
    if (!confirmation) {
      return;
    }

    setLoading(true);

    // Add the user (nothing happens if they exist)
    try {
      // Inform the user of what's going on

      // Prepare the API call
      const endpoint = `/admin/user/${user.id}/invite/group`;
      const payload: Record<string, number> = {
        accessLevel: null,
        group: groupAccess.id,
      };
      // Give user access
      await authorizedPut(endpoint, payload);

      // Give the new user access

      updateUser();
      updateUsers();
      navigate(`/users/${user.id}`);
      setLoading(false);
    } catch (err) {
      console.error("Failed to update user", err);
      setLoading(false);
      toast.error(
        `${translations.UserAdmin.RemoveAccessFailed[language]} ${user.name}`
      );
    }
  }

  return (
    <RoleWrapper keyName="userAdmin">
      <Paper sx={{ p: 2 }}>
        <Stack spacing={1}>
          <Stack>
            <Typography variant="h5" component="h3">
              {translations.words.Customer[language]} {groupAccess.name}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            width={500}
          >
            <Typography width={200}>
              {translations.words.AccessLevel[language]}
            </Typography>
            <Box flex={1} pl={1.5}>
              <Select
                onChange={(e) => setAccessLevel(e.value)}
                value={{
                  label: accessLevelsHidden[accessLevel][language],
                  value: accessLevel,
                }}
                options={Object.keys(accessLevels).map((key) => ({
                  label: accessLevels[parseInt(key)][language],
                  value: parseInt(key),
                }))}
              />
            </Box>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
            width={500}
          >
            <Typography width={200}>
              {translations.UserAdmin.TimeBasedAccess[language]}
            </Typography>
            <Tooltip
              title="Coming soon"
              placement="right"
              arrow
              disableInteractive
            >
              <Box>
                <Checkbox disabled />
              </Box>
            </Tooltip>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              endIcon={loading ? <Loading relative size={20} /> : <Delete />}
              variant="contained"
              color="error"
              onClick={removeAccess}
              disabled={loading}
            >
              {translations.UserAdmin.RemoveAccess[language]}
            </Button>
            <Button
              endIcon={loading ? <Loading relative size={20} /> : <Save />}
              variant="contained"
              color="success"
              onClick={giveAccess}
              disabled={accessLevel === groupAccess.accessLevel || loading}
            >
              {translations.UserAdmin.SaveChanges[language]}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </RoleWrapper>
  );
}
