import { useContext } from "react";
import { FilterCountContext } from "providers/FilterCountProvider";

export function useSeverityCount() {
  const context = useContext(FilterCountContext);
  const { severity, severityLoading, setSeverityTabActive } = context;

  return {
    counts: severity,
    loading: severityLoading,
    setActive: setSeverityTabActive,
  };
}
