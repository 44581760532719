import { useState } from "react";
import {
  Typography,
  Stack,
  Paper,
  Divider,
  Box,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useParams, Outlet, useNavigate, Link } from "react-router-dom";

import FullPage from "components/FullPage";
import { Add, Clear, Person, Search } from "@mui/icons-material";
import translations from "translations";
import { useLanguage, useUserAdmin } from "hooks";
import RoleWrapper from "components/RoleHOC/wrapper";

interface IProps {
  myEmail: string;
}

export default function UserAdministrator({ myEmail }: IProps) {
  const { language } = useLanguage();
  const params = useParams();
  const navigate = useNavigate();
  const currentUser = parseInt(params.user);
  const [searchValue, setSearchValue] = useState<string>("");
  const [users, updateUsers] = useUserAdmin();

  function isIn(source: string, test: string): boolean {
    const string = source.replace(/[^a-zA-Z0-9@. ]/g, "");
    return string.toLowerCase().includes(test.toLowerCase());
  }

  function filterFunction(user: (typeof users)[number]): boolean {
    // Check if we match on ID, name or email
    const textMatch =
      isIn(JSON.stringify(user.id), searchValue) ||
      isIn(user.name, searchValue) ||
      isIn(user.email, searchValue);
    return textMatch;
  }

  const filteredUsers = users
    .filter(filterFunction)
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  const myUser = filteredUsers.find(
    (u) => u.email.toLowerCase() === myEmail.toLowerCase()
  );
  return (
    <FullPage title={translations.UserAdmin[language]} tab={0} tabs={[]}>
      <RoleWrapper keyName="userAdmin">
        <IconButton
          sx={{ position: "absolute", top: 60, right: 5 }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <Clear />
        </IconButton>

        <Stack
          spacing={1}
          direction={{ xs: "column", md: "row" }}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{
            height: "100%",
            overflowY: "auto",
            position: "relative",
          }}
          paddingLeft={{ xs: 1, md: 0 }}
          paddingRight={{ xs: 1, md: 2 }}
        >
          <Stack direction="column" minWidth={300} maxWidth={300}>
            <Stack sx={{ pb: 2, pr: 2 }}>
              <Link
                to="/users/new"
                replace
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box sx={{ py: 2 }}>
                  <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Add />
                    <Typography>
                      {translations.UserAdmin.InviteNewUser[language]}
                    </Typography>
                  </Stack>
                </Box>
              </Link>

              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ pr: 2 }}
              >
                <Search sx={{ my: 0.5 }} />
                <TextField
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  placeholder={translations.UserAdmin.SearchForUser[language]}
                  variant="standard"
                  sx={{ flex: 1 }}
                />
              </Stack>
            </Stack>
            <Stack
              sx={{ overflowY: "auto" }}
              paddingRight={{ xs: 0, md: 2 }}
              height={{ xs: 300, md: "100%" }}
              spacing={1}
            >
              {filteredUsers.length === 0 && (
                <Typography>
                  {translations.UserAdmin.NoUsersFound[language]}
                </Typography>
              )}

              {myUser && (
                <Link
                  key={myUser.id}
                  to={`${myUser.id}`}
                  replace
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Paper
                    sx={{
                      p: 2,
                      backgroundColor:
                        myUser.id === currentUser ? "#D6E7FF" : undefined,
                    }}
                  >
                    <Stack
                      width="100%"
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography>{myUser.name}</Typography>
                      <Tooltip
                        title={translations.words.ThisIsYou[language]}
                        placement="right"
                        arrow
                        disableInteractive
                      >
                        <Person />
                      </Tooltip>
                    </Stack>
                  </Paper>
                </Link>
              )}
              {filteredUsers
                .filter((user) => user.email !== myEmail)
                .map((user) => (
                  <Link
                    key={user.id}
                    to={`${user.id}`}
                    replace
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Paper
                      sx={{
                        p: 2,
                        backgroundColor:
                          user.id === currentUser ? "#D6E7FF" : undefined,
                      }}
                    >
                      <Stack
                        width="100%"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography noWrap={true}>{user.name}</Typography>
                      </Stack>
                    </Paper>
                  </Link>
                ))}
            </Stack>
          </Stack>
          <Box overflow={{ xs: "none", md: "auto" }} width="100%">
            <Outlet context={{ updateUsers }} />
          </Box>
        </Stack>
      </RoleWrapper>
    </FullPage>
  );
}
