import { useSkyqraftHiddenFilter, useLanguage } from "hooks";
import translations from "translations";
import ReactSelect from "react-select";

export default function SkyqraftHiddenFilter() {
  const { skyqraftHidden, setSkyqraftHidden } = useSkyqraftHiddenFilter();
  const { language } = useLanguage();

  const options = [
    {
      value: "none",
      label: translations.Filter.HideHidden[language],
    },
    {
      value: "both",
      label: translations.Filter.ShowAll[language],
    },
    {
      value: "only",
      label: translations.Filter.HiddenOnly[language],
    },
  ];

  return (
    <ReactSelect
      options={options}
      value={options.find((option) => option.value === skyqraftHidden)}
      onChange={(selected) => {
        const selectedValue = selected.value;
        if (
          selectedValue === "none" ||
          selectedValue === "both" ||
          selectedValue === "only"
        ) {
          setSkyqraftHidden(selectedValue);
        }
      }}
    />
  );
}
