import { MutableRefObject } from "react";

// Define a utility function
export function getCanvasBox(
  canvasReference: MutableRefObject<HTMLCanvasElement | null>
) {
  if (!canvasReference.current)
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    };
  const { width, height, top, left } =
    canvasReference.current.getBoundingClientRect();
  return { width, height, top, left };
}
