import { Button } from "@mui/material";
import RoleWrapper from "components/RoleHOC/wrapper";
import translations from "translations";
import { useCurrentProject, useLanguage } from "hooks";
import { toast } from "react-toastify";
import { getLambdaAxiosWithAuth } from "utils/request";
import { dateTimeToString } from "utils/utils";

type Languages = ReturnType<typeof useLanguage>["language"];

export async function getVegetationReport(
  project: ReturnType<typeof useCurrentProject>,
  reportType: "csv" | "excel",
  language: Languages
) {
  const generationPrompt =
    reportType === "csv"
      ? translations.Menu.Tools.UsageReport.GeneratingCSV[language]
      : translations.Menu.Tools.UsageReport.GeneratingExcel[language];
  const fileType = reportType === "csv" ? "csv" : "xlsx";

  const toastPointer = toast.info(generationPrompt, { autoClose: false });
  const query = new URLSearchParams(window.location.search);

  const infringementID = ["3", "4", "6", "7", "12"]; //Infringement ID's with vegetation

  query.set("infringementID", infringementID.join(","));

  const axiosInstance = await getLambdaAxiosWithAuth();
  const response = await axiosInstance.get(
    `/report/lidar/infringement/${reportType}?${query.toString()}`,
    { responseType: "blob", headers: { MissionID: project.id } }
  );
  const report = response.data;
  toast.dismiss(toastPointer);
  toast.success(translations.Menu.Tools.UsageReport.Generated[language]);

  const url = window.URL.createObjectURL(
    new Blob([report], { type: "application/octet-stream" })
  );

  const link = document.createElement("a");
  link.href = url;
  const d = new Date();
  const timeName = dateTimeToString(d);

  const fileName = `vegetation_report_project_${project.name}_${project.year}_${timeName}.${fileType}`;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}

export default function VegetationReport() {
  const { language } = useLanguage();
  const project = useCurrentProject();
  return (
    <RoleWrapper keyName="vegetationReport">
      <h2>{translations.Menu.Tools.VegetationReport[language]}</h2>
      <p>{translations.Menu.Tools.VegetationReport.Tooltip[language]}</p>
      <hr />
      <Button
        onClick={() => getVegetationReport(project, "excel", language)}
        variant="contained"
        className="primaryButton"
        style={{ marginLeft: 5, textTransform: "none" }}
      >
        Excel
      </Button>

      <Button
        onClick={() => {
          getVegetationReport(project, "csv", language);
        }}
        variant="contained"
        className="secondaryButton"
        style={{ marginLeft: 5, textTransform: "none" }}
      >
        CSV
      </Button>
    </RoleWrapper>
  );
}
