import { connect } from "react-redux";
import Component from "./HideImage";
import { RootState } from "state/store";
import { hideImage } from "state/actions";

interface IDispatchProps {
  hideImage: (mission: number | string, image: number | string) => void;
}

interface IStateProps {}

interface IOwnProps {}

const mapStateToProps = (state: any, ownProps: IOwnProps) => {
  return {};
};

const mapDispatchToProps = {
  hideImage,
};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
