import { connect } from "react-redux";
import { RootState } from "state/store";
import Component from "./UserAdministrator";

interface IStateProps {
  myEmail: string;
}

interface IDispatchProps {}

interface IOwnProps {}

const mapStateToProps = (state: RootState) => {
  return {
    myEmail: state.user.email,
  };
};

const mapDispatchToProps = {};

export default connect<IStateProps, IDispatchProps, IOwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Component);
