import { MenuItem, Tooltip } from "@mui/material";
import translations from "translations";
import { RestartAlt } from "@mui/icons-material";
import { useLanguage } from "hooks";

type Props = {
  menuPayload: {
    onChangeRegion: Function;
    region;
    index: number;
    supervisor: boolean;
  };
};

export default function EditWorkflowContextMenu({ menuPayload }: Props) {
  const { language } = useLanguage();

  const setWorkflowStatus = (number) => {
    menuPayload.onChangeRegion({
      ...menuPayload.region,
      workflow_status: menuPayload.region.workflow_status.map((value, i) =>
        i === menuPayload.index ? number : value
      ),
    });
  };

  return (
    <MenuItem
      style={{ marginTop: 5, padding: 0 }}
      onClick={() => setWorkflowStatus(1)}
    >
      <Tooltip
        title={
          translations.AnnotationTool.WorkflowStatusMenu.ReviewRequested[
            language
          ]
        }
      >
        <RestartAlt />
      </Tooltip>
    </MenuItem>
  );
}
