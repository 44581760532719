import { useRef, useState, useEffect } from "react";
import colormap from "colormap";
import { COLOR_OPTIONS } from "./constants";
import { useSelector } from "hooks";
import { useInputListener } from "views/image/AnnotationPreview/hooks/inputListener";
import "./style.scss";

import { useSearchParams } from "react-router-dom";

interface IProps {
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
  imagePosition: {
    topLeft: { x: number; y: number };
    bottomRight: { x: number; y: number };
  };
}

function updateImageData(
  imageData: ImageData,
  thermalData: number[],
  updateLow,
  updateHigh,
  dataMinTemp,
  dataMaxTemp,
  updateColors
) {
  for (let i = 0; i < thermalData.length; i += 1) {
    const { red, green, blue } = computePixelColor(
      updateLow,
      updateHigh,
      dataMinTemp,
      dataMaxTemp,
      updateColors,
      thermalData[i]
    );
    imageData.data[i * 4] = red;
    imageData.data[i * 4 + 1] = green;
    imageData.data[i * 4 + 2] = blue;
    imageData.data[i * 4 + 3] = 255;
  }
  return imageData;
}

function computePixelColor(
  lowThreshold,
  highThreshold,
  dataMinTemp,
  dataMaxTemp,
  colors,
  pixelTemp: number
) {
  let percentage =
    (pixelTemp - (lowThreshold || dataMinTemp)) /
    ((highThreshold || dataMaxTemp) - (lowThreshold || dataMinTemp));
  const percentage_steps = 1 / colors.length;
  percentage = Math.max(Math.min(percentage, 1), 0);
  let upper_color = Math.round(
    (percentage - (percentage % percentage_steps)) / percentage_steps
  );
  let lower_color = Math.round(
    (percentage - (percentage % percentage_steps) + percentage_steps) /
      percentage_steps
  );

  if (lower_color < 0 || upper_color < 0) {
    lower_color++;
    upper_color++;
  }

  if (lower_color >= colors.length || upper_color >= colors.length) {
    lower_color--;
    upper_color--;
  }

  const bilinear_movement = (percentage % percentage_steps) / percentage_steps;
  const red =
    colors[lower_color][0] +
    (colors[upper_color][0] - colors[lower_color][0]) * bilinear_movement;
  const green =
    colors[lower_color][1] +
    (colors[upper_color][1] - colors[lower_color][1]) * bilinear_movement;
  const blue =
    colors[lower_color][2] +
    (colors[upper_color][2] - colors[lower_color][2]) * bilinear_movement;

  return { red, green, blue };
}

function ThermalViewer({ imagePosition, canvasRef }: IProps) {
  useInputListener({
    canvasRef,
    annotations: [],
    reviewMode: -1,
  });
  const minTemp = useSelector((state) => state.imageViewer.minTemp);
  const maxTemp = useSelector((state) => state.imageViewer.maxTemp);
  const thermalData = useSelector(
    (state) => state.imageViewer.thermalData.temperature
  );
  const thermalWidth = useSelector(
    (state) => state.imageViewer.thermalData.width
  );
  const thermalHeight = useSelector(
    (state) => state.imageViewer.thermalData.height
  );
  const [searchParams] = useSearchParams();
  const low = searchParams.has("low")
    ? parseFloat(searchParams.get("low"))
    : minTemp;
  const high = searchParams.has("high")
    ? parseFloat(searchParams.get("high"))
    : maxTemp;

  const myRef = useRef<HTMLCanvasElement>(null);
  const [colors, setColors] = useState<number[][]>(
    colormap({
      colormap: "temperature",
      nshades: 50,
      format: "rba",
      alpha: 1,
    })
  );
  const [colorIndex, setColorIndex] = useState(0);

  useEffect(() => {
    const canvas = myRef.current;
    if (!canvas) return;

    canvas.width = thermalWidth;
    canvas.height = thermalHeight;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const imageData = ctx.getImageData(0, 0, thermalWidth, thermalHeight);
    const updatedData = updateImageData(
      imageData,
      thermalData,
      low,
      high,
      minTemp,
      maxTemp,
      colors
    );
    ctx.putImageData(updatedData, 0, 0);
  }, [low, high, minTemp, maxTemp, colors, thermalData]);

  useEffect(() => {
    updateColors();
  }, [colorIndex]);

  const updateColors = () => {
    setColors(
      colormap({
        colormap: COLOR_OPTIONS[colorIndex],
        nshades: 50,
        format: "rba",
        alpha: 1,
      })
    );
  };

  const colorsReversed = [...colors].reverse();
  let normalSize = thermalHeight / colorsReversed.length;
  let n_partitions_left = colorsReversed.length;

  const upper_height =
    ((maxTemp - (high || maxTemp)) / (maxTemp - minTemp)) * thermalHeight;

  const lower_height =
    (((low || minTemp) - minTemp) / (maxTemp - minTemp)) * thermalHeight;

  let remaining_height = thermalHeight;

  if (upper_height > normalSize) {
    n_partitions_left -= 1;
    remaining_height -= upper_height;
  }

  if (lower_height > normalSize) {
    n_partitions_left -= 1;
    remaining_height -= lower_height;
  }

  if (n_partitions_left < colorsReversed.length) {
    normalSize = remaining_height / n_partitions_left;
  }
  const width = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const height = imagePosition.bottomRight.y - imagePosition.topLeft.y;
  const stylePosition = {
    left: imagePosition.topLeft.x || 0,
    top: imagePosition.topLeft.y || 0,
    width: Number.isNaN(width) ? 0 : width,
    height: Number.isNaN(height) ? 0 : height,
  };

  return (
    <div id="thermalContainer">
      <div
        id="thermal"
        style={{ height: thermalHeight, pointerEvents: "none" }}
      >
        <canvas
          style={{
            ...stylePosition,
            zIndex: 0,
            position: "absolute",
          }}
          ref={myRef}
        />
      </div>
    </div>
  );
}

export default ThermalViewer;
