import styled from "styled-components";
import { SecondaryFont } from "arkionui/constants";

export const TableRow = styled.tr`
	border-bottom: 1px solid #ccc;
	background-color: ${(props) => props.color};
`;

export const ColorCircle = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: ${(props) => props.color};
`;

export const ColorCircleCenter = styled(ColorCircle)`
	margin:auto;
	position: absolute;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
	z-index: 20;
	padding: 1px;
	width: 23px;
	height: 23px;
  color: #FFF;
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 600;
	display: flex;
  justify-content: center;
  align-items: center;
`;

export const CellExtraPadding = styled.div`
	position: absolute;
	background-color: ${(props) => props.color};
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover{
		top: -2px;
		left: -2px;
		right: -2px;
		bottom: -2px;
		box-shadow: 0px 0px 5px 5px #aaa;
		z-index: 10;
		background-color: #FFEA9D;
	}
	
`;

export const NoDataCell = styled.div`
    color: #353535;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 300;
`;

export const TableHeader = styled.th`
	border: 1px solid #ccc;
	font-family: ${SecondaryFont};
	font-weight: 400;
	font-size: 1rem;
	width: 92px;
	height: 36px;
`;

export const TableData = styled.td`
	text-align: center;
	border: 1px solid #ccc;
	width: 64px;
	height: 36px;
	position: relative;
	background-color: ${(props) => props.color};
`;
