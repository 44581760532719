import { ReactElement } from "react";
import { getLambdaAxiosWithAuth } from "utils/request";
import { toast } from "react-toastify";
import translations from "translations";
import { Button } from "@mui/material";
import { useCurrentProject, useLanguage } from "hooks";

export default function BluePowerline(): ReactElement {
  const { language } = useLanguage();
  const currentProject = useCurrentProject();

  async function blueifyPowerlines() {
    if (!currentProject) {
      toast.error("No project selected");
    }

    // Inform the user what's happening
    const toastHandle = toast.info("Updating powerlines", {
      autoClose: false,
      closeButton: false,
      closeOnClick: false,
    });

    const lambdaInstance = await getLambdaAxiosWithAuth();
    const response = await lambdaInstance.post(
      `/project/${currentProject.id}/powerline/bluify`
    );
    if (response.status === 401) {
      toast.error("Unauthorized");
      return;
    } else if (response.status === 500) {
      console.error("Updating powerlines had an internal error");
      toast.error("Server error. Arkion have been notified");
      return;
    } else if (response.status === 504) {
      toast.error("Update took too long. Project is too large");
      return;
    } else if (response.status !== 200) {
      toast.error("Unknown error");
      return;
    }

    // Present the user with a final summary
    toast.update(toastHandle, {
      autoClose: 5000,
      closeButton: true,
      closeOnClick: true,
      render: "Powerlines updated",
      type: "success",
    });
  }

  return (
    <div>
      <h2>{translations.Menu.Tools.Powerlines.Bluify.Title[language]}</h2>
      <p>{translations.Menu.Tools.Powerlines.Bluify.Description[language]}</p>
      <Button
        onClick={blueifyPowerlines}
        variant="contained"
        className="primaryButton"
        fullWidth
      >
        {translations.Menu.Tools.Powerlines.Bluify.Button[language]}
      </Button>
    </div>
  );
}
