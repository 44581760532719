import { authorizedGet, authorizedPost, authorizedPut } from "utils/request";
import { MissionOrder } from "interfaces";
import { getMissions } from "state/actions/mission";
import axios from "axios";
import { getLambdaURL } from "utils/path";
export interface OrderResponse {
  message: string;
  missionId: number;
}

export function orderMission(
  order: MissionOrder,
  callback: Function,
  onFail: Function = null
) {
  return async function (dispatch) {
    try {
      let formData = new FormData();
      let keys = Object.keys(order);
      keys.forEach((key) => {
        formData.append(key, order[key]);
      });
      const lambdaURL = getLambdaURL();
      const accessToken = await authorizedGet("/auth/token");

      const response = await axios.post<OrderResponse>(
        `${lambdaURL}/mission/order`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      if (response.status < 400) {
        dispatch(getMissions());
        callback(response.data);
      } else {
        onFail && onFail();
      }
    } catch (err) {
      onFail && onFail(err);
    }
  };
}

export function acceptMissionOrder(
  mission: number,
  contract_number: string,
  callback: Function = null,
  onFail: Function = null
) {
  return async function (dispatch) {
    try {
      const response = await authorizedPost<OrderResponse>(
        `/mission/${mission}/accept`,
        { contract_number }
      );
      dispatch(getMissions(() => callback && callback(response)));
    } catch (err) {
      onFail && onFail(err);
    }
  };
}

export interface IPlanningDetails {
  email_list?: number[];
  uploader_list?: number[];
  invoice_reference?: string;
  planning_done?: boolean;
}

export function updatePlanning(
  mission: number,
  planningDetails: IPlanningDetails,
  callback: Function = null,
  onFail: Function = null
) {
  return async function (dispatch) {
    try {
      const response = await authorizedPut<string>(
        `/mission/${mission}/planning`,
        planningDetails
      );
      dispatch(getMissions(() => callback && callback(response)));
    } catch (err) {
      onFail && onFail(err);
    }
  };
}

export function markFlightDone(mission: number, callback: Function = null) {
  return async function (dispatch) {
    const response = await authorizedPost(`/mission/${mission}/flight_done`);
    dispatch(getMissions(() => callback && callback(response)));
  };
}
export function markAnalysisDone(mission: number, callback: Function = null) {
  return async function (dispatch) {
    const response = await authorizedPost(`/mission/${mission}/analysis_done`);
    dispatch(getMissions(() => callback && callback(response)));
  };
}
export function markReportDone(mission: number, callback: Function = null) {
  return async function (dispatch) {
    const response = await authorizedPost(
      `/mission/${mission}/final_report_done`
    );
    dispatch(getMissions(() => callback && callback(response)));
  };
}
