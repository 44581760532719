import React from "react";

type Props = {
  label: string;
  epoch: number;
  sidebarWidth: number;
  startEpoch: number;
  endEpoch: number;
  borderStyle: "solid" | "dashed" | "dotted";
  marginBottom: number;
};

export default function TimeSeparator({
  label,
  epoch,
  sidebarWidth,
  startEpoch,
  endEpoch,
  borderStyle,
  marginBottom,
}: Props) {
  const epochLength = endEpoch - startEpoch;
  const offset = `${(100 * (epoch - startEpoch)) / epochLength}%`;
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: sidebarWidth + 16,
        height: "100%",
        pointerEvents: "none",
        width: `calc( 100% - ${sidebarWidth + 16}px )`,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{ width: offset }} />
      <div
        style={{
          height: `calc( 100% - ${marginBottom}px )`,
          borderLeft: `1px ${borderStyle} black`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        <p
          style={{
            position: "absolute",
            bottom: 0,
            fontFamily: "monospace",
            textAlign: "left",
            left: 5,
            textOverflow: "ellipsis",
            overflow: "hidden",
            margin: 0,
            marginBottom: 0,
            marginLeft: 0,
            fontSize: 12,
          }}
        >
          {label}
        </p>
      </div>
    </div>
  );
}
