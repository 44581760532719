import React, { ReactElement } from "react";
import { Chip } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Brightness1 } from "@mui/icons-material";
import translations from "translations";
import { getClientSeverityName } from "utils/utils";
import hasAccess from "utils/authTools";
import { useLanguage } from "hooks";

interface Props {
  region;
  objectTypes;
  issueSeverities;
  saveObject;
  onChangeRegion;
  index;
  annotatorObjectColor: boolean;
  horizontal?: boolean;
}

function TypeButtonSeverity({
  region,
  objectTypes,
  issueSeverities,
  variant,
  onChangeRegion,
  index,
  saveObject,
  annotatorObjectColor,
}) {
  const { language } = useLanguage();
  let typeName, typeColor;

  if (!!region.is_defect[index] && "detection" === variant) {
    return null;
  } else if (
    !!!region.is_defect[index] &&
    ["defect", "severity"].includes(variant)
  ) {
    return null;
  }

  if (["detection", "defect"].includes(variant)) {
    let type = objectTypes.find((obj) => region.types[index] === obj.id);
    typeName =
      type?.id || translations.AnnotationTool.SelectDetection[language];
    typeColor =
      (annotatorObjectColor ? type?.class_color : type?.color) ||
      (!!region.is_defect[index] ? "#F00" : "#0F0");
  } else {
    let issueSeverity = issueSeverities.find(
      (s) => s.id === region.severities[index]
    );
    typeName =
      issueSeverity?.id || translations.AnnotationTool.SelectSeverity[language];
    typeColor = "#F00";
  }

  return (
    <div style={{ display: "flex", padding: 0 }}>
      <Chip
        size={"small"}
        sx={{ width: "110px" }}
        label={getClientSeverityName(typeName).name}
        variant={"outlined"}
        className={`selectTypeChip ${variant}`}
        onClick={() => {
          if (region.types[index] && region.is_defect[index]) {
            onChangeRegion({
              ...region,
              editType:
                region.editType === variant && region.editIndex === index
                  ? undefined
                  : variant,
              editIndex:
                region.editType === variant && region.editIndex === index
                  ? undefined
                  : index,
            });
          }
        }}
      />
      <ExpandLessIcon
        onClick={() => {
          if (region.types[index] && region.is_defect[index]) {
            saveObject(
              {
                ...region,
                severities: region.severities.map((value, i) =>
                  i === index ? (value > 1 ? value - 1 : value) : value
                ),
                workflow_status: region.workflow_status.map((value, i) =>
                  i === index ? Math.max(value, 2) : value
                ),
                isNew: false,
              },
              "severity"
            );
          }
        }}
      />

      <ExpandMoreIcon
        onClick={() => {
          if (region.types[index] && region.is_defect[index]) {
            saveObject(
              {
                ...region,
                severities: region.severities.map((value, i) =>
                  i === index ? (value < 4 ? value + 1 : value) : value
                ),
                workflow_status: region.workflow_status.map((value, i) =>
                  i === index ? Math.max(value, 2) : value
                ),
                isNew: false,
              },
              "severity"
            );
          }
        }}
      />
    </div>
  );
}
function TypeButton({
  region,
  objectTypes,
  issueSeverities,
  variant,
  onChangeRegion,
  index,
  annotatorObjectColor,
  horizontal,
}) {
  const { language } = useLanguage();
  let typeName, typeColor;

  let userHasAccess = hasAccess("annotationToolEditObjects");

  if (!!region.is_defect[index] && "detection" === variant) {
    return null;
  } else if (
    !!!region.is_defect[index] &&
    ["defect", "severity"].includes(variant)
  ) {
    return null;
  }

  if (["detection", "defect"].includes(variant)) {
    let type = objectTypes.find((obj) => region.types[index] === obj.id);
    typeName =
      type?.name || translations.AnnotationTool.SelectDetection[language];
    typeColor =
      (annotatorObjectColor ? type?.class_color : type?.color) ||
      (!!region.is_defect[index] ? "#F00" : "#0F0");
  } else {
    let issueSeverity = issueSeverities.find(
      (s) => s.id === region.severities[index]
    );
    typeName =
      issueSeverity?.name ||
      translations.AnnotationTool.SelectSeverity[language];
    typeColor = "#F00";
  }

  return (
    <Chip
      sx={{
        minWidth: !horizontal || variant === "defect" ? "160px" : "320px",
      }}
      size={"small"}
      label={typeName}
      variant={"outlined"}
      className={`selectTypeChip ${variant}`}
      icon={<Brightness1 fontSize={"small"} style={{ color: typeColor }} />}
      onClick={() => {
        if (variant === "detection" && !userHasAccess) {
          return;
        }
        onChangeRegion({
          ...region,
          editType:
            region.editType === variant && region.editIndex === index
              ? undefined
              : variant,
          editIndex:
            region.editType === variant && region.editIndex === index
              ? undefined
              : index,
        });
      }}
    />
  );
}

export default function TypeSelector({
  region,
  objectTypes,
  issueSeverities,
  saveObject,
  onChangeRegion,
  index,
  annotatorObjectColor,
  horizontal = true,
}: Props): ReactElement {
  return (
    <div className="typeContainer">
      <div
        className="typeSelector"
        style={{
          flexDirection: !horizontal ? "column" : "row",
          width: !horizontal ? "185px" : "330px",
          marginBottom: "4px ",
          marginLeft: "3px",
        }}
      >
        <TypeButton
          region={region}
          objectTypes={objectTypes}
          issueSeverities={issueSeverities}
          variant="detection"
          onChangeRegion={onChangeRegion}
          index={index}
          annotatorObjectColor={annotatorObjectColor}
          horizontal={horizontal}
        />
        <TypeButton
          region={region}
          objectTypes={objectTypes}
          issueSeverities={issueSeverities}
          variant="defect"
          onChangeRegion={onChangeRegion}
          index={index}
          annotatorObjectColor={annotatorObjectColor}
          horizontal={horizontal}
        />
        <TypeButtonSeverity
          region={region}
          objectTypes={objectTypes}
          issueSeverities={issueSeverities}
          variant="severity"
          saveObject={saveObject}
          onChangeRegion={onChangeRegion}
          index={index}
          annotatorObjectColor={annotatorObjectColor}
        />
      </div>
    </div>
  );
}
