interface ILanguages {
  EN: string;
  SE: string;
  NO: string;
}

export function Translated({ EN, SE, NO }: ILanguages) {
  return {
    EN: EN,
    SE: SE,
    NO: NO,
  };
}
