import { useState, ReactNode } from "react";
import { IContextValue, context } from "./context";
import { IMatrix } from "../../types";
import { compose, scale, translate } from "transformation-matrix";

const defaultMatrix = compose(translate(-30, -100), scale(1.2, 1.2));

interface IProviderProps {
  children: ReactNode;
}

function CanvasProvider({ children }: IProviderProps) {
  // Set up some states to keep track of
  const [matrix, setMatrix] = useState<IMatrix>(defaultMatrix);
  const [mode, setModeState] = useState<"add" | "edit" | "view">("view");
  const [selectedAnnotation, setSelectedAnnotation] = useState<number | null>(
    null
  );

  // This is useful in case we need to
  // change a state based on the name
  // of the mode
  function setMode(mode: "add" | "edit" | "view") {
    setSelectedAnnotation(null);

    setModeState(mode);
  }

  const payload: IContextValue = {
    mode,
    setMode,
    matrix,
    setMatrix,
    selectedAnnotation,
    setSelectedAnnotation,
  };

  return <context.Provider value={payload}>{children}</context.Provider>;
}

export default CanvasProvider;
