import { useEffect, useState } from "react";
import styled from "styled-components";
import { Tooltip } from "@mui/material";

const backgroundColors = {
  disabled: "#939393",
  grey: "#939393",
  green: "#24BE4F",
  lightGreen: "#36D71C",
  red: "#E41616",
  yellow: "#FFB800",
};

const Circle = styled.button`
	border-radius: 50%;
	height: 50px;
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	padding: 0;
	margin: 0;
	transition: .3s;

	&[disabled] {
		background-color: ${backgroundColors.disabled};
	}
	&:not([disabled]) {
		background-color: ${(props) => backgroundColors[props.color]};
		cursor: pointer;

		&:hover {
			cursor: pointer;
			transform: scale(1.05);
		}
		${
      (props) => props.isClicked && "transform: scale(1.2) !important;" // !important to override the hover effect }
    }
`;

interface IProps {
  icon: React.ReactElement;
  disabled?: boolean;
  onClick: () => void;
  tooltipText: string;
  color: keyof typeof backgroundColors;
  hotkey?: string;
}
export default function PrimaryAction(props: IProps) {
  const { icon, disabled, onClick, tooltipText, hotkey, ...htmlProps } = props;

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    if (disabled) {
      return;
    }
    setIsClicked(true);
    onClick();
    setTimeout(() => setIsClicked(false), 200); // Remove the class after 200ms
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        hotkey &&
        e.key.toLowerCase() === hotkey.toLowerCase() &&
        !["INPUT", "TEXTAREA"].includes((e.target as HTMLElement).tagName)
      ) {
        handleClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [hotkey, handleClick]);

  const hotkeyText = hotkey && !disabled ? ` (hotkey=${hotkey})` : "";

  return (
    <Tooltip title={`${tooltipText}${hotkeyText}`} placement="top" arrow>
      <Circle
        onClick={handleClick}
        disabled={disabled}
        isClicked={isClicked}
        {...htmlProps}
      >
        {icon}
      </Circle>
    </Tooltip>
  );
}
