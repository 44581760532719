import React from "react";
import { Collapse, Tooltip, Button, Stack } from "@mui/material";
import {
  ArrowBack,
  ArrowDownward,
  ArrowUpward,
  ArrowForward,
} from "@mui/icons-material";
import translations from "translations";
import { useSearchParams } from "react-router-dom";
import { useLanguage } from "hooks";

export default function NavigationPopup() {
  const { language } = useLanguage();
  const [searchParams] = useSearchParams();
  const showNavigation = !!parseInt(searchParams.get("nav") ?? "0");
  function changeImage(direction: string, filter: boolean) {}
  return (
    <Collapse in={showNavigation}>
      <Tooltip
        title={translations.ImageViewer.NavigateChild[language]}
        placement={"top"}
        disableInteractive
        arrow
      >
        <Stack direction="row" spacing={1} margin={1}>
          <Button
            onClick={() => changeImage("left", false)}
            style={{ backgroundColor: "#FFF" }}
          >
            <ArrowBack />
          </Button>
          <Button
            onClick={() => changeImage("down", false)}
            style={{ backgroundColor: "#FFF" }}
          >
            <ArrowDownward />
          </Button>
          <Button
            onClick={() => changeImage("up", false)}
            style={{ backgroundColor: "#FFF" }}
          >
            <ArrowUpward />
          </Button>
          <Button
            onClick={() => changeImage("right", false)}
            style={{ backgroundColor: "#FFF" }}
          >
            <ArrowForward />
          </Button>
        </Stack>
      </Tooltip>
    </Collapse>
  );
}
