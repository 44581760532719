import { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EventHint, captureException } from "@sentry/react";
import {
  useObjectTypeFilter,
  useSeverityFilter,
  useCurrentProject,
  getFilterActive,
  useSelector,
} from "hooks";
import type { IAnnotation } from "views/AnnotationTool/api";
import {
  reviewModes,
  workflowStatuses,
  severities,
} from "constants/imageReview";
import useGetReviewAnnotations from "views/image/Review/hooks/getReviewAnnotations";
import useImageNavigation from "views/image/Review/hooks/imageNavigation";
import * as api from "views/image/api";
import { updateMatchingAnnotation } from "views/image/utils";
import Annotations from "views/image/Annotations";
import * as utils from "./utils";
import { annotationActions, orderImagesByOptions } from "./constants";
import AnnotationReviewButtons from "./AnnotationReviewButtons";
import MachineReview from "./MachineReview";

const reviewModeId = reviewModes.MACHINE_OUTPUT;

export default function MachineReviewContainer() {
  const params = useParams();
  const imageId: number = parseInt(params.image);

  const [imageIds, setImageIds] = useState<number[]>([]);
  const [loadingImages, setLoadingImages] = useState(true);
  const [loadingAnnotations, setLoadingAnnotations] = useState(true);
  const [orderImagesBy, setOrderImagesBy] = useState(
    orderImagesByOptions.CONFIDENCE_ASC
  );
  const orderImagesByRef = useRef(orderImagesBy);
  const [annotations, setAnnotations] = useState<IAnnotation[]>([]);
  const [preloadedAnnotations, setPreloadedAnnotations] = useState<{
    imageId?: number;
    annotations?: IAnnotation[];
  }>({});

  const { objectTypeFilter } = useObjectTypeFilter();
  const { severityFilter } = useSeverityFilter();
  const currentProject = useCurrentProject();
  const { getAnnotations } = useGetReviewAnnotations();

  const [searchParams] = useSearchParams();
  const filterActive = getFilterActive(searchParams);
  const objectTypes = useSelector((state) => state.objects.objectTypes);

  const {
    currentIndex,
    navigateToNextImage,
    navigateToPrevImage,
    goToImageByIndex,
    goToImage,
  } = useImageNavigation({
    imageIds,
    currentImage: imageId,
  });

  const isDefect = (typeId) => {
    return objectTypes.find((o) => o.id === typeId)?.issue;
  };
  const hasAnyDefect = annotations.some((annotation) =>
    annotation.types.some(isDefect)
  );

  async function getImageIds(args: {
    goToFirstImage: boolean;
  }) {
    try {
      setLoadingImages(true);
      setImageIds([]);

      const imageIds = await utils.getImageIds({
        projectId: currentProject.id,
        orderBy: orderImagesBy,
      });

      if (imageIds.length) {
        setImageIds(imageIds);

        if (args.goToFirstImage) {
          goToImage(imageIds[0]);
        }
      } else {
        toast.error("No (more) images to review found");
      }
    } catch (error) {
      console.error(error);
      const exceptionHint: EventHint = {
        event_id: "SuperFalseReviewContainer.getImageIds.request",
        originalException: error,
        data: {
          projectId: currentProject.id,
        },
      };
      captureException(error, exceptionHint);
      toast.error("Failed to get images to review");
    }
    setLoadingImages(false);
  }

  useEffect(() => {
    getImageIds({
      // Only jump to first image when changing order, not on first load
      goToFirstImage: orderImagesBy !== orderImagesByRef.current,
    });
    orderImagesByRef.current = orderImagesBy;
  }, [filterActive, orderImagesBy]);

  function _getAnnotations(imageId) {
    return getAnnotations({
      imageId,
      reviewModeId,
      projectId: currentProject?.id,
      params: {
        severities: severityFilter.join(","),
        types: objectTypeFilter.join(","),
      },
    });
  }

  async function onImageLoad() {
    if (preloadedAnnotations?.imageId === imageId) {
      setAnnotations(preloadedAnnotations.annotations);
    } else {
      // Make sure to fetch current image annotation before preloading next image
      await refreshAnnotations();
    }
    await preloadAnnotations();
  }

  useEffect(() => {
    // We use currentIndex instead of imageId because we want to wait until imageIds has been loaded
    if (currentProject?.id && currentIndex > -1) {
      onImageLoad();
    }
  }, [currentProject?.id, currentIndex, objectTypeFilter, severityFilter]);

  async function refreshAnnotations() {
    setLoadingAnnotations(true);
    const annotations = await _getAnnotations(imageId);
    setAnnotations(annotations);
    setLoadingAnnotations(false);
  }

  async function preloadAnnotations() {
    const nextImageId = imageIds[currentIndex + 1];

    if (!nextImageId || preloadedAnnotations.imageId === nextImageId) {
      return;
    }
    const annotations = await _getAnnotations(nextImageId);

    setPreloadedAnnotations({
      imageId: nextImageId,
      annotations,
    });
  }

  async function updateAnnotations(args: {
    ids: number[];
    update: {
      workflow_status?: number;
      skyqraft_hidden?: boolean;
      severity?: number;
    };
    onSuccess: () => void;
  }) {
    const { ids, update, onSuccess } = args;

    await api.updateAnnotations({
      projectId: currentProject.id,
      ids,
      update,
      onSuccess,
    });
  }

  function onImageReviewed() {
    if (currentIndex === imageIds.length - 1) {
      toast.info(
        "That was the final image in this review. Refresh browser to see if there are more images to review"
      );
    }
    navigateToNextImage();
  }

  async function updateAllNonDefectAnnotations(args: {
    action: (typeof annotationActions)[keyof typeof annotationActions];
  }) {
    const { action } = args;

    const ids = annotations
      .filter((annotation) => !annotation.types.some(isDefect))
      .flatMap((annotation) => Number(annotation.type_id));

    if (ids.length) {
      await updateAnnotations({
        ids,
        update: utils.getUpdateAnnotationActionPayload(action),
        onSuccess: onImageReviewed,
      });
    }
  }

  async function updateAnnotation(args: {
    id: number;
    action: (typeof annotationActions)[keyof typeof annotationActions];
    severity?: (typeof severities)[keyof typeof severities];
  }) {
    const { id, action, severity } = args;

    const update = {
      ...utils.getUpdateAnnotationActionPayload(action),
      // Only include severity if it is set, otherwise it might remove the default value from getUpdateAnnotationActionPayload
      ...(severity && { severity }),
    };

    await updateAnnotations({
      ids: [id],
      update,
      onSuccess: () => {
        onAnnotationUpdate({
          typeId: id,
          ...update,
        });
      },
    });
  }

  function onAnnotationUpdate(args: {
    typeId: number;
    workflow_status?: number;
    type?: number;
    severity?: number;
    skyqraft_hidden?: boolean;
  }) {
    const updatedAnnotations = updateMatchingAnnotation({
      annotations,
      ...args,
    });

    const unreviewedAnnotationExists = updatedAnnotations.some((annotation) => {
      return annotation.workflow_status.some(
        (status) => status === workflowStatuses.REVIEW_REQUESTED
      );
    });

    if (unreviewedAnnotationExists) {
      setAnnotations(updatedAnnotations);
    } else {
      onImageReviewed();
    }
  }

  return (
    <>
      <MachineReview
        loading={loadingImages}
        loadingAnnotations={loadingAnnotations}
        currentIndex={currentIndex}
        imageIds={imageIds}
        setOrderImagesBy={setOrderImagesBy}
        navigateToNextImage={navigateToNextImage}
        navigateToPrevImage={navigateToPrevImage}
        goToImageByIndex={goToImageByIndex}
        updateAllNonDefectAnnotations={updateAllNonDefectAnnotations}
        refreshAnnotations={refreshAnnotations}
        hasAnyDefect={hasAnyDefect}
      />
      {Boolean(imageIds.length) && (
        <Annotations
          loading={loadingAnnotations || loadingImages}
          reviewMode={reviewModeId}
          imageId={imageId}
          annotations={annotations}
          onAnnotationTypeUpdate={onAnnotationUpdate}
          renderAnnotationReviewButtons={(typeId: number) => {
            const annotation = annotations.find((a) =>
              a.type_id.includes(typeId)
            );

            return (
              <AnnotationReviewButtons
                annotation={annotation}
                typeId={typeId}
                updateAnnotation={updateAnnotation}
                isDefect={annotation.types.some(isDefect)}
              />
            );
          }}
        />
      )}
    </>
  );
}
