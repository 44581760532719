import { useFlags } from "launchdarkly-react-client-sdk";
import NewDomainManager from "./NewDomainManager";

export default function FlagProtectedNewDomainManager() {
  // https://app.launchdarkly.com/projects/default/flags/new-domain-notification
  // https://app.launchdarkly.com/projects/default/flags/new-domain-redirect
  const { newDomainRedirect, newDomainNotification } = useFlags();

  if (!newDomainRedirect && !newDomainNotification) return null;
  return (
    <NewDomainManager
      notify={newDomainNotification}
      redirect={newDomainRedirect}
    />
  );
}
