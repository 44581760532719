import { useState } from "react";
import { Typography, Stack, Divider } from "@mui/material";
import translations from "translations";
import { useCurrentProject, useLanguage } from "hooks";
import KMLUploader from "components/KMLUploader";
import { IKeyPayload } from "components/KMLUploader/MetaSelector";
import CeleryKMLUploader from "components/CeleryKmlUploader";
import { useFlags } from "launchdarkly-react-client-sdk";
import { onApiSave, onCelerySave } from "./utils";

const initialKeys: IKeyPayload = {
  poleClientID: null,
  poleNote: null,
  powerlineClientID: null,
  powerlineFeedBay: null,
  powerlineTopologyColor: null,
  powerlineFeedStation: null,
};

export default function PowerlineUpload() {
  const { language } = useLanguage();
  const currentProject = useCurrentProject();
  const [keys, setKeys] = useState<IKeyPayload>(initialKeys);
  const { newKmlUploadPipeline } = useFlags();

  console.info(
    newKmlUploadPipeline
      ? "Using new KML upload pipeline"
      : "Using old KML upload pipeline"
  );

  return (
    <Stack spacing={2}>
      {newKmlUploadPipeline ? (
        <CeleryKMLUploader
          projectID={currentProject?.id ?? null}
          onSave={(geoJson) => onCelerySave(geoJson, currentProject.id)}
          keys={keys}
          setKeys={setKeys}
        />
      ) : (
        <KMLUploader
          projectID={currentProject?.id ?? null}
          onSave={(kmlData) => onApiSave(kmlData, keys, currentProject.id)}
          keys={keys}
          setKeys={setKeys}
        />
      )}
      <Divider sx={{ pt: 4 }} />
      <Typography sx={{ color: "text.secondary" }}>
        {
          translations.MissionLanding.OrderProgress.OrderMission.PowerlineUpload
            .Footnote[language]
        }
      </Typography>
    </Stack>
  );
}
