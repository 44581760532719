import React from "react";
import CMSRichTextContent from ".";
type Props = {
  content: any;
};
export default function CMSOrderedList({ content }: Props) {
  return (
    <ol>
      {content.map((c, i) => (
        <CMSRichTextContent key={i} content={c} />
      ))}
    </ol>
  );
}
