import { useState, useEffect } from "react";
import { authorizedGet } from "utils/request";

interface IImageStatus {
  mission: {
    id: number;
    name: string;
    active: boolean;
  };
  assignedImages: number;
  flaggedAssignedImages: number;
}

interface IProps {
  mission?: number;
}

/**
 * A React hook that collects the assignment status of assignments from backend API
 */
export function useImageStatus({ mission }: IProps = {}): [
  IImageStatus[],
  boolean
] {
  const [imageStatus, setImageStatus] = useState<IImageStatus[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // Find the endpoint based on the input
    let endpoint = "/assignments/images";

    // Send a request
    setLoading(true);
    authorizedGet<{ missions: IImageStatus[] }>(endpoint)
      .then((response) => {
        setLoading(false);
        setImageStatus(response.missions);
      })
      .catch((err) => {
        setLoading(false);
        setImageStatus([]);
        console.error("Failed to collect image stats", err);
      });
  }, [mission]);

  return [imageStatus, loading];
}
